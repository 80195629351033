import { Button, FormControl, InputLabel, MenuItem, Paper, Typography } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TurtleTabs from 'components/common/TurtleTabs'
import TitleSection from 'components/layout/TitleSection'
import { GradeScore, scoreGradeMap } from 'constants/mapping'
import { greyFontColor, mainColor } from 'constants/style'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import EvaluationContent from './EvaluationContent'
import TurtlePaper from 'components/common/TurtlePaper'
import { Box, Stack } from '@mui/system'
import MessageCard from 'components/layout/EmptyPageLayout'
import StackX from 'components/common/StackX'
import { Link } from 'react-router-dom'
import ScoreCard from './ScoreCard'
import {
  fetch_My사정관리스트,
  fetch_사정관_평가질문목록,
  fetch_사정관_평가질문별_점수목록,
  fetch_사정관_평가총점,
  getCommentAndScoreList,
  getEvaluatioinList,
  getEvaluationAvgAndGrade,
} from './service'
import { useRecoilValue } from 'recoil'
import userInfoStates from 'atoms/userInfoStates'
import MessageBox from 'components/common/MessageBox'
import ScoreTable from './ScoreTable'
import moment from 'moment'
import { AnyARecord } from 'dns'

type Props = {}

const K_비교과성적페이지 = (props: Props) => {
  const [evaluationList, setEvaluationList] = useState([])
  const userInfo = useRecoilValue(userInfoStates)

  useEffect(() => {
    fnSelectEvaluationList()
  }, [])

  const fnSelectEvaluationList = async () => {
    const _evaluationList = await getEvaluatioinList(userInfo.id)
    setEvaluationList(_evaluationList)
  }

  const [selectedEvaluationNo, setSelectedEvaluationNo] = React.useState('')
  const [avg, setAvg] = React.useState('')
  const [grade, setGrade] = React.useState('')
  const [commentList, setCommmentList] = React.useState<any>([])
  const [scoreList, serScoreList] = React.useState<any>([])
  const [questionScoreData, setQuestionScoreData] = React.useState<any>({})

  const { data: questions } = useQuery('사정관_평가질문목록', fetch_사정관_평가질문목록)

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedEvaluationNo(event.target.value as string)
    fnSelectEvaluation(event.target.value)
  }

  const fnSelectEvaluation = async (id: any) => {
    const _evaluation = await getEvaluationAvgAndGrade(id)
    if(_evaluation!=null){
      setAvg(_evaluation.avg.toFixed(2))
      setGrade(_evaluation.grade)
      fnSelectCommentAndScoreList(id)
    }
  }

  const fnSelectCommentAndScoreList = async (id: any) => {
    const _data = await getCommentAndScoreList(id)
    setCommmentList(_data[0])
    serScoreList(_data[1])
    questionScoreData.officerCommentList = _data[0]
    questionScoreData.officerSurveyList = _data[1]
    setQuestionScoreData(questionScoreData)
    console.log(_data[1])
  }

  return (
    <TitleSection title="비교과분석">
      <Stack direction={'row'} justifyContent={'end'} gap={2}>
        <Link to="/suSi/inputAndAnalysis/saJungDan">
          <Button variant="contained">사정관 평가받기</Button>
        </Link>
      </Stack>
      <Box sx={{ mt: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">평가 내역 선택</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedEvaluationNo}
            label="평가 내역 선택"
            onChange={handleChange}
          >
            {(evaluationList !=null && evaluationList.length > 0) ? (
              evaluationList.map((item: any, index: number) => (
                <MenuItem value={item.id}>{item.series + ' ' + moment(item.createDt).format('YYYY-MM-DD')}</MenuItem>
              ))
            ) : (
              <Typography>평가 내역이 존재하지 않습니다.</Typography>
            )}
          </Select>
        </FormControl>
      </Box>
      {avg == '' ? null : <ScoreCard averageScore={avg} grade={grade} />}
      <EvaluationContent questions={questions} questionScoreData={questionScoreData} />
    </TitleSection>
  )
}

export default K_비교과성적페이지

type PageContextParam = {
  evaluators: any
}
