import { Box, BoxProps, Typography } from '@mui/material'
import StackX from 'components/common/StackX'
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { gradeMap } from 'utils/util'

type Props = {}

function GradeChart2({
  data,
  ...boxProps
}: {
  data: any[]
} & BoxProps) {
  return (
    <Box position={'relative'} {...boxProps}>
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          height: '100%',
        }}
      >
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <ComposedChart data={data} barCategoryGap={8} style={{ stroke: '#fff', strokeWidth: 2 }}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" fontWeight={700} dy={10} stroke="0" />
            <YAxis
              stroke="0"
              domain={[1, 7]}
              width={40}
              textAnchor="left"
              dx={-20}
              tickFormatter={(x) => `${gradeMap[Number(x)] ?? ''}`}
              allowDecimals={false}
              tickCount={7}
              color="#9A9A9A"
              tickLine={false}
            />
            {/* <Legend /> */}
            <Bar dataKey="grade" fill="#1C4BC3" barSize={40} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Box>
  )
}

const BarRect = ({ title, grade, ...rest }: any & { title: string; grade: string }) => {
  const { fill, x, y, width, height } = rest
  return (
    <foreignObject x={x} y={y} width={width} height={height} style={{ backgroundColor: fill ?? '#1C4BC3' }}>
      <StackX
        px={'25px'}
        py={'21px'}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}
        borderBottom={'2px solid white'}
      >
        <Typography variant="H7_ExBold, 20, 28" sx={{ color: 'white' }}>
          {title}
        </Typography>
        <Typography variant="H7_ExBold, 20, 28" sx={{ color: 'white' }}>
          {grade}
        </Typography>
      </StackX>
    </foreignObject>
  )

  return <rect x={x} y={y} width={width} height={height} fill={fill ?? '#1C4BC3'} />
}

export default GradeChart2
