import { Box } from '@mui/system'
import { createColumnHelper } from '@tanstack/table-core'
import { memberAxios } from 'api/instance'
import FilterBox, { createRadioButtonInfos } from 'components/common/FilterBox'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import useSmoothLoading from 'hooks/useSmoothLoading'
import { useContext, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import BaseTable from '../common/BaseTable'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import { getSelected } from 'utils/util'
import ConditionMessage from '../common/ConditionMessage'
import InfoBadge from 'components/InfoBadge'
import { Typography } from '@mui/material'

type Props = {}
type Step9DataItem = {
  collegeCode: string
  collegeName: string
  interview: string
  line: string
  recommend: string
  recruitmentUnit: string
  recruitmentUnitId: number
  typeName: string
}

function Step9({}: Props) {
  const { stepState } = useContext(StepPagesContext)

  const [recommendState, setRecommendState] = useState<string>('전체')
  const [interviewState, setInterviewState] = useState<string>('전체')

  // const body2 = {
  //   interviewList: [0, 1, 2],
  //   recommendList: [0, 1],
  //   recruitmentUnitIdList: [6382, 6383, 6384, 6501, 6502, 6503, 6605, 6668, 7005, 7037],
  // }

  const recruitmentUnitIds = stepState[8].bundle.selected?.map((x: any) => x.recruitmentUnitId) ?? []

  const recommendButtonInfos = createRadioButtonInfos(['전체', '유', '무'], recommendState, setRecommendState)
  const interviewButtonInfos = createRadioButtonInfos(
    ['전체', '제시문 기반', '생기부 기반', '면접 없음'],
    interviewState,
    setInterviewState
  )

  const recommandParamMap: Record<string, number[]> = {
    전체: [0, 1],
    무: [0],
    유: [1],
  }

  const interviewParamMap: Record<string, number[]> = {
    '면접 없음': [0],
    '제시문 기반': [1],
    '생기부 기반': [2],
    전체: [0, 1, 2],
  }

  const body = {
    interviewList: interviewParamMap[interviewState],
    recommendList: recommandParamMap[recommendState],
    recruitmentUnitIdList: recruitmentUnitIds,
  }

  let { data, isFetching } = useQuery(['학종-조건검색', recommendState, interviewState], () => {
    return memberAxios.post('/pearlyd/sr/ci/list', body).then((res) => {
      console.log(res)
      return res.data.list as Step9DataItem[]
    })
  })

  data = data ?? []

  const isLoading = useSmoothLoading({ isLoading: isFetching })
  const [rowSelection, setRowSelection] = useBundledRowSelection(data, (dataList, id) => {
    return id
  })

  const columnHelper = createColumnHelper<Step9DataItem>()
  const columns = [
    columnHelper.accessor('recruitmentUnitId', {
      id: 'recruitmentUnitId',
    }),
    columnHelper.accessor('collegeName', {
      header: '대학명',
    }),
    columnHelper.accessor('typeName', {
      header: '전형명',
    }),
    columnHelper.accessor('recruitmentUnit', {
      id: 'recruitmentUnit',
      header: '모집단위',
    }),
    columnHelper.accessor('recommend', {
      header: '학교장 추천',
    }),
    columnHelper.accessor('interview', {
      header: '면접',
    }),
  ]
  const isAnyRowSelected = getSelected(rowSelection).length > 0

  return (
    <SubtitleSection title="조건검색">
      <Box
        mb={4}
        sx={{
          '& .filter-box': {
            borderBottom: 'none !important',
          },
          '& .filter-box:last-child': {
            borderBottom: '1px solid #C2C2C2 !important',
          },
          '& .head': {
            width: '130px',
          },
        }}
      >
        <FilterBox
          title="학교장 추천서"
          buttonInfos={recommendButtonInfos}
          infoBadge={
            <InfoBadge
              tooltipContent={
                <Box sx={{ width: '277px' }}>
                  <Typography variant="H4_Exbold, 24, 32">학교장 추천서?</Typography>
                  <Typography color="#656565" fontSize={'14px'} lineHeight={'24px'} fontWeight={700} mt={2}>
                    지역균형전형은 모두 다 학교장 추천 전형이에요. 따라서 학교장 추천서를 요구해요. 관심대학에 담기 전에
                    한 번 확인해보세요.
                  </Typography>
                </Box>
              }
            />
          }
        />
        <FilterBox
          title="면접"
          buttonInfos={interviewButtonInfos}
          infoBadge={
            <InfoBadge
              tooltipContent={
                <Box sx={{ width: '277px' }}>
                  <Typography variant="H4_Exbold, 24, 32">생기부 기반? 제시문 기반?</Typography>
                  <Typography color="#656565" fontSize={'14px'} lineHeight={'24px'} fontWeight={700} mt={2}>
                    면접에서 문제로 주는 질문의 유형을 의미해요. 제시문 기반은 말 그대로 제시문을 학생이 풀이 하는
                    것이고, 생기부 기반은 학생의 생활기록부 내용을 토대로 질문해요.
                  </Typography>
                </Box>
              }
            />
          }
        />
      </Box>

      <TurtlePaper>
        <BaseTable
          columns={columns}
          data={data}
          enableSelection
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnVisibility={{
            recruitmentUnitId: false,
          }}
          isLoading={isLoading}
          getRowId={(row) => {
            return String(row.recruitmentUnitId)
          }}
          searchInfo={{ label: '모집단위 검색', value: 'recruitmentUnit', mode: true }}
        />
      </TurtlePaper>
      <ConditionMessage show={!isAnyRowSelected} text="체크 박스를 선택해주십시오." />
      <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />
    </SubtitleSection>
  )
}

export default Step9
