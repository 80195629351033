import React, { useContext } from 'react'
import { Typography } from '@mui/material'
import { Box, Stack, Theme } from '@mui/system'
import TurtlePaper from 'components/common/TurtlePaper'
import TurtleTable2 from 'components/common/TurtleTable2'
import TurtleTabs from 'components/common/TurtleTabs'
import { TextBox, TitledItem, 수시전형_상세보기_테이블스타일 } from 'DetailModal/common'
import { CellContext, createColumnHelper } from '@tanstack/react-table'
import { SubjectDetailModalContext } from './K_상세보기_교과모달_내용'
import IconO from 'components/common/IconO'
import IconX from 'components/common/IconX'
import Center from 'components/common/Center'

type Props = {}

const 학년별_비율_테이블 = () => {
  const {
    recruitmentUnitInfo: { firstClassRate, twoClassRate, thirdClassRate },
  } = useContext(SubjectDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('firstClassRate', {
      header: '1학년 비율',
    }),
    columnHelper.accessor('twoClassRate', {
      header: '2학년 비율',
    }),
    columnHelper.accessor('thirdClassRate', {
      header: '3학년 비율',
    }),
  ]

  return (
    <TurtleTable2
      columns={columns}
      data={[
        {
          firstClassRate: `${Number(firstClassRate).toFixed(1)}%`,
          twoClassRate: `${Number(twoClassRate).toFixed(1)}%`,
          thirdClassRate: `${Number(thirdClassRate).toFixed(1)}%`,
        },
      ]}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const 등급별_환산점수_테이블 = () => {
  const {
    recruitmentUnitInfo: {
      typeTotalScore,
      firstGradeScore,
      twoGradeScore,
      thirdGradeScore,
      fourthGradeScore,
      fifthGradeScore,
      sixthGradeScore,
      seventhGradeScore,
      eighthGradeScore,
      ninthGradeScore,
    },
  } = useContext(SubjectDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const cell = (info: CellContext<any, any>) => Number(info.renderValue()).toFixed(0)

  const columns = [
    columnHelper.accessor('typeTotalScore', {
      header: '전형총점',
    }),
    columnHelper.accessor('firstGradeScore', {
      header: '1등급',
      cell,
    }),
    columnHelper.accessor('twoGradeScore', {
      header: '2등급',
      cell,
    }),
    columnHelper.accessor('thirdGradeScore', {
      header: '3등급',
      cell,
    }),
    columnHelper.accessor('fourthGradeScore', {
      header: '4등급',
      cell,
    }),
    columnHelper.accessor('fifthGradeScore', {
      header: '5등급',
      cell,
    }),
    columnHelper.accessor('sixthGradeScore', {
      header: '6등급',
      cell,
    }),
    columnHelper.accessor('seventhGradeScore', {
      header: '7등급',
      cell,
    }),
    columnHelper.accessor('eighthGradeScore', {
      header: '8등급',
      cell,
    }),
    columnHelper.accessor('ninthGradeScore', {
      header: '9등급',
      cell,
    }),
  ]

  return (
    <TurtleTable2
      columns={columns}
      data={[
        {
          typeTotalScore,
          firstGradeScore,
          twoGradeScore,
          thirdGradeScore,
          fourthGradeScore,
          fifthGradeScore,
          sixthGradeScore,
          seventhGradeScore,
          eighthGradeScore,
          ninthGradeScore,
        },
      ]}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const 반영_교과목_테이블 = () => {
  const {
    recruitmentUnitInfo: {
      reflectKorean,
      reflectMath,
      reflectEnglish,
      reflectScience,
      reflectSociety,
      reflectHistory,
      reflectEtc,
      courseSubjectYn,
      usefulGuideline,
    },
  } = useContext(SubjectDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const renderSubjectReflect = (info: CellContext<any, any>) => {
    return <Center>{info.renderValue() === '1' ? <IconO /> : <IconX />}</Center>
  }

  const columns = [
    columnHelper.accessor('reflectKorean', {
      header: '국어',
      cell: renderSubjectReflect,
    }),
    columnHelper.accessor('reflectMath', {
      header: '수학',
      cell: renderSubjectReflect,
    }),
    columnHelper.accessor('reflectEnglish', {
      header: '영어',
      cell: renderSubjectReflect,
    }),
    columnHelper.accessor('reflectScience', {
      header: '과학',
      cell: renderSubjectReflect,
    }),
    columnHelper.accessor('reflectSociety', {
      header: '사회',
      cell: renderSubjectReflect,
    }),
    columnHelper.accessor('reflectHistory', {
      header: '한국사',
      cell: renderSubjectReflect,
    }),
    columnHelper.accessor('reflectEtc', {
      header: '기타',
      cell: renderSubjectReflect,
    }),
    columnHelper.accessor('courseSubjectYn', {
      header: '진로선택과목',
      cell: (info) => (info.renderValue() === '1' ? '반영' : '미반영'),
    }),
    columnHelper.accessor('usefulGuideline', {
      header: '학생부 활용지표',
    }),
  ]

  return (
    <TurtleTable2
      columns={columns}
      data={[
        {
          reflectKorean,
          reflectMath,
          reflectEnglish,
          reflectScience,
          reflectSociety,
          reflectHistory,
          reflectEtc,
          courseSubjectYn,
          usefulGuideline,
        },
      ]}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const 비교과_반영방법_테이블 = () => {
  const {
    recruitmentUnitInfo: { nonSubjectRatio, attendance, volunteer, lifeRecord },
  } = useContext(SubjectDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const cell = (info: CellContext<any, any>) => <Center>{info.renderValue() === '1' ? <IconO /> : <IconX />}</Center>

  const columns = [
    columnHelper.accessor('nonSubjectRatio', {
      header: '비교과 반영비율',
      cell: (info) => Number(info.renderValue()).toFixed(0) + '%',
    }),
    columnHelper.accessor('attendance', {
      header: '출결',
      cell,
    }),
    columnHelper.accessor('volunteer', {
      header: '봉사활동',
      cell,
    }),
    columnHelper.accessor('lifeRecord', {
      header: '정성평가',
      cell,
    }),
  ]

  return (
    <TurtleTable2
      columns={columns}
      data={[
        {
          nonSubjectRatio,
          attendance,
          volunteer,
          lifeRecord,
        },
      ]}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const K_상세보기_성적반영방법탭 = (props: Props) => {
  const {
    recruitmentUnitInfo: { additionalScoreText },
  } = useContext(SubjectDetailModalContext)

  return (
    <>
      <TitledItem title="학년별 비율">
        <학년별_비율_테이블 />
      </TitledItem>
      <TitledItem title="등급별 환산점수">
        <등급별_환산점수_테이블 />
      </TitledItem>

      <TitledItem title="반영 교과목">
        <반영_교과목_테이블 />
      </TitledItem>
      <TitledItem title="비교과 반영방법">
        <비교과_반영방법_테이블 />
      </TitledItem>
      <TitledItem title="가산점">
        <TextBox>{additionalScoreText}</TextBox>
      </TitledItem>
    </>
  )
}

export default K_상세보기_성적반영방법탭
