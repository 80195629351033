import { Box, ButtonBase, Stack, Typography } from '@mui/material'
import userInfoStates from 'atoms/userInfoStates'
import Center from 'components/common/Center'
import StackX from 'components/common/StackX'
import StackY from 'components/common/StackY'
import { MouseEventHandler } from 'react'
import { useRecoilState } from 'recoil'
import { BsFillPersonFill } from 'react-icons/bs'
import { mainColor } from 'constants/style'

type Props = {}

const OfficerProfileCard = ({
  name,
  label,
  imageUrl,
  readyCount,
  onButtonClick,
  isSelfEvaluate = false,
}: {
  name: string
  label: string
  imageUrl?: string
  readyCount?: number
  onButtonClick?: MouseEventHandler
  isSelfEvaluate?: boolean
}) => {
  return (
    <Stack
      borderRadius={'12px'}
      boxShadow={'0px 0px 20px 0px rgba(0, 0, 0, 0.08)'}
      sx={{ width: '240px' }}
      direction={'column'}
      justifyContent={'space-between'}
    >
      {isSelfEvaluate ? (
        <Center
          alignSelf={'center'}
          mt={2}
          boxShadow={'0px 0px 20px 0px rgba(0, 0, 0, 0.08)'}
          borderRadius={'50%'}
          width={100}
          height={100}
          sx={{ float: 'right' }}
        >
          <BsFillPersonFill fontSize={'77px'} color={mainColor} />
        </Center>
      ) : (
        <Box
          alignSelf={'center'}
          mt={2}
          boxShadow={'0px 0px 20px 0px rgba(0, 0, 0, 0.08)'}
          component={'img'}
          src={imageUrl}
          borderRadius={'50%'}
          width={100}
          height={100}
          sx={{ float: 'right' }}
        />
      )}
      <Typography mt={0.5} variant="H3_Bold, 28, 36" whiteSpace={'nowrap'} alignSelf={'center'}>
        {name}
      </Typography>
      <Typography
        whiteSpace={'nowrap'}
        variant="D3_bold, 16, 24"
        sx={{ color: '#9A9A9A' }}
        alignSelf={'end'}
        mt={1}
        mr={2}
      >
        {isSelfEvaluate ? '' : `대기자 ${readyCount}명`}
      </Typography>
      <Box mt={'15px'} px="21px" height={'100px'} position={'relative'} overflow={'auto'}>
        <Typography variant="H9_regular, 20, 28" sx={{ wordBreak: 'keep-all' }} fontSize={'18px'}>
          {label}
        </Typography>
      </Box>
      <Box pb="19px" height={'69px'}>
        <Box sx={{ height: '1px', border: '1px solid #ff5600' }}></Box>
        <Center mt="20px">
          <ButtonBase sx={{}} onClick={onButtonClick}>
            <Typography variant="H8_bold, 20, 28" sx={{ color: '#ff5600' }}>
              {isSelfEvaluate ? '평가하러 가기' : '1:1 평가받기'}
            </Typography>
          </ButtonBase>
        </Center>
      </Box>
    </Stack>
  )
}
export default OfficerProfileCard
