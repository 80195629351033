import { Box, Stack, Typography } from '@mui/material'
import Center from 'components/common/Center'

export default function K_거북스쿨사용법_페이지() {
  return (
    <Box mb={10}>
      <Center>
        <Box width="969px">
          <Typography variant="H2_Bold, 36, 42" alignSelf={'start'}>
            거북스쿨 사용법
          </Typography>
        </Box>
      </Center>

      <Stack direction={'column'} mt="40px">
        <Center>
          <img src="/asset/image/거북스쿨_사용법.png" style={{ width: '969px', height: '2009px' }} />
        </Center>
      </Stack>
    </Box>
  )
}
