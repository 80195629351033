import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'
import { muiTheme } from 'theme'
import './App.scss'
import RootRoutes from './routes/root'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
/* eslint-disable */
if (process.env.NODE_ENV === 'production') {
  console.log = function no_console() {}
}
//console.warn = function no_console() {}

//localStorage.clear()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ThemeProvider theme={muiTheme}>
          <RecoilNexus />
          <ReactQueryDevtools initialIsOpen={false} />
          <RootRoutes />
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  )
}

export default App
