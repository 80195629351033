import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import TurtlePaper from 'components/common/TurtlePaper'
import { greyFontColor, mainColor } from 'constants/style'

export const gradeMap = {
  'A+': '의치한약수',
  A: '서울상위권',
  'B+': '서울중위권',
  B: '서울하위건, 경기상위권',
  'C+': '경기중위권, 지방상위권',
  C: '경기하위권, 지방중하위권',
  D: '낙제',
}

export default function ScoreCard({ averageScore, grade }: { averageScore: string; grade: string }) {
  const gradeList = Object.keys(gradeMap).map((grade) => (
    <div
      key={grade}
      style={{
        display: 'flex',
        padding: '6px 0px',
        color: greyFontColor,
        fontSize: '16px',
        lineHeight: '29px',
      }}
    >
      <Typography style={{ width: 32 }}>{grade}</Typography>
      <Typography>:</Typography>
      <Typography style={{ width: 260, textAlign: 'right' }}>{gradeMap[grade as keyof typeof gradeMap]}</Typography>
    </div>
  ))

  return (
    <TurtlePaper
      sx={{
        width: 'fit-content',
        padding: '42px 24px',
        marginTop: '40px',
      }}
    >
      <Stack direction={'row'} gap={3}>
        <div>
          <Box>
            <Typography variant="H8_bold, 20, 28">
              사정관 평가 점수 전체 평균
              <div
                style={{
                  marginTop: 5,
                  backgroundColor: 'black',
                  height: 2,
                  width: '100%',
                }}
              />
            </Typography>
            <Typography
              variant="H1_Exbold, 36, 54"
              style={{
                color: mainColor,
              }}
            >
              {averageScore}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography
              variant="H8_bold, 20, 28"
              style={{
                // fontSize: 20,
                // fontWeight: 700,
                marginTop: 30,
                textAlign: 'left',
              }}
            >
              비교과 등급
              <div
                style={{
                  marginTop: 5,
                  backgroundColor: 'black',
                  height: 2,
                  width: '100%',
                }}
              />
            </Typography>
            <Typography
              variant="H1_Exbold, 36, 54"
              style={{
                color: mainColor,
              }}
            >
              {grade}
            </Typography>
          </Box>
        </div>
        <div>{gradeList}</div>
      </Stack>
    </TurtlePaper>
  )
}
