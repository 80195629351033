import { useContext } from 'react'

import { K_상세보기_타대학_동일계열차트공통 } from 'DetailModal/common'
import { StudentDetailModalContext } from './K_상세보기_학종모달_내용'

type Props = {}

const K_상세보기_타대학_동일계열 = () => {
  const { predictsData, reflectData } = useContext(StudentDetailModalContext)

  if (!predictsData) return null
  const grade = predictsData.studentRecordComparativeDepartment.myGyoGwaScore

  const chartData = predictsData.predictionList.map((x) => ({
    grade: Number(x.cut70),
    collegeName: x.collegeName,
    major: x.recruitmentUnit,
  }))

  return <K_상세보기_타대학_동일계열차트공통 data={chartData} grade={Number(grade)} />
}

export default K_상세보기_타대학_동일계열
