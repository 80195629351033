import use교과_이용가능_체크 from 'hooks/use교과_이용가능_체크'
import StepPages from '../common/StepPages'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'

type Props = {}

function K_교과_페이지({}: Props) {
  // const { isAuthorizationLoading } = use교과_이용가능_체크()
  // if (isAuthorizationLoading) return <></>

  const pages = [
    {
      label: (
        <>
          자격 및<br />
          대학선택
        </>
      ),
      page: <Step1 />,
    },
    {
      label: '최저등급',
      page: <Step2 />,
    },
    {
      label: '비교과',
      page: <Step3 />,
    },
    {
      label: '모집단위',
      page: <Step4 />,
    },
    {
      label: '면접',
      page: <Step5 />,
    },
    {
      label: '전형일자 확인',
      page: <Step6 />,
    },
  ]

  return <StepPages title="교과" pages={pages} px={2} />
}

export default K_교과_페이지
