import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { createColumnHelper } from '@tanstack/react-table'
import { memberAxios } from 'api/instance'
import FilterBox, { createRadioButtonInfos } from 'components/common/FilterBox'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import useSmoothLoading from 'hooks/useSmoothLoading'
import { useContext, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import BaseTable, { baseTableStyle2 } from '../common/BaseTable'
import ConditionMessage from '../common/ConditionMessage'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import { getSelected } from 'utils/util'
import InfoBadge from 'components/InfoBadge'

type EarlydSubjectInterview = {
  collegeName: string
  interviewDate: string
  interviewRate: string
  interviewTime: string
  interviewTypeName: string
  recruitmentUnit: string
  recruitmentUnitId: number
  selectionModel: string
  typeName: string
}

const Step5 = () => {
  const { stepState, pageSize } = useContext(StepPagesContext)

  const recruitmentUnitIds = stepState[4].bundle.selected

  const [filter, setFilter] = useState<'전체' | '생기부 기반' | '제시문 기반' | '면접 없음'>('전체')

  const { data, isFetching } = useQuery(['step5-교과-면접', recruitmentUnitIds?.join('$')], () => {
    return memberAxios
      .post('/pearlyd/subject/interview/colleges', {
        interviewList: [0, 1, 2],
        recruitmentUnitIdList: recruitmentUnitIds,
      })
      .then((x) => {
        return x.data.earlydSubjectInterviewList as EarlydSubjectInterview[]
      })
  })

  const isLoadingSmooth = useSmoothLoading({ isLoading: isFetching })

  const dataList = useMemo(() => {
    return (data ?? []).filter(({ interviewTypeName }) => {
      if (filter === '제시문 기반' && interviewTypeName.includes('제시문')) return true
      if (filter === '생기부 기반' && interviewTypeName.includes('생기부')) return true
      if (filter === '면접 없음' && interviewTypeName.includes('없음')) return true
      if (filter === '전체') return true
      return false
    })
  }, [filter, data])

  const [rowSelection, setRowSelection] = useBundledRowSelection(dataList, (_, identifier) => {
    return Number(identifier)
  })

  if (isFetching) {
    return (
      <TurtlePaper>
        <Skeleton variant="rounded" sx={{ width: '100%', height: '500px', bgcolor: 'grey.100' }} />
      </TurtlePaper>
    )
  }

  const columnHelper = createColumnHelper<EarlydSubjectInterview>()

  const columns = [
    columnHelper.accessor('recruitmentUnitId', {
      id: 'recruitmentUnitId',
    }),
    columnHelper.accessor('collegeName', {
      header: () => '대학명',
      minSize: 150,
    }),
    columnHelper.accessor('typeName', {
      header: () => '전형명',
    }),
    columnHelper.accessor('recruitmentUnit', {
      header: () => '모집단위',
    }),
    columnHelper.accessor('interviewRate', {
      header: () => '면접 비율',
    }),
    columnHelper.accessor('interviewTypeName', {
      header: () => '면접방식',
    }),
    columnHelper.accessor('selectionModel', {
      header: () => '선발유형',
    }),
  ]

  const buttonInfos = createRadioButtonInfos(['전체', '생기부 기반', '제시문 기반', '면접 없음'], filter, setFilter)

  const isAnyRowSelected = getSelected(rowSelection).length > 0

  return (
    <SubtitleSection
      titleSection={
        <Stack mb={3} direction={'row'}>
          <Typography variant="H4_Exbold, 24, 32" fontWeight={700}>
            면접
          </Typography>
          <InfoBadge
            tooltipContent={
              <Box width={'264px'}>
                <Typography variant="H4_Exbold, 24, 32">생기부 기반? 제시문 기반?</Typography>
                <Typography fontSize={'14px'} lineHeight={'24px'} fontWeight={700} mt={1} color={'#656565'}>
                  면접에서 문제로 주는 질문의 유형을 의미해요. 제시문 기반은 말 그대로 제시문을 학생이 풀이 하는 것이고,
                  생기부 기반은 학생의 생활기록부 내용을 토대로 질문해요.
                </Typography>
              </Box>
            }
            placement="right"
            ml={2}
          />
        </Stack>
      }
    >
      <FilterBox title="면접방법" buttonInfos={buttonInfos} sx={{ mb: 5 }} />
      <TurtlePaper>
        <BaseTable
          isLoading={isLoadingSmooth}
          enablePagination
          pageSize={pageSize}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          tableStyle={baseTableStyle2}
          columns={columns}
          data={dataList}
          enableSelection
          columnVisibility={{ recruitmentUnitId: false }}
          getRowId={(row) => String(row.recruitmentUnitId)}
          searchInfo = {{label:"모집단위 검색",value:"recruitmentUnit",mode:true}}
        />
      </TurtlePaper>
      <ConditionMessage show={!isAnyRowSelected} text="체크 박스를 선택해주십시오." />
      <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />
    </SubtitleSection>
  )
}

export default Step5
