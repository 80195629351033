import { Box, BoxProps, Stack, Typography } from '@mui/material'
import StackX from 'components/common/StackX'
import React from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'

type Props = {}

const WithRechartResponsive = ({
  responsiveWidth,
  responsiveHeight,
  ...boxProps
}: BoxProps & { responsiveWidth?: number; responsiveHeight?: number }) => {
  return (
    <Box position={'relative'} {...boxProps}>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          height: '100%',
          userSelect: 'none',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            width: '11px',
            height: '14px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#e4e4e4',
            height: '14px',
            borderRadius: 2,
          },
          '&::-webkit-scrollbar-track:hover': {
            border: '1px solid #e0e0e0',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#9e9e9e',
            borderRadius: 2,
            height: 1,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#757575',
          },
        }}
      >
        <ResponsiveContainer width={responsiveWidth ?? '100%'} height={responsiveHeight ?? '100%'}>
          {boxProps.children as any}
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default WithRechartResponsive
