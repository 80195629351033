import React, { useContext } from 'react'
import { Typography } from '@mui/material'
import { Box, Stack, Theme } from '@mui/system'
import TurtlePaper from 'components/common/TurtlePaper'
import TurtleTable2 from 'components/common/TurtleTable2'
import TurtleTabs from 'components/common/TurtleTabs'
import { TextBox, TitledItem, 수시전형_상세보기_테이블스타일 } from 'DetailModal/common'
import { createColumnHelper } from '@tanstack/react-table'
import { SubjectDetailModalContext } from './K_상세보기_교과모달_내용'
import { str2Int } from 'utils/util'
import _ from 'lodash'

type Props = {}

const 기본정보테이블 = () => {
  const {
    recruitmentUnitInfo: { region, competitionRate, basicTypeAdmission, centerType, typeName, recruitmentNum },
  } = useContext(SubjectDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('region', {
      header: '지역',
    }),
    columnHelper.accessor('competitionRate', {
      header: '작년 경쟁률',
    }),
    columnHelper.accessor('basicTypeAdmission', {
      header: '전형유형',
    }),
    columnHelper.accessor('centerType', {
      header: '기본전형',
    }),
    columnHelper.accessor('typeName', {
      header: '전형명',
    }),
    columnHelper.accessor('recruitmentNum', {
      header: '모집인원',
    }),
  ]

  return (
    <TurtleTable2
      columns={columns}
      data={[
        {
          region,
          competitionRate,
          basicTypeAdmission,
          centerType,
          typeName,
          recruitmentNum,
        },
      ]}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const 전형방법테이블 = () => {
  const {
    recruitmentUnitInfo: {
      selectionModel,
      subjectScore,
      subjectRate,
      paperScore,
      paperRate,
      interviewScore,
      interviewRate,
      typeTotalScore,
      stepTwoInterviewRate,
      stepTwoInterviewScore,
      stepTwoPaperRate,
      stepTwoPaperScore,
      selectionRate,
    },
  } = useContext(SubjectDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  let columns: any = []
  let data: any = []

  if (selectionModel === '일괄합산') {
    columns = [
      columnHelper.accessor('selectionModel', {
        header: '선발모형',
      }),
      columnHelper.accessor('학생부(교과)', {
        header: '학생부(교과)',
      }),
      columnHelper.accessor('서류평가', {
        header: '서류평가',
      }),
      columnHelper.accessor('면접평가', {
        header: '면접평가',
      }),
      columnHelper.accessor('합계', {
        header: '합계',
      }),
    ]

    data = [
      {
        selectionModel,
        '학생부(교과)': `${str2Int(subjectRate)}%`,
        서류평가: `${str2Int(paperRate)}%`,
        면접평가: `${str2Int(interviewRate)}%`,
        합계: `100%`,
      },
    ]
  } else {
    columns = [
      columnHelper.accessor('selecti선발모형onModel', {
        header: '선발모형',
      }),
      columnHelper.accessor('학생부(교과)', {
        header: '학생부(교과)',
      }),
      columnHelper.accessor('서류평가', {
        header: '서류평가',
      }),
      columnHelper.accessor('면접평가', {
        header: '면접평가',
      }),
      columnHelper.accessor('합계', {
        header: '합계',
      }),
      columnHelper.accessor('selectionRate', {
        header: '선발배수',
      }),
    ]
    data = [
      {
        선발모형: '1 단계',
        '학생부(교과)': `${str2Int(subjectRate)}%`,
        서류평가: `${str2Int(paperRate)}%`,
        면접평가: `${str2Int(interviewRate)}%`,
        합계: `100%`,
        selectionRate: '-',
      },
      {
        선발모형: '2 단계',
        '학생부(교과)': '-', // CHECK : -로 고정?
        서류평가: `${str2Int(stepTwoPaperRate)}%`,
        면접평가: `${str2Int(stepTwoInterviewRate)}`,
        합계: `100%`,
        selectionRate,
      },
    ]
  }

  return (
    <TurtleTable2
      columns={columns}
      sx={{ '& td:first-of-type': { bgcolor: '#e9e9e9' } }}
      data={data}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const 면접평가테이블 = () => {
  const {
    recruitmentUnitInfo: { interviewTypeName, interviewDate, interviewTime, interviewPaper },
  } = useContext(SubjectDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('interviewTypeName', {
      header: '면접유형',
    }),
    columnHelper.accessor('interviewDate', {
      header: '면접날짜',
    }),
    columnHelper.accessor('interviewTime', {
      header: '면접시간',
    }),
    columnHelper.accessor('interviewPaper', {
      header: '면접시 서류종류',
    }),
  ]

  return (
    <TurtleTable2
      columns={columns}
      data={[{ interviewTypeName, interviewDate, interviewTime, interviewPaper }]}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const K_상세보기_전형정보탭 = (props: Props) => {
  const {
    recruitmentUnitInfo: { supportQualificationText, interviewText, lowestText },
  } = useContext(SubjectDetailModalContext)

  const multilineText = _.flatMap(supportQualificationText.split('\\n'), (item) => [item, <br />]).map((x, index) => (
    <span key={index}>{x}</span>
  ))

  return (
    <>
      <TitledItem title="기본정보">
        <기본정보테이블 />
      </TitledItem>
      <TitledItem title="지원자격">
        <TextBox>{multilineText}</TextBox>
      </TitledItem>
      <TitledItem title="전형방법">
        <전형방법테이블 />
      </TitledItem>
      <TitledItem title="최저등급">
        <TextBox>{lowestText === '-' ? '없음' : lowestText}</TextBox>
      </TitledItem>
      <TitledItem title="면접평가">
        <면접평가테이블 />
      </TitledItem>
      <TitledItem title="면접안내">
        <TextBox>{interviewText}</TextBox>
      </TitledItem>
    </>
  )
}

export default K_상세보기_전형정보탭
