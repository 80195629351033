import { placeholder } from '@babel/types'
import { TextareaAutosize, Typography } from '@mui/material'
import Box from '@mui/material/Box'

const CommentItem = ({
  title,
  comment,
  placeholder = '',
  disabled = false,
}: {
  title: string
  comment: string
  placeholder?: string
  disabled?: boolean
}) => {
  return (
    <Box mt={4}>
      <Typography variant="H4_Exbold, 24, 32">{title}</Typography>
      <Typography variant="D4_regular, 16, 24" lineHeight={'24px'}>
        <TextareaAutosize
          style={{ width: '100%', marginTop: '16px', border: '1px solid #bbb', padding: '11px 15px', resize: 'none' }}
          minRows={3}
          placeholder={placeholder}
          value={comment}
          disabled={disabled}
        />
      </Typography>
    </Box>
  )
}

export default CommentItem
