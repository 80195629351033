import { Box, display, useTheme } from '@mui/system'
import React, { Component, PureComponent, useEffect, useReducer, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import './Step1Chart.scss'

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
  Brush,
  Scatter,
  Label,
} from 'recharts'
import { reverseGradeValue } from 'utils/util'
import { Props } from 'recharts/types/container/Surface'
import { Typography } from '@mui/material'

type Step1ChartData = {
  collegeName: string
  range: number[]
  myGrade: number
  typeName: string
  reflectRecruitmentUnit: string
}

class CustomizedReferenceText extends PureComponent {
  render() {
    const {
      stroke,
      payload,
      viewBox: { x, y },
    } = this.props as any

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject className="node" x="0" y="0" width="70" height="50">
          <Box
            className="x-axes-tick-label"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'start',
              fontSize: 12,
              backgroundColor: '#D8145C',
              paddingBottom: '8px',
              paddingLeft: '10px',
              borderBottomRightRadius: '20px',
            }}
          >
            <span style={{ marginTop: 4, fontWeight: 700, color: '#fff' }}>내 성적</span>
          </Box>
        </foreignObject>
      </g>
    )
  }
}

type Step1Props = {
  data: Step1ChartData[]
  fnClickChartX: (collegeName: string) => void
  fnSearchSelectedUniv: (title: string) => boolean
}

export default function Step1Chart({ data, fnClickChartX, fnSearchSelectedUniv }: Step1Props) {
  const ref = useRef<HTMLDivElement | null>(null)

  let preprocessed = data
    .map((item, index) => ({
      ...item,
      index,
      range: [Number(reverseGradeValue(item.range[1]).toFixed(2)), Number(reverseGradeValue(item.range[0]).toFixed(2))],
      xLabelData: `${item.collegeName}/${item.typeName}/${item.reflectRecruitmentUnit}`,
    }))
    .filter((item) => {
      return (
        item.range.length === 2 &&
        !isNaN(item.range[0]) &&
        !isNaN(item.range[1]) &&
        item.range[0] <= 9 &&
        item.range[0] >= 1 &&
        item.range[1] <= 9 &&
        item.range[1] >= 1 &&
        item.range[0] < item.range[1] &&
        item.range[1] - item.range[0] > 1e-3
      )
    })

  const myGrade = data[0]?.myGrade ?? 0

  const barSize = 120
  return (
    <Box
      height={'91%'}
      sx={{
        userSelect: 'none',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          width: '11px',
          height: '14px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#e4e4e4',
          height: '14px',
          borderRadius: 2,
        },
        '&::-webkit-scrollbar-track:hover': {
          border: '1px solid #e0e0e0',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#9e9e9e',
          borderRadius: 2,
          height: 1,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#757575',
        },
      }}
    >
      <ResponsiveContainer width={Math.max(1188, barSize * preprocessed.length)} height={'100%'} ref={ref}>
        <ComposedChart data={preprocessed} barCategoryGap={'20%'} barSize={71} margin={{ right: 10, bottom: 10 }}>
          <XAxis
            type="category"
            tickLine={false}
            height={100}
            dataKey="xLabelData"
            tick={<TickX fnClickChartX={fnClickChartX} fnSearchSelectedUniv={fnSearchSelectedUniv} />}
            interval={0}
          />
          <YAxis
            interval={0}
            type="number"
            tickCount={9}
            axisLine={false}
            tickLine={false}
            domain={[1, 9]}
            tick={<TickY />}
          />
          <CartesianGrid vertical={false} color="#D9D9D9" z={1} />
          <Bar dataKey="range" fill="#103abf" name={'최초컷~추합컷'} />
          <Legend
            fill="#ff0000"
            verticalAlign="top"
            layout="horizontal"
            align="right"
            wrapperStyle={{
              paddingLeft: '10px',
              paddingBottom: '30px',
              fontSize: 14,
              fontWeight: 700,
            }}
          />
          <Tooltip
            formatter={([first, second]: [number, number]) => {
              return `${reverseGradeValue(second).toFixed(2)} ~ ${reverseGradeValue(first).toFixed(2)}`
            }}
          />
          <defs>
            <filter x="-0.15" y="-0.1" width="1.3" height="1.1" id="solid">
              <feFlood floodColor="#D8145C" result="bg" />
              <feMerge>
                <feMergeNode in="bg" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <ReferenceLine y={reverseGradeValue(myGrade)} stroke="#D8145C" strokeWidth={2}>
            <Label
              value="내 위치"
              offset={6}
              position="insideTopLeft"
              fill="#ffffff"
              filter="url(#solid)"
              fontSize={11}
              fontWeight={700}
            />
          </ReferenceLine>
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}

class TickY extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props as {
      x: number
      y: number
      stroke: string
      payload: any
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text fontSize={12} fill="#9A9A9A" textAnchor="end" fontWeight={400} fontFamily="Roboto">
          {reverseGradeValue(payload.value) + '등급'}
        </text>
      </g>
    )
  }
}

class TickX extends Component<any, any> {
  render() {
    const { x, y, stroke, payload, fnClickChartX, fnSearchSelectedUniv } = this.props as {
      x: number
      y: number
      stroke: string
      payload: any
      fnClickChartX: (collegeName: string) => void
      fnSearchSelectedUniv: (title: string) => boolean
    }
    const [title, subTitle, reflectRecruitmentUnit]: [string, string, string] = payload.value.split('/')
    const width = 110
    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject className="node" x={-width / 2} y="0" width={width} height="100%">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
              cursor: 'pointer',
              height: '100px',
            }}
            onClick={() => fnClickChartX(title)}
            className="hakjongChartTitle"
          >
            <p style={{ fontWeight: 800, color: fnSearchSelectedUniv(title) == true ? 'blue' : 'black' }}>{title}</p>
            <p
              style={{
                textAlign: 'center',
                marginTop: 4,
                fontWeight: 700,
                //color: '#616161',
                wordBreak: 'keep-all',
                color: fnSearchSelectedUniv(title) == true ? 'blue' : 'black',
              }}
            >
              {subTitle}
            </p>
            <p
              style={{
                marginTop: 4,
                fontWeight: 700,
                color: fnSearchSelectedUniv(title) == true ? 'blue' : 'black',
                //color: '#9e9e9e',
              }}
            >
              {reflectRecruitmentUnit}
            </p>
          </div>
        </foreignObject>
      </g>
    )
  }
}
