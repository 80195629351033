import { Box, Typography } from '@mui/material'
import { createColumnHelper } from '@tanstack/react-table'
import { memberAxios } from 'api/instance'
import userInfoStates from 'atoms/userInfoStates'
import RiskIndicator from 'components/common/RiskIndicator'
import RiskLabel from 'components/common/RiskLabel'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import useSmoothLoading from 'hooks/useSmoothLoading'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import BaseTable from '../common/BaseTable'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import { Step6DataItem } from './Step6'
import { Step7학종_DataItem } from './Step7'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import { getSelected } from 'utils/util'
import { Setter } from 'types/utilType'
import ConditionMessage from '../common/ConditionMessage'

type Props = {}

const Step8 = (props: Props) => {
  const userInfo = useRecoilValue(userInfoStates)
  const is문과 = userInfo.major === 'LiberalArts'

  const [numRowSelected, setNumRowSelected] = useState(0)

  const isAnyRowSelected = numRowSelected > 0

  return (
    <>
      <SubtitleSection title="3개 부문 진단">
        <Typography variant="H7_ExBold, 20, 28">종합 진단</Typography>
        <TurtlePaper mt={'10px'}>
          <종합_진단_테이블 setNumRowSelected={setNumRowSelected} />
        </TurtlePaper>
        <Typography variant="H7_ExBold, 20, 28" mt={5}>
          교과 진단
        </Typography>
        <TurtlePaper mt={'10px'}>
          <교과_테이블 />
        </TurtlePaper>
        <Typography variant="H7_ExBold, 20, 28" mt={5}>
          비교과 진단
        </Typography>
        <TurtlePaper mt={'10px'}>
          <비교과_테이블 />
        </TurtlePaper>
        {/* 
        <Typography variant="H7_ExBold, 20, 28" mt={5}>
          필수과목 이수 진단
        </Typography>
        <TurtlePaper mt={'10px'}>
          <계열적합성_진단_테이블 />
        </TurtlePaper>
        {/* </>
        )} */}
        <ConditionMessage show={!isAnyRowSelected} text="체크 박스를 선택해주십시오." />
        <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />
      </SubtitleSection>
    </>
  )
}
type 종합_Item = {
  collegeName: string
  recruitmentUnit: string
  recruitmentUnitId: string
  riskDeGyoGwaScore: number
  riskGyoGwaScore: number
  riskSeriesScore: number
  totalRiskScore: number
  typeName: string
}

const 종합_진단_테이블 = ({ setNumRowSelected }: { setNumRowSelected: Setter<number> }) => {
  const { stepState, grandSeries } = useContext(StepPagesContext)
  const { seriesRiskScore, aClass } = stepState[1].bundle

  const body = {
    grandSeries: grandSeries,
    seriesRiskScore,
    list: stepState[7].bundle.selected,
  }

  let { data, isFetching } = useQuery(['학종-8-종합', body], () => {
    return memberAxios.post('/pearlyd/sr/total/list', body).then((res) => {
      console.log(res.data)
      return res.data as 종합_Item[]
    })
  })

  data = data ?? []

  const isLoading = useSmoothLoading({ isLoading: isFetching })

  const columnHelper = createColumnHelper<종합_Item>()

  const [rowSelection, setRowSelection] = useBundledRowSelection(data ?? [], (dataList, id) => {
    return dataList.find((x) => String(x.recruitmentUnitId) === String(id))
  })

  useEffect(() => {
    setNumRowSelected(getSelected(rowSelection).length)
  }, [rowSelection])

  return (
    <BaseTable
      isLoading={isLoading}
      enableSelection
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      getRowId={(row) => String(row.recruitmentUnitId)}
      columns={[
        columnHelper.accessor('collegeName', {
          header: '대학명',
        }),
        columnHelper.accessor('typeName', {
          header: '전형명',
        }),
        columnHelper.accessor('recruitmentUnit', {
          header: '모집단위',
        }),

        // columnHelper.accessor('riskSeriesScore', {
        //   id: '위험도',
        //   header: () => (
        //     <Box>
        //       종합진단
        //       <br />
        //       계열적합성
        //     </Box>
        //   ),
        //   cell: (info) => (isNaN(info.getValue()) ? '-' : <RiskLabel riskScore={info.getValue()} />),
        // }),

        columnHelper.accessor('riskGyoGwaScore', {
          header: '교과',
          cell: (info) => (isNaN(info.getValue()) ? '-' : <RiskLabel riskScore={info.getValue()} />),
        }),
        columnHelper.accessor('riskDeGyoGwaScore', {
          header: '비교과',
          cell: (info) => (isNaN(info.getValue()) ? '-' : <RiskLabel riskScore={info.getValue()} />),
        }),
        columnHelper.accessor('totalRiskScore', {
          header: '위험도',
          cell: (info) => (isNaN(info.getValue()) ? '-' : <RiskIndicator riskScore={info.getValue()} />),
        }),
      ]}
      data={data}
      searchInfo={{ label: '모집단위 검색', value: 'recruitmentUnit', mode: true }}
    />
  )
}

const 교과_테이블 = () => {
  const { stepState } = useContext(StepPagesContext)
  const { selected } = stepState[6].bundle as { selected: Step6DataItem[] }

  const columnHelper = createColumnHelper<Step6DataItem>()
  return (
    <BaseTable
      columns={[
        columnHelper.accessor('collegeName', {
          header: '대학명',
        }),
        columnHelper.accessor('typeName', {
          header: '전형명',
        }),
        columnHelper.accessor('recruitmentUnit', {
          header: '모집단위',
        }),
        columnHelper.accessor('myScore', {
          header: '내 교과성적',
        }),
        columnHelper.accessor('cut50', {
          header: '최초컷',
        }),
        columnHelper.accessor('cut70', {
          header: '추합컷',
        }),
        columnHelper.accessor('riskScore', {
          header: '위험도',
          cell: (info) => (isNaN(info.getValue()) ? '-' : <RiskIndicator riskScore={info.getValue()} />),
          size: 50,
        }),
      ]}
      data={selected}
    />
  )
}
const 비교과_테이블 = () => {
  const { stepState } = useContext(StepPagesContext)
  const { selected } = stepState[7].bundle as { selected: Step7학종_DataItem[] }

  const columnHelper = createColumnHelper<Step7학종_DataItem>()

  return (
    <BaseTable
      columns={[
        columnHelper.accessor('collegeName', {
          header: '대학명',
        }),
        columnHelper.accessor('typeName', {
          header: '전형명',
        }),
        columnHelper.accessor('recruitmentUnit', {
          header: '모집단위',
        }),
        columnHelper.accessor('evaluation', {
          id: '평가항목A',
          header: () => (
            <Box>
              평가항목A
              <br />
              내용 / 반영비율 /내점수
            </Box>
          ),
          cell: (info) =>
            info.getValue()?.length >= 1 ? `${info.getValue()?.[0]?.itemName} / ${info.getValue()?.[0]?.score}` : '-',
        }),
        columnHelper.accessor('evaluation', {
          id: '평가항목B',
          header: () => (
            <Box>
              평가항목A
              <br />
              내용 / 반영비율 / 내점수
            </Box>
          ),
          cell: (info) =>
            info.getValue()?.length > 1 ? `${info.getValue()?.[1]?.itemName} / ${info.getValue()?.[1]?.score}` : '-',
        }),
        columnHelper.accessor('evaluation', {
          id: '평가항목C',
          header: () => (
            <Box>
              평가항목A
              <br />
              내용 / 반영비율 / 내점수
            </Box>
          ),
          cell: (info) =>
            info.getValue()?.length > 2 ? `${info.getValue()?.[2]?.itemName} / ${info.getValue()?.[2]?.score}` : '-',
        }),
        columnHelper.accessor('riskDeGyoGwaScore', {
          header: '위험도',
          cell: (info) => (isNaN(info.getValue()) ? '-' : <RiskIndicator riskScore={info.getValue()} />),
          size: 50,
        }),
      ]}
      data={selected}
    />
  )
}

const 계열적합성_진단_테이블 = () => {
  const { stepState } = useContext(StepPagesContext)

  const 종합진단_계열적합성 = stepState[1].bundle.종합진단_계열적합성 ?? {}

  const columnHelper = createColumnHelper<{
    '필수과목 선택 과목 수': number
    '필수과목 미선택 과목 수': number
    '장려과목 선택 과목 수': number
    '장려과목 미선택 과목 수': number
    위험도: -5
  }>()

  const columns = [
    columnHelper.accessor('필수과목 선택 과목 수', {}),
    columnHelper.accessor('필수과목 미선택 과목 수', {}),
    columnHelper.accessor('장려과목 선택 과목 수', {}),
    columnHelper.accessor('장려과목 미선택 과목 수', {}),
    columnHelper.accessor('위험도', {
      cell: (info) => <RiskIndicator riskScore={info.getValue()} />,
      size: 50,
    }),
  ]
  /**
   *
   */

  return <BaseTable columns={columns} data={[종합진단_계열적합성]} />
}

export default Step8
