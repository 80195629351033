import { Checkbox } from "@mui/material";
import { munMathAbleDepartment } from "api/fetches/essay";
import { IoIosArrowForward } from "react-icons/io";

interface TableRowProps {
  univInfo: munMathAbleDepartment;
  checkedUnivIdIndex: number[];
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
}

const TableRow = ({ univInfo, checkedUnivIdIndex, onClickDepartmentCheckBox }: TableRowProps) => {
  return (
    <tr>
      <td>{univInfo.recruitmentUnit}</td>
      <td>인문</td>
      <td>{univInfo.studentRecruitmentNum}</td>
      <td>{univInfo.competitionRate}</td>

      <td>{univInfo.suNosul.split(",").includes("N1") && "✓"}</td>
      <td>{univInfo.suNosul.split(",").includes("N2") && "✓"}</td>
      <td>{univInfo.suNosul.split(",").includes("N4") && "✓"}</td>
      <td>{univInfo.suNosul.split(",").includes("N3") && "✓"}</td>
      <td>
        <Checkbox checked={checkedUnivIdIndex?.includes(univInfo.id) ?? false} onChange={(e) => onClickDepartmentCheckBox(e, univInfo.id)} />
      </td>
    </tr>
  );
};

interface Props {
  univList: { collegeCode: string; collegeName: string }[];
  openUnivList: string[];
  departmentList: munMathAbleDepartment[];
  suriNonsulFilter: string[];
  engFilter: string[];
  selectedDepartmentList: number[];
  nowTableType: "recommend" | "notRecommend";
  onClickUnivRow: (collegeCode: string) => void;
  onClickTotalCheckBox: (isTotal: boolean, idList: number[]) => void;
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
  onClickCheckBox: (event: React.ChangeEvent<HTMLInputElement>, { id, isTotal, collegeCode }: { id: number; isTotal: boolean; collegeCode?: string }) => void;
}

/**
 * 문과 수리논술
 */
const SuriNonSulTable = ({
  univList,
  openUnivList,
  departmentList,
  suriNonsulFilter,
  engFilter,
  selectedDepartmentList,
  nowTableType,
  onClickDepartmentCheckBox,
  onClickUnivRow,
  onClickTotalCheckBox,
}: Props) => {
  return (
    <>
      {univList.map((univ) => {
        const userFilter = [...suriNonsulFilter, ...engFilter].sort().join(",");
        const recDepartmentList = departmentList.filter((department) => {
          const univCondition = department.suNosul.split(",").sort().join(",");
          return department.collegeCode === univ.collegeCode && univCondition.includes(userFilter);
        });
        const recIdList = recDepartmentList.map((department) => department.id);
        const notRecDepartmentList = departmentList.filter((department) => {
          const univCondition = department.suNosul.split(",").sort().join(",");
          return department.collegeCode === univ.collegeCode && univCondition.includes(userFilter) === false;
        });
        const notRecIdList = notRecDepartmentList.map((deaprtment) => deaprtment.id);
        let isCheckTotal = true;

        if (nowTableType === "recommend") {
          for (const department of recDepartmentList) {
            if (!isCheckTotal || !selectedDepartmentList.includes(department.id)) {
              isCheckTotal = false;
              break;
            }
          }
        } else {
          for (const department of notRecDepartmentList) {
            if (!isCheckTotal || !selectedDepartmentList.includes(department.id)) {
              isCheckTotal = false;
              break;
            }
          }
        }
          /**
           * 추천 데이터 및 비추천데이터가없으면,대학 이름이 나오면안됨.
           */
          if(nowTableType === "recommend"&&recDepartmentList.length<1){
              return ;
          }else if(nowTableType !== "recommend"&&notRecDepartmentList.length<1){
              return ;
          }


        return (
          <div key={"munSuriNonSul " + univ.collegeCode}>
            <div onClick={() => onClickUnivRow(univ.collegeCode)} className="univListRow" key={univ.collegeCode}>
              {univ.collegeName}
              <IoIosArrowForward className={openUnivList.includes(univ.collegeCode) ? "iconOpen" : ""} />
            </div>
            {openUnivList.includes(univ.collegeCode) && (
              <table className="univTable">
                <tbody>
                  <tr>
                    <th rowSpan={2}>모집단위명</th>
                    <th rowSpan={2}>계열</th>
                    <th rowSpan={2}>정원</th>
                    <th rowSpan={2}>경쟁률</th>
                    <th colSpan={3}>수리논술</th>
                    <th rowSpan={2}>영어제시문</th>
                    <th rowSpan={2}>
                      <div className="col">
                        전체선택
                        <Checkbox onClick={() => onClickTotalCheckBox(!isCheckTotal, nowTableType === "recommend" ? recIdList : notRecIdList)} checked={isCheckTotal} />
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td className="th">통계(도표)</td>
                    <td className="th">수리</td>
                    <td className="th">언어논술 없음</td>
                  </tr>
                  {nowTableType === "recommend" &&
                    recDepartmentList.map((department) => {
                      return <TableRow key={"univRow " + department.id} onClickDepartmentCheckBox={onClickDepartmentCheckBox} univInfo={department} checkedUnivIdIndex={selectedDepartmentList} />;
                    })}
                  {nowTableType === "notRecommend" &&
                    notRecDepartmentList.map((department) => {
                      return <TableRow key={"univRow " + department.id} onClickDepartmentCheckBox={onClickDepartmentCheckBox} univInfo={department} checkedUnivIdIndex={selectedDepartmentList} />;
                    })}
                </tbody>
              </table>
            )}
          </div>
        );
      })}
    </>
  );
};

export default SuriNonSulTable;
