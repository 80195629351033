import TurtlePaper from 'components/common/TurtlePaper'
import './EmptyPageLayout.scss'
import { BoxProps, Typography } from '@mui/material'

const MessageCard = ({
  title,
  contents,
  isTitleCenter = false,
  ...boxProps
}: { title: React.ReactNode; contents?: React.ReactNode; isTitleCenter?: boolean } & BoxProps) => (
  <TurtlePaper sx={{ display: 'flex', padding: '36px 30px', height: '126px', ...boxProps }} className="emptyPage">
    <Typography variant="D3_bold, 16, 24" alignSelf={isTitleCenter ? 'center' : 'inherit'}>
      {title}
    </Typography>
    <Typography variant="D5_Regular, 14, 20" className="contents" mt={2}>
      {contents}
    </Typography>
  </TurtlePaper>
)

export default MessageCard
