import { Checkbox } from "@mui/material";
import { otherDepartment } from "api/fetches/essay";
import { IoIosArrowForward } from "react-icons/io";

interface Props {
  otherUnivList: { collegeCode: string; collegeName: string }[];
  otherDepartmentList: otherDepartment[];
  openUnivList: string[];
  selectedDepartmentList: number[];
  onClickUnivRow: (collegeCode: string) => void;
  onClickTotalCheckBox: (isTotal: boolean, idList: number[]) => void;
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
}

const OtherNonsulTypeTable = ({ otherUnivList, openUnivList, otherDepartmentList, selectedDepartmentList, onClickUnivRow, onClickDepartmentCheckBox, onClickTotalCheckBox }: Props) => {
  return (
    <>
      {otherUnivList.map((univ,index) => {
        const departmentList = otherDepartmentList.filter((department) => {
          return department.collegeCode === univ.collegeCode;
        });
        const departmentIdList = departmentList.map((department) => department.id);
        const isCheckTotal = departmentIdList.every((id) => selectedDepartmentList.includes(id));
        //index가 1일경우 리스트를 오픈시킨다.

        /**
         * 추천 데이터 및 비추천데이터가없으면,대학 이름이 나오면안됨.
         */
       //  console.log("asdqwf",departmentList)
       if(departmentList.length<1){
          return ;
        }

        return (
          <div key={"crossableUniv : " + univ.collegeCode}>
            <div
              onClick={() => {
                try {
                  onClickUnivRow(univ.collegeCode);
                } catch (error) {
                  console.error(error);
                }
              }}
              className="univListRow"
            >
              {univ.collegeName}
              <IoIosArrowForward className={openUnivList.includes(univ.collegeCode) ? "iconOpen" : ""} />
            </div>
            {openUnivList.includes(univ.collegeCode)&& (
              <table className="univTable">
                <tbody>
                  <tr>
                    <th>모집단위</th>

                    <th>논술과목</th>
                    <th>부연설명</th>

                    <th>
                      <div className="col">
                        전체선택
                        <Checkbox checked={isCheckTotal} onClick={() => onClickTotalCheckBox(!isCheckTotal, departmentIdList)} />
                      </div>
                    </th>
                  </tr>

                  {departmentList.map((department) => {
                    return (
                      univ.collegeCode === department.collegeCode && (
                        <tr key={department.id + "crossable"}>
                          <td>{department.recruitmentUnit}</td>
                          <td>{department.essaySubject}</td>
                          <td>{department.examinationTendency}</td>

                          <td>
                            <Checkbox checked={selectedDepartmentList?.includes(department.id) ?? false} onChange={(e) => onClickDepartmentCheckBox(e, department.id)} />
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        );
      })}
    </>
  );
};

export default OtherNonsulTypeTable;
