import { Theme } from '@mui/system'
import MessageCard from 'components/layout/EmptyPageLayout'
import { muiTheme } from 'theme'

export const 관심대학_테이블스타일 = (theme: Theme) =>
  ({
    '& td, th': { px: 0.2, py: 0.5, border: '1px solid #C2C2C2', textAlign: 'center', verticalAlign: 'middle' },
    th: {
      bgcolor: '#F4F4F4',
      fontWeight: 700,
      lineHeight: '24px',
      borderTop: '1px solid #252525',
      ...muiTheme.typography['D3_bold, 16, 24'],
    },
    td: {
      ...muiTheme.typography['D5_Regular, 14, 20'],
    },
    [theme.breakpoints.up('lg')]: {
      '& td, th': { px: 1, py: 1 },
    },
  } as const)

export const emptyTablePlaceholder = (
  <MessageCard
    title="목표대학 내역이 없습니다."
    contents={'학종, 교과, 논술 컨설팅 메뉴에서 대학과 학과를 찾아 목표대학으로 설정할 수 있습니다.'}
  />
)
