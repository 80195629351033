import { Box, BoxProps, Grid, Stack, Typography } from '@mui/material'
import StackX from 'components/common/StackX'
import TurtlePaper from 'components/common/TurtlePaper'
import { greyFontColor, mainColor } from 'constants/style'
import GradeChart from './GradeChart'
import StackY from 'components/common/StackY'
import GradeChart2 from './GradeChart2'
import Center from 'components/common/Center'
import { memberAxios } from 'api/instance'
import { useQuery } from 'react-query'
import { 학종_사정관평가데이터_형식 } from './Step1'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import { useContext } from 'react'
import { StepPagesContext } from '../common/StepPagesContext'
import { gradeMap } from 'utils/util'
type Props = {}

function Step5({}: Props) {
  // let { data: officerReviewData } = useQuery('학종-all-평가-', () =>
  //   memberAxios
  //     .get('/pearlyd/sr/officer-student-evaludate/avg-score/all/details')
  //     .then((x) => x.data as 학종_사정관평가데이터_형식)
  // )

  const { step1Avg, step2Avg, step3Avg, step4Avg } = useContext(StepPagesContext)

  // const scoreData = officerReviewData?.mainAvgScoreList ?? [0, 0, 0, 0]

  const threeEvaluationAvg = () => {
    let scoreAvg = (step1Avg + step2Avg + step3Avg) / 3
    if (scoreAvg >= 6) {
      return 'A+'
    } else if (scoreAvg > 5) {
      return 'A'
    } else if (scoreAvg > 4) {
      return 'B+'
    } else if (scoreAvg > 3) {
      return 'B'
    } else if (scoreAvg > 2) {
      return 'C+'
    } else if (scoreAvg > 1) {
      return 'C'
    } else if (scoreAvg > 0) {
      return 'D'
    } else {
      return '-'
    }
  }

  const fourEvaluationAvg = () => {
    let scoreAvg = (step1Avg + step2Avg + step3Avg) / 3
    if (scoreAvg >= 6) {
      return 'A+'
    } else if (scoreAvg > 5) {
      return 'A'
    } else if (scoreAvg > 4) {
      return 'B+'
    } else if (scoreAvg > 3) {
      return 'B'
    } else if (scoreAvg > 2) {
      return 'C+'
    } else if (scoreAvg > 1) {
      return 'C'
    } else if (scoreAvg > 0) {
      return 'D'
    } else {
      return '-'
    }
  }

  // 진로역량 학업역량 공동체역량 기타역량
  // [진로역량 학업역량 공동체역량 기타역량]
  const data1 = [
    { name: '진로역량', grade: Number(step1Avg) + 1 },
    { name: '학업역량', grade: Number(step2Avg) + 1 },
    { name: '공동체역량', grade: Number(step3Avg) + 1 },
  ]
  const stackData1: any = convertToStackData(data1)

  const data2 = [
    { name: '발전가능성', grade: Number(step4Avg) + 1 },
    { name: '인성', grade: Number(step3Avg) + 1 },
    { name: '전공적합성', grade: Number(step1Avg) + 1 },
    { name: '학업역량', grade: Number(step2Avg) + 1 },
  ]

  const stackData2: any = convertToStackData(data2)

  return (
    <Box>
      <TurtlePaper
        flexDirection={'column'}
        gap={2}
        sx={(theme) => ({
          py: 4,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            height: '100%',
          },
        })}
      >
        <Box mb={2}>
          <Typography variant="H5_regular, 24, 32" fontWeight={700}>
            3개 평가
          </Typography>
        </Box>
        <Grid container spacing={2} width={'100%'} margin={0}>
          <Grid item xs={12} md={6} lg={4.5} height={'400px'}>
            <GradeChart minWidth={'150px'} width={'100%'} height={'100%'} data={stackData1} />
          </Grid>
          <Grid item xs={12} md={6} lg={4.5} height={'400px'}>
            <GradeChart2 minWidth={'150px'} width={'100%'} height={'100%'} data={data1} />
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <Center>
              <ScoreCard
                mainGrade={threeEvaluationAvg()}
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              />
            </Center>
          </Grid>
        </Grid>
      </TurtlePaper>
      <TurtlePaper
        flexDirection={'column'}
        gap={2}
        sx={(theme) => ({
          py: 4,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            height: '100%',
          },
          mt: 4,
        })}
      >
        <Box mb={2}>
          <Typography variant="H5_regular, 24, 32" fontWeight={700}>
            4개 평가
          </Typography>
        </Box>
        <Grid container spacing={2} width={'100%'} margin={0}>
          <Grid item xs={12} md={6} lg={4.5} height={'400px'}>
            <GradeChart minWidth={'150px'} width={'100%'} height={'100%'} data={stackData2} key={'3csa'} />
          </Grid>
          <Grid item xs={12} md={6} lg={4.5} height={'400px'}>
            <GradeChart2 minWidth={'150px'} width={'100%'} height={'100%'} data={data2} key="221312" />
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <Center>
              <ScoreCard
                mainGrade={fourEvaluationAvg()}
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                })}
              />
            </Center>
          </Grid>
        </Grid>
      </TurtlePaper>
      <StepMoveButtonGroup />
    </Box>
  )
}

function convertToStackData(data: { name: string; grade: number }[]) {
  const ret: any = {}
  data.forEach((x) => {
    ret[x.name] = x.grade
  })
  return ret
}

function ScoreCard({ mainGrade, sx, ...boxProps }: { mainGrade: string } & BoxProps) {
  const gradeMap = {
    'A+': '의치한약수',
    A: '서울상위권',
    'B+': '서울중위권',
    B: '서울하위건, 경기상위권',
    'C+': '경기중위권, 지방상위권',
    C: '경기하위권, 지방중하위권',
    D: '낙제',
  }

  const gradeList = Object.keys(gradeMap).map((grade) => (
    <Box
      key={grade}
      sx={{
        display: 'flex',
        padding: '6px 0px',
        color: greyFontColor,
        fontSize: '16px',
        lineHeight: '29px',
      }}
    >
      <Typography style={{ width: 32 }}>{grade}</Typography>
      <Typography>:</Typography>
      <Typography style={{ width: '100%', minWidth: '180px', maxWidth: '260px', textAlign: 'right' }}>
        {gradeMap[grade as keyof typeof gradeMap]}
      </Typography>
    </Box>
  ))

  return (
    <Box
      sx={[
        (theme) => ({
          boxShadow: '0px 0px 4px #eee',
          height: '100%',
          p: 2,
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
          },
        }),
      ]}
      {...boxProps}
    >
      <StackY gap={1}>
        <StackX justifyContent={'space-between'}>
          <Typography variant="H8_bold, 20, 28">비교과 등급</Typography>
          <Typography
            variant="H1_Exbold, 36, 54"
            style={{
              color: mainColor,
            }}
          >
            {mainGrade}
          </Typography>
        </StackX>

        <Box sx={{ minWidth: 0, marginTop: 1, backgroundColor: '#000', height: 2, width: '100%' }} />
        <Box>{gradeList}</Box>
      </StackY>
    </Box>
  )
}

export default Step5
