import { Checkbox } from '@mui/material'
import { munCrossableMathDepartment } from 'api/fetches/essay'
import { IoIosArrowForward } from 'react-icons/io'
import { 문과_교차지원필터_State } from '../../mungwa'

interface Props {
  munCrossableUnivList: {
    collegeCode: string
    collegeName: string
  }[]
  munCrossableDepartmentList: munCrossableMathDepartment[]
  crossApplyFilter: 문과_교차지원필터_State
  nowTableType: 'recommend' | 'notRecommend'
  selectedDepartmentList: number[]
  openUnivList: string[]
  onClickUnivRow: (collegeCode: string) => void
  onClickTotalCheckBox: (isTotal: boolean, idList: number[]) => void
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void
}

const LigwaCrossableTable = ({
  munCrossableUnivList,
  munCrossableDepartmentList,
  selectedDepartmentList,
  crossApplyFilter,
  openUnivList,
  nowTableType,
  onClickDepartmentCheckBox,
  onClickTotalCheckBox,
  onClickUnivRow,
}: Props) => {
  // const checkSelectSubject = () => {
  //   const result: number[] | undefined = []
  //   crossApplyFilter.includes('ES2') ? result.push(1) : result.push(0)
  //   crossApplyFilter.includes('ES3') ? result.push(1) : result.push(0)
  //   crossApplyFilter.includes('ES4') ? result.push(1) : result.push(0)
  //   crossApplyFilter.includes('ES5') ? result.push(1) : result.push(0)
  //   return result
  // }
  return (
    <>
      {munCrossableUnivList.map((univ) => {
        const getFilteredDepartmentList = (filterFn: (department: munCrossableMathDepartment) => boolean) =>
          munCrossableDepartmentList.filter(
            (department) => filterFn(department) && department.collegeCode === univ.collegeCode
          )
        // const _temp = checkSelectSubject()

        const recDepartmentList = getFilteredDepartmentList((department) => {
          console.log(department)

          if (crossApplyFilter['수리 범위가 수1+수2'] && department.suoneSutwo === 0) {
            return false
          }
          if (crossApplyFilter['언어논술 포함 이과논술'] && department.essaySubject === '수리논술') {
            return false
          }
          return true
        })
        const recIdList = recDepartmentList.map((department) => department.id)

        const notRecDepartmentList = getFilteredDepartmentList((department) => {
          const array = recDepartmentList.filter((item) => {
            return item.id === department.id
          })
          return array.length === 0
        })
        const notRecIdList = recDepartmentList.map((department) => department.id)

        const isCheckTotal =
          nowTableType === 'recommend'
            ? recDepartmentList.every((department) => selectedDepartmentList.includes(department.id))
            : notRecDepartmentList.every((department) => selectedDepartmentList.includes(department.id))

        return (
          <div key={'crossableUniv : ' + univ.collegeCode}>
            <div onClick={() => onClickUnivRow(univ.collegeCode)} className="univListRow">
              {univ.collegeName}
              <IoIosArrowForward className={openUnivList.includes(univ.collegeCode) ? 'iconOpen' : ''} />
            </div>
            {openUnivList.includes(univ.collegeCode) && (
              <table className="univTable">
                <tbody>
                  <tr>
                    <th>모집단위</th>
                    <th className="th">수1,수2</th>
                    <th className="th">논술 과목</th>
                    <th>
                      <div className="col">
                        전체선택
                        <Checkbox
                          checked={isCheckTotal}
                          onClick={() =>
                            onClickTotalCheckBox(!isCheckTotal, nowTableType === 'recommend' ? recIdList : notRecIdList)
                          }
                        />
                      </div>
                    </th>
                  </tr>
                  {/* <tr>
                    <td className="th">수1,수2</td>
                    {/* <td className="th">확통</td>
                    <td className="th">미적</td>
                    <td className="th">기하</td> */}
                  {/* <td className="th">논술 과목</td> */}
                  {/* </tr> */}
                  {(nowTableType === 'recommend' ? recDepartmentList : notRecDepartmentList).map(
                    (department) =>
                      univ.collegeCode === department.collegeCode && (
                        <tr key={department.id + 'crossable'}>
                          <td>{department.recruitmentUnit}</td>
                          <td>{department.suoneSutwo !== 0 && '✓'}</td>
                          {/* <td>{department.pbSt !== 0 && '✓'}</td> */}
                          {/* <td>{department.dfIn !== 0 && '✓'}</td> */}
                          {/* <td>{department.geometry !== 0 && '✓'}</td> */}
                          <td>{department.essaySubject}</td>
                          <td>
                            <Checkbox
                              checked={selectedDepartmentList?.includes(department.id) ?? false}
                              onChange={(e) => onClickDepartmentCheckBox(e, department.id)}
                            />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            )}
          </div>
        )
      })}
    </>
  )
}

export default LigwaCrossableTable
