import { memberAxios } from 'api/instance'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import DetailModalLayout from '../DetailModalLayout'
import K_상세보기_전형정보탭 from './K_상세보기_전형정보탭'
import K_상세보기_성적반영방법탭 from './K_상세보기_성적반영방법탭'
import K_상세보기_합격예측탭 from './K_상세보기_합격예측탭'

export type 학종상세_전형정보 = {
  basicTypeAdmission: string
  centerType: string
  collegeRegion: string
  competitionRate: string
  interviewDate: string
  interviewPaper: string
  interviewRate: string
  interviewText: string
  interviewTime: string
  interviewType: string
  lowestText: string
  paperRate: string
  recruitmentNum: string
  selectionModel: string
  subjectRate: string
  supportQualificationText: string
  typeName: string
}

export type 학종상세_성적반영방법 = {
  evaluateCode: string
  evaluateInfo: any
  evaluateRate: string
  firstClassRate: number
  thirdClassRate: number
  twoClassRate: number
}

export type 학종상세_합격예측 = {
  compulsorySubjectList: any[]
  predictionList: { collegeName: string; recruitmentUnit: string; cut70: string }[]
  studentRecordComparativeDepartment: {
    collegeName: string
    cut50: string
    cut70: string
    evaluation: any[]
    myGyoGwaScore: string
    recruitmentUnit: string
    recruitmentUnitId: number
    riskDeGyoGwaScore: number
    riskGyoGwaScore: number
    totalScore: number
  }
  totalFitScoreSum: number
}

export const StudentDetailModalContext = React.createContext<{
  typeInfoData: 학종상세_전형정보 | undefined
  reflectData: 학종상세_성적반영방법 | undefined
  predictsData: 학종상세_합격예측 | undefined
}>({
  typeInfoData: undefined,
  reflectData: undefined,
  predictsData: undefined,
})

const K_상세보기_학종모달_내용 = ({
  param,
}: {
  param: {
    recruitmentUnitId: number
    regionRange: number
    rowSeries: number
    college: string
    major: string
  }
}) => {
  const { recruitmentUnitId, regionRange, rowSeries } = param
  const { data: typeInfoData } = useQuery(['상세보기-학종모달-전형정보', recruitmentUnitId], () => {
    return memberAxios
      .get('/pearlyd/sr/detail/typeinfo', {
        params: { recruitmentUnitId },
      })
      .then((res) => {
        return res.data.studentRecordDetailTypeInfo as 학종상세_전형정보
      })
  })
  const { data: reflectData } = useQuery(['상세보기-학종모달-성적반영방법', recruitmentUnitId], () => {
    return memberAxios
      .get('/pearlyd/sr/detail/reflect', {
        params: { recruitmentUnitId },
      })
      .then((res) => {
        return res.data as 학종상세_성적반영방법
      })
  })
  const { data: predictsData } = useQuery(['상세보기-학종모달-합격예측', recruitmentUnitId], () => {
    return memberAxios
      .post('/pearlyd/sr/detail/predicts', {
        recruitmentUnitId,
        regionRange,
        rowSeries,
      })
      .then((res) => {
        return res.data as 학종상세_합격예측
      })
  })

  const [info, setInfo] = useState({
    typeInfoData,
    reflectData,
    predictsData,
  })

  useEffect(() => {
    setInfo({
      typeInfoData,
      reflectData,
      predictsData,
    })
  }, [typeInfoData, reflectData, predictsData])

  return (
    <StudentDetailModalContext.Provider value={info}>
      <DetailModalLayout
        college={param.college}
        department={param.major}
        tabContentList={[
          {
            tabName: '전형정보',
            tabContent: <K_상세보기_전형정보탭 />,
          },
          {
            tabName: '성적반영방법',
            tabContent: <K_상세보기_성적반영방법탭 />,
          },
          {
            tabName: '합격예측',
            tabContent: <K_상세보기_합격예측탭 />,
          },
        ]}
      />
    </StudentDetailModalContext.Provider>
  )
}

export default K_상세보기_학종모달_내용
