import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import './style.scss'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from '@mui/material'
import PaymentsIcon from '@mui/icons-material/Payments'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import {
  getProductInfoFetch,
  getCouponInfoFetch,
  addPreRegiPaymentFetch,
  validatePaymentFetch,
  getStoreCode,
  validatePaymentZeroFetch,
  addNewPreRegiPaymentFetch,
  insertOrder,
  insertContract,
  updateCouponUsed,
  upsertTicketCount,
} from '../../../../api/fetches/store'
import TextField from '@mui/material/TextField'
import userInfoStates from '../../../../atoms/userInfoStates'
import {useRecoilState, useRecoilValue} from 'recoil'
import { Box, Stack } from '@mui/system'
import TurtlePaper from 'components/common/TurtlePaper'
import { config } from '../../../../config'
import { pageContentConstraint } from 'constants/style'
import SimpleBackdrop from '../../../common/LoadingBlock'
import moment from 'moment'
import {userInfoFetch} from "../../../../api/fetches/user";
import { useNavigate } from 'react-router-dom'

function OrderPage() {
  const basicDate = new Date()
  const year = basicDate.getFullYear()
  const month = basicDate.getMonth() + 1
  const date = basicDate.getDate()

  const params = useParams()
  //const userInfo = useRecoilValue(userInfoStates)
  const [userInfo, setUserInfo] = useRecoilState(userInfoStates)
  const [blockOpenFlag, setBlockOpenFlag] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false)
  const [couponNumber, setCouponNumber] = useState<string | undefined>()
  const [serviceDate, setServiceDate] = useState<string | undefined>()
  const [serviceDateForMoment, setServiceDateForMoment] = useState<any>()
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [product, setProduct] = useState<any>()

  const [couponInfo, setCouponInfo] = useState<{
    discountInfo: string
    discountValue: number
    couponDiscountPrice: number
  }>({
    discountInfo: '',
    discountValue: 0,
    couponDiscountPrice: 0,
  })

  const navigate = useNavigate()

  useEffect(() => {
    getProductInfo()
  }, [])

  const getProductInfo = async () => {
    const _product = await getProductInfoFetch(params.productId)
    if (_product.data.productTypeCode == 'FIXEDTERM') {
      const basicDate = new Date(_product.data.term)
      const year1 = basicDate.getFullYear()
      const month1 = basicDate.getMonth() + 1
      const date1 = basicDate.getDate()
      setServiceDate(year1 + '년 ' + month1 + '월 ' + date1 + '일')
      setServiceDateForMoment(moment(basicDate).format('YYYY-MM-DD HH:mm:ss'))
    }
    if (_product.data.productTypeCode == 'TERM') {
      if (_product.data.availableTerm == '1M') {
        const basicDate = new Date()
        const year1 = basicDate.getFullYear()
        const month1 = basicDate.getMonth() + 2
        const date1 = basicDate.getDate()
        setServiceDate(year1 + '년 ' + month1 + '월 ' + date1 + '일')
        setServiceDateForMoment(moment(basicDate).add(1, 'M').format('YYYY-MM-DD HH:mm:ss'))
      }
      if (_product.data.availableTerm == '6M') {
        const basicDate = new Date()
        const year1 = basicDate.getFullYear()
        const month1 = basicDate.getMonth() + 7
        const date1 = basicDate.getDate()
        setServiceDate(year1 + '년 ' + month1 + '월 ' + date1 + '일')
        setServiceDateForMoment(moment(basicDate).add(6, 'M').format('YYYY-MM-DD HH:mm:ss'))
      }

      if (_product.data.availableTerm == '12M') {
        const basicDate = new Date()
        const year1 = basicDate.getFullYear() + 1
        const month1 = basicDate.getMonth() + 1
        const date1 = basicDate.getDate()
        setServiceDate(year1 + '년 ' + month1 + '월 ' + date1 + '일')
        setServiceDateForMoment(moment(basicDate).add(1, 'year').format('YYYY-MM-DD HH:mm:ss'))
      }
    }
    if (_product.data.productTypeCode == 'TICKET') {
      const basicDate = new Date()
      const year1 = basicDate.getFullYear()
      const month1 = basicDate.getMonth() + 7
      const date1 = basicDate.getDate()
      setServiceDate(year1 + '년 ' + month1 + '월 ' + date1 + '일')
      setServiceDateForMoment(moment(basicDate).add(6, 'M').format('YYYY-MM-DD HH:mm:ss'))
    }

    if (_product.data.productTypeCode == 'PACKAGE') {
      const basicDate = new Date()
      const year1 = basicDate.getFullYear()
      const month1 = basicDate.getMonth() + 7
      const date1 = basicDate.getDate()
      setServiceDate(year1 + '년 ' + month1 + '월 ' + date1 + '일')
      setServiceDateForMoment(moment(basicDate).add(6, 'M').format('YYYY-MM-DD HH:mm:ss'))
    }

    setProduct(_product.data)
  }

  //쿠폰정보
  const addCouponInfo = async () => {
    const _coupon = await getCouponInfoFetch(couponNumber, params.productId)
    if (_coupon.status) {
      setCouponInfo(_coupon.couponInfo)
      alert('쿠폰이 등록되었습니다.')
      handleClose()
    } else {
      alert(_coupon.message)
    }
  }

  const processPayment = async () => {
    let currentPaymentPrice = 0
    if (couponInfo.discountValue != 0) {
      currentPaymentPrice = product.productPrice - couponInfo.couponDiscountPrice
    } else {
      currentPaymentPrice = product.productPrice - product.discountPrice
    }

    let preRegistparams: any = {}
    preRegistparams.serviceId = params.productId
    preRegistparams.memberId = userInfo.id
    preRegistparams.productPrice = currentPaymentPrice
    const storeCode = await getStoreCode()
    //사전등록
    const data = await addNewPreRegiPaymentFetch(preRegistparams)
    if (!data.status) {
      return false
    }

    const merchantUid = data.merchantUid
    const price = data.productPrice

    //setBlockOpenFlag(true)
    if (currentPaymentPrice == 0) {
      //오더입력
      let orderParam: any = {}
      orderParam.cancelAmount = 0
      orderParam.impUid = merchantUid
      orderParam.memberId = userInfo.id
      orderParam.merchantUid = merchantUid
      orderParam.orderState = 'COMPLETE'
      orderParam.paidAmount = 0
      orderParam.payServiceId = params.productId
      orderParam.cardName = 'free'
      orderParam.cardNumber = 'free'
      const _data = await insertOrder(orderParam)

      if (_data.resultCode == 100) {
        //계약입력
        fnInsertContract(_data.data, params.productId)
      } else {
        const message = _data.message
        alert('[결제 실패]   ' + message)
        setBlockOpenFlag(false)
      }
    } else {
      const IMP = window.IMP // 생략 가능
      IMP.init('imp54305088') // 예: imp00000000a

      const mobileUrl =
        couponNumber !== undefined
          ? config.devBaseUrl +
            'pay/payments/validate/mobile?coupon_number=' +
            couponNumber +
            '&service_id=' +
            params.productId +
            '&member_id=' +
            userInfo.id
          : config.devBaseUrl +
            'pay/payments/validate/mobile?service_id=' +
            params.productId +
            '&member_id=' +
            userInfo.id

      IMP.request_pay(
        {
          pg: 'html5_inicis.' + storeCode,
          pay_method: 'card',
          merchant_uid: merchantUid, // 주문번호
          name: product.productNm,
          amount: currentPaymentPrice, // 숫자 타입
          buyer_email: userInfo.email,
          buyer_name: userInfo.nickname,
          buyer_tel: userInfo.phone,
          buyer_addr: userInfo.address,
          m_redirect_url: mobileUrl,
        },
        async function (rsp: any) {
          console.log('rsp', rsp)
          if (rsp.success) {
            //오더입력
            fnInsertOrderForPaid(rsp, merchantUid)
          } else {
            setBlockOpenFlag(false)
          }
        }
      )
    }
  }

  const fnInsertOrderForPaid = async (rsp: any, merchantUid: any) => {
    //카드번호 마스킹 처리
    let cardNum: any = rsp.card_number
    let cardNumF = cardNum.substring(0, 6)
    let cardNumB = cardNum.slice(-1)
    cardNum = cardNumF + '*********' + cardNumB

    let orderParam: any = {}
    orderParam.cancelAmount = 0
    orderParam.impUid = rsp.imp_uid
    orderParam.memberId = userInfo.id
    orderParam.merchantUid = merchantUid
    orderParam.orderState = 'COMPLETE'
    orderParam.paidAmount = rsp.paid_amount
    orderParam.payServiceId = params.productId
    orderParam.cardName = rsp.card_name
    orderParam.cardNumber = cardNum
    const _data = await insertOrder(orderParam)

    if (_data.resultCode == 100) {
      //계약입력
      fnInsertContract(_data.data, params.productId)
    } else {
      const message = _data.message
      alert('[결제 실패]   ' + message)
      setBlockOpenFlag(false)
    }
  }

  const fnInsertContract = async (orderNumber: any, payServiceId: any) => {
    let contractParams: any = {}
    contractParams.contractPeriodEndDt = serviceDateForMoment
    contractParams.contractStartDt = moment().format('YYYY-MM-DD HH:mm:ss')
    contractParams.contractUse = 1
    contractParams.productCode = product.productTypeCode
    contractParams.regularContractFl = 0
    contractParams.memberId = userInfo.id
    contractParams.orderId = orderNumber

    const _data = await insertContract(contractParams)
    if (_data.resultCode == 100) {
      if (product.productTypeCode == 'TICKET' || payServiceId == 56) {
        fnUpsertTicketCount()
      } else {
        if (couponInfo.couponDiscountPrice == 0) {
          alert('결제성공')
          setBlockOpenFlag(false)
          // 결제 성공시 user 정보 재조회
          getUser()
          //location.href = '/store/details'
        } else {
          //쿠폰을 사용했다면 쿠폰 횟수 차감
          fnUpdateCouponUsed()
        }
      }
    }
  }

  const fnUpsertTicketCount = async () => {
    let ticketParam: any = {}
    ticketParam.memberId = userInfo.id
    const _data = await upsertTicketCount(ticketParam)
    if (_data.resultCode == 100) {
      if (couponInfo.couponDiscountPrice == 0) {
        alert('결제성공')
        setBlockOpenFlag(false)
        // 결제 성공시 user 정보 재조회
        getUser()
        //location.href = '/store/details'
      } else {
        //쿠폰을 사용했다면 쿠폰 횟수 차감
        fnUpdateCouponUsed()
      }
    }
  }

  const fnUpdateCouponUsed = async () => {
    let couponParams: any = {}
    couponParams.couponNumber = couponNumber
    const _data = await updateCouponUsed(couponParams)
    if (_data.resultCode == 100) {
      alert('결제성공')
      // 결제 성공시 user 정보 재조회
      getUser()
      //location.href = '/store/details'
    }
    setBlockOpenFlag(false)
  }

  const getUser = async () => {
    const userInfo = await userInfoFetch()
    setUserInfo(userInfo)
    navigate('/store/details')
  }

  return product == null ? null : (
    <Box className="orderContainer" sx={{ ...pageContentConstraint, mt: '20px' }}>
      <div className="orderleft">
        <span className="orderTitle">주문/결제</span>
      </div>
      <TurtlePaper className="orderMainContainer" sx={{ width: '100%' }}>
        <div className="orderMainWrap">
          <div className="orderProductTitle">상품</div>
          <div className="orderProductDetail">
            <span className="orderProductDetailSpan">{product?.productNm}</span>
            <span className="orderProductDetailSpan">
              {Number(product.productPrice)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
            </span>
          </div>
          {couponInfo?.couponDiscountPrice == 0 &&
          //
          product.discountPrice > 0 ? (
            <div className="orderProductDetail">
              <span className="orderProductDetailSpan">프로모션 할인금액</span>
              <span className="orderProductDetailSpan">
                {' - ' +
                  Number(product.discountPrice)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                  '원'}
              </span>
            </div>
          ) : null}
        </div>

        {couponInfo?.couponDiscountPrice != 0 ? (
          <Box className="orderMainWrap">
            <div className="orderProductTitle">할인</div>
            <div className="orderProductDiscountDetail">
              <div className="orderProductDetail">
                <span className="orderProductDetailDiscountSpan">쿠폰코드</span>
                <span className="orderProductDetailDiscountSpan">할인금액</span>
              </div>
              <div className="orderProductDetail">
                <span className="orderProductDetailSpan">{couponNumber}</span>
                <span className="orderProductDetailSpan">
                  {couponInfo?.couponDiscountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </span>
              </div>
            </div>
          </Box>
        ) : undefined}

        <div className="orderMainWrap">
          {couponInfo?.couponDiscountPrice != 0 ? null : (
            <Button className="orderMainButton" variant="outlined" onClick={handleClickOpen}>
              <ControlPointIcon />
              쿠폰 등록
            </Button>
          )}

          <Stack p={3} gap={1} bgcolor={'#F7F7F7'} mt={2}>
            <Typography p={0} variant="D4_regular, 16, 24">
              위의 서비스가 요구하는 정보를 입력한 경우, 환불이 불가합니다.
            </Typography>
            <Typography>결제일로부터 서비스 미사용시 14일 이내 환불 받으실 수 있습니다</Typography>
            <Typography>기타 문의 및 영수증 발급은 고객센터를 이용해 주시기 바랍니다.</Typography>
          </Stack>
        </div>
      </TurtlePaper>
      <TurtlePaper className="orderMainContainer" sx={{ width: '100%' }}>
        <div className="orderProductTitle">최종 결제 정보</div>

        <div className="orderProductDetail">
          <table className="table-wrapper">
            <thead>
              <tr>
                <th className="tableTitle">결제날짜</th>
                <th className="tableTitle">설명/구분</th>
                <th className="tableTitle">{product.availableCount > 0 ? '사용가능횟수/서비스기간' : '서비스 기간'}</th>
                <th className="tableTitle">할인금액</th>
                <th className="tableTitle">최종 결제금액</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{year + '년 ' + month + '월 ' + date + '일'}</td>
                <td>{product.productTypeNm}</td>
                <td>
                  {product.availableCount > 0
                    ? product.availableCount + '회 / 결재일로 부터 6개월'
                    : year + '년 ' + month + '월 ' + date + '일 ~ ' + serviceDate}
                </td>
                <td>
                  {couponInfo?.couponDiscountPrice != 0 ? couponInfo.couponDiscountPrice : product.discountPrice} 원
                </td>
                <td className="finalPrice">
                  {couponInfo?.couponDiscountPrice != 0
                    ? (product.productPrice - couponInfo.couponDiscountPrice)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'
                    : (product.productPrice - product.discountPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                      '원'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="lastbutton">
          <button className="basicButton" onClick={processPayment}>
            <PaymentsIcon />
            결제 하기
          </button>
        </div>
      </TurtlePaper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{'쿠폰 등록'}</DialogTitle> */}
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="코드"
            variant="outlined"
            onChange={(e) => setCouponNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={addCouponInfo} autoFocus>
            쿠폰 등록
          </Button>
        </DialogActions>
      </Dialog>
      <SimpleBackdrop openFlag={blockOpenFlag} />
    </Box>
  )
}

export default OrderPage
