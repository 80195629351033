import {Box, Paper, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';

import "./style.css"
import {detailsContentFetch} from "../../../../../api/fetches/store";
import {useParams} from "react-router";
import {useRecoilValue} from "recoil";
import userInfoStates from "../../../../../atoms/userInfoStates";
import { pageContentConstraint } from 'constants/style';

function Details() {
    const params = useParams();
    const userInfo = useRecoilValue(userInfoStates);
    const basicDate = new Date();
    const year = basicDate.getFullYear();
    const month = basicDate.getMonth() + 1;
    const date = basicDate.getDate();
    const [serviceDate,setServiceDate] = useState<string|undefined>();
    const [details,setDetails] = useState<{
        productName:string;
        paidAmount:number;
        paidDate:string;
        productImage:string;
        productPrice:number;
        cardName:string;
        cardNumber:string;
        vbankCode:string;
        vbankName:string;
        productPaymentType:string;
        term:Date;
    }|null>(null)
    useEffect(()=>{

        getDetailList();
    },[]);

    const getDetailList = async () =>{
        const _details = await detailsContentFetch(params.orderDetailId);
        const basicDate = new Date(_details.term);
        const year1 = basicDate.getFullYear();
        const month1 = basicDate.getMonth() + 1;
        const date1 = basicDate.getDate();
        setServiceDate(year1+"년 "+month1+"월 "+date1+"일")
        setDetails(_details);
    }



    return (
        <Box className="MainContainer" sx={{ ...pageContentConstraint, mt:'20px' }}>
            <Paper elevation={5} className="pageContainer" >
                <div className="orderDetailDetailContainer">
                    <div className="orderDetailDetailWrap">
                        <div className="orderDetailDetailTitle">결제상세</div>
                        <div className="orderDetailDetailWrapWrap">
                            <div className="orderDetailDetailWrapWrapWrap">
                                <span className="orderDetailDetailNorMalText">주문금액</span>
                                <span className="orderDetailDetailNorMalText">{details?.productPrice} 원</span>
                            </div>
                            <div className="orderDetailDetailWrapWrapWrap">
                                <span className="orderDetailDetailDiscount">할인</span>
                                <span className="orderDetailDetailDiscount">{details?.paidAmount==details?.productPrice ? 0 : "-"+((details?.productPrice||0) -( details?.paidAmount||0))} 원</span>
                            </div>
                            <div className="orderDetailDetailWrapWrapWrap mt-20">
                                <span className="orderDetailDetailNorMalText">결제금액</span>
                                <span className="orderDetailDetailNorMalText">{(details?.paidAmount||0)} 원</span>
                            </div>
                        </div>
                    </div>

                    <div className="orderDetailDetailWrap">
                        <div className="orderDetailDetailTitle">결제방법</div>
                        <div className="orderDetailDetailWrapWrap">
                            <div className="orderDetailDetailWrapWrapWrap">
                                <span className="orderDetailDetailNorMalText">카드 간편결제</span>
                                <span className="orderDetailDetailNorMalText">{details?.cardName}</span>
                            </div>
                            <div className="orderDetailDetailWrapWrapWrap">
                                <span className="orderDetailDetailDiscount">({details?.cardNumber})</span>
                                <span className="orderDetailDetailDiscount"></span>
                            </div>
                            <div className="orderDetailDetailWrapWrapWrap mt-20">
                                <span className="orderDetailDetailNorMalText">할부</span>
                                <span className="orderDetailDetailNorMalText">일시불</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="orderDetailDetailPayment mt-20">
                    <table className="table-wrapper">
                        <thead>
                        <tr>
                            <th className="tableTitle">결제날짜</th>
                            <th className="tableTitle">설명/구분</th>
                            <th className="tableTitle">서비스 기간</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>

                            <td>{year+"년 "+month+"월 "+date+"일"}</td>
                            <td>{details?.productPaymentType==="SINGLE" ? "일반" : "패키지"}</td>
                            <td>{year+"년 "+month+"월 "+date+"일"} ~ {serviceDate} </td>

                        </tr>

                        </tbody>
                    </table>
                </div>
                {/*<div className="ButtonContainer mt-40"><button className="basic2Button">영수증 발급</button></div>*/}
                {/*
                <Box className="page_body">
                    <Box className="page_body_item">
                        <span>수령인</span><span>{userInfo.nickname}</span>
                    </Box>
                    <Box className="page_body_item">
                        <span>연락처</span><span>{userInfo.phone}</span>
                    </Box>
                </Box>
                */}
            </Paper>


        </Box>
    );
}

export default Details;