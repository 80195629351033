interface Props {
  suriNonsulFilter: string[]
  engFilter: string[]

  onClickNonsulFilterButton: (type: string) => void
}

const SuriNonsulAndEngFilter = ({ suriNonsulFilter, engFilter, onClickNonsulFilterButton }: Props) => {
  return (
    <>
      <div className="selectUnivConditionContainer">
        <div className="title">
          <p>수리논술과 영어제시문 출제 대학 확인</p>
        </div>
        <div className="selectBox">
          <div className="">
            <div className="selectLine d-flex">
              <p>수리논술</p>
              {[
                { label: '전체', code: 'N' },
                { label: '통계(도표)', code: 'N1' },
                { label: '수리', code: 'N2' },
                { label: '언어논술 없는 수리논술', code: 'N4' },
              ].map((cond) => {
                return (
                  <button
                    onClick={() => onClickNonsulFilterButton(cond.code)}
                    className={
                      suriNonsulFilter.length === 3 ? 'select' : suriNonsulFilter.includes(cond.code) ? 'select' : ''
                    }
                    key={cond.label}
                  >
                    {cond.label}
                  </button>
                )
              })}
            </div>
            <div className="selectLine">
              <p>영어</p>
              <button
                className={engFilter.includes('N3') ? 'select' : ''}
                onClick={() => onClickNonsulFilterButton('N3')}
              >
                영어제시문
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </>
  )
}

export default SuriNonsulAndEngFilter
