import { Box, Stack, Typography } from '@mui/material'
import { tabletMediaQuery } from 'constants/style'
import { Link, useLocation } from 'react-router-dom'
import './SideNavigation.scss'

const urlMap: any = {
  strategy: [
    { sub: 'interestUniv', label: '나의 관심대학' },
    { sub: 'combination', label: '조합' },
    // { sub: 'jungsiPossibleUniv', label: '정시가능대학' },
  ],
  nonSul: [
    { sub: 'munGwa', label: '문과 논술' },
    { sub: 'liGwa', label: '이과 논술' },
    { sub: 'uiChiHanYacSu', label: '의치한약수 논술' },
  ],
  inputAndAnalysis: [
    { sub: 'hakJongInput', label: '생기부 입력' },
    { sub: 'gradeInput', label: '모의고사 성적 입력' },
    { sub: 'departmentCompatibility', label: '계열 적합성 진단' },
    { sub: 'saJungDan', label: '사정관 평가 신청' },
    { sub: 'nonSubject', label: '비교과 분석' },
  ],
  officer: [
    { sub: 'applylist', label: '평가 신청자 리스트' },
    { sub: 'profile', label: '프로필 관리' },
  ],
  gyoGwa: [{ sub: 'searchUniv/1', label: '대학검색' }],
  hakJong: [
    {
      sub: '',
      label: '학종',
    },
  ],
}

const SideNav = () => {
  const location = useLocation()

  const [firstPath, secondPath, thirdPath] = location.pathname.split('/').filter((x: any) => x)

  if (thirdPath === 'self') {
    return <></>
  }

  if (thirdPath === 'howTo') {
    return <></>
  }

  const menuName = {
    strategy: '지원전략',
    inputAndAnalysis: '입력과 분석',
    officer: '사정관',
  }[secondPath]

  if (!menuName) {
    return <></>
  }

  const menuList = urlMap[secondPath].map(({ sub, label }: { sub: string; label: string }, i: number) => {
    return (
      <Link to={`/${firstPath}/${secondPath}/${sub}`} key={i} state={{ initial: true }}>
        <Stack
          key={i}
          justifyContent={'center'}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              alignItems: 'center',
            },
            borderBottom: '1px solid',
            borderColor: 'grey.400',
            px: 2,
            py: 1,
            bgcolor: 'grey.100',
            height: '100%',
          })}
        >
          <Typography variant="D5_Regular, 14, 20">{label}</Typography>
        </Stack>
      </Link>
    )
  })

  return (
    <Stack
      sx={(theme) => ({
        left: 0,
        height: 'fit-content',
        position: 'sticky',
        top: '64px',
        width: '230px',
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'row',
          alignItems: 'stretch',
          width: '100%',
          position: 'static',
          marginBottom: 4,
        },
      })}
    >
      <Stack
        sx={(theme) => ({
          width: '230px',
          bgcolor: 'primary.main',
          justifyContent: 'center',
          color: 'white',
          fontWeight: 600,
          px: 2,
          py: 1.5,
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        })}
      >
        <Typography variant="D3_bold, 16, 24">{menuName}</Typography>
      </Stack>
      <Stack
        sx={(theme) => ({
          border: '1px solid',
          borderColor: 'grey.300',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            flex: 1,
            '& a': {
              flex: 1,
              borderRight: '1px solid #ddd',
            },
            '& p': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        })}
      >
        {menuList}
      </Stack>
    </Stack>
  )
}

export default SideNav
