import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { memberAxios } from 'api/instance'
function use학종_이용가능_체크() {
  const {
    data: purchaseDone,
    isLoading: purchase_loading,
    isSuccess: purchase_success,
    isError: purchase_error,
  } = useQuery('수시결제-체크', () =>
    memberAxios.get('/pearlyd/pay/check').then((x) => {
      return (x.data as { status: boolean }).status
    })
  )

  const {
    data: 생기부_입력완료,
    isLoading: 생기부_입력완료_loading,
    isSuccess: 생기부_입력완료_success,
    isError: 생기부_입력완료_error,
  } = useQuery('생기부-체크', () =>
    memberAxios.get('/pearlyd/check/schoolrecord').then((x) => {
      return (x.data as { status: boolean }).status
    })
  )

  const {
    data: 모의고사_입력완료,
    isLoading: 모의고사_입력완료_loading,
    isSuccess: 모의고사_입력완료_success,
    isError: 모의고사_입력완료_error,
  } = useQuery('모의고사_입력체크', () =>
    memberAxios.get('/mock/check/input').then((x) => {
      return (x.data as { status: boolean }).status
    })
  )

  const isAuthorizationLoading = purchase_loading || 생기부_입력완료_loading || 모의고사_입력완료_loading
  const isError = purchase_error || 생기부_입력완료_error || 모의고사_입력완료_error

  const isNotPurchased = purchase_success && !purchaseDone
  const isPurchased = purchase_success && purchaseDone
  const is생기부등록안됨 = 생기부_입력완료_success && !생기부_입력완료
  const is모의고사등록안됨 = 모의고사_입력완료_success && !모의고사_입력완료
  useEffect(() => {
    if (isNotPurchased) {
      alert('수시이용권을 결제하셔야 이용할 수 있습니다. 결제 페이지로 이동합니다.')
      window.location.href = '/suSi/inputAndAnalysis/hakJongInput'
    }

    if (isPurchased && is생기부등록안됨) {
      alert('생활기록부 입력이 선행되어야합니다. 생활기록부 입력 화면으로 이동합니다.')
      window.location.href = '/suSi/inputAndAnalysis/hakJongInput'
    }

    if (isPurchased && !is생기부등록안됨 && is모의고사등록안됨) {
      alert('모의고사 입력이 선행되어야합니다. 모의고사 입력 화면으로 이동합니다.')
      window.location.href = '/suSi/inputAndAnalysis/gradeInput'
    }
  }, [isNotPurchased, isNotPurchased, is생기부등록안됨])

  useEffect(() => {
    if (isError) {
      console.log({ isNotPurchased, is생기부등록안됨 })
    }
    if (isError) {
      alert('에러 발생하였습니다. 관리자에게 문의부탁드립니다.')
      window.location.href = '/'
    }
  }, [isError])

  return { isAuthorizationLoading }
}

export default use학종_이용가능_체크
