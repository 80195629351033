import React from 'react'
import { RiDeleteBinLine } from 'react-icons/ri'

type Props = {
  onClick: (e: React.MouseEvent) => void
}

const DeleteButton = ({ onClick }: Props) => {
  return (
    <button style={{ textDecoration: 'underline' }} onClick={onClick}>
      <RiDeleteBinLine fontSize={18} fill="#FF5600" />
    </button>
  )
}

export default DeleteButton
