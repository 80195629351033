import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionSummary, Box, Skeleton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { createColumnHelper } from '@tanstack/react-table'
import DetailModal from 'DetailModal/DetailModal'
import K_상세보기_교과모달_내용 from 'DetailModal/Subject/K_상세보기_교과모달_내용'
import { memberAxios } from 'api/instance'
import InfoBadge from 'components/InfoBadge'
import Center from 'components/common/Center'
import DetailButton from 'components/common/DetailButton'
import RiskIndicator from 'components/common/RiskIndicator'
import StackX from 'components/common/StackX'
import StackY from 'components/common/StackY'
import TurtlePaper from 'components/common/TurtlePaper'
import MessageCard from 'components/layout/EmptyPageLayout'
import usePrevious from 'hooks/usePrevious'
import _ from 'lodash'
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Setter } from 'types/utilType'
import { getClampedRiskScore, getSelected } from 'utils/util'
import BaseTable, { baseTableStyle2 } from '../common/BaseTable'
import ConditionMessage from '../common/ConditionMessage'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import { StepPagesContext } from '../common/StepPagesContext'

type Props = {}

type EarlydSubjectRecruitmentUnit = {
  collegeName: string
  competitionRate: string
  risk50: string
  risk70: string
  recruitmentNum: string
  recruitmentUnit: string
  recruitmentUnitId: string
  selectionModel: string
  myScore: string
  myRiskScore: string
}

const TableCollectionContext = createContext<{
  globalRowSelection: Record<string, boolean>
  setGlobalRowSelection: Setter<Record<string, boolean>>
  grouped: _.Dictionary<EarlydSubjectRecruitmentUnit[]>

  setModalOpen: Setter<boolean>
  setDetailRecruitmentId: Setter<number | null>
}>({
  globalRowSelection: {},
  setGlobalRowSelection: () => {},
  grouped: {},
  setModalOpen: () => {},
  setDetailRecruitmentId: () => {},
})

const Step4 = (props: Props) => {
  const { stepState, setBundle } = useContext(StepPagesContext)
  const riskIndicators = useMemo(() => {
    return {
      1: <RiskIndicator riskScore={1} height={25} />,
      2: <RiskIndicator riskScore={2} height={25} />,
      3: <RiskIndicator riskScore={3} height={25} />,
      4: <RiskIndicator riskScore={4} height={25} />,
      5: <RiskIndicator riskScore={5} height={25} />,
    }
  }, [])
  const [modalOpen, setModalOpen] = useState(false)
  const [detailRecruitmentId, setDetailRecruitmentId] = useState<number | null>(null)

  const recruitmentUnitIdList = stepState[3].bundle.selected?.flatMap((x: any) => x.split(',').map(Number))

  const { data, isLoading, isError } = useQuery(['step4-모집단위', recruitmentUnitIdList?.join('$')], () => {
    return memberAxios
      .post('/pearlyd/subject/recruitment-unit/colleges', {
        recruitmentUnitIdList,
      })
      .then((x) => x.data.earlydSubjectRecruitmentUnitList as EarlydSubjectRecruitmentUnit[])
  })

  const dataList = data ?? []
  const grouped = useMemo(() => _.groupBy(dataList, 'collegeName'), [data])
  const collegeNames = Object.keys(grouped)

  const [globalRowSelection, setGlobalRowSelection] = useState<Record<string, boolean>>({})
  useEffect(() => {
    const selected = getSelected(globalRowSelection).map(Number)
    setBundle({
      selected,
      selectedData: selected.map((selectedId) =>
        dataList.find((x) => String(x.recruitmentUnitId) === String(selectedId))
      ),
    })
  }, [globalRowSelection])

  if (isLoading) {
    return (
      <TurtlePaper>
        <Skeleton variant="rounded" sx={{ width: '100%', height: '500px', bgcolor: 'grey.100' }} />
      </TurtlePaper>
    )
  }

  if (isError) {
    return <MessageCard title="오류가 발생하였습니다." contents="관리자에게 문의하여 주십시오." />
  }

  const isAnyRowSelected = getSelected(globalRowSelection).length > 0

  return (
    <TableCollectionContext.Provider
      value={{ globalRowSelection, setGlobalRowSelection, grouped, setModalOpen, setDetailRecruitmentId }}
    >
      <Stack>
        <StackX alignItems={'end'} gap={'10px'} mb={3}>
          <Typography variant="H4_Exbold, 24, 32" fontWeight={700}>
            {'모집단위 선택'}
          </Typography>
          <InfoBadge
            placement="right"
            tooltipContent={
              <Box width={'305px'}>
                <Typography variant="H4_Exbold, 24, 32">실제 대학의 점수계산식이에요.</Typography>
                <Typography mt={1}>
                  실제 대학에서 사용하는 점수 계산식으로 점수를 계산했어요. 대학별로 만점기준과 식이 달라요.
                </Typography>
                <Stack mt={3} direction={'row'}>
                  <Typography color={'#FF5600'} variant="D3_bold, 16, 24">
                    {'ex)'}
                  </Typography>
                  <StackY ml={2} color={'#9A9A9A'}>
                    <Typography variant="D3_bold, 16, 24"> Σ(기준점수 × 과목별 이수단위)</Typography>
                    <Box height={'1px'} bgcolor={'#9A9A9A'}></Box>
                    <Typography variant="D3_bold, 16, 24" textAlign={'center'}>
                      {' '}
                      전과목 이수단위의 합
                    </Typography>
                  </StackY>
                </Stack>
              </Box>
            }
          />
        </StackX>
        {collegeNames.map((collegeName, index) => (
          <Accordion
            key={index}
            defaultExpanded={index === 0}
            sx={{
              '&.Mui-expanded': { margin: '0 !important' },
              '& .MuiAccordionSummary-root.Mui-expanded ': { borderTop: '1px solid #c4c4c4 !important' },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header-${index}`}
            >
              <Typography>{collegeName}</Typography>
            </AccordionSummary>
            <Box px={2}>
              <SubTable collegeName={collegeName} riskIndicators={riskIndicators}></SubTable>
            </Box>
          </Accordion>
        ))}
        <ConditionMessage show={getSelected(globalRowSelection).length === 0} text="체크 박스를 선택해주십시오." />
        <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />
      </Stack>

      <DetailModal
        param={detailRecruitmentId}
        open={modalOpen}
        setOpen={setModalOpen}
        Contents={K_상세보기_교과모달_내용}
      />
    </TableCollectionContext.Provider>
  )
}

const SubTable = ({
  collegeName,
  riskIndicators,
}: {
  collegeName: string
  riskIndicators: Record<number, ReactNode>
}) => {
  const { grouped, setDetailRecruitmentId, setModalOpen, setGlobalRowSelection } = useContext(TableCollectionContext)

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const previousSelection = usePrevious(rowSelection)

  useEffect(() => {
    setGlobalRowSelection((prev) => {
      const newObj = { ...prev }
      _.keys(previousSelection).forEach((key) => (newObj[key] = false))
      _.keys(rowSelection).forEach((key) => (newObj[key] = true))
      return newObj
    })
  }, [rowSelection])

  const createTable = (collegeName: keyof typeof grouped) => {
    const columnHelper = createColumnHelper<EarlydSubjectRecruitmentUnit>()
    const columns = [
      columnHelper.accessor('recruitmentUnitId', {
        id: 'recruitmentUnitId',
      }),
      columnHelper.accessor((row) => row.recruitmentUnit, {
        id: '모집단위',
        header: () => '모집단위',
        minSize: 150,
      }),
      columnHelper.accessor('recruitmentNum', {
        header: () => '정원',
        size: 60,
      }),
      columnHelper.accessor('competitionRate', {
        header: () => '작년 경쟁률',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('selectionModel', {
        header: () => '선발유형',
      }),
      columnHelper.accessor('risk50', {
        header: () => (
          <>
            <p>{'최초컷'}</p>
            <Typography fontSize={12} lineHeight={0.8}>
              (환산점수)
            </Typography>
          </>
        ),
      }),
      columnHelper.accessor('risk70', {
        header: () => (
          <>
            <p>{'추합컷'}</p>
            <Typography fontSize={12} lineHeight={0.8}>
              (환산점수)
            </Typography>
          </>
        ),
        cell: (x) => Number(x.getValue()).toFixed(1),
      }),
      columnHelper.accessor('myScore', {
        header: () => (
          <>
            <p>{'환산점수'}</p>
            <Typography fontSize={12} lineHeight={0.8}>
              (환산점수)
            </Typography>
          </>
        ),
        cell: (x) => Number(x.getValue()).toFixed(1),
      }),
      columnHelper.accessor('myRiskScore', {
        header: () => '위험도',
        cell: (info) => (
          <Center>
            {riskIndicators[getClampedRiskScore(info.getValue()) as keyof typeof riskIndicators]}
            {/* <RiskIndicator riskScore={info.getValue()} height={25} /> */}
          </Center>
        ),
        size: 70,
      }),
      columnHelper.display({
        id: 'detail',
        maxSize: 80,
        header: () => <span style={{ whiteSpace: 'nowrap' }}>상세보기</span>,
        cell: ({ row }) => (
          <Stack justifyContent={'center'} alignItems={'center'}>
            <DetailButton
              onClick={() => {
                setDetailRecruitmentId(Number(grouped[collegeName][row.index].recruitmentUnitId))
                setModalOpen(true)
              }}
            />
          </Stack>
        ),
      }),
    ]

    return (
      <BaseTable
        // enablePagination
        // pageSize={pageSize}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        tableStyle={baseTableStyle2}
        columns={columns}
        data={grouped[collegeName]}
        enableSelection
        columnVisibility={{ recruitmentUnitId: false }}
        getRowId={(row) => row.recruitmentUnitId}
        searchInfo={{ label: '모집단위 검색', value: 'recruitmentUnit', mode: true }}
      />
    )
  }
  const table = useMemo(() => createTable(collegeName), [grouped, setDetailRecruitmentId, setModalOpen, rowSelection])
  return <>{table}</>
}

export default Step4
