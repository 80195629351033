import { createColumnHelper } from '@tanstack/react-table'
import { TextBox, TitledItem, 수시전형_상세보기_테이블스타일 } from 'DetailModal/common'
import TurtleTable2 from 'components/common/TurtleTable2'
import { useContext } from 'react'
import { StudentDetailModalContext, 학종상세_전형정보 } from './K_상세보기_학종모달_내용'
import { Typography } from '@mui/material'

type Props = {}

const 기본정보테이블 = () => {
  const { typeInfoData } = useContext(StudentDetailModalContext)

  const columnHelper = createColumnHelper<학종상세_전형정보>()
  const columns = [
    columnHelper.accessor('collegeRegion', {
      header: '지역',
    }),
    columnHelper.accessor('competitionRate', {
      header: '작년 경쟁률',
    }),
    columnHelper.accessor('typeName', {
      header: '전형유형',
    }),
    columnHelper.accessor('centerType', {
      header: '기본전형',
    }),
    columnHelper.accessor('basicTypeAdmission', {
      header: '전형명',
    }),
    columnHelper.accessor('recruitmentNum', {
      header: '모집인원',
    }),
  ]
  return <TurtleTable2 columns={columns} data={[typeInfoData ?? {}]} tableStyle={수시전형_상세보기_테이블스타일} />
}

const 전형방법테이블 = () => {
  const { typeInfoData } = useContext(StudentDetailModalContext)

  if (!typeInfoData) return <></>

  const { selectionModel, subjectRate, paperRate, interviewRate } = typeInfoData

  const columnHelper = createColumnHelper<학종상세_전형정보>()

  const columns = [
    columnHelper.accessor('selectionModel', {
      header: '선발모형',
    }),
    columnHelper.accessor('interviewRate', {
      id: '서류평가',
      header: '서류평가',
      cell: (info) => 100 - Number(info.getValue()) + '%',
    }),
    columnHelper.accessor('interviewRate', {
      header: '면접평가',
      cell: (info) => info.getValue() + '%',
    }),
    columnHelper.display({
      id: '합계',
      header: '합계',
      cell: '100%',
    }),
  ]

  const data = [
    {
      selectionModel,
      subjectRate,
      paperRate,
      interviewRate,
    },
  ]

  return (
    <TurtleTable2
      columns={columns}
      sx={{ '& td:first-of-type': { bgcolor: '#e9e9e9' } }}
      data={data}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const 면접평가_테이블 = () => {
  const { typeInfoData } = useContext(StudentDetailModalContext)

  if (!typeInfoData) return <></>

  const { interviewType, interviewDate, interviewTime, interviewPaper } = typeInfoData

  const columnHelper = createColumnHelper<학종상세_전형정보>()
  //면접유형 면접날짜 면접시간 면접시 서류종류
  const columns = [
    columnHelper.accessor('interviewType', {
      header: '면접유형',
    }),
    columnHelper.accessor('interviewDate', {
      header: '면접날짜',
    }),
    columnHelper.accessor('interviewTime', {
      header: '면접시간',
    }),
    columnHelper.accessor('interviewPaper', {
      header: '면접시 서류종류',
    }),
  ]

  const data = [
    {
      interviewType,
      interviewDate,
      interviewTime,
      interviewPaper,
    },
  ]

  return (
    <TurtleTable2
      columns={columns}
      sx={{ '& td:first-of-type': { bgcolor: '#e9e9e9' } }}
      data={data}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const K_상세보기_전형정보탭 = (props: Props) => {
  const { typeInfoData } = useContext(StudentDetailModalContext)

  const interviewText = typeInfoData?.interviewText
  const prettierInterviewText = (interviewText ?? '')
    .split('•')
    .flatMap((x) => x.split('<br/>'))
    .map((x) => <Typography mt={0.5}>{x}</Typography>)

  return (
    <>
      <TitledItem title="기본정보">
        <기본정보테이블 />
      </TitledItem>
      <TitledItem title="지원자격">
        <TextBox>{typeInfoData?.supportQualificationText}</TextBox>
      </TitledItem>
      <TitledItem title="전형방법">
        <전형방법테이블 />
      </TitledItem>
      <TitledItem title="최저등급">
        <TextBox>{typeInfoData?.lowestText === '-' ? '없음' : typeInfoData?.lowestText}</TextBox>
      </TitledItem>
      <TitledItem title="면접평가">
        <면접평가_테이블 />
      </TitledItem>
      <TitledItem title="면접안내">
        <TextBox>{typeInfoData?.interviewText === '-' ? '없음' : prettierInterviewText}</TextBox>
      </TitledItem>
    </>
  )
}

export default K_상세보기_전형정보탭
