import { Stack, StackProps } from '@mui/system'
import React from 'react'

type Props = {}

const StackX = (props: StackProps) => {
  return (
    <Stack direction={'row'} alignItems={'center'} {...props}>
      {props.children}
    </Stack>
  )
}

export default StackX
