import React, { PureComponent, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { reverseGradeValue } from 'utils/util'
// import './Step1Chart.scss'

import TurtleTowLineAxisTick from 'components/common/TurtleTowLineAxisTick'
import {
  Brush,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import WithRechartResponsive from 'hoc/WithRechartResponsive'
import { BoxProps } from '@mui/material'

type Step6ChartDataItem = {
  collegeName: string
  cut50: number
  myScore: number
  recruitmentUnit: string
  applicationDetailedType: string
}

type Step2ChartProps = {
  data: Step6ChartDataItem[]
}

export default function Step6Chart({ data, ...boxProps }: Step2ChartProps & BoxProps) {
  const isSmallDeviceWidth = useMediaQuery({ maxWidth: 800 })
  const ref = useRef<HTMLDivElement | null>(null)
  const NILL = -999
  const [refPivot, setReferenceLinePivot] = useState({ x: NILL, y: NILL })
  const rerender = React.useReducer(() => ({}), {})[1]

  const chartDom = (ref?.current as any)?.current

  const line = chartDom?.querySelector('.recharts-reference-line-line') as SVGLineElement
  const x1 = Number(line?.getAttribute('x1'))
  const y1 = Number(line?.getAttribute('y1'))

  useEffect(() => {
    if (isNaN(x1) || isNaN(y1)) {
      rerender()
      return
    }
    setReferenceLinePivot({ x: x1 ?? NILL, y: y1 ?? NILL })
  }, [x1, y1, line])

  const windowSize = isSmallDeviceWidth ? 4 : 6

  data = data ?? []
  let indexedData = data.map((item, index) => ({
    ...item,
    index,
    cut50: reverseGradeValue(Number(item.cut50)),
    xLabelData: `${item.collegeName}/${item.recruitmentUnit}/${item.applicationDetailedType}`,
  }))

  const refLabelW = 60
  const refLabelH = 21
  const myGrade = data[0]?.myScore
  const barSize = 120

  return (
    <WithRechartResponsive
      {...boxProps}
      responsiveWidth={Math.max(1188, barSize * data.length)}
      height={'500px'}
      ref={ref}
    >
      <ComposedChart data={indexedData} margin={{ left: 10, right: 35 }}>
        <XAxis
          type="category"
          interval={0}
          tickLine={false}
          height={65}
          dataKey="xLabelData"
          tick={<TurtleTowLineAxisTick />}
        />
        <YAxis
          interval={0}
          type="number"
          tickCount={7}
          axisLine={false}
          tickLine={false}
          domain={[3, 9]}
          tick={<YAxisTick />}
        />
        <CartesianGrid vertical={false} color="#D9D9D9" z={1} />
        <Line dataKey="cut50" fill="#103abf" name={'최초컷'} />
        <Legend
          fill="#ff0000"
          verticalAlign="top"
          layout="horizontal"
          align="right"
          wrapperStyle={{
            paddingLeft: '10px',
            paddingBottom: '30px',
            fontSize: 14,
            fontWeight: 700,
          }}
        />
        <Tooltip
          formatter={(x: number) => {
            return `${reverseGradeValue(x).toFixed(2)}`
          }}
        />
        <defs>
          <clipPath id="round-corner">
            <rect
              x={refPivot.x - refLabelW / 2}
              y={refPivot.y - refLabelH}
              width={refLabelW * 1.5}
              height={refLabelH * 2}
              rx={refLabelH}
              ry={refLabelH}
            />
          </clipPath>
        </defs>
        <rect
          clipPath="url(#round-corner)"
          x={refPivot.x}
          y={refPivot.y}
          width={refLabelW}
          height={refLabelH}
          stroke="#D8145C"
          fill="#D8145C"
        />
        <ReferenceLine y={reverseGradeValue(myGrade)} stroke="#D8145C" strokeWidth={2}>
          <Label
            value="내 위치"
            offset={6}
            position="insideTopLeft"
            fill="#ffffff"
            filter="url(#solid)"
            fontSize={11}
            fontWeight={700}
          />
        </ReferenceLine>
        {/* <ReferenceLine y={reverseGradeValue(myGrade)} stroke="#D8145C" strokeWidth={2}>
          <Label value="내 성적" offset={6} position="insideTopLeft" fill="#FFF" fontSize={11} fontWeight={700} />
        </ReferenceLine> */}
      </ComposedChart>
    </WithRechartResponsive>
  )
}

class YAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props as {
      x: number
      y: number
      stroke: string
      payload: any
    }

    const val = reverseGradeValue(payload.value)
    // const postfix = val === 1 || val === 7 ? '등급' : ''

    return (
      <g transform={`translate(${x},${y})`}>
        <text fontSize={14} fill="#9A9A9A" textAnchor="end">
          {reverseGradeValue(payload.value) + '등급'}
        </text>
      </g>
    )
  }
}
