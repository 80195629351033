import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { Box, Stack, Theme } from '@mui/system'
import TurtlePaper from 'components/common/TurtlePaper'
import TurtleTable2 from 'components/common/TurtleTable2'
import TurtleTabs from 'components/common/TurtleTabs'
import { TextBox, TitledItem, 수시전형_상세보기_테이블스타일 } from 'DetailModal/common'
import { createColumnHelper } from '@tanstack/react-table'
import { SubjectDetailModalContext } from './K_상세보기_교과모달_내용'
import K_상세보기_동일대학타학과_차트 from './K_상세보기_동일대학타학과_차트'
import K_상세보기_타대학_동일계열 from './K_상세보기_타대학_동일계열'
import RiskIndicator from 'components/common/RiskIndicator'
import Center from 'components/common/Center'

type Props = {}

const 교과_테이블 = () => {
  const {
    recruitmentUnitInfo: { typeTotalScore, cut50 },
    earlydGyoGwaPredictionPassInfo: { totalGyoGwaScore, risk50, myScore, differenceScore, myRiskScore},
  } = useContext(SubjectDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  //TODO : 차이 위험도 대학별내점수
  const columns = [
    columnHelper.accessor('totalGyoGwaScore', {
      header: '전형총점',
    }),
    columnHelper.accessor('risk50', {
      header: '최초컷 (환산점수)',
    }),
    columnHelper.accessor('myScore', {
      header: '대학별 내 점수',
    }),
    columnHelper.accessor('differenceScore', {
      header: '차이',
      cell: (info) => <Typography color={info.getValue() > 0 ? '#1C4BC3' : '#FF5F52'}>{info.getValue()}</Typography>,
    }),
    columnHelper.accessor('myRiskScore', {
      header: '위험도',
      cell: (info) => {
        return (
          <Center>
            <RiskIndicator riskScore={info.getValue()} height={30} />
          </Center>
        )
      },
    }),
  ]

  return (
    <TurtleTable2
      columns={columns}
      data={[
        {
          totalGyoGwaScore,
          risk50,
          myScore,
          differenceScore,
          myRiskScore,
          // typeTotalScore,
          // cut50,
        },
      ]}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const K_상세보기_합격예측탭 = (props: Props) => {
  return (
    <>
      <TitledItem title="교과">
        <교과_테이블 />
      </TitledItem>
      <Stack
        sx={(theme) => ({
          width: '100%',
          height: '100%',
          minWidth: 0,
          maxWidth: '100%',
          [theme.breakpoints.down('lg')]: { flexWrap: 'wrap' },
        })}
        direction={'row'}
        gap={2}
      >
        <TitledItem title="동일대학 타학과" sx={{ flexGrow: 1, height: '500px', minWidth: 0, width: '50%' }}>
          <K_상세보기_동일대학타학과_차트 />
        </TitledItem>
        <TitledItem title="타대학 동일계열" sx={{ flexGrow: 1, height: '500px', minWidth: 0, width: '50%' }}>
          <K_상세보기_타대학_동일계열 />
        </TitledItem>
      </Stack>

      <TitledItem title="면접이 포함된 경우의 유의사항">
        <TextBox>
          면접때 비중있게 보는 것 중의 하나가, 계열과 관련된 활동과 선택과목 이수여부와 성적입니다.
          <br />
          아래 선택과목 이수 여부와 성적을, 지원 여부를 결정하는데 참조하시기 바랍니다.
        </TextBox>
      </TitledItem>
    </>
  )
}

export default K_상세보기_합격예측탭
