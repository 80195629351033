import { createColumnHelper } from '@tanstack/table-core'
import DetailModal from 'DetailModal/DetailModal'
import K_상세보기_학종모달_내용 from 'DetailModal/Student/K_상세보기_학종모달_내용'
import { memberAxios } from 'api/instance'
import Center from 'components/common/Center'
import DetailButton from 'components/common/DetailButton'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import React, { useContext, useEffect, useState } from 'react'
import BaseTable from '../common/BaseTable'
import { CollegeSaveDone } from '../common/CollegeSaveDone'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import { getSelected } from 'utils/util'
import ConditionMessage from '../common/ConditionMessage'

type Props = {}
type Step11DataItem = {
  collegeName: string
  interviewDate: string
  interviewTime: string
  lowest: string
  lowestText: string
  recruitmentUnit: string
  recruitmentUnitId: string
  typeName: string
}

function Step11({}: Props) {
  const { stepState } = useContext(StepPagesContext)
  const previousSelectedList = stepState[10].bundle.selected ?? []
  const { cClass, regionRange } = stepState[1].bundle ?? []

  const [rowSelection, setRowSelection] = useBundledRowSelection(previousSelectedList, (_, id) => id)

  const [modalOpen, setModalOpen] = useState(false)
  const [detailParam, setDetailParam] = useState<{
    recruitmentUnitId: number
    regionRange: string
    rowSeries: string
    college: string
    major: string
  } | null>(null)
  console.log(detailParam)

  const [showDoneBox, setShowDoneBox] = React.useState(false)

  useEffect(() => {
    setShowDoneBox(false)
  }, [rowSelection])

  const columnHelper = createColumnHelper<Step11DataItem>()
  const columns = [
    columnHelper.accessor('recruitmentUnitId', {
      id: 'recruitmentUnitId',
    }),
    columnHelper.accessor('collegeName', {
      header: '대학명',
    }),
    columnHelper.accessor('typeName', {
      header: '전형명',
    }),
    columnHelper.accessor('recruitmentUnit', {
      header: '모집단위',
    }),
    columnHelper.accessor('interviewDate', {
      header: '전형일',
    }),
    columnHelper.accessor('interviewTime', {
      header: '면접시간',
    }),
    columnHelper.display({
      id: 'detail',
      header: '상세보기',
      size: 60,
      cell: ({ row }) => (
        <DetailButton
          onClick={() => {
            const { recruitmentUnitId, collegeName, recruitmentUnit } = previousSelectedList[row.index]
            setDetailParam({
              recruitmentUnitId,
              rowSeries: cClass,
              regionRange,
              college: collegeName,
              major: recruitmentUnit,
            })
            setModalOpen(true)
          }}
        />
      ),
    }),
  ]
  const isAnyRowSelected = getSelected(rowSelection).length > 0

  return (
    <SubtitleSection title="전형일자 확인" mt={'50px'}>
      <TurtlePaper>
        <BaseTable
          columns={columns}
          data={previousSelectedList}
          enableSelection
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          getRowId={(row) => {
            return String(row.recruitmentUnitId)
          }}
          columnVisibility={{
            recruitmentUnitId: false,
          }}
          searchInfo = {{label:"모집단위 검색",value:"recruitmentUnit",mode:true}}
        />
      </TurtlePaper>
      {isAnyRowSelected ? (
        <Center>
          <CollegeSaveDone show={showDoneBox} />
        </Center>
      ) : (
        <ConditionMessage show={!isAnyRowSelected} text="관심 전형을 선택해주십시오." />
      )}
      <StepMoveButtonGroup
        isNextButtonEnabled={isAnyRowSelected}
        onNextClick={() => {
          const body = {
            list: stepState[8].bundle.selected
              .filter((item: any) => stepState[11].bundle.selected.includes(String(item.recruitmentUnitId)))
              .map((x: any) => ({
                collegeName: x.collegeName,
                recruitmentUnit: x.recruitmentUnit,
                recruitmentUnitId: x.recruitmentUnitId,
                riskDeGyoGwaScore: x.riskDeGyoGwaScore,
                riskGyoGwaScore: x.riskGyoGwaScore,
                riskSeriesScore: x.riskSeriesScore,
                totalRiskScore: x.totalRiskScore,
                typeName: x.typeName,
              })),
            regionRange: stepState[1].bundle.regionRange,
            seriesRiskScore: stepState[1].bundle.seriesRiskScore,
          }

          memberAxios.post('/pearlyd/sr/interest/add', body).then((res) => {
            if (res.data.status) {
              setShowDoneBox(true)
            }
          })
        }}
      />

      <DetailModal param={detailParam} open={modalOpen} setOpen={setModalOpen} Contents={K_상세보기_학종모달_내용} />
    </SubtitleSection>
  )
}

export default Step11
