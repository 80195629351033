import { Typography } from '@mui/material'
import _ from 'lodash'

export function formatDateYYYYMMDD(date: string) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export function str2Int(str: string) {
  return Number(str).toFixed(0)
}

const colorMap: Record<number, string> = {
  1: '#FF5F52',
  2: '#FF5600',
  3: '#00CA8D',
  4: '#3D94DE',
  5: '#1C4BC3',
}

export const riskLabelMap: Record<number, string> = {
  1: '위험',
  2: '도전',
  3: '소신',
  4: '적정',
  5: '안정',
}

export function getRiskText(riskValue: number | string) {
  riskValue = Number(riskValue)
  const clamped = getClampedRiskScore(riskValue)
  return riskLabelMap[clamped]
}

export function getRiskColor(riskValue: number | string) {
  riskValue = Number(riskValue)
  const clamped = getClampedRiskScore(riskValue)
  return colorMap[clamped]
}

export function getRiskLabel(riskValue: string | number) {
  const color = getRiskColor(riskValue)
  const label = getRiskText(riskValue)
  return (
    <Typography variant="D1_light, 18, 26" sx={{ color }}>
      {label}
    </Typography>
  )
}

export const reverseGradeValue = (value: number | string) => 10 - Number(value)

export const getClampedRiskScore = (score: number | string) => {
  return Math.floor(_.clamp(Number(score), 1, 5))
}

export const riskScoreToRiskLabel = (score: number | string) => {
  const labelMap: Record<number, string> = riskLabelMap
  let value = getClampedRiskScore(score)

  return (
    <Typography variant="D1_regular, 18, 26" color={colorMap[value]}>
      {labelMap[value]}
    </Typography>
  )
}

export const allRegions = [
  '전국',
  '서울',
  '경기',
  '인천',
  '대전',
  '세종',
  '충남',
  '충북',
  '광주',
  '전남',
  '전북',
  '대구',
  '경북',
  '경남',
  '부산',
  '울산',
  '강원',
  '제주',
]

export const getSelected = <T extends Object>(data: T) => {
  return _.keys(_.pickBy(data))
}

export const alertError = (errorMessage?: string) => {
  errorMessage = errorMessage ?? '에러 발생하였습니다. 관리자에게 문의부탁드립니다.'
  alert(errorMessage)
}

export const gradeMap = {
  7: 'A+',
  6: 'A',
  5: 'B+',
  4: 'B',
  3: 'C+',
  2: 'C',
  1: 'D',
  '0': 'error,0',
  '-1': 'error,-1',
} as Record<string, string>

export const getGradeFromScore = (score: number | string) => {
  const num = Math.ceil(Number(score))
  return gradeMap[_.clamp(num, 1, 7)]
}
