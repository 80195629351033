import { Box, Button, Stack, SxProps } from '@mui/material'
import { mainColor } from 'constants/style'
import React from 'react'
import { BiPlus } from 'react-icons/bi'

type Props = {
  onClick?: React.MouseEventHandler
  sx?: SxProps
}

const AddButton = ({ onClick, sx }: Props) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: '#adcc4a',
        borderRadius: '6px',
        width: 28,
        height: 28,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': { cursor: 'pointer' },
        ...sx,
      }}
    >
      <BiPlus fontSize={24} fill="#fff" />
    </Box>
  )
}

export default AddButton
