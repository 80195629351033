import { Stack, StackProps } from '@mui/system'
import React from 'react'

type Props = {}

const Center = (props: StackProps) => {
  return (
    <Stack justifyContent={'center'} alignItems={'center'} {...props}>{props.children}</Stack>
  )
}

export default Center