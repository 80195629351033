import { Stack } from '@mui/system'
import { createColumnHelper } from '@tanstack/react-table'
import DetailModal from 'DetailModal/DetailModal'
import K_상세보기_교과모달_내용 from 'DetailModal/Subject/K_상세보기_교과모달_내용'
import { memberAxios } from 'api/instance'
import DetailButton from 'components/common/DetailButton'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import BaseTable, { baseTableStyle2 } from '../common/BaseTable'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import { getSelected } from 'utils/util'
import { CollegeSaveDone } from '../common/CollegeSaveDone'
import Center from 'components/common/Center'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'

type Props = {}
type EarlydSubjectInterview = {
  collegeName: string //'경희대'
  interviewDate: string //"2023-07-06 or (면접없음)",
  interviewRate: string //"20%",
  interviewTime: string //"14:00 or (면접없음)",
  interviewTypeName: string //"0: 면접없음, 1:생기부기반, 2:제시문기반",
  recruitmentUnit: string // "경영학과",
  recruitmentUnitId: number
  selectionModel: string //"1차/2차 or 일괄합산",
  typeName: string //"네오르네상스"
}

const Step6 = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [detailRecruitmentId, setDetailRecruitmentId] = useState<number | null>(null)

  const { stepState, pageSize } = useContext(StepPagesContext)
  const step4SelectedList = stepState[4].bundle.selectedData
  const recruitmentUnitIdList = stepState[5].bundle.selected

  const { data } = useQuery(
    ['교과-step6-전형일자-확인', recruitmentUnitIdList?.join('$')],
    () => {
      return memberAxios
        .post('/pearlyd/subject/interview/colleges', {
          interviewList: [0, 1, 2],
          recruitmentUnitIdList,
        })
        .then((x) => {
          return x.data.earlydSubjectInterviewList as EarlydSubjectInterview[]
        })
    },
    { enabled: recruitmentUnitIdList && recruitmentUnitIdList?.length > 0 }
  )

  const dataList = data ?? []

  const [rowSelection, setRowSelection] = useBundledRowSelection(dataList, (_, identifier) => {
    return Number(identifier)
  })

  const [showSaveDone, setShowDoneBox] = useState(false)

  useEffect(() => {
    setShowDoneBox(false)
  }, [rowSelection])

  const columnHelper = createColumnHelper<EarlydSubjectInterview>()

  const columns = [
    columnHelper.accessor('recruitmentUnitId', {
      id: 'recruitmentUnitId',
    }),
    columnHelper.accessor('collegeName', {
      header: () => '대학명',
      minSize: 150,
    }),
    columnHelper.accessor('typeName', {
      header: () => '전형명',
    }),
    columnHelper.accessor('recruitmentUnit', {
      header: () => '모집단위',
    }),
    columnHelper.accessor('interviewDate', {
      header: () => '전형일',
    }),
    columnHelper.accessor('interviewTime', {
      header: () => '면접 시간',
    }),

    columnHelper.display({
      id: 'detail',
      maxSize: 80,
      header: () => '상세보기',
      cell: ({ row }) => (
        <Stack justifyContent={'center'} alignItems={'center'}>
          <DetailButton
            onClick={() => {
              setDetailRecruitmentId(dataList[row.index].recruitmentUnitId)
              setModalOpen(true)
            }}
          />
        </Stack>
      ),
    }),
  ]
  const isAnyRowSelected = getSelected(rowSelection).length > 0

  return (
    <>
      <SubtitleSection title="전형일자 확인">
        <TurtlePaper>
          <BaseTable
            enablePagination
            pageSize={25}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            tableStyle={baseTableStyle2}
            columns={columns}
            data={dataList}
            enableSelection
            columnVisibility={{ recruitmentUnitId: false }}
            getRowId={(row) => String(row.recruitmentUnitId)}
            searchInfo = {{label:"모집단위 검색",value:"recruitmentUnit",mode:true}}
          />
        </TurtlePaper>
        <Center>
          <CollegeSaveDone show={showSaveDone} />
        </Center>
        <StepMoveButtonGroup
          isNextButtonEnabled={isAnyRowSelected}
          onNextClick={() => {
            const earlydSubjectRecruitmentUnitList = step4SelectedList.filter(
              (x: any) => rowSelection[x.recruitmentUnitId]
            )
            memberAxios
              .post('/pearlyd/subject/interest/colleges/add', { earlydSubjectRecruitmentUnitList })
              .then((x) => {
                if (x.status === 200) {
                  setShowDoneBox(true)
                } else {
                  alert('오류가 발생하였습니다. 관리자에게 문의하여 주십시오.')
                }
              })
          }}
        />
      </SubtitleSection>

      <DetailModal
        param={detailRecruitmentId}
        open={modalOpen}
        setOpen={setModalOpen}
        Contents={K_상세보기_교과모달_내용}
      />
    </>
  )
}

export default Step6
