import use학종_이용가능_체크 from 'hooks/use학종_이용가능_체크'
import StepPages from '../common/StepPages'
import Step1 from './Step1'
import Step10 from './Step10'
import Step11 from './Step11'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import Step8 from './Step8'
import Step9 from './Step9'

type Props = {}

function K_학종_페이지({}: Props) {
  const { isAuthorizationLoading } = use학종_이용가능_체크()

  if (isAuthorizationLoading) return <></>

  const pages = [
    {
      label: '진로역량',
      page: <Step1 />,
    },
    {
      label: '학업역량',
      page: <Step2 />,
    },
    {
      label: '공동체역량',
      page: <Step3 />,
    },
    {
      label: '기타역량',
      page: <Step4 />,
    },
    {
      label: '생기부평가',
      page: <Step5 />,
    },
    {
      label: '대학선택',
      page: <Step6 />,
    },
    {
      label: (
        <>
          대학별
          <br />
          유불리
        </>
      ),
      page: <Step7 />,
    },
    {
      label: (
        <>
          3개 부문
          <br />
          종합진단
        </>
      ),
      page: <Step8 />,
    },
    {
      label: '조건검색',
      page: <Step9 />,
    },
    {
      label: '최저등급',
      page: <Step10 />,
    },
    {
      label: (
        <>
          전형일자
          <br />
          확인
        </>
      ),
      page: <Step11 />,
    },
  ]

  return <StepPages title="학종" pages={pages} px={2} />
}

export default K_학종_페이지
