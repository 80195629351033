import { memberAxios } from 'api/instance'
import { userAPI, userUpdateAPI } from 'api/urls'

export interface UserInfo {
  id: null | string
  address: null | string
  email: null | string
  graduateYear: null | string
  gtypeId: null | number
  highschoolName: null | string
  hstTypeId: null | number
  introduction: null | string
  isOfficer: boolean | null
  isTeacher: boolean | null
  nickname: string | null
  phone: string | null
  profileImageUrl: string | null
  stypeId: null | number
  usingService: string | null
  major: string | null
  ckSmsAgree: boolean | null
  serviceRangeCode: string | null
}

export const userInfoFetch = async (): Promise<UserInfo> => {
  const res = await memberAxios.get(userAPI)
  return res.data
}

export interface userUpdateParams {
  address: string
  gtypeId: string
  graduateYear: string
  hstTypeId: string
  introduction: string
  nickname: string
  profileImageUrl: string
  stypeId: string
  ckSmsAgree: string
  major: string
  [key: string]: string // 인덱싱 가능 타입 지정
}

interface userUpdateReturn {
  message: null | string
  status: boolean
}

export const userUpdateFetch = async (
  params: userUpdateParams,
  imageFile: HTMLInputElement | undefined
): Promise<userUpdateReturn> => {
  console.log('params [ ', params, ' ] ')
  const form = new FormData()
  // @ts-ignore

  if (imageFile != undefined) {
    const file = (imageFile?.files as FileList)[0]
    form.append('profileImageUrl', file)
  }

  form.append('data', JSON.stringify(params))

  const res = await memberAxios.post(userUpdateAPI, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res.data
}
