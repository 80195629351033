import { Box, ButtonBase, CircularProgress, FormControl, InputLabel, MenuItem, Stack, Typography } from '@mui/material'
import { memberAxios } from 'api/instance'
import userInfoStates from 'atoms/userInfoStates'
import SwitchButton from 'components/button/SwitchButton'
import Center from 'components/common/Center'
import RiskIndicator from 'components/common/RiskIndicator'
import RiskLabel from 'components/common/RiskLabel'
import StackX from 'components/common/StackX'
import StackY from 'components/common/StackY'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import { mainColor } from 'constants/style'
import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { Setter } from 'types/utilType'
import { getRiskColor, getSelected } from 'utils/util'
import BaseTable, { baseTableStyle2, getDefaultTurtleTableColumnModel } from '../common/BaseTable'
import ConditionMessage from '../common/ConditionMessage'
import { StepPagesContext } from '../common/StepPagesContext'
import { ExplainSection } from './common'
import { ReactComponent as CheckAllIcon } from 'assets/icon/select/check_all.svg'
import { ReactComponent as UncheckAllIcon } from 'assets/icon/select/uncheck_all.svg'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import InfoBadge from 'components/InfoBadge'
import { title } from 'process'
import { grade } from './../../../../api/fetches/menu'
import GradeCard from './GradeCard'
import { createColumnHelper } from '@tanstack/table-core'
import {
  getCommentAndScoreList,
  getEvaluatioinList,
  getEvaluationAvgAndGrade,
} from '../inputAndAnalysis/비교과/service'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import moment from 'moment'

type Props = {}

function Step1({}: Props) {
  const userInfo = useRecoilValue(userInfoStates)
  const is문과 = userInfo.major === 'LiberalArts'

  const {
    setBundle,
    commentList,
    setCommmentList,
    scoreList,
    serScoreList,
    avg,
    setAvg,
    step1Avg,
    setStep1Avg,
    setGrandSeries,
    setMiddleSeries,
    setRowSeries,
  } = useContext(StepPagesContext)

  const [evaluationList, setEvaluationList] = useState([])
  const [selectedEvaluationNo, setSelectedEvaluationNo] = React.useState('')

  const [grade, setGrade] = React.useState('')
  const [questionScoreData, setQuestionScoreData] = React.useState<any>({})
  const [jinroGrade, setJinroGrade] = React.useState('')

  useEffect(() => {
    fnSelectEvaluationList()
  }, [])

  const fnSelectEvaluationList = async () => {
    const _evaluationList = await getEvaluatioinList(userInfo.id)
    setEvaluationList(_evaluationList)
  }

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedEvaluationNo(event.target.value as string)
    let valueSpl = event.target.value.split('|')
    let index = Number(valueSpl[0])
    let series = valueSpl[1]
    let seriesSplt = series.split('>')
    let grand = seriesSplt[0]
    let middle = seriesSplt[1]
    let row = seriesSplt[2]
    setGrandSeries(grand)
    setMiddleSeries(middle)
    setRowSeries(row)
    fnSelectEvaluation(index)
  }

  const fnSelectEvaluation = async (id: any) => {
    const _evaluation = await getEvaluationAvgAndGrade(id)
    setAvg(_evaluation.avg.toFixed(2))
    setGrade(_evaluation.grade)
    fnSelectCommentAndScoreList(id)
  }

  const fnSelectCommentAndScoreList = async (id: any) => {
    const _data = await getCommentAndScoreList(id)
    setCommmentList(_data[0])
    serScoreList(_data[1])
    questionScoreData.officerCommentList = _data[0]
    questionScoreData.officerSurveyList = _data[1]
    setQuestionScoreData(questionScoreData)
    let jinro = convertScoreToGrade(_data[1])
    setJinroGrade(jinro)
  }

  const convertScoreToGrade = (data: any) => {
    let scoreSum = 0
    for (let i = 0; i < 7; i++) {
      scoreSum = scoreSum + data[i].score
    }

    let scoreAvg = scoreSum / 7
    setStep1Avg(scoreAvg)
    if (scoreAvg >= 6) {
      return 'A+'
    } else if (scoreAvg > 5) {
      return 'A'
    } else if (scoreAvg > 4) {
      return 'B+'
    } else if (scoreAvg > 3) {
      return 'B'
    } else if (scoreAvg > 2) {
      return 'C+'
    } else if (scoreAvg > 1) {
      return 'C'
    } else if (scoreAvg > 0) {
      return 'D'
    } else {
      return '-'
    }
  }

  //새롭게 구성한 함수 영역
  //-------------------------------------------------------------------------

  const analysisResultContentRef = useRef<HTMLDivElement>()

  const handleScrollToElement = () => {
    if (analysisResultContentRef.current) {
      analysisResultContentRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const [isRiskMeasureButtonClicked, setIsRiskMeasureButtonClicked] = React.useState<boolean>(false)

  useEffect(() => {
    if (isRiskMeasureButtonClicked) {
      setTimeout(() => {
        setShowAnalysisResult(true)
        setIsRiskMeasureButtonClicked(false)
      }, 1000)
    }
  }, [isRiskMeasureButtonClicked])

  const [showAnalysisResult, setShowAnalysisResult] = React.useState<boolean>(false)
  useEffect(() => {
    if (showAnalysisResult) {
      handleScrollToElement()
    }
  }, [showAnalysisResult])

  const [selectedScoreClass, setSelectedScoreClass] = useState<string>('')

  const [aClass, setAClass] = React.useState('')
  const [bClass, setBClass] = React.useState('')
  const [cClass, setCClass] = React.useState('')
  const [regionRangeDisable, setRegionRangeDisable] = useState<any>()
  const [dataCnt, setDatacnt] = useState<any>()
  // 개발 용으로 state 설정해놓음 위에 주석 풀어야함
  // const [aClass, setAClass] = React.useState('공학계열')
  // const [bClass, setBClass] = React.useState('전기. 전자. 컴퓨터')
  // const [cClass, setCClass] = React.useState('응용소프트웨어공학')

  useEffect(() => {
    setShowAnalysisResult(false)
    setIsRiskMeasureButtonClicked(false)
  }, [cClass, selectedScoreClass])

  useEffect(() => {
    setBClass('')
    setCClass('')
  }, [aClass])
  useEffect(() => {
    setCClass('')
  }, [bClass])
  useEffect(() => {
    setShowAnalysisResult(false)
  }, [cClass])

  let { data: aClasses } = useQuery(
    '학종-step1-대분류',
    () =>
      memberAxios
        .post('/menu/code/line/list', {
          series: '',
          type: 'G',
        })
        .then((res) => {
          return res.data as string[]
        }),
    { initialData: [] }
  )

  let { data: bClasses } = useQuery(
    ['학종-step1-중분류', aClass],
    () =>
      memberAxios
        .post('/menu/code/line/list', {
          series: aClass,
          type: 'M',
        })
        .then((res) => {
          return res.data as string[]
        }),
    { enabled: aClass !== '' }
  )
  const { data: cClasses, isFetching: isCClassFetching } = useQuery(
    ['학종-step1-소분류', bClass],
    () =>
      memberAxios
        .post('/menu/code/line/list', {
          series: bClass,
          type: 'R',
        })
        .then((res) => {
          return res.data as string[]
        }),
    { enabled: bClass !== '' }
  )

  //학교 리스트(불필요)
  let { data: recruitmentUnitData, isFetching } = useQuery(
    ['학종-step1-모집단위리스트', aClass, bClass, cClass],
    () =>
      memberAxios
        .post('/pearlyd/sr/rq/list', {
          grandSeries: aClass,
          middleSeries: bClass,
          rowSeries: cClass,
        })
        .then((res) => _.uniq(res.data.map((x: any) => x.recruitmentUnit) as string[])),
    { enabled: cClass !== '' }
  )

  recruitmentUnitData = recruitmentUnitData ?? []

  const [pickedUnits, setPickedUnits] = useState<Record<string, boolean>>({})
  const numPickedUnits = getSelected(pickedUnits).length
  const pickedRecruit = getSelected(pickedUnits)

  /**
   * 모집단위가 변경될때마다 조회하는 갯수가 달라짐
   */
  useEffect(() => {
    const keys = Object.keys(pickedUnits)
    const recruitmentUnitDataArray: any = []
    keys.forEach((key) => {
      if (pickedUnits[key]) {
        recruitmentUnitDataArray.push(key)
      }
    })
    //체크 초기화
    setSelectedScoreClass('')
    checkDataList(recruitmentUnitDataArray)
    //갯수체크
  }, [pickedUnits])
  const checkDataList = (recruitmentUnitDataArray: any) => {
    memberAxios
      .post('/pearlyd/sr/rq/ck/data', {
        grandSeries: aClass,
        middleSeries: bClass,
        rowSeries: cClass,
        recruitmentUnitList: recruitmentUnitDataArray,
      })
      .then((res) => {
        const result: any = {}
        const result2: any = {}
        if (res.data) {
          const data: [] = res.data
          data?.forEach((x: any): any => {
            const level: string = x.level
            result[level] = true
            result2[level] = x.cnt
          })
        }
        // result[]
        setRegionRangeDisable(result)
        setDatacnt(result2)
      })
  }

  useEffect(() => {
    const obj: any = {}
    for (const item of recruitmentUnitData ?? []) {
      obj[item] = true
    }
    setPickedUnits(obj)
  }, [cClass, recruitmentUnitData.length])

  let { data: step1ResultData } = useQuery(
    ['학종-step1-테이블데이터', cClass, selectedScoreClass, pickedRecruit.join('$')],
    () =>
      memberAxios
        .post('/newPearlyd/pearlyd/subject/rist/info', {
          grandSeries: aClass,
          middleSeries: bClass,
          rowSeries: cClass,
          regionRange: selectedScoreClass.replaceAll(' ', ''),
          recruitmentUnitList: pickedRecruit,
        })
        .then((res) => {
          return res.data as {
            list: {
              essentialFlag: '1' | '2'
              essentialGrade: number
              isCheck: 0 | 1 | 2
              mainSubjectName: string
              myGrade: string
              riskScore: string
              subjectName: string
            }[]
            totalScoreSum: number
            byCollegeLevelList: {
              collegeName: string
              recruitmentUnit: string
              typeName: string
            }[]
          }
        }),
    { enabled: cClass !== '' && selectedScoreClass !== '' }
  )

  const riskScore = is문과 ? 5 : Number(step1ResultData?.totalScoreSum)

  let { data: officerReviewData } = useQuery('학종-진로역량-평가', () =>
    memberAxios
      .get('/pearlyd/sr/officer-student-evaludate/avg-score/jinro/details')
      .then((x) => x.data as 학종_사정관평가데이터_형식)
  )

  const tableDataList = step1ResultData?.list ?? []

  useEffect(() => {
    setBundle((prev) => {
      const 종합진단_계열적합성 = is문과
        ? {}
        : {
            '필수과목 선택 과목 수': tableDataList.reduce(
              (acc, cur) => (cur.essentialFlag === '2' && cur.isCheck === 1 ? acc + 1 : acc),
              0
            ),
            '필수과목 미선택 과목 수': tableDataList.reduce(
              (acc, cur) => (cur.essentialFlag === '2' && cur.isCheck === 0 ? acc + 1 : acc),
              0
            ),
            '장려과목 선택 과목 수': tableDataList.reduce(
              (acc, cur) => (cur.essentialFlag === '1' && cur.isCheck === 1 ? acc + 1 : acc),
              0
            ),
            '장려과목 미선택 과목 수': tableDataList.reduce(
              (acc, cur) => (cur.essentialFlag === '1' && cur.isCheck === 0 ? acc + 1 : acc),
              0
            ),
            위험도: step1ResultData?.totalScoreSum,
          }

      const newObj = _.cloneDeep(prev)
      Object.assign(newObj, {
        종합진단_계열적합성,
        recruitmentUnitData: pickedRecruit,
        seriesRiskScore: riskScore,
        aClass,
        cClass,
        regionRange: selectedScoreClass,
      })
      return newObj
    })
  }, [step1ResultData, recruitmentUnitData.join('$')])

  const table = getTable(tableDataList)

  const isScoreClassSelected = selectedScoreClass !== ''
  const numTotalUnits = recruitmentUnitData.length

  const isNoTableData = !(tableDataList.length > 0)
  const isAnalysisResultButtonEnabled = isScoreClassSelected && cClass.length > 0

  const isNextButtonEnabled = showAnalysisResult && numPickedUnits > 0

  const preTableData = _.uniqBy(step1ResultData?.byCollegeLevelList ?? [], (x) => {
    return `${x.collegeName}__${x.typeName}__${x.recruitmentUnit}`
  })
  const columnHelper = createColumnHelper<(typeof preTableData)[0]>()

  return (
    <>
      <SubtitleSection
        //title="전공(계열)선택"
        title="사정관 평가 내역 선택"
        //subtitle="*이미 관심대학에 저장된 대학>모집단위는 아래 리스트에 나오지 않습니다."
        sx={{ mt: '50px' }}
      >
        {/* <TurtlePaper sx={{ px: '30px', py: '25px' }}>
          <Typography variant="D3_bold, 16, 24">목표계열 선택</Typography>
          <StackX mt={2} gap={1}>
            <TurtleSelectBox value={aClass} setValue={setAClass} label="대분류" menuList={aClasses} />
            <TurtleSelectBox
              disabled={aClass === ''}
              value={bClass}
              setValue={setBClass}
              label="중분류"
              menuList={bClasses}
            />
            <TurtleSelectBox
              disabled={bClass === ''}
              value={cClass}
              setValue={setCClass}
              label="소분류"
              menuList={cClasses}
            />
          </StackX>
          <Box sx={{ borderBottom: '1px solid #efefef', mt: 3 }}></Box>

          {cClass && (
            <>
              <StackX justifyContent={'space-between'} mt={3}>
                <Typography variant="D3_bold, 16, 24">포함되는 모집단위</Typography>
                <StackX>
                  <Typography
                    component={'span'}
                    sx={{ color: '#00CA8D', userSelect: 'none' }}
                    variant="D3_bold, 16, 24"
                  >
                    {`${numPickedUnits}`}
                  </Typography>
                  <Typography
                    component={'span'}
                    sx={{ color: '#C2C2C2', userSelect: 'none' }}
                    variant="D3_bold, 16, 24"
                  >
                    {`/${recruitmentUnitData.length}`}
                  </Typography>

                  <ButtonBase
                    onClick={() => {
                      const newObj: any = {}
                      recruitmentUnitData?.forEach((x) => {
                        newObj[x] = numPickedUnits !== numTotalUnits
                      })
                      setPickedUnits(newObj)
                    }}
                    sx={{
                      ml: 2,
                      p: '10px',
                      borderRadius: '10px',
                      border: '1px solid var(--gray-06-e-9-e-9-e-9, #E9E9E9)',
                      background: 'var(--gray-07-f-4-f-4-f-4, #F4F4F4)',
                    }}
                  >
                    {numPickedUnits === numTotalUnits ? <UncheckAllIcon /> : <CheckAllIcon />}
                    <Typography
                      ml={'4px'}
                      variant="D2_exBold, 16, 24"
                      sx={{
                        color: '#252525',
                      }}
                    >
                      {numPickedUnits === numTotalUnits ? '전체해제' : '전체선택'}
                    </Typography>
                  </ButtonBase>
                </StackX>
              </StackX>
              {recruitmentUnitData.length === 0 && (
                <Typography variant="body2" mt={3}>
                  모집단위가 없습니다.
                </Typography>
              )}
              <StackX mt={1} gap={1} sx={{ flexWrap: 'wrap' }}>
                {recruitmentUnitData.sort().map((unit, index) => (
                  <SwitchButton
                    key={`${unit}-${index}`}
                    selected={pickedUnits[unit] === undefined ? true : pickedUnits[unit]}
                    onClick={() => {
                      setPickedUnits((prev) => ({
                        ...prev,
                        [unit]: !(prev[unit] ?? false),
                      }))
                    }}
                  >
                    {unit}
                  </SwitchButton>
                ))}
              </StackX>
            </>
          )}
          {cClass && <Box sx={{ borderBottom: '1px solid #efefef', mt: 3 }}></Box>}
          {
            <Stack mt={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                <Typography variant="D3_bold, 16, 24">목표 성적대 선택 </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '14px',
                    marginLeft: '2px',
                    color: '#9a9a9a',
                  }}
                >
                  * 해당 목표 성적대 선택 학교만 출력됩니다. 또한 진로선택과목은 대학의 진로 선택 과목 등급 평균인, A는
                  1등급, B는 3등급, C는 5등급으로 환산합니다.
                </Typography>
              </Box>
              <Stack mt={2} display={'flex'} direction={'row'} gap={2} flexWrap={'wrap'}>
                {[
                  '의치한약수',
                  '서울상위권',
                  '서울중위권',
                  '서울하위권,경기상위권',
                  '경기중위권,지방상위권',
                  '경기하위권,지방중하위권',
                  '지방하위권',
                ].map((scoreClass, index) => {
                  const isSelected = selectedScoreClass === scoreClass
                  let isDisabled = false
                  let cnt = '0'
                  if (regionRangeDisable) {
                    isDisabled = regionRangeDisable[7 - index] === undefined ? true : false
                  }
                  if (dataCnt) {
                    cnt = dataCnt[7 - index] !== undefined ? dataCnt[7 - index] : '0'
                  }

                  return (
                    <ButtonBase
                      key={index}
                      onClick={(e) => {
                        setSelectedScoreClass(scoreClass)
                      }}
                      //현재 카테고리에 데이터 갯수가 없을경우 disabled처리
                      disabled={isDisabled}
                      sx={{
                        py: 2,
                        px: 3,
                        borderRadius: '9px',
                        minWidth: '210px',
                        border: '1px solid #eaeaea',
                        boxShadow: isSelected ? '0' : '0px 2px 2px #c8c8c8',
                        bgcolor: isDisabled ? '#e5e5e5' : isSelected ? '#1c4bc3' : '#fbfbfb',
                        color: isSelected ? '#fff' : '#444',
                        '&:hover': {
                          bgcolor: isSelected ? '#1c5bc3' : '#f0f0f0',
                          color: isSelected ? '#fff' : '#333',
                          // border: '1px solid #c8c8c8',
                        },
                      }}
                    >
                      <Typography>{scoreClass + (cnt !== undefined ? '(' + cnt + ')' : '')}</Typography>
                    </ButtonBase>
                  )
                })}
              </Stack>
              {cClass && selectedScoreClass && (
                <Box mt={5}>
                  <TurtlePaper>
                    <BaseTable
                      tableStyle={baseTableStyle2}
                      enablePagination
                      pageSize={5}
                      data={preTableData}
                      columns={[
                        columnHelper.accessor('collegeName', {
                          header: () => <span>대학명</span>,
                        }),
                        columnHelper.accessor('typeName', {
                          header: () => <span>전형명</span>,
                        }),
                        columnHelper.accessor('recruitmentUnit', {
                          header: () => <span>모집단위</span>,
                        }),
                      ]}
                    />
                  </TurtlePaper>
                </Box>
              )}
              <Center mt={4}>
                 {step1ResultDataSuccess && cClass && isNoTableData && isScoreClassSelected && (
                  <Typography sx={{ color: mainColor, mb: 4 }}>
                    데이터가 존재하지 않습니다. 다른 성적대를 선택해주십시오.
                  </Typography>
                )}

                {isRiskMeasureButtonClicked ? (
                  <Center
                  // sx={{ visibility: isRiskMeasureButtonClicked ? 'visible' : 'hidden' }}
                  >
                    <CircularProgress size={40} thickness={3} />
                    <Typography fontSize={'18px'} mt={2} sx={{ color: '#a2a2a299' }}>
                      잠시 기다려주십시오
                    </Typography>
                  </Center>
                ) : (
                  <ButtonBase
                    disabled={!isAnalysisResultButtonEnabled}
                    sx={{
                      px: 5,
                      justifySelf: 'end',
                      color: isAnalysisResultButtonEnabled ? '#fff' : '#a3a3a3',
                      bgcolor: isAnalysisResultButtonEnabled ? mainColor : '#e5e5e5',
                      py: 1,
                      borderRadius: '7px',
                      fontWeight: 700,
                      boxShadow: '0px 2px 3px #c8c8c8',
                    }}
                    onClick={() => {
                      if (showAnalysisResult) {
                        setShowAnalysisResult(false)
                      }

                      setIsRiskMeasureButtonClicked(true)
                    }}
                  >
                    위험도 측정하기
                    <AiOutlineSearch fontSize={17} style={{ marginLeft: 5 }} />
                  </ButtonBase>
                )}
              </Center>
            </Stack>
          }
        </TurtlePaper> */}
        <Box sx={{ mt: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">평가 내역 선택</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedEvaluationNo}
              label="평가 내역 선택"
              onChange={handleChange}
            >
              {evaluationList!=null && evaluationList.length > 0 ? (
                evaluationList.map((item: any, index: number) => (
                  <MenuItem value={item.id + '|' + item.series}>
                    {item.series + ' ' + moment(item.createDt).format('YYYY-MM-DD')}
                  </MenuItem>
                ))
              ) : (
                <Typography>평가 내역이 존재하지 않습니다.</Typography>
              )}
            </Select>
          </FormControl>
        </Box>
      </SubtitleSection>
      {selectedEvaluationNo != '' && scoreList.length > 0 ? (
        <>
          <Box ref={analysisResultContentRef} pt={5}>
            <TurtlePaper mt={5} sx={{ px: '30px', py: '25px' }}>
              <Typography variant="H3_Bold, 28, 36" sx={{ color: '#000' }}>
                진로역량
              </Typography>
              <Stack mt={5} gap={'30px'}>
                <ExplainSection
                  title="전공(계열) 관련 교과 이수 노력"
                  items={[
                    {
                      text: '전공(계열)과 관련된 과목을 적절하게 선택하고, 이수한 과목은 얼마나 되는가?',
                      grade: JSON.stringify(scoreList[0].grade),
                    },
                    {
                      text: (
                        <>
                          전공(계열)과 관련된 과목을 이수하기 위하여 추가적인 노력을 하였는가?
                          <br />
                          (예 : 공동교육과정, 온라인수업, 소인수과목 등)
                        </>
                      ),
                      grade: JSON.stringify(scoreList[1].grade),
                    },
                    {
                      text: '선택과목(일반/진로)은 교과목 학습단계(위계)에 따라 이수하였는가?',
                      grade: JSON.stringify(scoreList[2].grade),
                    },
                  ]}
                />
                <ExplainSection
                  title="전공(계열) 관련 교과 성취도"
                  items={[
                    {
                      text: '전공(계열)과 관련된 과목의 석차등급/성취도, 원점수, 평균, 표준편차, 이수단위, 수강자수, 성취도별 분포비율등을 종합적으로 고려한 성취수준은 적절한가?',
                      grade: JSON.stringify(scoreList[3].grade),
                    },
                    {
                      text: <>전공(계열)과 관련된 동일 교과 내 일반선택과목 대비 진로선택과목의 성취수준은 어떠한가?</>,
                      grade: JSON.stringify(scoreList[4].grade),
                    },
                  ]}
                />
                <ExplainSection
                  title="진로 탐색 활동과 경험"
                  items={[
                    {
                      text: '자신의 관심 분야나 흥미와 관련한 다양한 활동에 참여하여 노력한 경험이 있는가?',
                      grade: JSON.stringify(scoreList[5].grade),
                    },
                    {
                      text: (
                        <>교과 활동이나 창의적 체험활동에서 전공(계열)에 대한 관심을 가지고 탐색한 경험이 있는가?</>
                      ),
                      grade: JSON.stringify(scoreList[6].grade),
                    },
                  ]}
                />
              </Stack>
            </TurtlePaper>
          </Box>
        </>
      ) : null}
      {selectedEvaluationNo != '' && commentList.length > 0 ? (
        <>
          <TurtlePaper mt={5} sx={{ px: '30px', py: '25px' }}>
            <Stack>
              <Typography variant="D2_exBold, 16, 24" mb={'10px'}>
                사정관 주석
              </Typography>
              <Typography variant="D4_regular, 16, 24">{commentList[0].comment}</Typography>
            </Stack>
          </TurtlePaper>
          <GradeCard title="진로역량" grade={jinroGrade} />
          <StepMoveButtonGroup isNextButtonEnabled={true} />
          {/* <ConditionMessage show={!isNextButtonEnabled} text="포함되는 모집단위를 한 개 이상 선택해주십시오." /> */}
        </>
      ) : null}

      {/* cClass && showAnalysisResult && tableDataList.length > 0 &&  
      {selectedEvaluationNo != '' && scoreList.length > 0 && commentList.length > 0 ? (
        <>
          <Box ref={analysisResultContentRef} pt={5}>
            {/* {!is문과 && (
              <TurtlePaper mt={5} sx={{ px: '30px', py: '25px' }}>
                <StackX sx={{ display: 'flex' }} justifyContent={'space-between'} mb={4}>
                  <StackX gap={2}>
                    <Typography variant="H4_Exbold, 24, 32">필수과목 이수 현황과 등급</Typography>
                    <InfoBadge
                      placement="right"
                      tooltipContent={
                        <Box width={'338px'}>
                          <Typography variant="H4_Exbold, 24, 32" color={'#252525'}>
                            필수과목 이수현황이란?
                          </Typography>
                          <br />
                          <Typography variant="D5_Bold, 14, 18" mt={1} color={'#656565'}>
                            해당 학과에서 요구하는 필수과목 충족 여부를 종합적으로 확인하는 페이지에요.{' '}
                          </Typography>
                          <Stack mt={'16px'} gap={'12px'}>
                            <Stack direction={'row'} gap={'10px'}>
                              <Typography variant="D5_Bold, 14, 18" color={'#FF5600'} fontWeight={800}>
                                필수
                              </Typography>
                              <Typography variant="D6_bold, 12, 18" color={'#9A9A9A'}>
                                이수하지 않으면 결격사유가 될 수 있어요.
                              </Typography>
                            </Stack>
                            <Box height={'1px'} bgcolor={'#E9E9E9'}></Box>
                            <Stack direction={'row'} gap={'10px'}>
                              <Typography variant="D5_Bold, 14, 18" color={'#1C4BC3'} fontWeight={800}>
                                장려
                              </Typography>
                              <Typography variant="D6_bold, 12, 18" color={'#9A9A9A'}>
                                결격사유는 아니지만 참조가 될 수 있는 과목이에요.
                              </Typography>
                            </Stack>
                            <Box height={'1px'} bgcolor={'#E9E9E9'}></Box>
                            <Stack direction={'row'} gap={'10px'}>
                              <Typography variant="D5_Bold, 14, 18" color={'#252525'} fontWeight={800}>
                                무관
                              </Typography>
                              <Typography variant="D6_bold, 12, 18" color={'#9A9A9A'}>
                                학과와 관련이 없는 과목이에요.
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      }
                    />
                  </StackX>
                </StackX>
                {table}
              </TurtlePaper>
            )} */}

      {/* <TurtlePaper mt={5} sx={{ px: '30px', py: '25px', display: 'flex', justifyContent: 'space-between' }}>
              <StackX>
                <RiskIndicator riskScore={riskScore} />
                <StackY ml={4} justifyContent={'center'}>
                  <StackX>
                    <Typography variant="H4_Exbold, 24, 32">총괄 위험도 :&nbsp;</Typography>
                    <RiskLabel riskScore={riskScore} fontWeight={700} />
                  </StackX>
                  <Typography variant="H6_Bold, 22, 30" sx={{ color: '#9a9a9a' }}>
                    서울상위권 기준
                  </Typography>
                </StackY>
              </StackX>
              <RiskExplainInfoBadge />
            </TurtlePaper> */}

      {/*
            
            
        </>
      ) : null
}
      */}
    </>
  )
}

const TurtleSelectBox = ({
  value,
  menuList,
  setValue,
  label,
  disabled,
}: {
  label: string
  value: any
  menuList?: string[]
  setValue: Setter<any>
  disabled?: boolean
}) => {
  const ref = useRef<HTMLElement>(null)

  return (
    <FormControl sx={{ minWidth: '200px' }} size="small">
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        ref={ref}
        disabled={disabled}
        labelId={label}
        value={value}
        label={label}
        onChange={(e) => {
          setValue(e.target.value)
        }}
      >
        {menuList?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default Step1

export const getGrade = (n: number, officerReviewData: any) => officerReviewData?.list[n]?.avgGrade ?? '-'

function getTable(tableData: any[] | null | undefined) {
  if (!tableData) return <></>

  const subjects = tableData?.map((x) => x.subjectName) ?? []
  const rows = []
  const addi = [
    ['essentialFlag', '조건'],
    ['isCheck', '이수현황'],
    ['essentialGrade', '권장등급'],
    ['myGrade', '내 등급'],
    ['riskScore', '위험도'],
  ]

  for (const [key, label] of addi) {
    const item: Record<string, any> = {
      과목: label,
    }
    for (let [i, field] of Object.entries(tableData.map((x) => x[key as keyof typeof x]))) {
      const itemKey = subjects[Number(i)] as string

      switch (label) {
        case '조건':
          field = Number(field)
          if (field === 1) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#1C4BC3' }}>
                장려
              </Typography>
            )
          } else if (field === 2) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#FF5600' }}>
                필수
              </Typography>
            )
          } else if (field === 0) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#252525' }}>
                무관
              </Typography>
            )
          } else {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: 'red' }}>
                -
              </Typography>
            )
          }
          break
        case '이수현황':
          field = Number(field)
          if (field === 2) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#1C4BC3' }}>
                무관
              </Typography>
            )
          } else if (field === 1) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#FF5600' }}>
                이수
              </Typography>
            )
          } else if (field === 0) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#252525' }}>
                미이수
              </Typography>
            )
          } else {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: 'red' }}>
                -
              </Typography>
            )
          }
          break
        case '위험도':
          item[itemKey] = <RiskIndicator riskScore={field} height={20} />
          break
        default:
          item[subjects[Number(i)] as string] = <Typography>{field}</Typography>
          break
      }
    }
    rows.push(item)
  }
  subjects.unshift('과목')
  const columns = getDefaultTurtleTableColumnModel(subjects)
  const table = <BaseTable columns={columns} data={rows} enableSelection={false} tableStyle={baseTableStyle2} sx={{}} />
  return table
}

export type 학종_사정관평가데이터_형식 = {
  list: any[]
  mainAvgScoreList: number[]
  mainAvgScoreConversionList: string[]
  middleAvgScoreList: number[]
  middleAvgScoreConversionList: string[]
  commentList: string[]
  evaluation3TypesAvgGrade: null
  evaluation3TypesAvgScore: null
  evaluation4TypesAvgGrade: null
  evaluation4TypesAvgScore: null
  status: boolean
  message: string
  myAverageRating?: string
}

export const RiskExplainInfoBadge = () => {
  return (
    <InfoBadge
      tooltipContent={
        <Box>
          <Typography variant="H4_Exbold, 24, 32" color={'#252525'}>
            위험도는 합격예측 구간이에요.
          </Typography>
          <Typography mt={1} color={'#656565'} fontWeight={700} fontSize={'14px'} lineHeight={'18px'}>
            칸이 찰수록 안정적이에요.
          </Typography>
          <Stack mt={2} gap={'12px'}>
            {[
              ['안정', '안정권에 들어가는 수준이에요.'],
              ['적정', '성적에 맞는 적당한 수준이에요.'],
              ['소신', '위험요소가 있지만 지원할만 해요.'],
              ['도전', '가능성이 적어 위험할 수 있어요.'],
              ['위험', '성적보다 너무 높은 대학이에요.'],
            ].map(([label, explainText], index) => {
              const content = (
                <Stack direction={'row'} alignItems={'center'} key={index * 2}>
                  <RiskIndicator riskScore={5 - index} />
                  <Typography
                    color={getRiskColor(5 - index)}
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    fontWeight={800}
                    ml={'12px'}
                  >
                    {label}
                  </Typography>
                  <Typography variant="D6_bold, 12, 18" color={'#9A9A9A'} ml={'10px'}>
                    {explainText}
                  </Typography>
                </Stack>
              )
              const divider = <Box height={'1px'} bgcolor={'#E9E9E9'} key={index * 2 + 1}></Box>

              if (index === 0) {
                return content
              }
              return (
                <>
                  {divider}
                  {content}
                </>
              )
            })}
          </Stack>
        </Box>
      }
    />
  )
}
