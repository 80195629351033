import { Box, Button, Divider, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { deleteCombinaion, fetchGeneratedCombination, fetchGeneratedCombinations, renameCombination } from 'api/api'
import DetailButton from 'components/common/DetailButton'
import TextButton from 'components/common/TextButton'
import TurtleTable from 'components/common/TurtleTable'
import TitleSection from 'components/layout/TitleSection'
import { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useQuery } from 'react-query'
import CombinationGenerateModal from './CombinationGenerateModal'
import { format } from 'date-fns'
import MessageCard from 'components/layout/EmptyPageLayout'
import { 관심대학_테이블스타일 } from '../InterestUniversityPage/common'
import { FormControl } from '@mui/base'

type Props = {}

const CombinationPage = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false)

  const { data: groupIdData, refetch: refetchCombinations } = useQuery(
    '모의지원조합_리스트',
    fetchGeneratedCombinations
  )
  const groupIdInfos = groupIdData?.data

  const [selectedIndex, setSelectedIndex] = useState(-1)

  const [isRenameMode, setIsRenameMode] = useState(false)
  const [groupName, setGroupName] = useState('')

  const selectedGroupId = groupIdData?.data?.groupList?.[selectedIndex]?.id ?? -1
  const numGroups = groupIdData?.data?.groupCount ?? 0

  const { data: selectedGroupData, refetch: refetchSelectedGroup } = useQuery({
    queryKey: '선택된_조합',
    queryFn: () => {
      if (selectedGroupId) {
        return fetchGeneratedCombination(selectedGroupId)
      }
    },
    staleTime: 0,
  })

  useEffect(() => {
    if (!groupIdData) return
    const isValidSelectedIndex = selectedIndex >= 0 && selectedIndex < numGroups
    setSelectedIndex(isValidSelectedIndex ? selectedIndex : numGroups - 1)
  }, [groupIdData])

  useEffect(() => {
    setGroupName(groupIdInfos?.groupList[selectedIndex]?.groupName ?? '')
    refetchSelectedGroup()
  }, [selectedIndex])

  if (!groupIdInfos) return <></>
  if (!selectedGroupData) return <></>

  const select = (
    <FormControl>
      <Select
        sx={{ width: 200 }}
        size="small"
        value={selectedIndex}
        onChange={(v) => {
          setSelectedIndex(Number(v.target.value))
        }}
      >
        {groupIdInfos.groupList.map((item, i) => (
          <MenuItem value={i} key={i}>
            {item.groupName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const groupTableRows =
    selectedGroupData.data.interestCollegeCombinationList?.map((data: any, i) => {
      return [
        data?.collegeName,
        '-----',
        data?.recruitmentUnit,
        data?.combinationTypeName,
        format(new Date(data?.interviewDate), 'yyyy-MM-dd'), // interviewDate admissionDate
        <DetailButton></DetailButton>,
      ]
    }) ?? []

  const selectBoxMenu = (
    <Stack direction={'row'} justifyContent={'space-between'}>
      <Stack direction={'row'}>
        {isRenameMode ? (
          <TextField
            value={groupName}
            size="small"
            onChange={(e) => {
              setGroupName(e.target.value)
            }}
          />
        ) : (
          select
        )}
        {isRenameMode ? (
          <TextButton
            text="완료"
            sx={{ color: '#888', textDecoration: 'underline' }}
            onClick={() => {
              renameCombination(selectedGroupId, groupName).then(() => {
                refetchCombinations()
                setIsRenameMode(false)
              })
              setIsRenameMode(false)
            }}
          />
        ) : (
          <TextButton
            text="조합명 변경"
            sx={{ color: '#888', textDecoration: 'underline' }}
            onClick={() => {
              setIsRenameMode(true)
            }}
          />
        )}
      </Stack>
      <TextButton
        text="조합 삭제"
        sx={{ color: '#888', textDecoration: 'underline' }}
        onClick={() => {
          deleteCombinaion(selectedGroupId).then(({ status }) => {
            if (status === 200) {
              refetchCombinations()
              alert('삭제 되었습니다.')
            } else {
              alert('삭제 실패 ')
            }
          })
        }}
      />
    </Stack>
  )

  const selectedGroupTable =
    selectedIndex === -1 ? (
      <></>
    ) : (
      <Stack gap={2} mt={1}>
        <Typography variant="h6" fontWeight={700}>
          생성된 조합
        </Typography>
        <Box sx={{ maxWidth: 828 }}>
          {selectBoxMenu}
          <TurtleTable
            sx={{
              mt: 2,
              width: '100%',
              ...관심대학_테이블스타일,
            }}
            headerLabels={['대학명', '전형명', '학과명', '전형', '전형일', '상세내역']}
            rowNodeLists={groupTableRows}
            numMinimumRows={6}
          ></TurtleTable>
        </Box>
      </Stack>
    )

  return (
    <TitleSection title="조합">
      <Box mt={6}>
        <Stack direction="row" alignItems={'end'}>
          <Button
            variant="contained"
            sx={{ paddingLeft: 3, paddingRight: 3 }}
            onClick={() => {
              setModalOpen(!modalOpen)
            }}
          >
            <FaPlus fontSize={19} />
            <Typography ml={1}>조합 생성</Typography>
          </Button>
          <Typography variant="body2" color="grey.400" ml={1}>
            *조합을 생성하여 모의지원을 해보세요.
          </Typography>
        </Stack>
        <Box py={3}>
          <Divider />
        </Box>
        {groupIdInfos.groupCount < 1 ? (
          <MessageCard title="조합 없음" contents={'생성된 조합이 없습니다. 위 버튼을 눌러 조합을 생성해주십시오.'} />
        ) : (
          selectedGroupTable
        )}
      </Box>
      <CombinationGenerateModal
        open={modalOpen}
        setOpen={(open) => {
          setModalOpen(open)
          refetchCombinations()
        }}
      />
    </TitleSection>
  )
}

export default CombinationPage
