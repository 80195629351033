import { memberAxios } from 'api/instance'
import {
  essayAbleInternalListAPI,
  essayAbleMedicalMathListAPI,
  essayAbleNaturalMathListAPI,
  essayAbleScienceListAPI,
  essayAbleScienceMedicalListAPI,
  essayAddInterestAPI,
  essayAllListAPI,
  essayCrossApplyListAPI,
  essayInterestListAPI,
  essayLowestGradeListAPI,
  essayMedicalEtcListAPI,
  essayMedicalRegionListAPI,
  essayMockMarkListAPI,
  essayMunMathListAPI,
  essayOtherListAPI,
} from 'api/urls'

/**
 *
 * 해당 학생의 모의고사 점수 및 표준점수 합계 조회 (모의고사등록하지 않았으면, status=false)
 * @param scheduleId 모의고사 스케쥴 아이디
 * @returns
 */
export const essayMockMarkListFetch = async (scheduleId: number) => {
  const { data } = await memberAxios.get(essayMockMarkListAPI, { params: { scheduleId } })
  console.log('essayMockMarkListFetch : ', data)
}

/**
 *
 * 논술 가능한 대학 코드 리스트를 가져온다
 * @param admissionSeriesCode 전형 계열 코드
 * @returns
 */
export const essayAllListFetch = async (admissionSeriesCode: string): Promise<number[]> => {
  const res = await memberAxios.get(essayAllListAPI, { params: { admissionSeriesCode } })
  return res.data
}

/**
 *
 * 문과 수리논술 모집단위 리스트를 가져온다
 * @param admissionSeriesCode 전형 계열 코드
 * @returns
 */

export interface munMathAbleDepartment {
  admissionSeriesCode: string
  collegeCode: string
  collegeName: string
  competitionRate: string
  english: null | string
  id: number
  math: null
  munMathen: number
  n1: number
  n2: number
  n3: number
  n4: number
  recruitmentUnit: string
  studentRecruitmentNum: string
  suNosul: string
}
export const essayMunMathListFetch = async (): Promise<munMathAbleDepartment[]> => {
  const res = await memberAxios.get(essayMunMathListAPI)
  console.log('essayAllListFetch res : ', res)

  return res.data.list
}

/**
 * 그 외 논술 전형 리스트 가져오기
 * @param admissionSeriesCode 전형 계열 코드
 */

export interface otherDepartment {
  collegeCode: string
  collegeName: string
  essaySubject: string
  examinationTendency: string
  id: number
  recruitmentUnit: string
  typeTime: string
  exceptNonsul: string
}

export const essayOtherListFetch = async (
  admissionSeriesCode: string
): Promise<{ list: otherDepartment[]; status: boolean }> => {
  const res = await memberAxios.get(essayOtherListAPI, { params: { admissionSeriesCode } })
  console.log('essayOtherList res : ', res)
  return res.data
}

/**
 * 내신점수등급차 리스트 조회
 * @param admissionSeriesCode 전형 계열 코드
 */
export interface advantageDepartment {
  collegeCode: string
  collegeName: string
  grade1: number
  grade2: number
  grade3: number
  grade4: number
  grade5: number
  grade6: number
  grade7: number
  grade8: number
  grade9: number
  gradeType: string
}

export const essayAbleInternalListFetch = async ({
  admissionSeriesCode,
  selectRecruitmentUnitList,
}: {
  admissionSeriesCode: string
  selectRecruitmentUnitList: number[]
}): Promise<{ list: advantageDepartment[]; status: boolean; msg: string }> => {
  const res = await memberAxios.post(essayAbleInternalListAPI, { admissionSeriesCode, selectRecruitmentUnitList })

  console.log('essayAbleInternalListFetch res : ', res)

  return res.data
}

/**
 * 최저등급리스트 조회 리스트 조회
 * @param selectRecruitmentUnitList 선택된 모집단위 리스트
 * @param selectUniversityList 선택된 대학 리스트
 */

export interface lowestGradeOfDepartment {
  admissionDate: string
  admissionTime: string
  available: boolean
  collegeCode: string
  collegeName: string
  competitionRate: string
  content: string
  id: number
  recruitmentUnit: string
  studentRecruitmentNum: string
}

export const essayLowestGradeListFetch = async ({
  admissionSeriesCode,
  scheduleId,
  selectRecruitmentUnitList,
  selectUniversityList,
}: {
  admissionSeriesCode: string
  scheduleId: number
  selectRecruitmentUnitList: number[]
  selectUniversityList: string[]
}): Promise<{ list: lowestGradeOfDepartment[]; status: boolean }> => {
  const res = await memberAxios.post(essayLowestGradeListAPI, {
    admissionSeriesCode,
    scheduleId,
    selectRecruitmentUnitList,
    selectUniversityList,
  })

  console.log('essayLowestGradeListFetch res : ', res)

  return res.data
}

/**
 * 이과교차지원논술2 리스트 조회
 * @param selectRecruitmentUnitList 선택된 모집단위 리스트
 */

export interface munCrossableMathDepartment {
  collegeCode: string
  collegeName: string
  commonMath: number
  detailSeries: null
  dfIn: number
  examinationTendency: string
  geometry: number
  id: number
  pbSt: number
  recruitmentUnit: string
  essaySubject: string
  suoneSutwo: number
  typeType: null | string
}

export const essayCrossApplyListFetch = async (
  selectRecruitmentUnitList: number[]
): Promise<{ list: munCrossableMathDepartment[]; status: boolean }> => {
  const res = await memberAxios.post(essayCrossApplyListAPI, { selectRecruitmentUnitList })

  return res?.data ?? []
}

/**
 * 이과 수학가능과목
 * @param selectRecruitmentUnitList 선택된 모집단위 리스트
 */
export interface liGwaMathAbleUniv {
  admissionSeriesCode: string
  collegeCode: string
  collegeName: null | string
  commonMath: number
  dfIn: number
  geometry: number
  id: number
  pbSt: number
  recommend: boolean
  selectSubject: null
  suoneSutwo: number
}

/**
 * 자연계열,의치한약수 수학가능과목 리스트 조회
 * @param mathSubjectList 수학 과목 리스트
 */
export const essayAbleNaturalMathListFetch = async (
  mathSubjectList: string[],
  admissionSeriesCode: string
): Promise<{ list: liGwaMathAbleUniv[]; status: boolean }> => {
  const res = await memberAxios.post(essayAbleNaturalMathListAPI, { mathSubjectList, admissionSeriesCode })

  console.log('essayAbleNaturalMathListFetch res : ', res)

  return res.data
}

/**
 * 이과 과학가능과목
 * @param admissionSeriesCode 전형계열코드
 * @param selectUniversityList 선택 대학 리스트
 */
export interface scienceAbleDepartment {
  collegeCode: string
  collegeName: string
  commonScience: number
  hwaone: number
  hwaonetwo: number
  id: number
  jiown: number
  jiowntwo: number
  mulone: number
  mulonetwo: number
  recruitmentUnit: string
  sangone: number
  sangonetwo: number
  selectScienceSubject: string
}
export const essayAbleNaturalScienceListFetch = async (
  admissionSeriesCode: string,
  selectUniversityList: string[]
): Promise<{ idList: number[]; sciencelist: scienceAbleDepartment[]; status: boolean }> => {
  const res = await memberAxios.post(essayAbleScienceListAPI, { admissionSeriesCode, selectUniversityList })

  console.log('essayAbleNaturalScienceListFetch res : ', res)

  return res.data
}

/**
 * 관심대학조회
 */

export interface interestUniv {
  admissionDate: string
  collegeCode: string
  id: number
  recruitmentUnit: string
}

export const essayInterestListFetch = async (): Promise<{ list: interestUniv[]; status: boolean }> => {
  const res = await memberAxios.get(essayInterestListAPI)

  console.log('essayInterestListFetch : ', res)

  return res.data
}

/**
 * 관심대학추가
 * @param idList 관심대학리스트
 */
export const essayAddInterestFetch = async (idList: number[]) => {
  const res = await memberAxios.post(essayAddInterestAPI, { idList })

  console.log('essayAddInterestFetch res : ', res)

  return res.data
}

/**
 * 관심대학추가
 * @param selectRecruitmentUnitList 선택된 모집단위 리스트
 */

export const essayaMedicalEtcList = async (selectRecruitmentUnitList: { selectRecruitmentUnitList: number[] }) => {
  const res = await memberAxios.post(essayAddInterestAPI, { selectRecruitmentUnitList })

  console.log('essayAddInterestFetch res : ', res)

  return res.data
}

/**
 * 의치한약수 기타가능선택 리스트 조회
 */
export interface essayMedicalEtcDepartment {
  english: number
  id: number
  medicalNonSul: number
  munNonSul: number
  recruitmentUnit: string
  rthreeEtcCode: string
  collegeName: string
}
export const essayMedicalEtcFetch = async ({
  selectRecruitmentUnitList,
}: {
  selectRecruitmentUnitList: number[]
}): Promise<{ message: null; status: boolean; list: [essayMedicalEtcDepartment[]] }> => {
  console.log()
  const res = await memberAxios.post(essayMedicalEtcListAPI, { selectRecruitmentUnitList })
  console.log('essayMedicalEtcFetch res : ', res)

  return res.data
}

/**
 * 의치한약수 지역인재 리스트 조회
 */
export interface essayRegionDepartment {
  collegeName: string
  id: number
  recruitmentUnit: string
  rthreeRegionInfo: string
  typeRate: string
}
export const essayMedicalRegionListFetch = async ({
  selectRecruitmentUnitList,
}: {
  selectRecruitmentUnitList: number[]
}): Promise<{ message: null; status: boolean; essayRegionDataList: essayRegionDepartment[] }> => {
  const res = await memberAxios.post(essayMedicalRegionListAPI, { selectRecruitmentUnitList })
  console.log('essayMedicalEtcFetch res : ', res)

  return res.data
}

/**
 * 의치한약수 수학가능과목 리스트 조회 API
 */

export interface medicalMathableDepartment {
  admissionSeriesCode: string
  collegeCode: string
  collegeName: string
  commonMath: number
  dfIn: number
  geometry: number
  id: number
  pbSt: number
  recommend: null
  recruitmentUnit: string
  selectMathSubject: number
  suoneSutwo: number
}

export const essayMedicalMathListFetch = async (): Promise<{ status: boolean; list: medicalMathableDepartment[] }> => {
  const res = await memberAxios.post(essayAbleMedicalMathListAPI, { admissionSeriesCode: 'R3' })

  return res.data
}

export interface medicalScienceDepartment {
  collegeCode: string
  collegeName: string
  commonScience: number
  hwaone: number
  hwaonetwo: number
  id: number
  jiown: number
  jiowntwo: number
  mulone: number
  mulonetwo: number
  recruitmentUnit: string
  sangone: number
  sangonetwo: number
  selectScienceSubject: number
}

export const essayMedicalScienceListFetch = async (
  selectRecruitmentUnitList: number[]
): Promise<{ idList: null; sciencelist: medicalScienceDepartment[]; status: boolean }> => {
  const res = await memberAxios.post(essayAbleScienceMedicalListAPI, {
    selectRecruitmentUnitList,
    admissionSeriesCode: 'R3',
  })

  console.log('res ssss L ', res)

  return res.data
}
