import { Box, Stack, Typography } from '@mui/material'
import TurtlePaper from 'components/common/TurtlePaper'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ExplainSection } from './common'
import AlphabetGradeChart from './AlphabetGradeChart'
import { useContext } from 'react'
import { StepPagesContext } from '../common/StepPagesContext'
import useScrollToTopOnMount from 'hooks/useScrollToTop'
import { memberAxios } from 'api/instance'
import { getGrade, 학종_사정관평가데이터_형식 } from './Step1'
import { useQuery } from 'react-query'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import GradeCard from './GradeCard'

type Props = {}
const reverseGrade = (x: number) => 9 - x

const data = [
  {
    name: '의치한약수서',
    grade: [1.0, 2.5],
  },
  {
    name: '연고서성한',
    grade: [1.2, 3.5],
  },
  {
    name: '중경외시건동홍',
    grade: [1.8, 4.7],
  },
  {
    name: '국숭세단, 경기상위권',
    grade: [2.0, 5.5],
  },
  {
    name: '지방국립대',
    grade: [3.0, 5.9],
  },
  {
    name: '지방중위권대',
    grade: [4.0, 6.9],
  },
  {
    name: '지방하위권대',
    grade: [5.0, 9],
  },
].map((x) => ({ ...x, grade: [reverseGrade(x.grade[1]), reverseGrade(x.grade[0])] }))

const Chart1 = ({ myGrade }: { myGrade: number }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart height={300} data={data} margin={{ top: 10 }}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="name" fontWeight={700} tickLine={false} dy={10} stroke="0" />
        <YAxis
          stroke="0"
          domain={[1, 8]}
          tickFormatter={(x) => `${reverseGrade(Number(x))}등급`}
          allowDecimals={false}
          tickCount={9}
          color="#9A9A9A"
          tickLine={false}
          interval={0}
        />
        <Tooltip formatter={(x: any) => `${reverseGrade(x[1]).toFixed(1)} ~ ${reverseGrade(x[0]).toFixed(1)}`} />
        {/* <Legend /> */}
        <Bar dataKey="grade" fill="#103abf" barSize={64} name="등급" />
        <ReferenceLine y={9 - myGrade} stroke="#D8145C" strokeWidth={2}>
          <Label value="내 성적" offset={5} position="insideTopLeft" fill="#D8145C" fontSize={11} fontWeight={700} />
        </ReferenceLine>
      </BarChart>
    </ResponsiveContainer>
  )
}

function Step2({}: Props) {
  // let { data: officerReviewData } = useQuery('학종-학업역량-평가', () =>
  //   memberAxios
  //     .get('/pearlyd/sr/officer-student-evaludate/avg-score/hakup/details')
  //     .then((x) => x.data as 학종_사정관평가데이터_형식)
  // )

  const { setBundle, commentList, scoreList, avg, setStep2Avg } = useContext(StepPagesContext)

  const convertTotalScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 7; i < 15; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 8
    setStep2Avg(scoreAvg)
    if (scoreAvg >= 6) {
      return 'A+'
    } else if (scoreAvg > 5) {
      return 'A'
    } else if (scoreAvg > 4) {
      return 'B+'
    } else if (scoreAvg > 3) {
      return 'B'
    } else if (scoreAvg > 2) {
      return 'C+'
    } else if (scoreAvg > 1) {
      return 'C'
    } else if (scoreAvg > 0) {
      return 'D'
    } else {
      return '-'
    }
  }

  const convertSection1ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 7; i < 9; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 2
    return Number(scoreAvg).toFixed(0)
  }

  const convertSection2ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 9; i < 12; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 3
    return Number(scoreAvg).toFixed(0)
  }

  const convertSection3ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 12; i < 15; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 3
    return Number(scoreAvg).toFixed(0)
  }

  let totalGrade = convertTotalScoreToGrade()
  let section1Grade = convertSection1ScoreToGrade()
  let section2Grade = convertSection2ScoreToGrade()
  let section3Grade = convertSection3ScoreToGrade()
  const data2 = [
    {
      name: '학업성취도',
      grade: Number(section1Grade),
    },
    {
      name: '학업태도',
      grade: Number(section2Grade),
    },
    {
      name: '탐구력',
      grade: Number(section3Grade),
    },
  ]

  //const myGrade = Number(officerReviewData?.myAverageRating) ?? 1

  console.log(avg)

  return (
    <>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px', height: '350px' }}>
        <Chart1 myGrade={avg} />
      </TurtlePaper>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px', height: '350px', maxWidth: '100%' }}>
        <AlphabetGradeChart data={data2} />
      </TurtlePaper>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px' }}>
        <Typography variant="H3_Bold, 28, 36" sx={{ color: '#000' }}>
          학업역량
        </Typography>
        <Stack mt={5} gap={'30px'}>
          <ExplainSection
            title="학업성취도"
            items={[
              {
                text: '대학 수학에 필요한 기본 교과목(예 : 국어, 수학, 영어, 사회/과학 등)의 교과성적은 적절한가? 그 외 교과목(예 : 예술, 체육, 기술가정/정보, 제2외국어/한문, 교양 등)의 교과성적은 어느정도인가?',
                grade: JSON.stringify(scoreList[7].grade),
              },
              {
                text: '학기별/학년별 성적의 추이는 어떠한가?',
                grade: JSON.stringify(scoreList[8].grade),
              },
            ]}
          />
          <ExplainSection
            title="학업태도"
            items={[
              {
                text: '성취동기와 목표의식을 가지고 자발적으로 학습하려는 의지가 있는가?',
                grade: JSON.stringify(scoreList[9].grade),
              },
              {
                text: <>새로운 지식을 획득하기 위해 자기주도적으로 노력하고 있는가?</>,
                grade: JSON.stringify(scoreList[10].grade),
              },
              {
                text: '교과 수업에 적극적으로 참여해 수업 내용을 이해하려는 태도와 열정이 있는가?',
                grade: JSON.stringify(scoreList[11].grade),
              },
            ]}
          />
          <ExplainSection
            title="탐구력"
            items={[
              {
                text: '교과와 각종 탐구 활동 등을 통해 지식을 화강하려고 노력하고 있는가?',
                grade: JSON.stringify(scoreList[12].grade),
              },
              {
                text: <>교과와 각종 탐구 활동에서 구체적인 성과를 보이고 있는가?</>,
                grade: JSON.stringify(scoreList[13].grade),
              },
              {
                text: <>교내 활동에서 학문에 대한 열의와 지적 관심이 드러나고 있는가?</>,
                grade: JSON.stringify(scoreList[14].grade),
              },
            ]}
          />
        </Stack>
      </TurtlePaper>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px' }}>
        <Stack>
          <Typography variant="D2_exBold, 16, 24" mb={'10px'}>
            사정관 주석
          </Typography>
          <Typography variant="D4_regular, 16, 24">{commentList[1].comment}</Typography>
        </Stack>
      </TurtlePaper>
      <GradeCard title="학업역량" grade={totalGrade} />
      <StepMoveButtonGroup />
    </>
  )
}

export default Step2
