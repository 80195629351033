import Risk1 from 'assets/images/riskIndicator/risk1.svg'
import Risk2 from 'assets/images/riskIndicator/risk2.svg'
import Risk3 from 'assets/images/riskIndicator/risk3.svg'
import Risk4 from 'assets/images/riskIndicator/risk4.svg'
import Risk5 from 'assets/images/riskIndicator/risk5.svg'

import { getClampedRiskScore } from 'utils/util'
import Center from './Center'
import { Image } from '@mui/icons-material'

const RiskIndicator = ({ riskScore, height = 25 }: { riskScore: number | string; height?: number }) => {
  riskScore = getClampedRiskScore(Number(riskScore))

  // const Icon = {
  //   1: <Icon src={Risk1} alt={"a"}>,
  //   2: Risk2,
  //   3: Risk3,
  //   4: Risk4,
  //   5: Risk5,
  // }[getClampedRiskScore(riskScore)]

  // if (!Icon) return <div>{riskScore}</div>
  const checkRiskScore = () => {
    switch (riskScore) {
      case 1:
        return <img style={{ width: '3rem', height: '20px' }} src={Risk1}></img>
        break
      case 2:
        return <img style={{ width: '3rem', height: '20px' }} src={Risk2}></img>
        break
      case 3:
        return <img style={{ width: '3rem', height: '20px' }} src={Risk3}></img>
        break
      case 4:
        return <img style={{ width: '3rem', height: '20px' }} src={Risk4}></img>
        break
      case 5:
        return <img style={{ width: '3rem', height: '20px' }} src={Risk5}></img>
        break
    }
    return <img style={{ width: '3rem', height: '20px' }} src={Risk1}></img>
  }

  return (
    <Center flexDirection={'column'} sx={{ width: 'fit-content' }}>
      {/*<Icon height={height} width={height * 1.9047619} />*/}
      {checkRiskScore()}
    </Center>
  )
}

export default RiskIndicator
