import { Checkbox } from "@mui/material";
import { essayMedicalEtcDepartment, essayRegionDepartment, liGwaMathAbleUniv } from "api/fetches/essay";
import "./style.scss";

interface Props {
  departmentList: essayRegionDepartment[];
  selectedDepartmentList: number[];
  filter: string[];
  //   onClickUnivTotalCheckBox: (isTotal: boolean, univList: string[]) => void;
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
}

/**
 *
 * @returns 기타 가능 선택
 */
const RegionTalentTable = ({ departmentList, filter, selectedDepartmentList, onClickDepartmentCheckBox }: Props) => {
  if (departmentList.length === 0) return <div></div>;

  console.log(filter, "filter");
  const filterArr = filter
    .reduce((accRegion, newRegion) => {
      console.log("accRegion : ", accRegion);
      console.log("newRegion : ", newRegion);

      return accRegion + "/" + newRegion;
    }, "")
    .split("/");
  filterArr.shift();

  console.log("filterArr : ", filterArr);
  const recUnivList = departmentList.filter((department) => {
    if (filterArr.length === 0) {
      return true;
    } else {
      for (let i = 0; i < filterArr.length; i++) {
        if (department.rthreeRegionInfo.includes(filterArr[i])) {
          return true;
        }
      }
      return false;
    }
  });

  //   const recUnivList = departmentList.filter((univ) => {
  //     if (filter.includes("munGwaNonSul") && univ.munNonSul === 0) return false;
  //     if (filter.includes("medicalNonSul") && univ.medicalNonSul === 0) return false;
  //     if (filter.includes("eng") && univ.english === 0) return false;
  //     return true;
  //   });

  //   const recUnivIdList = recUnivList.map((univ) => univ.id);

  //   const notRecUnivList = departmentList.filter((univ) => {
  //     return !recUnivIdList.includes(univ.id);
  //   });

  //   const notRecUnivIdList = notRecUnivList.map((univ) => univ.id);

  //   const isCheckTotal =
  //     nowTableType === "recommend" ? recUnivList.every((univ) => selectedUnivList.includes(univ.collegeCode)) : notRecUnivList.every((univ) => selectedUnivList.includes(univ.collegeCode));

  return (
    <table className="univTable">
      <tbody>
        <tr>
          <th>대학명</th>
          <th>모집단위명</th>
          <th>논술</th>
          <th>학생부</th>

          <th>
            <div className="col">
              선택
              {/* 전체선택
              <Checkbox checked={isCheckTotal} onChange={() => onClickUnivTotalCheckBox(!isCheckTotal, nowTableType === "recommend" ? recUnivIdList : notRecUnivIdList)} /> */}
            </div>
          </th>
        </tr>
        <TableRows univList={recUnivList} selectedUnivList={selectedDepartmentList} onClickDepartmentCheckBox={onClickDepartmentCheckBox}></TableRows>

        {/* <TableRows univList={nowTableType === "recommend" ? recUnivList : notRecUnivList} selectedUnivList={selectedDepartmentList} onClickDepartmentCheckBox={onClickDepartmentCheckBox}></TableRows> */}
      </tbody>
    </table>
  );
};

export default RegionTalentTable;

interface TableRowProps {
  univList: essayRegionDepartment[];
  selectedUnivList: number[];
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
}

const TableRows = ({ univList, selectedUnivList, onClickDepartmentCheckBox }: TableRowProps) => {
  return (
    <>
      {univList.map((univ) => {
        let hakSaengBoo = "";
        let nonSul = "";
        const typeRate = univ.typeRate.split(" ");
        typeRate.forEach((type) => {
          if (type.includes("학생부")) {
            hakSaengBoo = type.split(":")[1];
          }
          if (type.includes("논술")) {
            nonSul = type.split(":")[1];
          }
        });

        return (
          <tr key={univ.id + "crossable"}>
            <td>{univ.collegeName}</td>
            <td>{univ.recruitmentUnit}</td>
            <td>{nonSul}</td>
            <td>{hakSaengBoo}</td>

            <td>
              <Checkbox checked={selectedUnivList?.includes(univ.id) ?? false} onChange={(e) => onClickDepartmentCheckBox(e, univ.id)} />
            </td>
          </tr>
        );
      })}
    </>
  );
};
