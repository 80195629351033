import ContentsBox from 'components/block/contentsBox'
import React, { useEffect, useState } from 'react'
import './style.css'
import { ButtonBase, Paper, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  downloadSchoolrecrodFileFetch,
  getApplyStudentInfoFetch,
  getSurveyCompleteListFetch,
} from '../../../../../api/fetches/officer'
import { Link } from 'react-router-dom'
import TitleSection from 'components/layout/TitleSection'
import { Box, Stack } from '@mui/system'
import TurtlePaper from 'components/common/TurtlePaper'
import { useQuery } from 'react-query'
import { createColumnHelper } from '@tanstack/table-core'
import { Button } from '@mui/base'
import TurtleTable2 from 'components/common/TurtleTable2'
import RoundButton from 'components/common/RoundButton'
import { AiOutlineArrowRight } from 'react-icons/ai'
import MessageCard from 'components/layout/EmptyPageLayout'
import BaseTable from '../../common/BaseTable'

interface officerApplyStudentInfo {
  studentId: string
  // 평가자이름
  studentName: string
  series: string
  phone: string
  email: string
  // 평가자프로파일
  progressStatus: string
  //대기자 수
  readyCount: string
}

interface EvaludatedSurveyItem {
  studentName: string
  completeDt: string
  series: string
  phone: string
  email: string
}

const EvaluationSurveyTable = ({ evaludatedSurveys }: { evaludatedSurveys: EvaludatedSurveyItem[] }) => {
  const columnHelper = createColumnHelper<EvaludatedSurveyItem>()

  const textRender = (info: any) => <Typography variant="D3_bold, 16, 24">{info.getValue()}</Typography>
  const columns = [
    columnHelper.accessor('studentName', {
      cell: textRender,
      header: () => '이름',
      size: 60,
    }),
    columnHelper.accessor('completeDt', {
      cell: textRender,
      header: () => <span>평가완료 날짜</span>,
    }),
    columnHelper.accessor('series', {
      cell: (info) => <Typography style={{ fontSize: '12px', textOverflow: 'ellipsis' }}>{info.getValue()}</Typography>,
      header: () => '목표 계열',
    }),
    columnHelper.accessor('phone', {
      cell: textRender,
      header: () => '휴대폰',
    }),
    columnHelper.accessor('email', {
      cell: textRender,
      header: () => '이메일',
    }),
    columnHelper.display({
      id: '학생생기부',
      header: () => '학생 생기부(PDF / HTML)',
      cell: (props) => (
        <RoundButton>
          <Typography variant="D3_bold, 16, 24">생기부 다운로드</Typography>
        </RoundButton>
      ),
    }),
    columnHelper.display({
      id: '채점내역',
      header: () => '채점내역',
      cell: (props) => (
        <RoundButton
          onClick={() => {
            const original: any = props.row.original
            const studentId = original.studentId
            location.href = '/suSi/officer/applylist/evaluation/' + studentId
          }}
        >
          <Typography variant="D3_bold, 16, 24">채점내역&nbsp;</Typography>
          <AiOutlineArrowRight />
        </RoundButton>
      ),
    }),
  ]

  return (
    <BaseTable columns={columns} data={evaludatedSurveys} enablePagination />
    // <TurtleTable2
    //   columns={columns}
    //   data={evaludatedSurveys}
    //   isPaginated={true}
    //   sx={{ '& td, th': { p: 1 } }}
    // ></TurtleTable2>
  )
}

const ApplyList = () => {
  let { data: applicants } = useQuery('applicants-list', getApplyStudentInfoFetch)
  let { data: evaludatedSurveys } = useQuery('survey-complete-list', getSurveyCompleteListFetch)

  const downloadPdfAndHtml = () => {
    downloadSchoolrecrodFileFetch(applicants?.studentId, applicants?.studentName)
  }

  evaludatedSurveys = evaludatedSurveys ?? []

  const evaluationApplySection = (
    <TitleSection title="평가신청자 리스트">
      {applicants ? (
        <TurtlePaper sx={{ padding: 3, fontSize: '14px', mt: 4 }}>
          <div>
            <div className="officerApplyWrap1">
              <span>이름</span>
              <span>목표계열</span>
              <span>신청자 현황</span>
              <span>이메일</span>
              <span>휴대폰</span>
              <span>학생 생기부(PDF / HTML)</span>
            </div>
            <>
              <div className="officerApplyWrap2">
                <span>{applicants.studentName}</span>
                <span>{applicants.series}</span>
                <span>{applicants.progressStatus}</span>
                <span>{applicants.email}</span>
                <span>{applicants.phone}</span>
                <RoundButton onClick={downloadPdfAndHtml}>
                  <Typography variant="D3_bold, 16, 24">생기부 다운로드</Typography>
                </RoundButton>
              </div>
              <div className="officerApplyWrap3">
                <span className="officerApplyWrap3Span">{applicants.readyCount}명 대기중</span>
                <Link to={'evaluation/' + applicants.studentId}>
                  <ButtonBase sx={{ bgcolor: '#FF5600', color: '#fff', px: '20px', py: '15px' }}>
                    <Typography variant="H6_Bold, 22, 30">평가하러 가기</Typography>
                    <ArrowForwardIcon />
                  </ButtonBase>
                </Link>
              </div>
            </>
          </div>
        </TurtlePaper>
      ) : (
        <MessageCard title="평가 신청자 리스트가 없습니다." />
      )}
    </TitleSection>
  )
  return (
    <Stack>
      {evaluationApplySection}
      <TitleSection title="평가 내역">
        <TurtlePaper sx={{ mt: 2, px: 2 }}>
          <EvaluationSurveyTable evaludatedSurveys={evaludatedSurveys} />
        </TurtlePaper>
      </TitleSection>
    </Stack>
  )
}

export default ApplyList
