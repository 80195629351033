import { Checkbox } from '@mui/material'
import { lowestGradeOfDepartment } from 'api/fetches/essay'
import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'

interface Props {
  checkedLowestUnivList: { collegeCode: string; collegeName: string }[]
  openUnivList: string[]
  checkedLowestRateDepartmentList: lowestGradeOfDepartment[]
  onClickUnivRow: (collegeCode: string) => void
  onClickCheckDateDepartment: (lowestGradeOfDepartment: lowestGradeOfDepartment) => void
  onClickTotalCheckBox: (isTotal: boolean, lowestGradeDepartmentList: lowestGradeOfDepartment[]) => void
  applyDateDepartmentList: lowestGradeOfDepartment[]
}

const SelectDepartmentTable = ({
  checkedLowestUnivList,
  openUnivList,
  checkedLowestRateDepartmentList,
  applyDateDepartmentList,
  onClickUnivRow,
  onClickCheckDateDepartment,
  onClickTotalCheckBox,
}: Props) => {
  console.log('checkedLowestUnivList : ', checkedLowestUnivList)

  const selectedDepartmentIdList = applyDateDepartmentList.map((department) => department.id)

  return (
    <>
      {checkedLowestUnivList.map((univ) => {
        const recDepartmentList = checkedLowestRateDepartmentList.filter(
          (department) => department.collegeCode === univ.collegeCode
        )
        const isTotalChecked = recDepartmentList.every((department) => selectedDepartmentIdList.includes(department.id))

        return (
          <div key={'crossableUniv : ' + univ.collegeCode}>
            <div onClick={() => onClickUnivRow(univ.collegeCode)} className="univListRow">
              {univ.collegeName}
              <IoIosArrowForward className={openUnivList.includes(univ.collegeCode) ? 'iconOpen' : ''} />
            </div>
            {openUnivList.includes(univ.collegeCode) && (
              <table className="univTable">
                <tbody>
                  <tr>
                    <th style={{ width: '30%' }}>학과</th>
                    <th style={{ width: '30%' }}>정원</th>
                    <th style={{ width: '30%' }}>작년 경쟁률</th>
                    <th style={{ width: '10%' }}>
                      <div className="col">
                        전체선택
                        <Checkbox
                          checked={isTotalChecked}
                          onChange={(e) => onClickTotalCheckBox(!isTotalChecked, recDepartmentList)}
                        />
                      </div>
                    </th>
                  </tr>

                  {recDepartmentList.map((department) => {
                    return (
                      univ.collegeCode === department.collegeCode && (
                        <tr key={department.id + 'crossable'}>
                          <td>{department.recruitmentUnit}</td>
                          <td>{department.studentRecruitmentNum}</td>
                          <td>{department.competitionRate}</td>

                          <td>
                            <Checkbox
                              checked={
                                applyDateDepartmentList.map((department) => department.id)?.includes(department.id) ??
                                false
                              }
                              onChange={() => onClickCheckDateDepartment(department)}
                            />
                          </td>
                        </tr>
                      )
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        )
      })}
    </>
  )
}
export default SelectDepartmentTable
