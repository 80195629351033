import { ReactNode } from "react";
import "./style.scss";

interface Props {
  size: "large" | "medium" | "small " | "extra-small";
  children: ReactNode;
  className?: string;
}

const Title = ({ size, children, className }: Props) => {
  return <p className={`title-${size} ${className}`}>{children}</p>;
};
export default Title;
