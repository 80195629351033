import React, { useContext, useEffect } from 'react'
import { StepPagesContext } from './StepPagesContext'

export const WithPageCommonLogic = ({ children, ...rest }: React.PropsWithChildren<{}>) => {
  const { step } = useContext(StepPagesContext)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [step])

  return <React.Fragment {...rest}>{children}</React.Fragment>
}
