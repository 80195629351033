import { Button, ButtonBase, StackProps, Typography, useMediaQuery, Stack, Box } from '@mui/material'
import { useTheme } from '@mui/system'
import TextButton from 'components/common/TextButton'
import { mainColor } from 'constants/style'
import _ from 'lodash'
import React, { MouseEventHandler, ReactNode } from 'react'
import { MuiStyleExtension, Setter } from 'types/utilType'
import Center from './Center'
import StackX from './StackX'

type FilterBoxProps = {
  title: string
  infoBadge?: ReactNode
  buttonInfos: {
    buttonContent: ReactNode
    checked: boolean
    onClick: MouseEventHandler
  }[]
} & StackProps

export const createAtLeastOneFilterButtonsParam = (
  filterState: Record<string, boolean>,
  setFilterState: Setter<typeof filterState>
) => {
  return Object.keys(filterState).map((buttonContent) => {
    const key = buttonContent as keyof typeof filterState
    return {
      buttonContent,
      onClick: () => {
        setFilterState((prev) => {
          const newState = { ...prev, [key]: !prev[key] }
          return _.findKey(newState) ? newState : prev
        })
      },
      checked: filterState[key],
    }
  })
}

export const createRadioButtonInfos = <T extends string>(
  buttonTextList: T[],
  filterState: T,
  setFilterState: React.Dispatch<React.SetStateAction<T>>
) => {
  return buttonTextList.map((buttonContent) => {
    return {
      buttonContent,
      onClick: () => {
        setFilterState(buttonContent)
      },
      checked: buttonContent === filterState,
    }
  })
}

export const createMultiSelectButtonInfos = <T extends string | number | symbol>(
  buttonTextList: string[],
  filterState: Record<T, boolean>,
  setFilterState: React.Dispatch<React.SetStateAction<typeof filterState>>
) => {
  return buttonTextList.map((text) => {
    const key = text as keyof typeof filterState
    return {
      buttonContent: text,
      onClick: () => {
        setFilterState((prev) => {
          const newState = { ...prev, [key]: !prev[key] }
          return _.findKey(newState) ? newState : prev
        })
      },
      checked: filterState[key],
    }
  })
}

export const FilterBoxLayout = ({
  children,
  title,
  ...stackProps
}: React.PropsWithChildren<{ title: string }> & StackProps) => {
  const theme = useTheme()
  const isUnderMiddleSize = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Stack border={'1px solid #C2C2C2'} className="filter-box" direction={'row'} {...stackProps}>
      <Center className="head" minWidth={'112px'} borderRight={'1px solid #d9d9d9'} bgcolor={'#F4F4F4'}>
        <Typography
          variant="D2_exBold, 16, 24"
          px={isUnderMiddleSize ? 1 : 2}
          py={2}
          whiteSpace={'nowrap'}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {title}
        </Typography>
      </Center>
      <StackX sx={{ flex: 1, flexWrap: 'wrap' }} pb={1} justifyContent={'space-between'}>
        {children}
      </StackX>
    </Stack>
  )
}

const FilterBox = ({ title, infoBadge, buttonInfos, ...rest }: FilterBoxProps) => {
  return (
    <FilterBoxLayout title={title} {...rest}>
      <Box>
        {buttonInfos.map(({ buttonContent: buttonContent, checked, onClick }, i) => (
          <ButtonBase
            key={i}
            sx={{
              pt: 1,
              px: 1,
              mx: 0.5,
              color: checked ? mainColor : 'black',
            }}
            onClick={onClick}
          >
            <Typography variant="D4_regular, 16, 24" sx={{}}>
              {buttonContent}
            </Typography>
          </ButtonBase>
        ))}
      </Box>
      <Box mr={2}>{infoBadge}</Box>
    </FilterBoxLayout>
  )
  // const theme = useTheme()
  // const isUnderMiddleSize = useMediaQuery(theme.breakpoints.down('md'))

  // return (
  //   <Stack className="filter-box" direction={'row'} border={'1px solid #C2C2C2'} sx={[sx]}>
  //     <Center className="head" minWidth={'112px'} borderRight={'1px solid #d9d9d9'} bgcolor={'#F4F4F4'}>
  //       <Typography
  //         variant="D2_exBold, 16, 24"
  //         px={isUnderMiddleSize ? 1 : 2}
  //         py={2}
  //         whiteSpace={'nowrap'}
  //         sx={{ display: 'flex', alignItems: 'center' }}
  //       >
  //         {title}
  //       </Typography>
  //     </Center>
  //     <Stack direction={'row'} sx={{ flexWrap: 'wrap' }} pb={1}></Stack>
  //   </Stack>
  // )
}

export default FilterBox
