import { memberAxios } from 'api/instance'
import {
  earlydAddThreerdAPI,
  earlydLifeRecordUploadAPI,
  earlydStudentSchoolRecordFileUploadCheckAPI,
  earlydStudentSchoolrecordListAPI,
  earlydSubjectCodeAllAPI,
  earlydThreeGradeHtmlFileUploadAPI,
  earlydThreeGradePdfFileUploadAPI,
  earlydThreeGradePdfFileUploadGrduateAPI,
  selectUnivList,
} from 'api/urls'

export const earlyLifeRecordUploadFetch = async ({ file }: { file: File }) => {
  const form = new FormData()
  form.append('file', file)
  const res = await memberAxios.post(earlydLifeRecordUploadAPI, file)

  console.log('res [ ', res, ' ] ')

  return res.data
}

export const earlyThreeGradeHtmlFileUploadFetch = async (
  file: File
): Promise<{ code: string; message: string; status: boolean }> => {
  const form = new FormData()
  form.append('file', file)
  const res = await memberAxios.post(earlydThreeGradeHtmlFileUploadAPI, form)

  return res.data
}

export const earlyThreeGradePdfFileUploadFetch = async (
  file: File
): Promise<{ code: string; message: string; status: boolean }> => {
  const form = new FormData()
  form.append('file', file)
  const res = await memberAxios.post(earlydThreeGradePdfFileUploadAPI, form)

  return res.data
}

export const earlyThreeGradeGraduatePdfFileUploadFetch = async (
  file: File
): Promise<{ code: string; message: string; status: boolean }> => {
  const form = new FormData()
  form.append('file', file)
  const res = await memberAxios.post(earlydThreeGradePdfFileUploadGrduateAPI, form)

  return res.data
}
export const earlydStudentSchoolRecordFileUploadCheckFetch = async (
  selectFileType: string
): Promise<{
  code: string
  message: string
  status: boolean
}> => {
  const res = await memberAxios.get(earlydStudentSchoolRecordFileUploadCheckAPI, {
    params: {
      selectFileType: selectFileType,
    },
  })
  console.log('res :', res)
  return res.data
}

export interface schoolrecordSubjectLearningItem {
  grade: string //학년
  semester: string //학기
  mainSubjectCode: string //교과
  mainSubjectName: string //교과이름
  subjectCode: string //과목
  subjectName: string //과목이름
  unit: string //단위수
  rawScore: string //원점수
  subSubjectAverage: string //과목평균
  standardDeviation: string //표준편차
  achievement: string //성취도
  studentsNum: string //수강자수
  ranking: string //석차등급
  etc: string //기타
}

export interface schoolrecordSelectSubjectItem {
  grade: string //학년
  semester: string //학기
  mainSubjectCode: string //교과
  mainSubjectName: string //교과이름
  subjectCode: string //과목
  subjectName: string //과목이름
  unit: string //단위수
  rawScore: string //원점수
  subSubjectAverage: string //과목평균
  standardDeviation: string //표준편차
  achievement: string //성취도
  achievementA: string //A 성취도별 분포비율
  achievementB: string //B 성취도별 분포비율
  achievementC: string //C 성취도별 분포비율
  studentsNum: string //수강자수
  etc: string //기타
}

export interface schoolrecordAttendanceDetailItem {
  [key: string]: string
  grade: string //학년
  classDays: string //수업일수
  absentDisease: string //결석질병
  absentUnrecognized: string // 결석 미인정
  absentEtc: string // 결석 기타
  lateDisease: string // 지각 질병
  lateUnrecognized: string // 지각 미인정
  lateEtc: string // 지각 기타
  leaveEarlyDisease: string // 조퇴 질병
  leaveEarlyUnrecognized: string // 조퇴 미인정
  leaveEarlyEtc: string // 조퇴 기타
  resultDisease: string // 결과 질병
  resultUnrecognized: string // 결과 미인정
  resultEarlyEtc: string // 결과 기타
  etc: string // 특이사항
}

export const earlydAddThreerdFetch = async (data: {
  schoolrecordSubjectLearningList: schoolrecordSubjectLearningItem[]
  schoolrecordSelectSubjectList: schoolrecordSelectSubjectItem[]
  schoolrecordAttendanceDetailList: schoolrecordAttendanceDetailItem[]
}): Promise<{
  code: null | string
  message: string
  status: boolean
}> => {
  const res = await memberAxios.post(earlydAddThreerdAPI, JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json' },
  })

  console.log('earlydAddThreerdFetch res : ', res)

  return res.data
}

interface earlydStudentSchoolrecordListParams {
  selectTypeList: string[]
  selectGrade: string
  selectSemester: string
}

export const earlydStudentSchoolrecordListFetch = async ({
  selectTypeList,
  selectGrade,
  selectSemester,
}: earlydStudentSchoolrecordListParams) => {
  const res = await memberAxios.post(earlydStudentSchoolrecordListAPI, { selectTypeList, selectGrade, selectSemester })

  console.log('res :  조회 ', res.data)
  return res.data
}

export const earlydSubjectListFetch = async () => {
  const res = await memberAxios.get(earlydSubjectCodeAllAPI, {})

  console.log('res :  조회 ', res.data)
  return res
}

export const getEarlydUnivList = async (body: any) => {
  const res = await memberAxios.post(selectUnivList, body)

  console.log('res :  조회 ', res.data)
  return res
}
