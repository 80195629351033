import { useQuery } from 'react-query'
import DetailModalLayout from '../DetailModalLayout'
import K_상세보기_성적반영방법탭 from './K_상세보기_성적반영방법탭'
import K_상세보기_전형정보탭 from './K_상세보기_전형정보탭'
import K_상세보기_합격예측탭 from './K_상세보기_합격예측탭'
import { memberAxios } from 'api/instance'
import React from 'react'
import MessageCard from 'components/layout/EmptyPageLayout'
import { Box } from '@mui/material'
import Center from 'components/common/Center'
import { useNavigate } from 'react-router-dom'

type RecruitmentUnitInfo = {
  additionalScoreText: string
  attendance: string
  basicTypeAdmission: string
  centerType: string
  collegeName: string
  competitionRate: string
  courseSubjectYn: string
  cut50: string
  cut70: string
  eighthGradeScore: string
  fifthGradeScore: string
  firstClassRate: string
  firstGradeScore: string
  fourthGradeScore: string
  interviewDate: null | string
  interviewPaper: string
  interviewRate: string
  interviewScore: string
  interviewText: string
  interviewTime: string
  interviewTypeName: string
  lifeRecord: string
  lowestText: string
  ninthGradeScore: string
  nonSubjectRatio: string
  paperRate: string
  paperScore: string
  recruitmentNum: string
  recruitmentUnit: string
  recruitmentUnitId: string
  reflectEnglish: string
  reflectEtc: string
  reflectHistory: string
  reflectKorean: string
  reflectMath: string
  reflectScience: string
  reflectSociety: string
  region: string
  selectionModel: string
  selectionRate: string
  seventhGradeScore: string
  sixthGradeScore: string
  stepTwoInterviewRate: string
  stepTwoInterviewScore: string
  stepTwoPaperRate: string
  stepTwoPaperScore: string
  subjectRate: string
  subjectScore: string
  supportQualificationText: string
  thirdClassRate: string
  thirdGradeScore: string
  twoClassRate: string
  twoGradeScore: string
  typeName: string
  typeTotalScore: string
  usefulGuideline: string
  volunteer: string
  myAverageRating: number
}

const defaultContextValue = {
  earlydSubjectOtherCollegeSameRowSeriesList: [],
  earlydSubjectSameCollegeOtherRecruitmentUnitList: [],
  recruitmentUnitInfo: {
    additionalScoreText: '',
    attendance: '',
    basicTypeAdmission: '',
    centerType: '',
    collegeName: '',
    competitionRate: '',
    courseSubjectYn: '',
    cut50: '',
    cut70: '',
    eighthGradeScore: '',
    fifthGradeScore: '',
    firstClassRate: '',
    firstGradeScore: '',
    fourthGradeScore: '',
    interviewDate: '',
    interviewPaper: '',
    interviewRate: '',
    interviewScore: '',
    interviewText: '',
    interviewTime: '',
    interviewTypeName: '',
    lifeRecord: '',
    lowestText: '',
    ninthGradeScore: '',
    nonSubjectRatio: '',
    paperRate: '',
    paperScore: '',
    recruitmentNum: '',
    recruitmentUnit: '',
    recruitmentUnitId: '',
    reflectEnglish: '',
    reflectEtc: '',
    reflectHistory: '',
    reflectKorean: '',
    reflectMath: '',
    reflectScience: '',
    reflectSociety: '',
    region: '',
    selectionModel: '',
    selectionRate: '',
    seventhGradeScore: '',
    sixthGradeScore: '',
    stepTwoInterviewRate: '',
    stepTwoInterviewScore: '',
    stepTwoPaperRate: '',
    stepTwoPaperScore: '',
    subjectRate: '',
    subjectScore: '',
    supportQualificationText: '',
    thirdClassRate: '',
    thirdGradeScore: '',
    twoClassRate: '',
    twoGradeScore: '',
    typeName: '',
    typeTotalScore: '',
    usefulGuideline: '',
    volunteer: '',
    myAverageRating: 0,
  },
  earlydGyoGwaPredictionPassInfo: {
    risk50: '',
    differenceScore: '',
    myRiskScore: '',
    myScore: '',
    totalGyoGwaScore: '',
  },
  code: '',
}
type QueryResponse = {
  recruitmentUnitInfo: RecruitmentUnitInfo
  earlydSubjectOtherCollegeSameRowSeriesList: any[]
  earlydSubjectSameCollegeOtherRecruitmentUnitList: any[]
  earlydGyoGwaPredictionPassInfo: {
    risk50: string
    differenceScore: string
    myRiskScore: string
    myScore: string
    totalGyoGwaScore: string
  }
  code: string
}

export const SubjectDetailModalContext = React.createContext<QueryResponse>(defaultContextValue)

const K_상세보기_교과모달_내용 = ({ param: recruitmentUnitId }: { param: number }) => {
  const { data } = useQuery(['상세보기-교과모달', recruitmentUnitId], () => {
    return memberAxios.get(`/pearlyd/subject/${recruitmentUnitId}/details`, {}).then((res) => {
      return res.data as QueryResponse
    })
  })

  const navigate = useNavigate()
  if (data?.code === 'C102') {
    alert('성적정보가 존재하지않음.')
    navigate('/suSi/inputAndAnalysis/hakJongInput')
  }

  if (data?.code === 'C103') {
    alert('사용자 정보에서 문과/이과 정보를 등록해 주세요.')
    navigate('/user/myInfo')
  }

  const contextValue = data ?? defaultContextValue

  if (!contextValue.recruitmentUnitInfo)
    return <Center sx={{ width: '100%', height: '100%', bgcolor: '#fff' }}>데이터가 존재하지 않습니다.</Center>

  const { collegeName, recruitmentUnit } = contextValue.recruitmentUnitInfo

  return (
    <SubjectDetailModalContext.Provider value={contextValue}>
      <DetailModalLayout
        college={collegeName}
        department={recruitmentUnit}
        tabContentList={[
          {
            tabName: '전형정보',
            tabContent: <K_상세보기_전형정보탭 />,
          },
          {
            tabName: '성적반영방법',
            tabContent: <K_상세보기_성적반영방법탭 />,
          },
          {
            tabName: '합격예측',
            tabContent: <K_상세보기_합격예측탭 />,
          },
        ]}
      />
    </SubjectDetailModalContext.Provider>
  )
}

export default K_상세보기_교과모달_내용
