import { Checkbox } from "@mui/material";
import { lowestGradeOfDepartment } from "api/fetches/essay";
import { IoIosArrowForward } from "react-icons/io";

interface Props {
  applyDateUnivList: { collegeCode: string; collegeName: string }[];
  applyDateDepartmentList: lowestGradeOfDepartment[];
  openUnivList: string[];
  interestDepartmentList: number[];
  onClickInterestDepartmnet: (departmentId: number) => void;
  onClickUnivRow: (collegeCode: string) => void;
}

const ApplyDateTable = ({ applyDateUnivList, openUnivList, applyDateDepartmentList, interestDepartmentList, onClickInterestDepartmnet, onClickUnivRow }: Props) => {
  return (
    <>
      {applyDateUnivList.map((univ) => {
        return (
          <div key={"crossableUniv : " + univ.collegeCode}>
            <div onClick={() => onClickUnivRow(univ.collegeCode)} className="univListRow">
              {univ.collegeName}
              <IoIosArrowForward className={openUnivList.includes(univ.collegeCode) ? "iconOpen" : ""} />
            </div>
            {openUnivList.includes(univ.collegeCode) && (
              <table className="univTable">
                <tbody>
                  <tr>
                    <th style={{ width: "30%" }}>모집단위</th>
                    <th style={{ width: "30%" }}>전형일</th>
                    <th style={{ width: "30%" }}>시험 시각</th>
                    <th style={{ width: "10%" }}>
                      <div className="col">
                        전체선택
                        {/* <Checkbox onChange={(e) => onClickCheckBox(e, { id: 0, isTotal: true, collegeCode: univ.collegeCode })} /> */}
                      </div>
                    </th>
                  </tr>

                  {applyDateDepartmentList.map((department) => {
                    return (
                      univ.collegeCode === department.collegeCode && (
                        <tr key={department.id + "crossable"}>
                          <td>{department.recruitmentUnit}</td>
                          <td>{department.admissionDate.split("T")[0]}</td>
                          <td>{department.admissionTime}</td>

                          <td>
                            <Checkbox checked={interestDepartmentList.includes(department.id)} onChange={() => onClickInterestDepartmnet(department.id)} />
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ApplyDateTable;
