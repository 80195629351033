import { Typography } from '@mui/material'
import Center from 'components/common/Center'

const ConditionMessage = ({ show = false, text = '' }: { show?: boolean; text?: string }) => {
  return (
    <>
      {
        <Center mt={4}>
          <Typography sx={{ color: 'grey', opacity: show ? 1 : 0 }}>{text}</Typography>
        </Center>
      }
    </>
  )
}

export default ConditionMessage
