import { Button, Typography } from '@mui/material'
import { createColumnHelper } from '@tanstack/react-table'
import {
  delteInterestUniversity,
  fetchInterestUniversitySubject,
  관심대학_교과_열타입,
  delteAllInterestUniversity,
} from 'api/api'
import DeleteButton from 'components/common/DeleteButton'
import DetailButton from 'components/common/DetailButton'
import RiskIndicator from 'components/common/RiskIndicator'
import TurtleTable2 from 'components/common/TurtleTable2'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { 관심대학페이지_컨텍스트 } from './InterestUniversityPage'
import { emptyTablePlaceholder, 관심대학_테이블스타일 } from './common'
import DetailModal from 'DetailModal/DetailModal'
import K_상세보기_교과모달_내용 from 'DetailModal/Subject/K_상세보기_교과모달_내용'
import Center from 'components/common/Center'
import { mainColor } from 'constants/style'

type Props = {}

const K_관심대학_교과탭 = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [detailRecruitmentId, setDetailRecruitmentId] = useState<number | null>(null)
  const { data, refetch } = useQuery('관심대학-교과', fetchInterestUniversitySubject, { refetchOnMount: true })
  const { setNumTotalRowMap } = useContext(관심대학페이지_컨텍스트)

  useEffect(() => {
    if (data) {
      setNumTotalRowMap((prev) => ({ ...prev, 교과: data.earlydSubjectInterestList.length }))
    }
  }, [data])

  let rows: 관심대학_교과_열타입[] = []

  if (!data) {
    rows = []
  } else {
    rows = data.earlydSubjectInterestList
  }

  const columnHelper = createColumnHelper<{
    id: number
    recruitmentUnitId: number
    collegeCode: string
    collegeName: string
    typeName: string
    recruitmentUnit: string
    cut70: string
    interviewDate: string
    risk50: string
    myGrade: string
    gradeDifference: string
    risk: string
    typeTotalScore: string
  }>()

  const columns = [
    columnHelper.accessor('collegeName', {
      header: '대학명',
    }),
    columnHelper.accessor('typeName', {
      header: '전형명',
    }),
    columnHelper.accessor('recruitmentUnit', {
      header: '모집단위',
    }),
    columnHelper.accessor('typeTotalScore', {
      header: '전형총점',
    }),
    columnHelper.accessor('risk50', {
      header: () => (
        <div>
          <p>최초컷</p>
          <Typography sx={{ fontSize: 12, fontWeight: 400 }}>(환산점수)</Typography>
        </div>
      ),
      cell: (info) => Number(info.getValue()).toFixed(2),
    }),
    columnHelper.accessor('myGrade', {
      header: '대학별 내 점수',
      cell: (info) => Number(info.getValue()).toFixed(2),
    }),
    columnHelper.accessor('gradeDifference', {
      header: '차이',
      cell: (info) => Number(info.getValue()).toFixed(2),
    }),
    columnHelper.accessor('risk', {
      header: '위험도',
      cell: (info) => (
        <Center>
          <RiskIndicator riskScore={info.getValue()} />
        </Center>
      ),
    }),
    columnHelper.display({
      id: '상세내역',
      header: '상세내역',
      cell: ({ row }) => (
        <DetailButton
          onClick={() => {
            setDetailRecruitmentId(rows[row.index].recruitmentUnitId)
            setModalOpen(true)
          }}
        />
      ),
      maxSize: 110,
    }),
    columnHelper.display({
      id: '삭제',
      header: '',
      cell: ({ row }) => (
        <DeleteButton
          onClick={() => {
            delteInterestUniversity('SUB', [rows[row.index].id]).then(() => {
              refetch()
            })
          }}
        />
      ), //todo deletebutton
    }),
  ]

  if (rows.length === 0) {
    return emptyTablePlaceholder
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Button
          style={{
            border: '1px solid ' + mainColor,
            color: mainColor,
            paddingLeft: 10,
            paddingRight: 10,
            width: '228px',
            height: '48px',
            marginTop: '20px',
          }}
          onClick={() => {
            delteAllInterestUniversity('SUB').then(() => {
              refetch()
            })
          }}
        >
          <Typography variant="D3_bold, 16, 24">전체삭제</Typography>
          <DeleteButton onClick={() => null} />
        </Button>
      </div>

      <TurtleTable2
        columns={columns}
        data={rows}
        tableStyle={관심대학_테이블스타일}
        sx={{ mt: 2, fontSize: '14px', fontWeight: 400, lineHeight: '24px' }}
      />
      <DetailModal
        param={detailRecruitmentId}
        open={modalOpen}
        setOpen={setModalOpen}
        Contents={K_상세보기_교과모달_내용}
      />
    </>
  )
}

export default K_관심대학_교과탭
