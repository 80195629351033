import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { StepPagesContext } from './StepPagesContext'

export default function useBundledRowSelection(dataList: any[], selectionMap: (dataList: any[], index: any) => any) {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const { setBundle } = useContext(StepPagesContext)

  useEffect(() => {
    setBundle({ selected: _.keys(_.pickBy(rowSelection)).map((identifier) => selectionMap(dataList, identifier)) })
  }, [rowSelection])

  return [rowSelection, setRowSelection] as const
}
