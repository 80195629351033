import { memberAxios } from './instance'

export const fetchInterestUniversityInfo = () => {
  return memberAxios.post('/interest/college/list', {
    selectTypeList: ['SUB', 'STU', 'ESS'],
  })
}

export type 관심대학_교과_열타입 = {
  basicTypeAdmission: string
  collegeCode: string
  collegeName: string
  cut70: string
  id: number
  myScore: number
  interviewDate: null | string
  recruitmentUnit: string
  typeName: string
  recruitmentUnitId: number
  tempTotalScore: number
}

export const fetchInterestUniversitySubject = () => {
  return memberAxios
    .post('/interest/college/list', {
      selectTypeList: ['SUB'],
    })
    .then((x) => {
      return x.data as { earlydSubjectInterestList: 관심대학_교과_열타입[] }
    })
}

export type 관심대학_학종_열타입 = {
  basicTypeAdmission: string
  collegeCode: string
  collegeName: string
  id: number
  interviewDate: string
  recruitmentUnit: string
  typeName: string
  recruitmentUnitId: number
  regionRange: string
  rowSeries: string
  lineRisk: string
  subjectRisk: string
  desubjectRisk: string
  diagnosisRisk: string
}

export const fetchInterestUniversityStudent = () => {
  return memberAxios
    .post('/interest/college/list', {
      selectTypeList: ['STU'],
    })
    .then((x) => {
      return x.data as { earlydStudentInterestList: 관심대학_학종_열타입[] }
    })
}
export type 관심대학_논술_열타입 = {
  admissionDate: string
  collegeCode: string
  collegeName: string
  id: number
  recruitmentUnit: string
  typeRate: string
  recruitmentUnitId: number
}

export const fetchInterestUniversityEssay = () => {
  return memberAxios
    .post('/interest/college/list', {
      selectTypeList: ['ESS'],
    })
    .then((x) => {
      return x.data as { essayInterestList: 관심대학_논술_열타입[] }
    })
}

export const delteInterestUniversity = (selectType: 'SUB' | 'STU' | 'ESS', idList: number[]) => {
  return memberAxios.delete('/interest/college/delete', {
    data: {
      selectType,
      idList,
    },
  })
}

export const delteAllInterestUniversity = (selectType: 'SUB' | 'STU' | 'ESS') => {
  return memberAxios.delete('/interest/college/deleteAll', {
    data: {
      selectType,
    },
  })
}

type AddCombinationData = {
  essInterestCollegeIds: number[]
  groupName: string
  stuInterestCollegeIds: number[]
  subInterestCollegeIds: number[]
}

export const postAddCombination = (data: AddCombinationData) => {
  return memberAxios.post('/interest/college/combination/add', data)
}

export const fetchGeneratedCombinations = () => {
  return memberAxios.get<{
    groupCount: number
    groupList: {
      id: number
      groupName: string
    }[]
  }>('/interest/college/combination/groups')
}

export const fetchGeneratedCombination = (id: number) => {
  return memberAxios.get<{
    interestCollegeCombinationList: {
      collegeName: string
      typeName: string
      admissionDate: string
      //학과명, 전형, 전형명,
    }[]
  }>(`/interest/college/combination/${id}`)
}

export const deleteCombinaion = (groupId: number) => {
  return memberAxios.delete(`/interest/college/combination/group/${groupId}`)
}

export const renameCombination = (groupId: number, newGroupName: string) => {
  return memberAxios.put(`/interest/college/combination/group/${groupId}`, {
    groupName: newGroupName,
  })
}
