import { memberAxios } from 'api/instance'
import {
  officerApplicationListAPI,
  officerApplyStudentAPI,
  officerCancelTicketAPI,
  officerEvaluationListAPI,
  officerFileDownloadAPI,
  officerListAPI,
  officerProfileInfoAPI,
  officerProfileUpdateAPI,
  officerSeriesListAPI,
  officerStudentInfoAPI,
  officerSurveyAddAPI,
  officerSurveyAddAPIByMySelf,
  officerSurveyCompleteAPI,
  officerSurveyListAPI,
  officerTicketCountAPI,
  officerUseTicketAPI,
} from 'api/urls'

export const getSeriesListFetch = async (type: string, series: string): Promise<[]> => {
  const res = await memberAxios.post('/compatibility/searchSeriesCdList', {
    type,
    series,
  })
  return res.data.data
}
interface series {
  grandSeries: string
  middleSeries: string
  rowSeries: string
}
;[]

interface myProfile {
  officerId: number | ''
  university: string | ''
  education: string | ''
  officerName: string | ''
  officerProfileImage: string | ''
  seriesList: series[] | []
}

interface officerProfile {
  memberId: string
  // 출신대학
  university: string
  // 학과/학력
  education: string
  // 평가자이름
  officerName: string
  // 평가자프로파일
  officerProfileImage: string
  //대기자 수
  readyCount: string
}
;[]
interface officerProfileResponse {
  status: boolean
  message: string
  officerProfileSearchList: officerProfile[]
}

interface commonStatusResponse {
  status: boolean
  message: string
}

interface officerApplicationInfo {
  officerId: string
  // 평가자이름
  officerName: string
  // 평가자프로파일
  progressStatus: string
  //대기자 수
  readyCount: string
}
;[]

interface officerApplyStudentInfo {
  studentId: string
  // 평가자이름
  studentName: string
  series: string
  // 평가자프로파일
  progressStatus: string
  //대기자 수
  readyCount: string
  phone: string
  email: string
}

interface officerSurveyInfo {
  evaluateContent: string
  surveyId: string
}
;[]
interface officerSurveyScoreInfo {
  surveyId: string
  score: string
}
;[]

interface officerCommentInfo {
  comment: string
  mainSurveyType: string
}
;[]
interface studentInfo {
  studentName: string
  series: string
}
interface officerEvaluationResponse {
  status: boolean
  officerSurveyList: officerSurveyScoreInfo[]
  officerCommentList: officerCommentInfo[]
}

/**
 * 평가자 프로필 저장
 * @param name
 * @param university
 * @param education
 * @param seriesItem
 * @param files
 */
export const saveProfileUpdateFetch = async (
  name: string | undefined,
  university: string | undefined,
  education: string | undefined,
  seriesItem: series[] | [],
  files: File | null
): Promise<commonStatusResponse> => {
  const formData = new FormData()
  if (files != null) {
    const file = files
    formData.append('file', file)
  }
  const data = { name: name, university: university, education: education, seriesList: seriesItem }
  //const blob = new Blob([JSON.stringify(data)], { type: "application/json" });
  formData.append('data', JSON.stringify(data))

  const res = await memberAxios.post(officerProfileUpdateAPI, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  console.log(res.data)

  return res.data
}
/**
 * 평가자 프로필 조회
 */
export const getProfileInfoFetch = async (): Promise<myProfile> => {
  const res = await memberAxios.get(officerProfileInfoAPI, {
    params: {},
  })

  return res.data
}
/**
 * 평가자 리스트 출력
 */
export const getOfficerListFetch = async (
  grandSeries: string | undefined,
  middleSeries: string,
  rowSeries: string
): Promise<officerProfileResponse> => {
  const res = await memberAxios.post(officerListAPI, {
    grandSeries: grandSeries,
    middleSeries: middleSeries,
    rowSeries: rowSeries,
  })

  return res.data
}
/**
 * 이용권 갯수 출력
 */
export const getTicketCountFetch = async (): Promise<any> => {
  const res = await memberAxios.get(officerTicketCountAPI, {
    params: {},
  })
  return res.data
}

/**
 * 이용권 사용
 */
export const useTicketFetch = async (memberId: string, series: string): Promise<commonStatusResponse> => {
  const res = await memberAxios.post(officerUseTicketAPI, {
    memberId,
    series,
  })
  return res.data
}

/**
 * 이용권 갯수 출력
 */
export const getApplicationListFetch = async (): Promise<officerApplicationInfo[]> => {
  const res = await memberAxios.get(officerApplicationListAPI, {
    params: {},
  })
  return res.data
}


//모든 학과 정보 조회
export const searchSeriesAllFetch = async (): Promise<series[]> => {

    const res = await memberAxios.post('/compatibility/searchSeriesAll', {
    })

    return res.data || [];
}



/**
 * 이용권 갯수 출력
 */
export const cancelTicketFetch = async (memberId: string): Promise<commonStatusResponse> => {
  const res = await memberAxios.post(officerCancelTicketAPI + '?memberId=' + memberId, {})
  return res.data
}

/**
 * 평가 신청자 조회
 */
export const getApplyStudentInfoFetch = async (): Promise<officerApplyStudentInfo> => {
  const res = await memberAxios.get(officerApplyStudentAPI, {})
  return res.data
}

/**
 * 평가 설문지 조회
 */
export const getSurveyListFetch = async (): Promise<officerSurveyInfo[]> => {
  const res = await memberAxios.get(officerSurveyListAPI, {})
  return res.data
}

/**
 * 평가가자 평가 완료내역 리스트 출력
 */

export type EvaludatedSurveyItem = {
  studentName: string
  completeDt: string
  series: string
  phone: string
  email: string
}

export const getSurveyCompleteListFetch = async () => {
  const res = await memberAxios.get<EvaludatedSurveyItem[]>(officerSurveyCompleteAPI, {}) //todo 수정
  return res.data
}

/**
 * 평가자 -> 학생 파일 다운로드
 */
export const downloadSchoolrecrodFileFetch = async (studentId: string | undefined, studentName: string | undefined) => {
  const res = await memberAxios
    .post(
      officerFileDownloadAPI,
      {
        studentId: studentId,
      },
      {
        responseType: 'blob',
      }
    )
    .then((res) => {
      const url = URL.createObjectURL(res.data)
      console.log(res)
      const link = document.createElement('a')
      link.href = url
      link.download = studentName + '_생활기록부.zip'
      link.click()
    })
}

// 다운로드 파일 이름을 추출하는 함수
const extractDownloadFilename = (response: any) => {
  const disposition = response.headers['content-disposition']
  const fileName = decodeURI(disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, ''))
  console.log('qwfqwf filename= ', fileName)
  return fileName
}

/**
 *
 * @param studentId
 * @param officerSurveyResultList
 * @param officerCommentList
 * @param saveType
 * @param isMySelf 자가 평가 :true , 평가자 평가 :false
 */
export const saveSurveyListFetch = async (
  studentId: string | undefined | null,
  officerSurveyResultList: officerSurveyScoreInfo[],
  officerCommentList: officerCommentInfo[],
  saveType: number,
  isMySelf: boolean,
  series: string
): Promise<commonStatusResponse> => {
  const url = isMySelf ? officerSurveyAddAPIByMySelf : officerSurveyAddAPI
  const res = await memberAxios.post(url, {
    studentId: studentId,
    officerSurveyResultList: officerSurveyResultList,
    officerCommentList: officerCommentList,
    saveType: saveType,
    series: series || '',
  })
  return res.data
}

/**
 * 평가 설문지 조회
 */
export const getSurveyScoreListFetch = async (
  studentId: string | undefined | null
): Promise<officerEvaluationResponse> => {
  const res = await memberAxios.get(officerEvaluationListAPI, {
    params: { studentId },
  })
  return res.data
}
/**
 * 평가 설문지 사용자 정보 조회
 * @param studentId
 */
export const getStudentInfoFetch = async (studentId: string | undefined | null): Promise<studentInfo> => {
  const res = await memberAxios.get(officerStudentInfoAPI, {
    params: { studentId },
  })
  return res.data
}
