import { Box, Collapse, Typography, styled } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/icon/arrow_forward.svg'
import { ReactComponent as CheckIcon } from 'assets/icon/check.svg'
import StackX from 'components/common/StackX'
import { Link } from 'react-router-dom'

const FloatingButton = styled('div')(({ theme }) => ({
  animation: `oscillate 2s ease-in-out infinite`,
  transitionDuration: '0.3s',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#C2C2C2',
  // '@keyframes oscillate ': {
  //   '0%, 100%': {
  //     opacity: 0.9,
  //   },
  //   '50%': {
  //     opacity: 1.0,
  //   },
  // },
}))

export const CollegeSaveDone = ({ show }: { show: boolean }) => {
  return (
    <Box sx={{ userSelect: 'none' }}>
      <Collapse orientation="horizontal" in={show}>
        <StackX
          sx={{
            overflow: 'hidden',
            border: `2px solid #E9E9E9`,
            borderWidth: show ? 2 : 0,
            px: '28px',
            py: '24px',
            bgcolor: '#F4F4F4',
          }}
          width={'343px'}
          mt={4}
          flexWrap={'wrap'}
          alignItems={'center'}
          height={'76px'}
          borderRadius={'20px'}
        >
          <CheckIcon />
          <Typography ml={'4px'} variant="H7_ExBold, 20, 28" color={'#252525'}>
            저장 완료
          </Typography>
          <Link to={'/suSi/strategy/interestUniv'}>
            <Typography ml={4} color="#C2C2C2" variant="D3_bold, 16, 24" fontWeight={700}>
              관심대학 보러가기
              <ArrowForwardIcon />
            </Typography>

            {/* <Typography
              ml={2}
              sx={(theme) => ({
                transition: theme.transitions.create(['background-color', 'transform'], {
                  duration: theme.transitions.duration.standard,
                }),
                textDecoration: 'underline',
              })}
            >
              관심대학 이동
            </Typography> */}
          </Link>
        </StackX>
      </Collapse>
    </Box>
  )
}
