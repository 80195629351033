import { createColumnHelper } from '@tanstack/react-table'
import { TextBox, TitledItem, 수시전형_상세보기_테이블스타일 } from 'DetailModal/common'
import TurtleTable2 from 'components/common/TurtleTable2'
import { useContext } from 'react'
import { SubjectDetailModalContext as EssayDetailModalContext } from './K_상세보기_논술모달_내용'

type Props = {}

//논술유형 등급별환산점수 응시정보
const 논술유형테이블 = () => {
  let {
    selectionModel,

    suNosul,
    examinationTendency,

    availableEtc,
    availableMath,
    availableSceience,

    region,
    competitionRate,
    centerType,
    admissionSeriesName,
    recruitmentUnit,
    studentRecruitmentNum,
  } = useContext(EssayDetailModalContext)

  let data: any[] = []
  let columns: any[] = []
  console.log(admissionSeriesName)

  const columnHelper = createColumnHelper<any>()
  if (admissionSeriesName === '문과') {
    columns = [
      columnHelper.accessor('suNosul', {
        header: '유형',
      }),
      columnHelper.accessor('요구과목', {
        header: '요구과목',
      }),
      columnHelper.accessor('examinationTendency', {
        header: '총 문제',
      }),
    ]
    data = [
      {
        suNosul,
        요구과목: availableMath,
        examinationTendency,
      },
    ]
  } else if (admissionSeriesName === '이과') {
    columns = [
      columnHelper.accessor('availableMath', {
        header: '수학요구과목',
      }),
      columnHelper.accessor('availableSceience', {
        header: '과학요구과목',
      }),
      columnHelper.accessor('examinationTendency', {
        header: '총 문제',
      }),
    ]
    data = [
      {
        availableMath,
        availableSceience,
        examinationTendency,
      },
    ]
  } else if (admissionSeriesName === '의치한약수') {
    columns = [
      columnHelper.accessor('availableMath', {
        header: '수학요구과목',
      }),
      columnHelper.accessor('availableSceience', {
        header: '과학요구과목',
      }),
      columnHelper.accessor('availableEtc', {
        header: '기타요구과목',
      }),
      columnHelper.accessor('examinationTendency', {
        header: '총 문제',
      }),
    ]
    data = [
      {
        availableMath,
        availableSceience,
        availableEtc,
        examinationTendency,
      },
    ]
  }

  return <TurtleTable2 columns={columns} data={data} tableStyle={수시전형_상세보기_테이블스타일} />
}

const 등급별환산점수테이블 = () => {
  const { selectionModel, grade1, grade2, grade3, grade4, grade5, grade6, grade7, grade8, grade9 } =
    useContext(EssayDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('grade1', {
      id: '전형총점',
      header: '전형총점',
    }),
    columnHelper.accessor('grade1', {
      header: '1등급',
    }),
    columnHelper.accessor('grade2', {
      header: '2등급',
    }),
    columnHelper.accessor('grade3', {
      header: '3등급',
    }),
    columnHelper.accessor('grade4', {
      header: '4등급',
    }),
    columnHelper.accessor('grade5', {
      header: '5등급',
    }),
    columnHelper.accessor('grade6', {
      header: '6등급',
    }),
    columnHelper.accessor('grade7', {
      header: '7등급',
    }),
    columnHelper.accessor('grade8', {
      header: '8등급',
    }),
    columnHelper.accessor('grade9', {
      header: '9등급',
    }),
  ]

  const data = [
    {
      grade1,
      grade2,
      grade3,
      grade4,
      grade5,
      grade6,
      grade7,
      grade8,
      grade9,
    },
  ]

  return <TurtleTable2 columns={columns} data={data} tableStyle={수시전형_상세보기_테이블스타일} />
}

const 응시정보테이블 = () => {
  const { interviewDate, interviewTime } = useContext(EssayDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('interviewDate', {
      header: '응시일자',
    }),
    columnHelper.accessor('interviewTime', {
      header: '시험시간',
    }),
  ]

  const data = [
    {
      interviewDate,
      interviewTime,
    },
  ]

  return <TurtleTable2 columns={columns} data={data} tableStyle={수시전형_상세보기_테이블스타일} />
}

const K_상세보기_논술정보탭 = (props: Props) => {
  const { lowestText } = useContext(EssayDetailModalContext)
  return (
    <>
      <TitledItem title="논술유형">
        <논술유형테이블 />
      </TitledItem>
      <TitledItem title="출제경향">
        <TextBox>{'TODO : 출제경향 서버 데이터'}</TextBox>
      </TitledItem>
      <TitledItem title="등급별 환산점수">
        <등급별환산점수테이블 />
      </TitledItem>
      <TitledItem title="응시정보">
        <응시정보테이블 />
      </TitledItem>
    </>
  )
}

export default K_상세보기_논술정보탭
