export const config = {
  kakaoClientId: process.env.REACT_APP_KAKAO_REST_API_LOGIN_KEY,
  naverClientId: process.env.REACT_APP_NAVER_LOGIN_CLIENT_ID,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  devBaseUrl: process.env.REACT_APP_BASE_URL,
  frontUrl: process.env.REACT_APP_FRONT_URL,
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    autoDomain: process.env.REACT_APP_FIREBASE_AUTO_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
};
