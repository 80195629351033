import { Box, Grid, Stack, Typography } from '@mui/material'
import InfoBadge from 'components/InfoBadge'
import TurtlePaper from 'components/common/TurtlePaper'

export default function GradeCard({ title, grade }: { title: string; grade: string }) {
  return (
    <TurtlePaper
      mt={5}
      sx={{ px: '30px', py: '25px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <Box>
        <Typography variant="H4_Exbold, 24, 32" sx={{}}>
          {title}
        </Typography>
        <Typography variant="H2_Bold, 36, 42" ml={4} sx={{ color: '#FF5600' }}>
          {grade ?? '-'}
        </Typography>
      </Box>
      <Box>
        <GradeInfoBadge />
      </Box>
    </TurtlePaper>
  )
}

export const GradeInfoBadge = () => {
  const gradeClass = [
    ['A+', '의치한약수'],
    ['A: ', '서울상위권'],
    ['B+', '서울중위권'],
    ['B: ', '서울하위건, 경기상위권'],
    ['C+', '경기중위권, 지방상위권'],
    ['C: ', '경기하위권, 지방중하위권'],
    ['D: ', '낙제'],
  ]

  return (
    <InfoBadge
      tooltipContent={
        <Box width={'260px'}>
          <Typography variant="H4_Exbold, 24, 32" color={'#252525'}>
            A? B?
          </Typography>
          <Typography mt={1} color={'#656565'} fontWeight={700} fontSize={'14px'} lineHeight={'18px'}>
            거북스쿨에서 자체적으로 평가하는 점수단위 에요. 생활기록부의 세부 내용을 점수화하여 대학별로 그룹을
            나누었어요.
          </Typography>

          <Typography color="#656565">
            <Box
              display="grid"
              gridTemplateColumns="21px 5px 1fr"
              gap={2}
              mt={'35px'}
              width={'100%'}
              fontWeight={700}
              fontSize={'16px'}
              px={2}
            >
              {gradeClass.flatMap(([gradeText, textLabel]) => [
                <span>{gradeText}</span>,
                <span>:</span>,
                <span style={{ textAlign: 'right' }}>{textLabel}</span>,
              ])}
            </Box>
          </Typography>
        </Box>
      }
    />
  )
}
