import {
  essayAddInterestFetch,
  essayAllListFetch,
  essayLowestGradeListFetch,
  essayMedicalEtcDepartment,
  essayMedicalEtcFetch,
  essayMedicalMathListFetch,
  essayMedicalRegionListFetch,
  essayMedicalScienceListFetch,
  essayRegionDepartment,
  lowestGradeOfDepartment,
  medicalMathableDepartment,
  medicalScienceDepartment,
} from "api/fetches/essay";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useCheckUserInputGrade } from "hooks/useCheckUserInputGrade";

export const useUiChiHanYacSu = () => {
  const navigate = useNavigate();
  const { scheduleId } = useCheckUserInputGrade();

  const [nowStep, setNowStep] = useState(0);

  const [suriNonsulFilter, setSuriNonsulFilter] = useState<string[]>(["ES2"]);
  const [scienceFilter, setScienceFilter] = useState<string[]>([]);
  const [etcFilter, setEtcFilter] = useState<string[]>([]);
  const [regionFilter, setRegionFilter] = useState<string[]>(["강원", "충북", "대전/충남", "부산/울산/경남", "대구/경북", "광주/전남", "전북", "제주"]);

  const [nowTableType, setNowTableType] = useState<"recommend" | "notRecommend">("recommend");
  const [openUnivList, setOpenUnivList] = useState<string[]>([]);
  const [departmentList, setDepartmentList] = useState<number[]>([]);

  // 수학가능과목 - 대학리스트 / 선택된 학과 리스트
  const [medicalMathablDepartmentList, setMedicalMathablDepartmentList] = useState<medicalMathableDepartment[]>([]);
  const [selectedMedicalMathablDepartmentList, setSelectedMedicalMathablDepartmentList] = useState<number[]>([]);

  // 과학가능과목
  const [scienceAbleUnivList, setScienceAbleUnivList] = useState<{ collegeCode: string; collegeName: string }[]>([]);
  const [scienceAbleDepartmentList, setScienceAbleDepartmentList] = useState<medicalScienceDepartment[]>([]);
  const [selectedScienceDepartmentList, setSelectedScienceDepartmentList] = useState<number[]>([]);

  // 기타 가능 선택
  const [medicalEtcDepartmentList, setMedicalEtcDepartmentList] = useState<essayMedicalEtcDepartment[]>([]);
  const [selectedMedicalEtcDepartmentList, setSelectedMedicalEtcDepartmentList] = useState<number[]>([]);

  // 지역인재
  const [regionDepartmentList, setRegionDepartmentList] = useState<essayRegionDepartment[]>([]);
  const [selectedRegionDepartmentList, setSelectedRegionDepartmentList] = useState<number[]>([]);

  // 최저등급확인
  const [lowestGradeOfUnivList, setLowestGradeOfUnivList] = useState<{ collegeCode: string; collegeName: string }[]>([]);
  const [lowestGradeOfDepartmentList, setLowestGradeOfDepartmentList] = useState<lowestGradeOfDepartment[]>([]);
  const [checkedLowestUnivList, setCheckedLowestUnivList] = useState<{ collegeCode: string; collegeName: string }[]>([]);
  const [checkedLowestRateDepartmentList, setCheckedLowestRateDepartmentList] = useState<lowestGradeOfDepartment[]>([]);

  const [applyDateUnivList, setapplyDateUnivList] = useState<{ collegeCode: string; collegeName: string }[]>([]);
  const [applyDateDepartmentList, setapplyDateDepartmentList] = useState<lowestGradeOfDepartment[]>([]);

  // 관심대학 추가 목록
  const [interestDepartmentList, setInterestDepartmentList] = useState<number[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    getMathAbleList();
    getAllDepartmentList();
  }, [suriNonsulFilter]);

  useEffect(() => {
    if (nowStep === 1) {
      getScienceAbleList();
    } else if (nowStep === 2) {
      getEssayMedicalEtcList();
    } else if (nowStep === 3) {
      getEssayMedicalRegionList();
    } else if (nowStep === 4) {
      checkLowestGrade();
    }
  }, [nowStep]);

  const getAllDepartmentList = async () => {
    const codeList = await essayAllListFetch("R3");
    console.log("codeList  ", codeList);
    setDepartmentList(codeList);
  };

  /**
   *  첫번째 스텝 수학강능과목 리스트
   * */
  const getMathAbleList = async () => {
    const { status, list } = await essayMedicalMathListFetch();

    if (status) {
      if (status) {
        console.log("--------------------------------------");
        console.log(list);
        setMedicalMathablDepartmentList(list);
      }
    }
  };

  /**
   * 과학가능과목 가져오기
   */
  const getScienceAbleList = async () => {
    try {
      setIsFetching(true);
      console.log("과학가능과목 가져오기 리스트 : ");
      console.log("최소조건 통과");
      const selectRecruitmentUnitList = getFilteredDepartmentList();

      const { sciencelist, status } = await essayMedicalScienceListFetch(selectRecruitmentUnitList);

      // const res = await essayAbleNaturalScienceListFetch("R3", selectedMedicalMathablDepartmentList);
      // console.log("과학가능과목 가져오기 리스트 : ", res);
      if (status) {
        const _univList = _.unionBy(
          sciencelist.map((science) => {
            return { collegeCode: science.collegeCode, collegeName: science.collegeName };
          }),
          "collegeCode"
        );
        setScienceAbleUnivList(_univList);
        setScienceAbleDepartmentList(sciencelist);
      }
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * 기타 가능 선택
   */
  const getEssayMedicalEtcList = async () => {
    setIsFetching(true);
    const selectRecruitmentUnitList = getFilteredDepartmentList();

    const res = await essayMedicalEtcFetch({ selectRecruitmentUnitList });
    console.log("getEssayMedicalEtcList res : ", res);

    if (res.status) {
      const departmentList = res.list[0];

      setMedicalEtcDepartmentList(departmentList);
    }
    setIsFetching(false);
  };

  /**
   * 지역인재
   */
  const getEssayMedicalRegionList = async () => {
    setIsFetching(true);
    const selectRecruitmentUnitList = getFilteredDepartmentList();

    const res = await essayMedicalRegionListFetch({ selectRecruitmentUnitList });
    if (res.status) {
      console.log("-------------------------------");
      console.log(res.essayRegionDataList);
      // if (res.essayRegionDataList.length < 6) {
      //   if (confirm("학교 지역이 입력되지 않아 지역인재 기능이 비활성화 되어있습니다. 마이페이지로 이동하시겠습니까?")) {
      //     navigate("/user/myInfo");
      //   }
      // }

      setRegionDepartmentList(res.essayRegionDataList);
    }
    setIsFetching(false);
  };

  /**
   * 최저등급 확인
   */
  const checkLowestGrade = async () => {
    setIsFetching(true);
    if (scheduleId !== null) {
      const selectRecruitmentUnitList = getFilteredDepartmentList();
      const lowestGrade = await essayLowestGradeListFetch({
        admissionSeriesCode: "R3",
        scheduleId: scheduleId,
        selectRecruitmentUnitList,
        selectUniversityList: [],
      });

      if (lowestGrade.status) {
        const _univList = _.unionBy(
          lowestGrade.list.map((department) => {
            return { collegeCode: department.collegeCode, collegeName: department.collegeName };
          }),
          "collegeCode"
        );
        setLowestGradeOfUnivList(_univList);
        setLowestGradeOfDepartmentList(lowestGrade.list);
      }
    } else {
      console.log("/-- schedule id is null --/");
    }
    setIsFetching(false);
  };

  const saveInterestDepartment = async () => {
    const res = await essayAddInterestFetch(interestDepartmentList);

    if (res.status) {
      alert("관심대학에 저장되었습니다.");
      navigate("/suSi/strategy/interestUniv");
    }
  };

  // -------------------------  event   ------------------------

  const onClickFilterButton = (type: string) => {
    switch (nowStep) {
      case 0: {
        if (type === "") {
          suriNonsulFilter.length === 4 ? setSuriNonsulFilter([]) : setSuriNonsulFilter(["ES2", "ES3", "ES4", "ES4"]);
        } else {
          let _suriNonsulFilter = [...suriNonsulFilter];
          if (_suriNonsulFilter.includes(type)) {
            _suriNonsulFilter = _suriNonsulFilter.filter((filter) => filter !== type);
          } else {
            _suriNonsulFilter.push(type);
          }
          setSuriNonsulFilter(_suriNonsulFilter);
        }
        break;
      }
      case 1: {
        if (type === "") {
          suriNonsulFilter.length === 9 ? setScienceFilter([]) : setScienceFilter(["mulOne", "hwaOne", "saengOne", "jiOne", "totalScience", "mul", "hwa", "saeng", "ji"]);
        } else {
          let _scienceFilter = [...scienceFilter];
          if (_scienceFilter.includes(type)) {
            _scienceFilter = _scienceFilter.filter((filter) => filter !== type);
          } else {
            _scienceFilter.push(type);
          }
          console.log("__scienceFilter : ", _scienceFilter);
          setScienceFilter(_scienceFilter);
        }
        break;
      }
      case 2: {
        if (type === "") {
          etcFilter.length === 3 ? setEtcFilter([]) : setEtcFilter(["munGwaNonSul", "medicalNonSul", "eng"]);
        } else {
          let _etcFilter = [...etcFilter];
          if (_etcFilter.includes(type)) {
            _etcFilter = _etcFilter.filter((filter) => filter !== type);
          } else {
            _etcFilter.push(type);
          }
          console.log("_etcFilter : ", _etcFilter);
          setEtcFilter(_etcFilter);
        }
        break;
      }
      case 3: {
        if (type === "") {
          regionFilter.length === 8 ? setRegionFilter([]) : setRegionFilter(["강원", "충북", "대전/충남", "부산/울산/경남", "대구/경북", "광주/전남", "전북", "제주"]);
        } else {
          let _regionFilter = [...regionFilter];
          if (_regionFilter.includes(type)) {
            _regionFilter = _regionFilter.filter((filter) => filter !== type);
          } else {
            _regionFilter.push(type);
          }
          console.log("_etcFilter : ", _regionFilter);
          setRegionFilter(_regionFilter);
        }
        break;
      }
      default:
        break;
    }
  };

  const onClickStep = (isNext: boolean) => {
    if (isNext) {
      if (nowStep < 5) {
        setNowStep((prev) => prev + 1);
      } else {
        saveInterestDepartment();
      }
    } else {
      nowStep !== 0 && setNowStep((prev) => prev - 1);
    }
  };

  const onClickTableType = (tableType: "recommend" | "notRecommend") => setNowTableType(tableType);

  const onClickUnivRow = (univCode: string) => {
    console.log("univCode : ", univCode);
    let _openUnivList = [...openUnivList];

    if (_openUnivList.includes(univCode)) {
      _openUnivList = _openUnivList.filter((_univCode) => _univCode !== univCode);
    } else {
      _openUnivList.push(univCode);
    }

    setOpenUnivList(_openUnivList);
  };

  /**
   * 대학 체크박스 클릭 이벤트
   * @deprecated
   * @param event
   * @param id 모집단위 아이디
   */
  const onClickUnivCheckBox = (univCode: string) => {
    // switch (nowStep) {
    //   case 0: {
    //     let _selectedMathAbleUnivList = [...selectedMathAbleUnivList];
    //     if (_selectedMathAbleUnivList.includes(univCode)) {
    //       _selectedMathAbleUnivList = _selectedMathAbleUnivList.filter((_univCode) => _univCode !== univCode);
    //     } else {
    //       _selectedMathAbleUnivList.push(univCode);
    //     }
    //     setSelectedMathAbleUnivList(_selectedMathAbleUnivList);
    //     break;
    //   }
    //   default:
    //     break;
    // }
  };

  /**
   * 대학 체크박스 전체선택 클릭 이벤트
   * @deprecated
   * @param isTotal 전체선택인지 해제인지
   * @param univCodeList 모집단위 아이디
   */
  const onClickUnivTotalCheckBox = (isTotal: boolean, univCodeList: string[]) => {
    // switch (nowStep) {
    //   case 0: {
    //     let _selectedMedicalMathablDepartmentList = [...selectedMedicalMathablDepartmentList];
    //     if (isTotal) {
    //       _selectedMedicalMathablDepartmentList = [..._selectedMedicalMathablDepartmentList, ...univCodeList];
    //     } else {
    //       _selectedMedicalMathablDepartmentList = _selectedMedicalMathablDepartmentList.filter((id) => !univCodeList.includes(id));
    //     }
    //     setSelectedMedicalMathablDepartmentList(_selectedMedicalMathablDepartmentList);
    //     break;
    //   }
    //   default:
    //     break;
    // }
  };

  /**
   * 모집단위 체크박스 클릭 이벤트
   * @param event
   * @param id 모집단위 아이디
   */
  const onClickDepartmentCheckBox = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    /**
     *  리팩토링 코드 기능검증 필요
     *  수정날짜 (6/20)
     */
    const stateList = [selectedMedicalMathablDepartmentList, selectedScienceDepartmentList, selectedMedicalEtcDepartmentList, selectedRegionDepartmentList][nowStep];
    let _stateList = [...stateList];
    const setStateList = [setSelectedMedicalMathablDepartmentList, setSelectedScienceDepartmentList, setSelectedMedicalEtcDepartmentList, setSelectedRegionDepartmentList][nowStep];

    if (event.target.checked) {
      _stateList.push(id);
    } else {
      _stateList = _stateList.filter((_id) => _id !== id);
    }
    setStateList(_stateList);

    // if (nowStep === 0) {
    //   let _selectedMedicalMathablDepartmentList = [...selectedMedicalMathablDepartmentList];

    //   if (event.target.checked) {
    //     _selectedMedicalMathablDepartmentList.push(id);
    //   } else {
    //     _selectedMedicalMathablDepartmentList = _selectedMedicalMathablDepartmentList.filter((_id) => _id !== id);
    //   }

    //   setSelectedMedicalMathablDepartmentList(_selectedMedicalMathablDepartmentList);
    // }
    // if (nowStep === 1) {
    //   let _selectedScienceDepartmentList = [...selectedScienceDepartmentList];

    //   if (event.target.checked) {
    //     _selectedScienceDepartmentList.push(id);
    //   } else {
    //     _selectedScienceDepartmentList = _selectedScienceDepartmentList.filter((_id) => _id !== id);
    //   }

    //   setSelectedScienceDepartmentList(_selectedScienceDepartmentList);
    // }
    // if (nowStep === 2) {
    //   let _selectedMedicalEtcDepartmentList = [...selectedMedicalEtcDepartmentList];

    //   if (event.target.checked) {
    //     _selectedMedicalEtcDepartmentList.push(id);
    //   } else {
    //     _selectedMedicalEtcDepartmentList = _selectedMedicalEtcDepartmentList.filter((_id) => _id !== id);
    //   }

    //   setSelectedMedicalEtcDepartmentList(_selectedMedicalEtcDepartmentList);
    // }
    // if (nowStep === 3) {
    //   let _selectedRegionDepartmentList = [...selectedRegionDepartmentList];

    //   if (event.target.checked) {
    //     _selectedRegionDepartmentList.push(id);
    //   } else {
    //     _selectedRegionDepartmentList = _selectedRegionDepartmentList.filter((_id) => _id !== id);
    //   }

    //   setSelectedRegionDepartmentList(_selectedRegionDepartmentList);
    // }
  };

  /**
   * 모집단위 체크박스 전체 클릭 이벤트
   * @param event
   * @param id 모집단위 아이디
   */
  const onClickTotalDepartmentCheckBox = (isTotal: boolean, selectedIdList: number[]) => {
    console.log("모집단위 체크박스 전체 클릭 이벤트");

    if (nowStep === 1) {
      console.log("여기여기");
      console.log("selectedIdList : ", selectedIdList);
      let _selectedScienceDepartmentList = [...selectedScienceDepartmentList];

      if (isTotal) {
        _selectedScienceDepartmentList = [..._selectedScienceDepartmentList, ...selectedIdList];
      } else {
        _selectedScienceDepartmentList = _selectedScienceDepartmentList.filter((id) => !_selectedScienceDepartmentList.includes(id));
      }
      setSelectedScienceDepartmentList(_selectedScienceDepartmentList);
    }
  };

  /**
   * 최저 등급 확인 체크박스 클릭 이벤트 (다른 모집단위 체크박스의 경우 id값반 받아오지만 최저등급의 경우 모든 정보가 다 필요)
   * @param lowestGradeOfDepartment
   */
  const onClickLowestRateDepartmentCheckBox = (lowestGradeOfDepartment: lowestGradeOfDepartment) => {
    let _finalChoiceDepartment = [...checkedLowestRateDepartmentList];
    const finalDepartmentIdList = checkedLowestRateDepartmentList.map((department) => department.id);

    if (finalDepartmentIdList.includes(lowestGradeOfDepartment.id)) {
      _finalChoiceDepartment = _finalChoiceDepartment.filter((department) => department.id !== lowestGradeOfDepartment.id);
    } else {
      _finalChoiceDepartment.push(lowestGradeOfDepartment);
    }

    const _univList = _.unionBy(
      _finalChoiceDepartment.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName };
      }),
      "collegeCode"
    );
    console.log(_univList);
    console.log(_finalChoiceDepartment);
    setCheckedLowestUnivList(_univList);
    setapplyDateUnivList(_univList);
    setCheckedLowestRateDepartmentList(_finalChoiceDepartment);
  };

  const onClickLowestRateTotalCheckBox = (isTotal: boolean, lowestGradeDepartmentList: lowestGradeOfDepartment[]) => {
    let _checkedLowestRateDepartmentList = [...checkedLowestRateDepartmentList];
    if (isTotal) {
      _checkedLowestRateDepartmentList = _.uniqBy([..._checkedLowestRateDepartmentList, ...lowestGradeDepartmentList], "id");
    } else {
      const selectedIdList = lowestGradeDepartmentList.map((department) => department.id);
      _checkedLowestRateDepartmentList = _checkedLowestRateDepartmentList.filter((department) => !selectedIdList.includes(department.id));
    }
    const _univList = _.unionBy(
      _checkedLowestRateDepartmentList.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName };
      }),
      "collegeCode"
    );

    setCheckedLowestUnivList(_univList);
    setapplyDateUnivList(_univList);
    setapplyDateDepartmentList(_checkedLowestRateDepartmentList);
    setCheckedLowestRateDepartmentList(_checkedLowestRateDepartmentList);
  };

  const onClickInterestDepartmnet = (departmentId: number) => {
    let _interestDepartment = [...interestDepartmentList];

    if (_interestDepartment.includes(departmentId)) {
      _interestDepartment = _interestDepartment.filter((department) => department !== departmentId);
    } else {
      _interestDepartment.push(departmentId);
    }

    setInterestDepartmentList(_interestDepartment);
  };

  // -------------------------------------------------------------------------------------------
  // 선택되지 않은 모집단위 id값 구하기
  const getFilteredDepartmentList = () => {
    let unSelectedIdList: number[] = [];

    for (let i = 0; i < nowStep; i++) {
      unSelectedIdList = [...unSelectedIdList, ...getUnSelectedDepartmentIdList(i)];
    }

    return getSelectedDepartmentList(unSelectedIdList);
  };

  const getUnSelectedDepartmentIdList = (step: number) => {
    if (step === 0) {
      return medicalMathablDepartmentList.filter((department) => !selectedMedicalMathablDepartmentList.includes(department.id)).map((department) => department.id);
    } else if (step === 1) {
      return scienceAbleDepartmentList.filter((department) => !selectedScienceDepartmentList.includes(department.id)).map((department) => department.id);
    } else if (step === 2) {
      return medicalEtcDepartmentList.filter((department) => !selectedMedicalEtcDepartmentList.includes(department.id)).map((department) => department.id);
    } else if (step === 3) {
      return regionDepartmentList.filter((department) => !selectedRegionDepartmentList.includes(department.id)).map((department) => department.id);
    }
    return [];
  };

  const getSelectedDepartmentList = (unSelectedIdList: number[]) => {
    return departmentList.filter((univCode) => !unSelectedIdList.includes(univCode));
  };
  // 선택되지 않은 모집단위 id값 구하기
  // --------------------------------------------------------------------------------------------

  return {
    nowStep,
    suriNonsulFilter,
    medicalMathablDepartmentList,
    selectedMedicalMathablDepartmentList,
    nowTableType,
    scienceAbleUnivList,
    scienceAbleDepartmentList,
    selectedScienceDepartmentList,
    scienceFilter,
    checkedLowestRateDepartmentList,
    checkedLowestUnivList,
    applyDateDepartmentList,
    openUnivList,
    lowestGradeOfDepartmentList,
    lowestGradeOfUnivList,
    applyDateUnivList,
    interestDepartmentList,
    etcFilter,
    medicalEtcDepartmentList,
    selectedMedicalEtcDepartmentList,
    regionDepartmentList,
    selectedRegionDepartmentList,
    regionFilter,
    isFetching,
    checkLowestGrade,
    onClickFilterButton,
    onClickStep,
    onClickTableType,
    onClickUnivRow,
    onClickDepartmentCheckBox,
    onClickTotalDepartmentCheckBox,

    onClickLowestRateDepartmentCheckBox,
    onClickLowestRateTotalCheckBox,
    onClickInterestDepartmnet,
  };
};
