import { memberAxios } from 'api/instance'
import React from 'react'
import { useQuery } from 'react-query'
import DetailModalLayout from '../DetailModalLayout'
import K_상세보기_전형정보탭 from './K_상세보기_전형정보탭'
import K_상세보기_논술정보탭 from './K_상세보기_논술정보탭'

type RecruitmentUnitInfo = {
  admissionSeriesName: string
  availableEtc: string
  availableMath: string
  availableSceience: string
  centerType: string
  collegeName: string
  competitionRate: string
  essayType: string
  examinationTendency: string
  grade1: string
  grade2: string
  grade3: string
  grade4: string
  grade5: string
  grade6: string
  grade7: string
  grade8: string
  grade9: string
  interviewDate: string
  interviewTime: string
  lowestText: string
  recruitmentUnit: string
  recruitmentUnitId: number
  region: string
  selectionModel: string
  studentRecruitmentNum: string
  suNosul: string
  typeRate: string
}

const defaultContextValue = {
  admissionSeriesName: '의치한약수',
  availableEtc: '의학논술, 영어, 언어논술',
  availableMath: '수1, 수2, 확통',
  availableSceience: '화1+화, 통합과학',
  centerType: '논술(고정)',
  collegeName: '건국대',
  competitionRate: '3 : 1',
  essayType: 'KU논술',
  examinationTendency: '3',
  grade1: '1000',
  grade2: '970',
  grade3: '940',
  grade4: '910',
  grade5: '880',
  grade6: '850',
  grade7: '750',
  grade8: '500',
  grade9: '280',
  interviewDate: '2023-07-05',
  interviewTime: '15:00',
  lowestText: '국, 수, 영, 탐 4개 영역 등급 합 7 이내',
  recruitmentUnit: '국어국문학과',
  recruitmentUnitId: 1,
  region: '서울',
  selectionModel: '일괄합산(고정)',
  studentRecruitmentNum: '7',
  suNosul: '수리 논리 없는 도표, 도표 없는 수리 논술, 도표 포함 수리 논술, 영어 제시문',
  typeRate: '논술:70% 학생부:30%',
}

export const SubjectDetailModalContext = React.createContext<RecruitmentUnitInfo>(defaultContextValue)

const K_상세보기_논술모달_내용 = ({ param: recruitmentUnitId }: { param: number }) => {
  const { data } = useQuery(['상세보기-논술모달', recruitmentUnitId], () => {
    return memberAxios.get(`/essay/${recruitmentUnitId}/details`, {}).then((res) => {
      return res.data as { recruitmentUnitInfo: RecruitmentUnitInfo }
    })
  })

  const contextValue = data?.recruitmentUnitInfo ?? defaultContextValue

  const { collegeName, recruitmentUnit } = contextValue

  return (
    <SubjectDetailModalContext.Provider value={contextValue}>
      <DetailModalLayout
        college={collegeName}
        department={recruitmentUnit}
        tabContentList={[
          {
            tabName: '전형정보',
            tabContent: <K_상세보기_전형정보탭 />,
          },
          {
            tabName: '논술정보',
            tabContent: <K_상세보기_논술정보탭 />,
          },
        ]}
      />
    </SubjectDetailModalContext.Provider>
  )
}

export default K_상세보기_논술모달_내용
