import { Box, SxProps } from '@mui/system'
import React, { PropsWithChildren } from 'react'

type Props = {
  onClick? : React.MouseEventHandler,
  sx?:SxProps
}

const RoundButton = ({onClick, children, sx ={}}: PropsWithChildren<Props>) => {
  return (
    <Box component={"button"} onClick={onClick} sx={{
      backgroundColor: "#f4f4f4",
      fontWeight:700,
      fontSize:"16px",
      borderRadius:"20px",
      height:"auto",
      wordBreak:"keep-all",
      px:"15px",
      py:"8px",
      ...sx}}>{children}</Box>
  )
}

export default RoundButton