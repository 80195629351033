import { Checkbox } from "@mui/material";
import { liGwaMathAbleUniv, medicalMathableDepartment } from "api/fetches/essay";
import "./style.scss";

interface Props {
  departmentList: medicalMathableDepartment[];
  selectedDepartmentList: number[];
  nowTableType: "recommend" | "notRecommend";
  suriNonsulFilter: string[];
  onClickTotalCheckBox: (isTotal: boolean, idList: number[]) => void;
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
}

/**
 *
 * @returns 수학 가능 과목 테이블
 */
const MedicalMathableTable = ({ departmentList, selectedDepartmentList, nowTableType, suriNonsulFilter, onClickTotalCheckBox, onClickDepartmentCheckBox }: Props) => {
  const recDeapartmentList = departmentList.filter((department) => {
    if (suriNonsulFilter.includes("ES2") && department.suoneSutwo === 0) return false;
    if (suriNonsulFilter.includes("ES3") && department.pbSt === 0) return false;
    if (suriNonsulFilter.includes("ES4") && department.dfIn === 0) return false;
    if (suriNonsulFilter.includes("ES5") && department.geometry === 0) return false;
    return true;
  });

  const recDepartmentIdList = recDeapartmentList.map((department) => department.id);

  const notRecDeapartmentList = departmentList.filter((department) => {
    return !recDepartmentIdList.includes(department.id);
  });

  const notRecDepartmentIdList = notRecDeapartmentList.map((department) => department.id);

  const isCheckTotal =
    nowTableType === "recommend"
      ? recDepartmentIdList.every((department) => selectedDepartmentList.includes(department))
      : notRecDepartmentIdList.every((department) => selectedDepartmentList.includes(department));

  /**
   * 추천 데이터 및 비추천데이터가없으면,대학 이름이 나오면안됨.
   */
  if(nowTableType === "recommend"&&recDeapartmentList.length<1){
    return <div></div>;
  }else if(nowTableType !== "recommend"&&notRecDeapartmentList.length<1){
    return <div></div>;
  }

  return (
    <table className="univTable">
      <tbody>
        <tr>
          <th>대학명</th>
          <th>모집단위명</th>
          <th>공통수학</th>
          <th>수1+수2</th>
          <th>확통</th>
          <th>미적</th>
          <th>기하</th>
          <th>
            <div className="col">
              전체선택
              {/* <Checkbox checked={isCheckTotal} onChange={() => onClickTotalCheckBox(!isCheckTotal, nowTableType === "recommend" ? recDepartmentIdList : notRecDepartmentIdList)} /> */}
            </div>
          </th>
        </tr>

        <TableRows
          departmentList={nowTableType === "recommend" ? recDeapartmentList : notRecDeapartmentList}
          selectedDepartmentList={selectedDepartmentList}
          onClickDepartmentCheckBox={onClickDepartmentCheckBox}
        ></TableRows>
      </tbody>
    </table>
  );
};

export default MedicalMathableTable;

interface TableRowProps {
  departmentList: medicalMathableDepartment[];
  selectedDepartmentList: number[];
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
}

const TableRows = ({ departmentList, selectedDepartmentList, onClickDepartmentCheckBox }: TableRowProps) => {
  return (
    <>
      {departmentList.map((department) => {
        return (
          <tr key={department.id + "crossable"}>
            <td>{department.collegeName}</td>
            <td>{department.recruitmentUnit}</td>
            <td>{department.commonMath === 1 ? "✓" : ""}</td>
            <td>{department.suoneSutwo === 1 ? "✓" : ""}</td>
            <td>{department.pbSt === 1 ? "✓" : ""}</td>
            <td>{department.dfIn === 1 ? "✓" : ""}</td>
            <td>{department.geometry === 1 ? "✓" : ""}</td>

            <td>
              <Checkbox checked={selectedDepartmentList?.includes(department.id) ?? false} onChange={(e) => onClickDepartmentCheckBox(e, department.id)} />
            </td>
          </tr>
        );
      })}
    </>
  );
};
