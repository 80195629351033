import { memberAxios } from 'api/instance'
import {
  mockAddListAPI,
  mockAddMarksAPI,
  mockAddScoreAPI,
  mockCheckAPI,
  mockCheckInputAPI,
  mockListMarksAPI,
  mockListRawAPI,
} from 'api/urls'

interface checkisCanMockInputReturn {
  mockexamMonth: string
  mockexamYear: string
  scheduleId: number
}

export const checkIsCanMockInputFetch = async (month: string): Promise<checkisCanMockInputReturn> => {
  const res = await memberAxios.get(mockCheckAPI, {
    params: { month },
  })

  console.log('----------------------')
  console.log(res)
  return res.data
}

/**
 * Name	Description
  month: string;
  year: string;
 */

export interface addMockScoreListParams {
  month: string
  year: string
}

export const addMockScoreListFetch = async (params: addMockScoreListParams) => {
  const res = await memberAxios.post(mockAddMarksAPI, { ...params })

  console.log('addMockScoreListFetch res', res)
  return res.data
}

/**
 * Name	Description
parentCode - 모의고사 부모 과목코드
score - 점수
subjectCode - 모의고사 과목 코드
scheduleId - 모의고사 스케쥴 아이디
 */

export interface addMockScoreParams {
  mockScoreList: {
    parentCode: string
    score: string
    subjectCode: string
  }[]
  scheduleId: string
}
/**
 * 원점수 저장
 * @param params
 * @returns
 */
export const addMockScoreFetch = async (
  params: addMockScoreParams
): Promise<{ code: string | null; message: string | null; status: boolean }> => {
  const res = await memberAxios.post(mockAddScoreAPI, { ...params })

  console.log('addMockScoreFetch res', res)
  return res.data
}

/**
 * 표준점수 저장
 * @param params
 * @returns
 */
export const addMockMarkScoreFetch = async (
  params: addMockScoreParams
): Promise<{ code: string | null; message: string | null; status: boolean }> => {
  const res = await memberAxios.post(mockAddMarksAPI, { ...params })
  console.log(params)

  console.log('addMockMarkScoreFetch res', res)
  return res.data
}

export const mockCheckInputFetch = async (
  month: string
): Promise<{ message: null | string; scheduleId: number; status: boolean }> => {
  const res = await memberAxios.get(mockCheckInputAPI, { params: { month } })

  console.log('mockCheckInputFetch res', res)
  return res.data
}

/**
 * 저장된 원점수 가져오기
 * @param scheduleId 쿼리가 아닌 url에 함께 보낸다
 * @returns
 */
export const mockListRawFetch = async (
  scheduleId: number
): Promise<{ list: { rawScore: string; subjectCode: string }[] }> => {
  const res = await memberAxios.get(mockListRawAPI + '/' + scheduleId)

  console.log('mockListRawFetch res', res)
  return res.data
}

/**
 * 저장된 표준점수 가져오기
 * @param scheduleId 쿼리가 아닌 url에 함께 보낸다
 * @returns
 */
export const mockListMarksFetch = async (
  scheduleId: number
): Promise<{ list: { marksScore: string; subjectCode: string }[] }> => {
  const res = await memberAxios.get(mockListMarksAPI + '/' + scheduleId)

  return res.data
}
