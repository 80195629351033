import { Box, BoxProps, Typography } from '@mui/material'
import React from 'react'

const checkCurrentStep = (nowStep: number, index: number) => {
  if (nowStep > index) {
    return 'before'
  }

  if (nowStep < index) {
    return 'after'
  }

  return 'now'
}

type Props = {
  infos: { label: React.ReactNode }[]
  currentStep: number
} & BoxProps

const StepIndicator = ({ infos, currentStep, sx, ...boxProps }: Props) => {
  return (
    <Box
      sx={sx}
      style={{
        display: 'flex',
        width: '100%',
      }}
      {...boxProps}
      mt={'40px'}
      mb={'50px'}
    >
      {infos.map((stepInfo, index) => {
        return (
          <Box
            key={index}
            className={`stepBox ${
              index === 0 ? 'start' : index + 1 === infos.length ? 'end' : 'middle'
            } ${checkCurrentStep(currentStep - 1, index)}`}
            sx={(theme) => ({
              userSelect: 'none',
              pl: '17px',
              pt: '14px',
              fontSize: '0.7rem',
              flex: 1,
              height: '86px',
              color: '#fff',
              borderWidth: '1px solid black',

              '&.now': {
                backgroundColor: '#1c4bc3',
                color: '#fff',
              },
              '&.before': {
                backgroundColor: '#e6ecf3',
                color: '#1c4bc3',
              },
              '&.after': {
                backgroundColor: '#f4f4f4',
                color: '#c2c2c2',
              },

              '&.start': {
                clipPath: 'polygon(0 0, 92% 0, 100% 50%, 92% 100%, 0 100%, 0 50%)',
                borderRadius: '5px',
              },
              '&.middle': {
                clipPath: 'polygon(0 0, 92% 0, 100% 50%, 92% 100%, 0 100%, 7% 50%)',
                borderRadius: '5px',
              },
              '&.end': {
                clipPath: 'polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 7% 50%)',
                borderRadius: '5px',
              },

              [theme.breakpoints.down('md')]: {
                pl: '8px',
                pt: '6px',
              },
            })}
          >
            <Typography
              variant="D5_Exbold, 14, 18"
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  fontSize: '12px',
                  fontFamily: 'nanumsquare_bold',
                },
              })}
            >
              {stepInfo.label}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export default StepIndicator
