type Props = {}

import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { getGradeFromScore, gradeMap } from 'utils/util'

const reverseGrade = (x: number) => 9 - x

const AlphabetGradeChart = ({ data }: { data: { name: string; grade: number }[] }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={500} height={300} data={data}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="name" fontWeight={700} tickLine={false} dy={10} stroke="0" />
        <YAxis
          stroke="0"
          domain={[1, 7]}
          width={40}
          textAnchor="left"
          dx={-20}
          tickFormatter={(x) => `${getGradeFromScore(x)}`}
          allowDecimals={false}
          tickCount={8}
          color="#9A9A9A"
          tickLine={false}
        />
        {/* <Legend /> */}
        <Bar dataKey="grade" fill="#103abf" barSize={36} />
      </BarChart>
    </ResponsiveContainer>
  )
}
export default AlphabetGradeChart
