import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { schoolrecordSelectSubjectItem } from 'api/fetches/earlyd'
import {useEffect} from "react";
interface gyoGwaCode {
    [key: string]: {
        label: string;
        value: string;
        list?: { label: string; value: string; type: string }[];
    };
}
const CourseGyogwaLine = ({
                              index,
                              schoolrecordSelectSubjectItem,
                              onChagneCourseGyogwaValue,
                              gyoGwaCode
                          }: {
    index: number
    schoolrecordSelectSubjectItem: schoolrecordSelectSubjectItem
    onChagneCourseGyogwaValue: (index: number, type: string, value: any) => void
    gyoGwaCode:gyoGwaCode
}) => {
    useEffect(()=>{

    },[gyoGwaCode])
    return (

        <>
            <div className="d-flex align-items-center subjectSelectContainer mb-3">
                <div className="col-1 ">
                    <FormControl className="formControl">
                        <Select
                            name="kor"
                            className="select"
                            value={schoolrecordSelectSubjectItem.semester}
                            onChange={(e) => {
                                onChagneCourseGyogwaValue(index, 'semester', e.target.value)
                            }}
                        >
                            <MenuItem disabled value={'_'}>
                                <em>학기선택</em>
                            </MenuItem>
                            <MenuItem value={1}>
                               1학기
                            </MenuItem>
                            <MenuItem value={2}>
                                2학기
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="col-2 ">
                    <FormControl className="formControl">
                        <Select
                            name="kor"
                            className="select"
                            value={schoolrecordSelectSubjectItem.mainSubjectCode}
                            onChange={(e) => {
                                onChagneCourseGyogwaValue(index, 'mainSubjectCode', e.target.value)
                                onChagneCourseGyogwaValue(index, 'mainSubjectName', gyoGwaCode[e.target.value].label)
                            }}
                        >
                            <MenuItem disabled value={'_'}>
                                <em>과목선택</em>
                            </MenuItem>
                            {Object.keys(gyoGwaCode).map((key, index) => {
                                return (
                                    <MenuItem key={key + index} {...gyoGwaCode[key]}>
                                        {gyoGwaCode[key].label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-1">
                    <FormControl className="formControl">
                        <Select
                            name="kor"
                            className="select"
                            value={schoolrecordSelectSubjectItem.subjectCode}
                            style={{color: '#fff'}}
                            onChange={(e) => {
                                onChagneCourseGyogwaValue(index, 'subjectCode', e.target.value)
                                const gyogwaList = gyoGwaCode[schoolrecordSelectSubjectItem.mainSubjectCode].list??[];
                                let gyoGwaName ="";
                                for(var i=0;i<gyogwaList.length;i++) {
                                    if(gyogwaList[i].value===e.target.value) {
                                        gyoGwaName = gyogwaList[i].label;
                                        break;
                                    }
                                }
                                onChagneCourseGyogwaValue(index, 'subjectName',gyoGwaName)
                            }}
                        >
                            <MenuItem disabled value={'_'}>
                                <em>세부과목</em>
                            </MenuItem>
                            {schoolrecordSelectSubjectItem.mainSubjectCode !== '_' &&
                                gyoGwaCode[schoolrecordSelectSubjectItem.mainSubjectCode].list
                                    ?.filter((subject) => {
                                        return subject.type === '1'
                                    })
                                    .map((subject) => {
                                        return (
                                            <MenuItem key={subject.value} {...subject}>
                                                {subject.label}
                                            </MenuItem>
                                        )
                                    })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-1 ">
                    <input
                        type="text"
                        value={schoolrecordSelectSubjectItem.unit}
                        onChange={(e) => onChagneCourseGyogwaValue(index, 'unit', e.target.value)}
                    ></input>
                </div>
                <div className="d-flex col-2 align-items-center ">
                    <input
                        type="text"
                        value={schoolrecordSelectSubjectItem.rawScore}
                        onChange={(e) => onChagneCourseGyogwaValue(index, 'rawScore', e.target.value)}
                    ></input>
                    /
                    <input
                        type="text"
                        value={schoolrecordSelectSubjectItem.subSubjectAverage}
                        onChange={(e) => onChagneCourseGyogwaValue(index, 'subSubjectAverage', e.target.value)}
                    ></input>
                </div>
                <div className="col-2 d-flex align-items-center  achievement ">
                    <FormControl className="formControl">
                        <Select
                            name="kor"
                            className="select"
                            value={schoolrecordSelectSubjectItem.achievement}
                            onChange={(e) => {
                                onChagneCourseGyogwaValue(index, 'achievement', e.target.value)
                            }}
                        >
                            {['A', 'B', 'C', 'D', 'E'].map((achievement) => {
                                return (
                                    <MenuItem value={achievement} key={achievement + 'normal'}>
                                        {achievement}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    (
                    <input
                        type="text"
                        className="ml-1"
                        value={schoolrecordSelectSubjectItem.studentsNum}
                        onChange={(e) => onChagneCourseGyogwaValue(index, 'studentsNum', e.target.value)}
                    ></input>
                    )
                </div>
                <div className="col-3 d-flex align-items-center achievement ">
                    <div className="d-flex align-items-center">
                        a(
                        <input
                            type="text"
                            value={schoolrecordSelectSubjectItem.achievementA}
                            onChange={(e) => onChagneCourseGyogwaValue(index, 'achievementA', e.target.value)}
                        ></input>
                        )
                    </div>
                    <div className="d-flex align-items-center">
                        b(
                        <input
                            type="text"
                            value={schoolrecordSelectSubjectItem.achievementB}
                            onChange={(e) => onChagneCourseGyogwaValue(index, 'achievementB', e.target.value)}
                        ></input>
                        )
                    </div>
                    <div className="d-flex align-items-center">
                        c(
                        <input
                            type="text"
                            value={schoolrecordSelectSubjectItem.achievementC}
                            onChange={(e) => onChagneCourseGyogwaValue(index, 'achievementC', e.target.value)}
                        ></input>
                        )
                    </div>
                </div>
            </div>
        </>
    )

}

export default CourseGyogwaLine;