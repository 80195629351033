import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();
/**
 * getMissingPetListParams page, size 값만 제외
 */
interface tokens {
  accessToken: string | null;
  refreshToken: string | null;
}

const defaultState = {
  accessToken: null,
  refreshToken: null,
};

export default atom<tokens>({
  key: "tokens",
  default: defaultState,
  effects_UNSTABLE: [persistAtom],
});
