import { earlyLifeRecordUploadFetch } from 'api/fetches/earlyd'
import { essayMockMarkListFetch } from 'api/fetches/essay'
import { mockScheduleListFetch } from 'api/fetches/menu'
import {
  addMockMarkScoreFetch,
  addMockScoreFetch,
  addMockScoreParams,
  mockCheckInputFetch,
  mockListMarksFetch,
  mockListRawFetch,
} from 'api/fetches/mock'
import { useEffect, useState } from 'react'
import { getDisplayFlagFetch } from '../../../../../api/fetches/setting'

export const useGradeInput = () => {
  const [inputType, setInputType] = useState<'score' | 'file'>('score')
  const [gradeType, setGradeType] = useState<string>('원점수')
  const [subjectCodes, setSubjectCodes] = useState([
    { subjectCode: 'S3', parentCode: 'S0' }, // 공통국어
    { subjectCode: null, parentCode: 'S3' }, // 선택국어
    { subjectCode: 'S7', parentCode: 'S0' }, // 공통수학
    { subjectCode: null, parentCode: 'S7' }, // 선택수학
    { subjectCode: 'S8', parentCode: 'S0' }, // 영어
    { subjectCode: 'S9', parentCode: 'S0' }, // 한국사
    { subjectCode: null, parentCode: 'S0' }, // 탐구
    { subjectCode: null, parentCode: 'S0' }, // 탐구
    { subjectCode: null, parentCode: 'S3' }, // 제2외국어 탐구
  ])
  const [tamGooList, setTamGooList] = useState<('science' | 'society' | null)[]>([null, null]) //사탐인지 과탐인지 선택

  const [scores, setScores] = useState<(null | string)[]>(new Array(9).fill(null))
  const [markScores, setMarkScores] = useState<(null | string)[]>(new Array(9).fill(null))
  const [mockScoreFlag, setMockScoreFlag] = useState(0)
  const [isCanSaveScore, setIsCanSaveScore] = useState(false)
  const [examInfo, setExamInfo] = useState<{
    mockexamMonth: string
    mockexamYear: string
    scheduleId: number
  } | null>(null)
  const [file, setFile] = useState<File | null>(null)

  console.log('marScores : ', markScores)

  //   console.log("examInfo : ", examInfo);
  //   console.log("subjectCodes : ", subjectCodes);
  useEffect(() => {
    getScheduleId()
    getDisplayFlag()
  }, [])

  const getScheduleId = async () => {
    const res = await mockScheduleListFetch()
    console.log('examInfo : ', res)

    if (res) {
      setExamInfo({
        mockexamMonth: res[0].mockexamMonth,
        mockexamYear: res[0].mockexamYear,
        scheduleId: res[0].id,
      })
      checkRecordedGrade(res[0].mockexamMonth)
    }
  }

  const getDisplayFlag = async () => {
    const flag = await getDisplayFlagFetch('MOCKSCORE')
    setMockScoreFlag(flag)
    setGradeType(flag === 0 ? '원점수' : '표점/등급')
  }

  const checkRecordedGrade = async (examMonth: string) => {
    const res = await mockCheckInputFetch(examMonth)
    if (res.status) {
      console.log('res : ', res)
      getSavedGrade(res.scheduleId)
      getSavedMarkScore(res.scheduleId)
    }
  }

  const getSavedMarkScore = async (scheduleId: number) => {
    const { list } = await mockListMarksFetch(scheduleId)

    if (list.length > 0) {
      console.log('getSavedMarkScore : ', list)

      const _subjectCodes = [...subjectCodes]
      const _markScores = [...markScores]
      const _tamGooList = [...tamGooList]

      list.forEach(({ marksScore, subjectCode }) => {
        switch (subjectCode) {
          case 'S1':
          case 'S2':
            _subjectCodes[1].subjectCode = subjectCode
            _markScores[1] = marksScore
            break
          case 'S3':
            _markScores[0] = marksScore
            break
          case 'S7':
            _markScores[2] = marksScore
            break
          case 'S4':
          case 'S5':
          case 'S6':
            _subjectCodes[3].subjectCode = subjectCode
            _markScores[3] = marksScore
            break
          case 'S8':
            _markScores[4] = marksScore
            break
          case 'S9':
            _markScores[5] = marksScore
            break
          case 'S10':
          case 'S11':
          case 'S12':
          case 'S13':
          case 'S14':
          case 'S15':
          case 'S16':
          case 'S17':
            if (_markScores[6] === null) {
              _tamGooList[0] = 'science'

              _markScores[6] = marksScore
              _subjectCodes[6].subjectCode = subjectCode
            } else {
              _tamGooList[1] = 'science'

              _markScores[7] = marksScore
              _subjectCodes[7].subjectCode = subjectCode
            }
            break
          case 'S18':
          case 'S19':
          case 'S20':
          case 'S21':
          case 'S22':
          case 'S23':
          case 'S24':
          case 'S25':
          case 'S26':
            if (_markScores[6] === null) {
              _tamGooList[0] = 'society'

              _markScores[6] = marksScore
              _subjectCodes[6].subjectCode = subjectCode
            } else {
              _tamGooList[1] = 'society'

              _markScores[7] = marksScore
              _subjectCodes[7].subjectCode = subjectCode
            }
            break
          case 'S27':
          case 'S28':
          case 'S29':
          case 'S30':
          case 'S31':
          case 'S32':
          case 'S33':
          case 'S34':
          case 'S35':
            _markScores[8] = marksScore
            _subjectCodes[8].subjectCode = subjectCode
            break
          default:
            break
        }
      })
      console.log('_tamGooList ㅇㅇ:', _tamGooList)
      setSubjectCodes(_subjectCodes)
      setMarkScores(_markScores)
      setTamGooList(_tamGooList)
    }
  }

  const getSavedGrade = async (scheduleId: number) => {
    const { list } = await mockListRawFetch(scheduleId)
    const _subjectCodes = [...subjectCodes]
    const _scores = [...scores]
    const _tamGooList = [...tamGooList]

    list.forEach(({ rawScore, subjectCode }) => {
      switch (subjectCode) {
        case 'S1':
        case 'S2':
          _subjectCodes[1].subjectCode = subjectCode
          _scores[1] = rawScore
          break
        case 'S3':
          _scores[0] = rawScore
          break
        case 'S7':
          _scores[2] = rawScore
          break
        case 'S4':
        case 'S5':
        case 'S6':
          _subjectCodes[3].subjectCode = subjectCode
          _scores[3] = rawScore
          break
        case 'S8':
          _scores[4] = rawScore
          break
        case 'S9':
          _scores[5] = rawScore
          break
        case 'S10':
        case 'S11':
        case 'S12':
        case 'S13':
        case 'S14':
        case 'S15':
        case 'S16':
        case 'S17':
          if (_scores[6] === null) {
            _tamGooList[0] = 'science'

            _scores[6] = rawScore
            _subjectCodes[6].subjectCode = subjectCode
          } else {
            _tamGooList[1] = 'science'

            _scores[7] = rawScore
            _subjectCodes[7].subjectCode = subjectCode
          }
          break
        case 'S18':
        case 'S19':
        case 'S20':
        case 'S21':
        case 'S22':
        case 'S23':
        case 'S24':
        case 'S25':
        case 'S26':
          if (_scores[6] === null) {
            _tamGooList[0] = 'society'

            _scores[6] = rawScore
            _subjectCodes[6].subjectCode = subjectCode
          } else {
            _tamGooList[1] = 'society'

            _scores[7] = rawScore
            _subjectCodes[7].subjectCode = subjectCode
          }
          break
        case 'S27':
        case 'S28':
        case 'S29':
        case 'S30':
        case 'S31':
        case 'S32':
        case 'S33':
        case 'S34':
        case 'S35':
          _scores[8] = rawScore
          _subjectCodes[8].subjectCode = subjectCode
          break
        default:
          break
      }
    })
    setSubjectCodes(_subjectCodes)
    setScores(_scores)
    setTamGooList(_tamGooList)
  }

  useEffect(() => {
    setIsCanSaveScore(checkIsCanSaveScore())
  }, [subjectCodes, scores, markScores])

  useEffect(() => {
    getEssayMockList()
  }, [])

  const getEssayMockList = () => {
    essayMockMarkListFetch(1)
  }

  // -----------------------   handler   -------------------------

  const onClickInputTypeBtn = (inputType: 'score' | 'file') => {
    setInputType(inputType)
  }

  const onClickExamMonth = (month: string) => {
    setGradeType(month)
  }

  const onChangeTamgooType = (value: 'science' | 'society', index: number) => {
    const _tamGooList = [...tamGooList]
    _tamGooList[index] = value
    setTamGooList(_tamGooList)
  }

  const onChangeSelectSubject = (code: string, index: number) => {
    const _subejctCodes = { ...subjectCodes }

    _subejctCodes[index] = { ...subjectCodes[index], subjectCode: code }

    setSubjectCodes(_subejctCodes)
  }

  const onChagneScore = (score: string, index: number) => {
    if (checkScoreCondition(score, index)) {
      if (gradeType === '원점수') {
        const _scores = [...scores]
        _scores[index] = score === '' ? null : score
        setScores(_scores)
      } else {
        //표준점수
        console.log(score)
        console.log(index)
        const _markScores = [...markScores]
        _markScores[index] = score === '' ? null : score
        setMarkScores(_markScores)
      }
    }
  }

  const onClickSaveScore = async () => {
    if (examInfo) {
      const params = makeSaveScoreParams()
      console.log('모의고사점수 ㄱㄱㄱㄱㄱㄱㄱㄱㄱ', params)
      if (params) {
        const res = gradeType === '원점수' ? await addMockScoreFetch(params) : await addMockMarkScoreFetch(params)
        alert(res.status ? '저장되었습니다.' : res.message)
      }
    }
  }

  const onChagneFileUpload = (File: FileList | null) => {
    if (File) {
      setFile(File[0])
    }
  }

  const onClickFileUpload = async () => {
    if (file) {
      const data = earlyLifeRecordUploadFetch({ file })
      console.log('data : ', data)
    }
  }

  // -----------------------   handler   -------------------------

  const checkIsCanSaveScore = () => {
    if (gradeType === '원점수') {
      const _scores = [...scores]
      _scores.pop()
      return _scores.includes(null) === false
    } else {
      let _markScores = [...markScores]
      if (_markScores.length === 9) {
        _markScores.splice(0, 1)
        _markScores.splice(1, 1)
        _markScores.splice(6, 1)
      }
      //_markScores.pop();
      console.log('qwfqwf', _markScores)
      return _markScores.includes(null) === false
    }
  }

  const checkScoreCondition = (score: string, index: number) => {
    if (gradeType === '원점수') {
      const _score = Number(score)

      switch (index) {
        case 0: //국어 원점수
          return _score <= 76
        case 1: //국어 세부 과목 원점수
          return _score <= 24
        case 2: // 수학 원점수
          return _score <= 74
        case 3: //수학 세부 과목 원점수
          return _score <= 26
        case 4: // 영어 원점수
          return _score <= 100
        case 5: // 한국사 원점수
          return _score <= 100
        default: // 나머지
          return _score <= 50
      }
    } else {
      const _score = Number(score)

      switch (index) {
        case 0:
          return _score <= 150
        case 1:
          return _score <= 150
        case 2:
          return _score <= 150
        case 3:
          return _score <= 150
        // case 4:
        //   return _score <= 9 && _score >= 1
        // case 5:
        //   return _score <= 9 && _score >= 1
        default:
          return _score <= 150
      }
    }
  }

  const makeSaveScoreParams = (): addMockScoreParams | null => {
    const mockScoreList: {
      parentCode: string
      score: string
      subjectCode: string
    }[] = []

    for (let i = 0; i < 9; i++) {
      const parentCode = subjectCodes[i].parentCode
      const subjectCode = subjectCodes[i].subjectCode
      const score = gradeType === '원점수' ? scores[i] : markScores[i]
      //외국어 과목이 none일경우
      if (i == 8 && subjectCode === 'none') {
        continue
      }
      if (subjectCode && score) {
        mockScoreList.push({
          parentCode,
          subjectCode,
          score,
        })
      }
    }

    if (examInfo) {
      return {
        mockScoreList,
        scheduleId: examInfo.scheduleId.toString(),
      }
    } else {
      return null
    }
  }

  return {
    inputType,
    scores,
    subjectCodes,
    isCanSaveScore,
    gradeType,
    file,
    tamGooList,
    markScores,
    examInfo,
    mockScoreFlag,
    onChangeTamgooType,
    onClickInputTypeBtn,
    onChagneScore,
    onChangeSelectSubject,
    onClickSaveScore,
    onClickExamMonth,
    onChagneFileUpload,
    onClickFileUpload,
  }
}
