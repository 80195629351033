import { Box } from '@mui/material'

const GradeClassTable = () => (
  <Box
    component="table"
    sx={{ border: '1px solid silver', mt: 2, td: { border: '1px solid #a5a5a5', px: 2, textAlign: 'center' } }}
  >
    <thead>
      <tr style={{ background: '#efefef' }}>
        <td>A+</td>
        <td>A</td>
        <td>B+</td>
        <td>B</td>
        <td>C+</td>
        <td>C</td>
        <td>D</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>의치한약수</td>
        <td>서울상위권</td>
        <td>서울중위권</td>
        <td>
          서울하위권
          <br />
          경기상위권
        </td>
        <td>
          경기중위권
          <br />
          지방상위권
        </td>
        <td>
          경기하위권
          <br />
          지방중위권
        </td>
        <td>지방하위권</td>
      </tr>
    </tbody>
  </Box>
)
export default GradeClassTable
