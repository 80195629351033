import { Box, BoxProps, Theme } from '@mui/material'
// import { SystemStyleObject } from '@mui/system'
import React, { forwardRef } from 'react'

type Props = { className?: string }

const TurtlePaper = forwardRef(
  ({ sx = {}, children, className, ...rest }: React.PropsWithChildren<Props> & BoxProps, ref) => {
    const defaultStyle = (theme: Theme) => ({
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.08)',
      [theme.breakpoints.down('md')]: {
        px: 2,
        py: 1,
      },
      [theme.breakpoints.down('sm')]: {
        px: 1,
        py: 0.5,
      },
      px: 4,
      py: 2,
    })

    if (Array.isArray(sx)) {
      sx.push(defaultStyle)
    } else if (sx !== undefined) {
      sx = [defaultStyle, sx as any]
    }

    return (
      <Box ref={ref} className={className} sx={sx} {...rest}>
        {children}
      </Box>
    )
  }
)

export default TurtlePaper
