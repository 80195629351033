import "./style.scss";

interface Props {
  nowFilter: string[];
  title: string;
  subTitle?: string;
  type: "medicalEtc" | "region";
  onClickFilterButton: (type: string) => void;
}

const filterType = {
  medicalEtc: [
    { label: "전체", code: "" },
    { label: "문과논술", code: "munGwaNonSul" },
    { label: "의학논술", code: "medicalNonSul" },
    { label: "영어", code: "eng" },
  ],
  region: [
    { label: "전국", code: "" },
    { label: "강원", code: "강원" },
    { label: "충북", code: "충북" },
    { label: "대전/충남", code: "대전/충남" },
    { label: "부산/울산/경남", code: "부산/울산/경남" },
    { label: "대구/경북", code: "대구/경북" },
    { label: "광주/전남", code: "광주/전남" },
    { label: "전북", code: "전북" },
    { label: "제주", code: "제주" },
  ],
};
/**
 * @todo
 * 논술내 대부분의 필터는 내용이 같다
 * suriNonSulAndEngFilter , suriNonsulSelectFilter 나중에 이 컴포넌트로 합치기 (데이터만 바꿔주면 됨)
 */

const NonSulFilter = ({ nowFilter, title, subTitle, type, onClickFilterButton }: Props) => {
  return (
    <>
      <div className="selectUnivConditionContainer">
        <div className="title">
          <p>{title}</p>
        </div>
        <div className="selectBox">
          <div>
            <div className="selectLine">
              <p>{subTitle}</p>
              {filterType[type].map((cond) => {
                return (
                  <button
                    onClick={() => onClickFilterButton(cond.code)}
                    className={nowFilter.length === filterType[type].length - 1 ? "select" : nowFilter.includes(cond.code) ? "select" : ""}
                    key={cond.label}
                  >
                    {cond.label}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </>
  );
};

export default NonSulFilter;
