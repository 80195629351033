import React from 'react'

type Props = {
  isLoading: boolean
  delay?: number
}

function useSmoothLoading({ isLoading, delay = 300 }: Props) {
  const [delayedIsLoading, setDelayedIsLoading] = React.useState(true)

  React.useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setDelayedIsLoading(isLoading)
      }, delay)
      return () => clearTimeout(timer)
    }
    return setDelayedIsLoading(isLoading)
  }, [isLoading])

  return delayedIsLoading
}

export default useSmoothLoading
