import { memberAxios } from 'api/instance'
import {
  couponInfoAPI,
  getStodeCodeAPI,
  newPayInsertContract,
  newPayInsertOrder,
  newPreRegiPaymentAPI,
  newUpdateCouponUsed,
  newUpsertTicketCount,
  orderDetailsInfoAPI,
  paymentDatailsAPI,
  preAfterPaymentAPI,
  preAfterPaymentZeroAPI,
  preRegiPaymentAPI,
  productInfoAPI,
  productListAPI,
  refundPaymentAPI,
  selectProductInfo,
  selectProductList,
} from 'api/urls'

interface checkisProductReturn {
  productId: string
  productNm: string
  productPrice: number
  productPaymentType: string
  productImage: string
  promotionDiscountPrice: number
  promotionUse: boolean
  explainComment: string
  refundPolicy: string
  term: Date
}
interface commonResponse {
  couponInfo: checkisCouponReturn
  status: boolean
  message: string
}
interface checkisCouponReturn {
  discountInfo: string
  discountValue: number
  couponDiscountPrice: number
}

interface checkisPayPaymentValid {
  merchantUid: string
  productPrice: number
  status: boolean
  message: string
}

interface commonStatusResponse {
  status: boolean
  message: string
}

interface payDetailReturn {
  data: payDetailResponse[]
  status: boolean
  message: string
}

interface payDetailResponse {
  orderId: number
  productName: string
  paidAmount: string
  paidDate: string
  productImage: string
}

interface payDetailContentResponse {
  productName: string
  paidAmount: number
  paidDate: string
  productImage: string
  productPrice: number
  cardName: string
  cardNumber: string
  vbankCode: string
  vbankName: string
  productPaymentType: string
  term: Date
}

export const getProductListFetch = async (params: any) => {
  const res = await memberAxios.post(selectProductList, {
    memberId: params.memberId,
  })
  return res.data.data
}

export const getProductInfoFetch = async (productId: string | undefined): Promise<any> => {
  const res = await memberAxios.post(selectProductInfo, {
    serviceId: productId,
  })
  return res.data
}

export const getCouponInfoFetch = async (
  couponNumber: string | undefined,
  payServiceId: string | undefined
): Promise<commonResponse> => {
  const res = await memberAxios.post(couponInfoAPI, { couponNumber: couponNumber, payServiceId: payServiceId })

  console.log(res.data)

  return res.data
}

export const addPreRegiPaymentFetch = async (
  couponNumber: string | undefined,
  payServiceId: string | undefined
): Promise<checkisPayPaymentValid> => {
  const res = await memberAxios.post(preRegiPaymentAPI, { couponNumber: couponNumber, payServiceId: payServiceId })

  return res.data
}

export const addNewPreRegiPaymentFetch = async (params: any): Promise<any> => {
  const res = await memberAxios.post(newPreRegiPaymentAPI, {
    serviceId: params.serviceId,
    memberId: params.memberId,
    productPrice: params.productPrice,
  })

  return res.data
}

export const validatePaymentFetch = async (
  impUid: string | undefined,
  payServiceId: string | undefined,
  couponNumber: string | undefined
): Promise<commonStatusResponse> => {
  const res = await memberAxios.post(preAfterPaymentAPI, {
    impUid: impUid,
    payServiceId: payServiceId,
    couponNumber: couponNumber,
  })

  return res.data
}

export const validatePaymentZeroFetch = async (
  impUid: string | undefined,
  payServiceId: string | undefined,
  couponNumber: string | undefined
): Promise<commonStatusResponse> => {
  const res = await memberAxios.post(preAfterPaymentZeroAPI, {
    impUid: impUid,
    payServiceId: payServiceId,
    couponNumber: couponNumber,
  })

  return res.data
}

export const insertOrder = async (params: any): Promise<any> => {
  const res = await memberAxios.post(newPayInsertOrder, {
    cancelAmount: params.cancelAmount,
    impUid: params.impUid,
    memberId: params.memberId,
    merchantUid: params.merchantUid,
    orderState: params.orderState,
    paidAmount: params.paidAmount,
    payServiceId: params.payServiceId,
    cardName: params.cardName,
    cardNumber: params.cardNumber,
  })

  return res.data
}

export const insertContract = async (params: any): Promise<any> => {
  const res = await memberAxios.post(newPayInsertContract, {
    contractPeriodEndDt: params.contractPeriodEndDt,
    contractStartDt: params.contractStartDt,
    contractUse: params.contractUse,
    productCode: params.productCode,
    regularContractFl: params.regularContractFl,
    memberId: params.memberId,
    orderId: params.orderId,
  })

  return res.data
}

export const updateCouponUsed = async (params: any): Promise<any> => {
  const res = await memberAxios.post(newUpdateCouponUsed, {
    couponNumber: params.couponNumber,
  })

  return res.data
}

export const getPaymentDeatailListFetch = async (): Promise<payDetailReturn> => {
  const res = await memberAxios.get(paymentDatailsAPI, {
    params: {},
  })

  console.log(res.data)

  return res.data
}

export const refundPaymentFetch = async (
  payOrderId: number | undefined,
  cancelReason: string | undefined
): Promise<commonStatusResponse> => {
  const res = await memberAxios.post(refundPaymentAPI, {
    payOrderId: payOrderId,
    cancelReason: cancelReason,
  })

  return res.data
}

export const detailsContentFetch = async (orderId: string | undefined): Promise<payDetailContentResponse> => {
  const res = await memberAxios.get(orderDetailsInfoAPI + orderId, {
    params: {},
  })

  return res.data
}

export const getStoreCode = async (): Promise<string> => {
  const res = await memberAxios.get(getStodeCodeAPI, {})

  return res.data
}

export const upsertTicketCount = async (params: any): Promise<any> => {
  const res = await memberAxios.post(newUpsertTicketCount, { memberId: params.memberId })
  return res.data
}
