import React from 'react'
import { RxCircle } from 'react-icons/rx'

type Props = { size?: number }

const IconO = ({ size = 16 }: Props) => {
  return <RxCircle fontSize={size} color="#1C4BC3" strokeWidth={1} />
}

export default IconO
