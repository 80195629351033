import React, { PropsWithChildren } from 'react'
import { Modal } from '@mui/material'
import { Box, Stack } from '@mui/system'

type Props = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  param: any | null
  Contents: React.FunctionComponent<{ param: any }>
}

const DetailModal = ({ open, setOpen, param, Contents }: Props) => {
  if (!open || param === null) {
    return null
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Stack
        sx={(theme) => ({
          width: '70vw',
          height: '90vh',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          [theme.breakpoints.down('md')]: {
            width: '90vw',
            height: '85vh',
          },
        })}
      >
        <Contents param={param} />
      </Stack>
    </Modal>
  )
}

DetailModal.__isStatic = false

export default DetailModal
