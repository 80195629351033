import { Stack, StackProps } from '@mui/system'
import React from 'react'

type Props = {}

const StackY = (props: StackProps) => {
  return (
    <Stack direction={'column'} justifyContent={'center'} {...props}>
      {props.children}
    </Stack>
  )
}

export default StackY
