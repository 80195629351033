import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { createColumnHelper } from '@tanstack/react-table'
import { TitledItem, 수시전형_상세보기_테이블스타일 } from 'DetailModal/common'
import TurtleTable2 from 'components/common/TurtleTable2'
import { useContext } from 'react'
import { riskScoreToRiskLabel } from 'utils/util'
import K_상세보기_타대학_동일계열차트공통 from './K_상세보기_타대학_동일계열'
import { StudentDetailModalContext } from './K_상세보기_학종모달_내용'
import MessageCard from 'components/layout/EmptyPageLayout'
import RiskLabel from 'components/common/RiskLabel'

type Props = {}

const K_상세보기_합격예측탭 = (props: Props) => {
  return (
    <>
      <TitledItem title="종합진단">
        <종합진단테이블 />
      </TitledItem>
      <TitledItem title="교과">
        <교과테이블 />
      </TitledItem>
      <TitledItem title="비교과">
        <Stack gap={'12px'}>
          <비교과테이블1 />
          <비교과테이블2 />
        </Stack>
      </TitledItem>
      <TitledItem title="계열적합성">
        <계열적합성 />
      </TitledItem>
      <TitledItem title="타대학 동일계열">
        <K_상세보기_타대학_동일계열차트공통 />
      </TitledItem>
    </>
  )
}

const 종합진단테이블 = () => {
  const { predictsData } = useContext(StudentDetailModalContext)

  if (!predictsData) return null

  const { studentRecordComparativeDepartment, totalFitScoreSum } = predictsData

  // 종합진단
  const 교과위험도 = Number(studentRecordComparativeDepartment.riskGyoGwaScore)
  const 비교과위험도 = Number(studentRecordComparativeDepartment.riskDeGyoGwaScore)
  const 계열적합성위험도 = Number(totalFitScoreSum)

  const 위험도 = (교과위험도 + 비교과위험도 + 계열적합성위험도) / 3

  const columnHelper = createColumnHelper<any>()

  const renderRiskLabel = (info: any) => <RiskLabel riskScore={info.getValue()} />
  const columns = [
    columnHelper.accessor('교과위험도', {
      header: '교과 위험도',
      cell: renderRiskLabel,
    }),
    columnHelper.accessor('비교과위험도', {
      header: '비교과 위험도',
      cell: renderRiskLabel,
    }),
    columnHelper.accessor('계열적합성위험도', {
      header: '계열적합성 위험도',
      cell: renderRiskLabel,
    }),
    columnHelper.accessor('위험도', {
      header: '위험도',
      cell: renderRiskLabel,
    }),
  ]

  const data = [
    {
      교과위험도,
      비교과위험도,
      계열적합성위험도,
      위험도,
    },
  ]

  return <TurtleTable2 columns={columns} data={data} tableStyle={수시전형_상세보기_테이블스타일} />
}
const 교과테이블 = () => {
  const { predictsData } = useContext(StudentDetailModalContext)

  if (!predictsData) return null
  console.log(predictsData)

  const { studentRecordComparativeDepartment } = predictsData

  const cut50 = Number(studentRecordComparativeDepartment.cut50)
  const cut70 = Number(studentRecordComparativeDepartment.cut70)
  const myScore = Number(studentRecordComparativeDepartment.myGyoGwaScore)
  const scoreGap = cut50 - myScore
  const delta = (cut70 - cut50) / 3

  let riskScore = 0
  if (myScore <= cut50) {
    riskScore = 4
  } else if (cut70 < myScore) {
    riskScore = 0
  } else if (myScore <= cut50 + delta) {
    riskScore = 3
  } else if (myScore <= cut50 + delta * 2) {
    riskScore = 2
  } else if (myScore <= cut50 + delta * 1) {
    riskScore = 1
  } else {
    riskScore = -999
    console.log('invalid risk score')
  }

  const risk = riskScoreToRiskLabel(riskScore)

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('cut50', {
      header: '50%컷',
    }),
    columnHelper.accessor('firstCut', {
      header: '최초컷',
    }),
    columnHelper.accessor('myScore', {
      header: '내 점수',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('scoreGap', {
      header: '차이',
      cell: (info) => {
        const riskVal = info.getValue()
        const color = riskVal > 0 ? '#1C4BC3' : '#FF5600'
        return (
          <Typography variant="D1_bold, 18, 26" sx={{ color: color }}>
            {riskVal > 0 ? `+${riskVal}` : riskVal}
          </Typography>
        )
      },
    }),
    columnHelper.accessor('risk', {
      header: '위험도',
      cell: (info) => info.renderValue(),
    }),
  ]

  const data = [{ cut50, firstCut: cut70, myScore, scoreGap, risk }]

  return <TurtleTable2 columns={columns} data={data} tableStyle={수시전형_상세보기_테이블스타일} />
}

const 비교과테이블1 = () => {
  const { predictsData } = useContext(StudentDetailModalContext)

  if (!predictsData) return null

  const { studentRecordComparativeDepartment } = predictsData

  const columnHelper = createColumnHelper<any[]>()

  const evaluations = studentRecordComparativeDepartment.evaluation
  const columns = [
    columnHelper.display({
      id: '평가방법',
      header: '평가방법',
      cell: () => evaluations.length + '개평가 (정성평가)',
    }),
    ...evaluations.map((x, i) =>
      columnHelper.accessor((x) => x[i].itemName, {
        header: `평가요소 ${i + 1} / 반영비율`,
      })
    ),
  ]

  const data = [evaluations]

  return (
    <TurtleTable2
      sx={{
        '& td:first-of-type': {
          bgcolor: '#E9E9E9',
        },
      }}
      columns={columns}
      data={data}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const 비교과테이블2 = () => {
  const { predictsData } = useContext(StudentDetailModalContext)

  if (!predictsData) return null

  const { studentRecordComparativeDepartment } = predictsData
  console.log(predictsData)

  const columnHelper = createColumnHelper<any[]>()

  const evaluations = studentRecordComparativeDepartment.evaluation
  const totalScore = studentRecordComparativeDepartment.totalScore
  const columns = [
    ...evaluations.map((x, i) =>
      columnHelper.accessor((x) => x[i].score, {
        header: x.itemName.split(' ')[0].trim(),
      })
    ),
    columnHelper.display({
      id: '합계',
      header: '합계',
      cell: () => totalScore,
    }),
    columnHelper.display({
      id: '위험도',
      header: '위험도',
      cell: () => riskScoreToRiskLabel(studentRecordComparativeDepartment.riskDeGyoGwaScore),
    }),
  ]

  const data = [evaluations]

  return <TurtleTable2 columns={columns} data={data} tableStyle={수시전형_상세보기_테이블스타일} />
}

const 계열적합성 = () => {
  const { predictsData } = useContext(StudentDetailModalContext)

  if (!predictsData) return null

  const { studentRecordComparativeDepartment, compulsorySubjectList } = predictsData

  if (compulsorySubjectList.length === 0) return <MessageCard title="데이터가 존재하지 않습니다."></MessageCard>

  const columnHelper = createColumnHelper<any[]>()

  const 과목 = compulsorySubjectList.map((x) => x.subjectName)
  과목.unshift('')

  const 조건포맷터 = (val: string) => {
    const color = {
      0: '#000000',
      1: '#1C4BC3',
      2: '#FF5600',
    }[val]
    return <Typography sx={{ color }}>{{ 0: '무관', 1: '장려', 2: '필수' }[val]}</Typography>
  }
  const 조건: any[] = compulsorySubjectList.map((x) => 조건포맷터(x.essentialFlag))
  조건.unshift('조건')

  const 이수현황포맷터 = (val: string) => {
    return <Typography>{{ 0: '미이수', 1: '이수', 2: '무관' }[val]}</Typography>
  }
  const 이수현황: any[] = compulsorySubjectList.map((x) => 이수현황포맷터(x.isCheck))
  이수현황.unshift('이수현황')

  const 권장등급: any[] = compulsorySubjectList.map((x) => x.essentialGrade)
  권장등급.unshift('관장등급')

  const 내등급: any[] = compulsorySubjectList.map((x) => x.myGrade)
  내등급.unshift('내 등급')

  const 위험도: any[] = compulsorySubjectList.map((x) => riskScoreToRiskLabel(x.riskScore))
  위험도.unshift('위험도')

  const columns = [
    ...과목.map((subject, i) =>
      columnHelper.accessor((x) => x[i], {
        id: String(i),
        header: subject,
        cell: ({ row, renderValue, column, getValue }) => {
          return renderValue()
        },
      })
    ),
  ]

  const data = [조건, 이수현황, 권장등급, 내등급, 위험도]
  return (
    <TurtleTable2
      columns={columns}
      data={data}
      tableStyle={수시전형_상세보기_테이블스타일}
      sx={{
        '& tr:first-of-type > th': {
          backgroundColor: '#E9E9E9',
          whiteSpace: 'nowrap',
        },
        '& td:first-of-type': {
          backgroundColor: '#E9E9E9',
          whiteSpace: 'nowrap',
        },
        '& td,th': {
          px: 1,
          py: 1,
        },
      }}
    />
  )
}

export default K_상세보기_합격예측탭
