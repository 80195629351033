import TurtlePaper from 'components/common/TurtlePaper'
import TurtleTabs, { TabInfo } from 'components/common/TurtleTabs'
import { Box, Stack } from '@mui/system'
import { Typography } from '@mui/material'

type Props = {
  college: string
  department: string
  tabContentList: {
    tabName: string
    tabContent: React.ReactElement
  }[]
}

type HeaderProps = {
  college: string
  department: string
}

const Header = ({ college: collegeName, department }: HeaderProps) => {
  return (
    <Stack direction={'row'} alignItems={'end'} gap={1} marginBottom={3}>
      <Typography variant="H1_Exbold, 36, 54">{collegeName}</Typography>
      <Typography variant="H6_Bold, 22, 30">{department}</Typography>
    </Stack>
  )
}

const DetailModalLayout = ({ college, department, tabContentList }: Props) => {
  return (
    <Box sx={{ bgcolor: '#f7f7f7', height: '100%', overflow: 'auto' }} p={4}>
      <Header college={college} department={department} />
      <TurtleTabs
        tabInfos={tabContentList.map((x) => ({
          ...x,
          tabName: <Typography variant="H6_Bold, 22, 30">{x.tabName}</Typography>,
        }))}
      />
    </Box>
  )
}

export default DetailModalLayout
