import { Box, Button, IconButton, Modal, Stack, TextField } from '@mui/material'
import { createColumn, createColumnHelper } from '@tanstack/react-table'
import { fetchInterestUniversityInfo, postAddCombination } from 'api/api'
import AddButton from 'components/common/AddButton'
import DeleteButton from 'components/common/DeleteButton'
import DetailButton from 'components/common/DetailButton'
import TurtlePaper from 'components/common/TurtlePaper'
import TurtleTable from 'components/common/TurtleTable'
import { generateDefaultColumnInfo } from 'components/common/TurtleTable2'
import TurtleTabs from 'components/common/TurtleTabs'
import BaseTable from 'components/pages/suSi/common/BaseTable'
import { mobileMediaQuery } from 'constants/style'
import _ from 'lodash'
import { useState } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { useQuery } from 'react-query'
import { formatDateYYYYMMDD } from 'utils/util'
import { 관심대학_테이블스타일 } from '../InterestUniversityPage/common'

type Props = {
  open: boolean
  setOpen: (isOpen: boolean) => void
}

const CombinationGenerateModal = ({ open, setOpen }: Props) => {
  const [pickedSub, setPickedSub] = useState<number[]>([])
  const [pickedStu, setPickedStu] = useState<number[]>([])
  const [pickedEssay, setPickedEssay] = useState<number[]>([])

  const [createCombinationName, setCreateCombinationName] = useState(`조합_${Math.round(Math.random() * 1000)}`)
  const { data: interestsData } = useQuery('관심대학_리스트', fetchInterestUniversityInfo)

  const resetState = () => {
    setPickedEssay([])
    setPickedStu([])
    setPickedSub([])
    setCreateCombinationName(`조합_${Math.round(Math.random() * 1000)}`)
  }

  const handleClose = () => setOpen(false)

  if (!interestsData) return <></>

  const interests = interestsData.data as {
    earlydSubjectInterestList: any[]
    earlydStudentInterestList: any[]
    essayInterestList: any[]
  }

  const {
    earlydSubjectInterestList: subjects,
    earlydStudentInterestList: students,
    essayInterestList: essays,
  } = interests

  const pickedSubRows = subjects
    .filter(({ id }: { id: number }) => pickedSub.includes(id))
    .map((x: any) => [
      x.collegeName,
      '논술',
      x.recruitmentUnit,
      <DeleteButton onClick={() => setPickedSub(pickedSub.filter((id) => id !== x.id))} />,
    ])

  const pickedStuRows = students
    .filter(({ id }: { id: number }) => pickedStu.includes(id))
    .map((x: any) => [
      x.collegeName,
      '학종',
      x.recruitmentUnit,
      <DeleteButton onClick={() => setPickedStu(pickedStu.filter((id) => id !== x.id))} />,
    ])

  const pickedEssayRows = essays
    .filter(({ id }: { id: number }) => pickedEssay.includes(id))
    .map((x: any) => [
      x.collegeName,
      '논술',
      x.recruitmentUnit,
      <DeleteButton onClick={() => setPickedEssay(pickedEssay.filter((id) => id !== x.id))} />,
    ])

  const selectionRowList = [...pickedSubRows, ...pickedStuRows, ...pickedEssayRows]

  const makeRowCells = (type: 'subject' | 'student' | 'essay') => (item: any) => {
    const buffer = type === 'essay' ? pickedEssay : type === 'subject' ? pickedSub : pickedStu
    const setBuffer = type === 'essay' ? setPickedEssay : type === 'subject' ? setPickedSub : setPickedStu

    const onAddClick = () => {
      if (numPicked >= 6) {
        return
      }
      setBuffer(_.uniq(buffer.concat(item.id)))
    }

    return {
      대학명: item.collegeName,
      자격: item.typeName,
      모집단위: item.recruitmentUnit,
      전형일: formatDateYYYYMMDD(item.interviewDate) ?? formatDateYYYYMMDD(item.admissionDate),
      상세내역: <DetailButton />,
      선택: buffer.includes(item.id) ? (
        <div></div>
      ) : (
        <Stack justifyContent={'center'} alignItems={'center'}>
          <AddButton onClick={onAddClick} />
        </Stack>
      ),
    }
  }

  const subjectTableRowList = (interests.earlydSubjectInterestList as any[]).map(makeRowCells('subject'))
  const studentTableRowList = (interests.earlydStudentInterestList as any[]).map(makeRowCells('student'))
  const essayTableRowList = (interests.essayInterestList as any[]).map(makeRowCells('essay'))

  // TODO : 유형마다 테이블 헤더 달라짐
  const attachTableHeader = (rowList: any) => {
    return (
      <Box sx={{ paddingTop: 2, maxWidth: '800px' }}>
        <BaseTable
          enablePagination
          pageSize={200}
          tableStyle={관심대학_테이블스타일}
          columns={generateDefaultColumnInfo(['대학명', '자격', '모집단위', '전형일', '상세내역', '선택'])}
          data={rowList}
        />
      </Box>
    )
  }

  const subjectTab = attachTableHeader(subjectTableRowList)
  const studentTab = attachTableHeader(studentTableRowList)
  const essayTab = attachTableHeader(essayTableRowList)

  const numPicked = pickedEssay.length + pickedStu.length + pickedSub.length

  const modalContents = (
    <Stack
      sx={{
        width: '90vw',
        maxHeight: '90vh',
        maxWidth: '1000px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '1px solid #888',
        boxShadow: 24,
        pt: 1,
        pb: 2,
        px: 2,
      }}
    >
      <Stack direction={'row'} justifyContent={'end'}>
        <IconButton onClick={handleClose} color="primary">
          <RxCross2 fontSize={22} />
        </IconButton>
      </Stack>
      <Stack
        // flexWrap={'wrap'}
        direction={'row'}
        sx={{
          gap: 3,
          [mobileMediaQuery]: {
            flexDirection: 'column',
            height: '80vh',
            overflowY: 'scroll',
          },
        }}
      >
        <TurtlePaper
          sx={{
            padding: 2,
            height: 'fit-content',
            width: '400px',
          }}
        >
          <TextField
            placeholder="조합 이름"
            variant="standard"
            sx={{ paddingTop: 2, paddingBottom: 1, width: '120px' }}
            value={createCombinationName}
            onChange={(e) => {
              setCreateCombinationName(e.target.value)
            }}
          />
          <TurtleTable
            sx={{
              'td:nth-child(3)': {
                whiteSpace: 'normal',
              },
            }}
            headerLabels={['대학명', '전형', '모집단위', '삭제']}
            rowNodeLists={selectionRowList}
            stuffMinimumRows={true}
            numMinimumRows={6}
          />
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              disabled={numPicked !== 6}
              variant="contained"
              sx={{ height: 32, marginTop: '16px' }}
              onClick={() => {
                postAddCombination({
                  groupName: createCombinationName,
                  stuInterestCollegeIds: pickedStu,
                  essInterestCollegeIds: pickedEssay,
                  subInterestCollegeIds: pickedSub,
                }).then((res) => {
                  if (res.status === 200) {
                    setOpen(false)
                    resetState()
                  } else {
                    alert('오류 발생')
                    return
                  }
                })
              }}
            >
              조합생성 <span style={{}}>&nbsp;↓</span>
            </Button>
          </Box>
        </TurtlePaper>
        <TurtlePaper
          sx={{
            padding: 2,
            flex: 1,
          }}
        >
          <TurtleTabs
            sx={{ '& .tab-contents-wrapper': { height: '400px', overflowY: 'scroll' } }}
            tabInfos={[
              {
                tabName: '교과',
                tabContent: subjectTab,
              },

              {
                tabName: '학종',
                tabContent: studentTab,
              },
              {
                tabName: '논술',
                tabContent: essayTab,
              },
            ]}
          />
        </TurtlePaper>
      </Stack>
    </Stack>
  )

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack>{modalContents}</Stack>
    </Modal>
  )
}

export default CombinationGenerateModal
