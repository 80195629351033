import {
  earlyThreeGradeHtmlFileUploadFetch,
  earlyThreeGradePdfFileUploadFetch,
  earlydAddThreerdFetch,
  earlydStudentSchoolRecordFileUploadCheckFetch,
  earlydStudentSchoolrecordListFetch,
  schoolrecordAttendanceDetailItem,
  schoolrecordSelectSubjectItem,
  schoolrecordSubjectLearningItem,
  earlyThreeGradeGraduatePdfFileUploadFetch,
} from 'api/fetches/earlyd'
import { ChangeEvent, useEffect, useState } from 'react'
import axios from 'axios'
import { menuCodeSubjectListFetch } from 'api/fetches/menu'

//일반 교과목 리스트
const normalGyogwaList = [
  { title: '국어', code: 'HH1' },
  { title: '수학', code: 'HH2' },
  { title: '영어', code: 'HH3' },
  { title: '기술가정/제2외국어/한문/교양', code: 'HH6' },
]

const makeInitialNormalGyogwaItem = (): schoolrecordSubjectLearningItem => {
  return {
    grade: '3',
    semester: '1',
    mainSubjectCode: '_',
    mainSubjectName: '',
    subjectCode: '_',
    subjectName: '',
    unit: '',
    rawScore: '',
    subSubjectAverage: '',
    standardDeviation: '',
    achievement: '',
    studentsNum: '',
    ranking: '',
    etc: '',
  }
}

const makeInitialCourseGyogwaItem = (): schoolrecordSelectSubjectItem => {
  return {
    grade: '3',
    semester: '1',
    mainSubjectCode: '_',
    mainSubjectName: '',
    subjectCode: '_',
    subjectName: '',
    unit: '',
    rawScore: '',
    subSubjectAverage: '',
    standardDeviation: '',
    achievement: '',
    achievementA: '',
    achievementB: '',
    achievementC: '',
    studentsNum: '',
    etc: '',
  }
}

const makeInitalAttendanceItem = () => {
  return {
    grade: '3',
    classDays: '',
    absentDisease: '',
    absentUnrecognized: '',
    absentEtc: '',
    lateDisease: '',
    lateUnrecognized: '',
    lateEtc: '',
    leaveEarlyDisease: '',
    leaveEarlyUnrecognized: '',
    leaveEarlyEtc: '',
    resultDisease: '',
    resultUnrecognized: '',
    resultEarlyEtc: '',
    etc: '',
  }
}

export const useHakJongInput = () => {
  const [schoolrecordSubjectLearningList, setSchoolrecordSubjectLearningList] = useState<
    schoolrecordSubjectLearningItem[]
  >([makeInitialNormalGyogwaItem()])

  const [openFlag, setOpen] = useState<boolean>(false)

  const [schoolrecordSelectSubjectList, setSchoolrecordSelectSubjectList] = useState<schoolrecordSelectSubjectItem[]>([
    makeInitialCourseGyogwaItem(),
    makeInitialCourseGyogwaItem(),
    makeInitialCourseGyogwaItem(),
    makeInitialCourseGyogwaItem(),
  ])
  const [schoolrecordAttendanceDetailList, setSchoolrecordAttendanceDetailList] = useState<
    schoolrecordAttendanceDetailItem[]
  >([makeInitalAttendanceItem()])
  const [isCanHtmlUpload, setIsCanHtmlUpload] = useState(false)
  const [isCanPdfUpload, setIsCanPdfUpload] = useState(false)
  const [isPdfUpload, setIsPdfUpload] = useState(false)
  /**
   * 초기 교과 코드 가져오기
   */

  useEffect(() => {
    getSavedGrade()
  }, [])

  const getSavedGrade = async () => {
    console.log('저장된 성작 가져오기')
    const res = await earlydStudentSchoolrecordListFetch({
      selectTypeList: ['SH', 'SSL', 'SSS', 'SAD', 'SCE'],
      selectGrade: '3',
      selectSemester: '',
    })
    console.log('re 저아s : ', res)
    if (res.status) {
      console.log('기록된 성적 드록하기')
      console.log('schoolrecordAttendanceDetailList : ', res.schoolrecordAttendanceDetailList)
      console.log('schoolrecordSelectSubjectList : ', res.schoolrecordSelectSubjectList)

      console.log('schoolrecordSubjectLearningList : ', res.schoolrecordSubjectLearningList)
      console.log('------------------------')
      console.log('res')
      console.log(res)
      console.log('------------------------')

      res.schoolrecordAttendanceDetailList.length > 0 &&
        setSchoolrecordAttendanceDetailList(res.schoolrecordAttendanceDetailList)
      res.schoolrecordSelectSubjectList.length > 0 &&
        setSchoolrecordSelectSubjectList(res.schoolrecordSelectSubjectList)
      res.schoolrecordSubjectLearningList.length > 0 &&
        setSchoolrecordSubjectLearningList(res.schoolrecordSubjectLearningList)
    }
  }

  const onChangeHTMlFile = (e: ChangeEvent<HTMLInputElement>, type: 'html' | 'pdf', isGraudate: boolean) => {
    if (e.target.files !== null) {
      const file = e.target.files[0]
      setOpen(true)
      uploadFile(type, file, isGraudate).then((i) => {
        setOpen(false)
        location.reload()
      })
    }
  }

  const uploadFile = async (type: 'html' | 'pdf', file: File, isGraduate: boolean) => {
    if (type === 'html' && !isGraduate) {
      const res = await earlyThreeGradeHtmlFileUploadFetch(file)
      if (res.status) {
        setIsCanHtmlUpload(false)
        alert('생활기록부(html) 업로드에 성공하였습니다.')
      } else {
        alert('html파일 업로드에 실패했습니다.' + res.message)
      }
    } else if (type === 'pdf' && isGraduate) {
      const res = await earlyThreeGradeGraduatePdfFileUploadFetch(file)
      if (res.status) {
        setIsPdfUpload(false)
        alert('생기부 졸업생 (pdf) 업로드에 성공하였습니다.')
      } else {
        alert('졸업생 pdf파일 업로드에 실패했습니다. 잠시후 다시 시도해주세요.')
      }
    } else {
      const res = await earlyThreeGradePdfFileUploadFetch(file)
      console.log(res)
      if (res.status) {
        setIsPdfUpload(true)
        alert('생활기록부(pdf) 업로드에 성공하였습니다.')
      } else {
        alert('재학생 pdf파일 업로드에 실패했습니다. 잠시후 다시 시도해주세요.')
      }
    }
  }

  useEffect(() => {
    checkCanUploadHtml()
    checkCanUploadGraduatePdf()
  }, [])

  const checkCanUploadHtml = async () => {
    const { status } = await earlydStudentSchoolRecordFileUploadCheckFetch('THREEGRADEHTML')

    setIsCanHtmlUpload(status)
  }

  const checkCanUploadGraduatePdf = async () => {
    const { status } = await earlydStudentSchoolRecordFileUploadCheckFetch('GRADUATEPDF')

    setIsCanPdfUpload(status)
  }

  const onChagneCourseGyogwaValue = (index: number, type: string, value: string) => {
    const _schoolrecordSelectSubjectList = [...schoolrecordSelectSubjectList]

    switch (type) {
      case 'mainSubjectCode':
      case 'mainSubjectName':
      case 'subjectCode':
      case 'subjectName':
      case 'unit':
      case 'rawScore':
      case 'subSubjectAverage':
      case 'standardDeviation':
      case 'achievement':
      case 'achievementA':
      case 'achievementB':
      case 'achievementC':
      case 'studentsNum':
      case 'semester':
        _schoolrecordSelectSubjectList[index][type] = value
        break

      default:
        break
    }
    setSchoolrecordSelectSubjectList(_schoolrecordSelectSubjectList)
  }

  const onChangeNormalGyogwaValue = (index: number, type: string, value: string) => {
    const _schoolrecordSubjectLearningList = [...schoolrecordSubjectLearningList]

    switch (type) {
      case 'semester':
      case 'mainSubjectCode':
      case 'mainSubjectName':
      case 'subjectCode':
      case 'subjectName':
      case 'unit':
      case 'rawScore':
      case 'subSubjectAverage':
      case 'standardDeviation':
      case 'achievement':
      case 'studentsNum':
      case 'ranking':
        _schoolrecordSubjectLearningList[index][type] = value
        break
      default:
        break
    }
    setSchoolrecordSubjectLearningList(_schoolrecordSubjectLearningList)
  }

  const onChagneAttendanceValue = (index: number, type: string, value: string) => {
    const _schoolrecordAttendanceDetailList = [...schoolrecordAttendanceDetailList]

    switch (type) {
      case 'classDays':
      case 'absentDisease':
      case 'absentUnrecognized':
      case 'absentEtc':
      case 'lateDisease':
      case 'lateUnrecognized':
      case 'lateEtc':
      case 'leaveEarlyDisease':
      case 'leaveEarlyUnrecognized':
      case 'leaveEarlyEtc':
      case 'resultDisease':
      case 'resultUnrecognized':
      case 'resultEarlyEtc':
        _schoolrecordAttendanceDetailList[index][type] = value
        break
      default:
        break
    }
    setSchoolrecordAttendanceDetailList(_schoolrecordAttendanceDetailList)
  }

  const onClickAddOrDelLine = (isAdd: boolean) => {
    const _schoolrecordSelectSubjectList = [...schoolrecordSelectSubjectList]

    if (isAdd) {
      _schoolrecordSelectSubjectList.push(makeInitialCourseGyogwaItem())
    } else {
      _schoolrecordSelectSubjectList.length > 4 && _schoolrecordSelectSubjectList.pop()
    }

    setSchoolrecordSelectSubjectList(_schoolrecordSelectSubjectList)
  }

  const onClickNormalAddOrDelLine = (isAdd: boolean) => {
    const _schoolrecordSubjectLearningList = [...schoolrecordSubjectLearningList]

    if (isAdd) {
      _schoolrecordSubjectLearningList.push(makeInitialNormalGyogwaItem())
    } else {
      _schoolrecordSubjectLearningList.length > 4 && _schoolrecordSubjectLearningList.pop()
    }

    setSchoolrecordSubjectLearningList(_schoolrecordSubjectLearningList)
    console.log('schoolrecordSubjectLearningList', schoolrecordSubjectLearningList)
  }

  const onClickSaveGrade = async () => {
    let isValidate = true
    schoolrecordSubjectLearningList.forEach((item) => {
      if (item.mainSubjectCode === '' || item.subjectCode === '' || item.unit === '' || item.ranking === '') {
        isValidate = false
      }
    })
    schoolrecordSelectSubjectList.forEach((item) => {
      if (item.mainSubjectCode === '' || item.subjectCode === '' || item.unit === '' || item.achievement === '') {
        isValidate = false
      }
    })
    console.log(isValidate)
    // if (!isValidate) {
    //   alert('데이터를 모두 넣어주세요')
    //   return
    // }

    const { status, message, code } = await earlydAddThreerdFetch({
      schoolrecordAttendanceDetailList,
      schoolrecordSelectSubjectList,
      schoolrecordSubjectLearningList,
    })

    alert(status ? message : '성적을 저장하는데 실패했습니다. 잠시후 다시 시도해주세요.')
  }

  return {
    schoolrecordSubjectLearningList,
    schoolrecordSelectSubjectList,
    schoolrecordAttendanceDetailList,
    isCanHtmlUpload,
    isCanPdfUpload,
    isPdfUpload,
    onChagneAttendanceValue,
    onChangeHTMlFile,
    onChagneCourseGyogwaValue,
    onChangeNormalGyogwaValue,
    onClickAddOrDelLine,
    onClickSaveGrade,
    onClickNormalAddOrDelLine,
    openFlag,
  }
}
