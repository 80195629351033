import { createColumnHelper } from '@tanstack/react-table'
import { TextBox, TitledItem, 수시전형_상세보기_테이블스타일 } from 'DetailModal/common'
import TurtleTable2 from 'components/common/TurtleTable2'
import { useContext } from 'react'
import { StudentDetailModalContext, 학종상세_성적반영방법 } from './K_상세보기_학종모달_내용'

type Props = {}

const 학년별비율테이블 = () => {
  const { reflectData } = useContext(StudentDetailModalContext)

  if (!reflectData) return null

  const { evaluateInfo, firstClassRate, twoClassRate, thirdClassRate } = reflectData

  const columnHelper = createColumnHelper<학종상세_성적반영방법>()

  const decoratePercentage = (info: any) => (info.getValue() ? `${info.getValue()}%` : '-')
  const columns = [
    columnHelper.accessor('firstClassRate', {
      header: '평가요소 1 / 반영비율',
      cell: decoratePercentage,
    }),
    columnHelper.accessor('twoClassRate', {
      header: '평가요소 2 / 반영비율',
      cell: decoratePercentage,
    }),
    columnHelper.accessor('thirdClassRate', {
      header: '평가요소 3 / 반영비율',
      cell: decoratePercentage,
    }),
    columnHelper.accessor('evaluateInfo', {
      header: '평가방법',
      cell: (info) => `정성평가`,
    }),
  ]

  const data = [{ evaluateInfo, firstClassRate, twoClassRate, thirdClassRate }]

  return <TurtleTable2 columns={columns} data={data} tableStyle={수시전형_상세보기_테이블스타일} />
}

const 비교과테이블 = () => {
  let { reflectData } = useContext(StudentDetailModalContext)

  if (!reflectData) return null

  const { evaluateInfo, firstClassRate, twoClassRate, thirdClassRate } = reflectData

  const columnHelper = createColumnHelper<학종상세_성적반영방법>()

  const columns = [
    columnHelper.accessor('evaluateInfo', {
      header: '평가방법',
      cell: (info) => `${info.getValue()?.length ?? 0}개평가`,
    }),
    columnHelper.accessor('evaluateInfo', {
      id: '평가요소1',
      header: '평가요소 1 / 반영비율',
      cell: (info: any) => info.getValue()?.[0] ?? '-',
    }),
    columnHelper.accessor('evaluateInfo', {
      id: '평가요소2',
      header: '평가요소 2 / 반영비율',
      cell: (info: any) => info.getValue()?.[1] ?? '-',
    }),
    columnHelper.accessor('evaluateInfo', {
      id: '평가요소3',
      header: '평가요소 3 / 반영비율',
      cell: (info: any) => info.getValue()?.[2] ?? '-',
    }),
  ]

  const data = [{ evaluateInfo, firstClassRate, twoClassRate, thirdClassRate }]

  return <TurtleTable2 columns={columns} data={data} tableStyle={수시전형_상세보기_테이블스타일} />
}

const K_상세보기_성적반영방법탭 = (props: Props) => {
  const {} = useContext(StudentDetailModalContext)
  return (
    <>
      <TitledItem title="학년별 비율">
        <학년별비율테이블 />
      </TitledItem>
      <TitledItem title="비교과">
        <비교과테이블 />
      </TitledItem>
    </>
  )
}

export default K_상세보기_성적반영방법탭
