import { createColumnHelper } from '@tanstack/react-table'
import {
  delteInterestUniversity,
  fetchInterestUniversityStudent,
  관심대학_학종_열타입,
  delteAllInterestUniversity,
} from 'api/api'
import DeleteButton from 'components/common/DeleteButton'
import DetailButton from 'components/common/DetailButton'
import RiskIndicator from 'components/common/RiskIndicator'
import TurtleTable2 from 'components/common/TurtleTable2'
import { useQuery } from 'react-query'
import { emptyTablePlaceholder, 관심대학_테이블스타일 } from './common'
import { useContext, useEffect, useState } from 'react'
import { 관심대학페이지_컨텍스트 } from './InterestUniversityPage'
import DetailModal from 'DetailModal/DetailModal'
import K_상세보기_학종모달_내용 from 'DetailModal/Student/K_상세보기_학종모달_내용'
import { getClampedRiskScore } from 'utils/util'
import Center from 'components/common/Center'
import BaseTable, { baseTableStyle2 } from 'components/pages/suSi/common/BaseTable'
import { Button, Typography } from '@mui/material'
import { mainColor } from 'constants/style'

type Props = {}

const K_관심대학_학종탭 = (props: Props) => {
  const { data, refetch } = useQuery('관심대학-학종', fetchInterestUniversityStudent, {
    refetchOnMount: true,
  })
  const { setNumTotalRowMap } = useContext(관심대학페이지_컨텍스트)
  const [modalOpen, setModalOpen] = useState(false)
  const [detailParam, setDetailParam] = useState<{
    recruitmentUnitId: number
    regionRange: string
    rowSeries: string
    college: string
    major: string
  } | null>(null)

  useEffect(() => {
    if (data) {
      setNumTotalRowMap((prev) => ({ ...prev, 학종: data.earlydStudentInterestList.length }))
    }
  }, [data])

  let rows: 관심대학_학종_열타입[] = []

  if (!data) {
    rows = []
  } else {
    rows = data.earlydStudentInterestList
  }

  const columnHelper = createColumnHelper<관심대학_학종_열타입>()

  const cell = (info: any) => (
    <Center>
      <RiskIndicator riskScore={getClampedRiskScore(info.getValue())} />
    </Center>
  )
  const columns = [
    columnHelper.accessor('collegeName', {
      header: '대학명',
    }),
    columnHelper.accessor('typeName', {
      header: '전형명',
    }),
    columnHelper.accessor('recruitmentUnit', {
      header: '모집단위',
    }),
    columnHelper.accessor('lineRisk', {
      header: '계열적합성',
      cell,
    }),
    columnHelper.accessor('subjectRisk', {
      header: '교과',
      cell,
    }),
    columnHelper.accessor('desubjectRisk', {
      header: '비교과',
      cell,
    }),
    columnHelper.accessor('diagnosisRisk', {
      header: '종합진단',
      cell,
    }),
    columnHelper.accessor('interviewDate', {
      header: '전형일',
    }),
    columnHelper.display({
      id: '상세내역',
      header: '상세내역',
      cell: ({ row }) => (
        <DetailButton
          onClick={() => {
            const { recruitmentUnitId, rowSeries, regionRange, collegeName, recruitmentUnit } = rows[row.index]
            setDetailParam({ recruitmentUnitId, rowSeries, regionRange, college: collegeName, major: recruitmentUnit })
            setModalOpen(true)
          }}
        />
      ),

      maxSize: 110,
    }),
    columnHelper.display({
      id: '삭제',
      header: '',
      cell: ({ row }) => (
        <DeleteButton
          onClick={() => {
            delteInterestUniversity('STU', [rows[row.index].id]).then(() => {
              refetch()
            })
          }}
        />
      ),
    }),
  ]

  if (rows.length === 0) {
    return emptyTablePlaceholder
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Button
          style={{
            border: '1px solid ' + mainColor,
            color: mainColor,
            paddingLeft: 10,
            paddingRight: 10,
            width: '228px',
            height: '48px',
            marginTop: '20px',
          }}
          onClick={() => {
            delteAllInterestUniversity('STU').then(() => {
              refetch()
            })
          }}
        >
          <Typography variant="D3_bold, 16, 24">전체삭제</Typography>
          <DeleteButton onClick={() => null} />
        </Button>
      </div>
      <BaseTable
        sx={{ mt: 2 }}
        enablePagination
        pageSize={20}
        tableStyle={관심대학_테이블스타일}
        columns={columns}
        data={rows}
        columnVisibility={{
          recruitmentUnitIds: false,
        }}
      />
      {/* <TurtleTable2
        sx={{ mt: 2, fontSize: '14px', fontWeight: 400, lineHeight: '24px' }}
        columns={columns}
        data={rows}
        tableStyle={관심대학_테이블스타일}
      /> */}

      <DetailModal param={detailParam} open={modalOpen} setOpen={setModalOpen} Contents={K_상세보기_학종모달_내용} />
    </>
  )
}

export default K_관심대학_학종탭
