import { Typography, useMediaQuery } from '@mui/material'
import { Box, Stack, useTheme } from '@mui/system'
import {
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { SxProps, Theme } from '@mui/system'
import React, { PropsWithChildren } from 'react'
import TurtleTowLineAxisTick from 'components/common/TurtleTowLineAxisTick'
import TurtlePaper from 'components/common/TurtlePaper'
import { reverseGradeValue } from 'utils/util'

export const TitledItem = ({ title, children, sx }: React.PropsWithChildren<{ title: string; sx?: SxProps }>) => {
  return (
    <Stack direction={'column'} mt={3} sx={sx}>
      <Typography variant="H4_Exbold, 24, 32" mb={1}>
        {title}
      </Typography>
      {children}
    </Stack>
  )
}

export const TextBox = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Typography
      variant="D4_regular, 16, 24"
      sx={{
        color: '#656565',
      }}
    >
      {children}
    </Typography>
  )
}

export const 수시전형_상세보기_테이블스타일 = (theme: Theme) =>
  ({
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.08)',
    td: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '26px',
      backgroundColor: '#fff',
      fontFamily: 'nanumsquare_bold',
    },
    th: {
      border: '1px solid black',
      bgcolor: '#C2C2C2',
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: 'nanumsquare_regular',
    },
    '& td,th': {
      border: '1px solid black',
      textAlign: 'center',
      verticalAlign: 'center',
      alignItems: 'center',
    },
    table: {
      borderStyle: 'hidden',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      '& th,td': {
        px: '24px',
        py: '12px',
      },
    },
  }) as const

export const K_상세보기_타대학_동일계열차트공통 = ({
  data,
  grade,
}: {
  grade: number
  data: {
    grade: number
    collegeName: string
    major: string
  }[]
}) => {
  const theme = useTheme()
  const isSmallDeviceWidth = useMediaQuery(theme.breakpoints.down('lg'))
  const windowSize = isSmallDeviceWidth ? 5 : 5

  console.log(grade)

  data.forEach((x) => {
    x.grade = reverseGradeValue(x.grade)
  })

  return (
    <TurtlePaper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Stack direction={'column'}>
        <Stack direction={'row'} justifyContent={'space-between'} mb={1}>
          <Typography variant="D5_Bold, 14, 18">최초컷(등급) 기준</Typography>
          <Typography variant="D6 regular, 12, 18">*대학별 점수로 환산 시 차이가 있을 수 있습니다.</Typography>
        </Stack>
        <Box sx={{ height: 364 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={data}>
              <XAxis
                tickLine={false}
                height={40}
                dataKey="collegeName"
                type="category"
                tick={<TurtleTowLineAxisTick />}
              />
              <YAxis
                tickLine={false}
                type="number"
                domain={[0, 8]}
                tickFormatter={(val) => String(reverseGradeValue(val))}
              />
              <CartesianGrid vertical={false} color="#D9D9D9" z={1} />
              <Line dataKey="grade" stroke="#FF5600" name="최초컷" />
              <Tooltip formatter={(val) => String(reverseGradeValue(val as number).toFixed(2))} />
              {data.length > windowSize && (
                <Brush startIndex={0} endIndex={windowSize - 1} stroke={'#000'} fontSize={0} height={18} />
              )}
              <ReferenceLine y={reverseGradeValue(grade)} stroke="blue" strokeWidth={3} />
              <Legend
                payload={[
                  { value: '최초컷', type: 'line', id: 'ID01', color: '#FF5600' },
                  { value: '내 점수', type: 'line', id: 'ID01', color: '#3360FF' },
                ]}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    </TurtlePaper>
  )
}
