import { Box, Typography } from '@mui/material'
import { createColumnHelper } from '@tanstack/table-core'
import { memberAxios } from 'api/instance'
import FilterBox, { createRadioButtonInfos } from 'components/common/FilterBox'
import RiskIndicator from 'components/common/RiskIndicator'
import StackX from 'components/common/StackX'
import TurtlePaper from 'components/common/TurtlePaper'
import MessageCard from 'components/layout/EmptyPageLayout'
import SubtitleSection from 'components/layout/SubtitleSection'
import ChartSkeleton from 'components/skeleton/ChartSkeleton'
import useSmoothLoading from 'hooks/useSmoothLoading'
import { useContext, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getRiskText, getSelected } from 'utils/util'
import BaseTable from '../common/BaseTable'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import Step7Chart from './Step7Chart'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import ConditionMessage from '../common/ConditionMessage'
import InfoBadge from 'components/InfoBadge'
import { fontWeight } from '@mui/system'

export type Step7학종_DataItem = {
  collegeName: string
  cut50: string
  cut70: string
  evaluation: {
    itemName: string
    score: number
  }[]
  myGyoGwaScore: string
  recruitmentUnit: string
  recruitmentUnitId: number
  riskDeGyoGwaScore: number
  riskGyoGwaScore: number
  totalScore: number
  typeName: string
}

type Props = {}

function Step7({}: Props) {
  const [filterState, setFilterState] = useState<string>('전체')

  const { stepState, setBundle } = useContext(StepPagesContext)

  const body = { list: stepState[6].bundle.selected }

  let { data, isPreviousData, isFetching, isPlaceholderData, isFetched } = useQuery(
    ['학종-7-대학별유불리-', body],
    () =>
      memberAxios.post('/pearlyd/sr/depart/info', body).then((res) => {
        let aa = res.data.list
        let sorted = aa.sort(function (a: any, b: any) {
          return b.totalScore - a.totalScore
        })

        console.log(sorted)

        return res.data as {
          existScore: boolean
          list: Step7학종_DataItem[]
        }
      })
  )

  const isLoading = useSmoothLoading({ isLoading: isFetching })

  const [rowSelection, setRowSelection] = useBundledRowSelection(data?.list ?? [], (dataList, id) => {
    return dataList.find((x) => String(x.recruitmentUnitId) === String(id))
  })

  const buttonInfos = createRadioButtonInfos(
    ['전체', '안정', '적정', '소신', '도전', '위험'],
    filterState,
    setFilterState
  )

  const evaluationCell = (nth: number) => (info: any) => {
    const item = info.getValue()[nth]
    if (!item) {
      return '-'
    }
    return (
      <>
        <Typography>{`${item.itemName}`}</Typography>
        <Typography>{`${item.score}점`}</Typography>
      </>
    )
  }

  const columnHelper = createColumnHelper<Step7학종_DataItem>()
  //모집단위 평가학목 A BCD 총점(7.0) 위험도
  const columns = [
    columnHelper.accessor('collegeName', {
      header: '대학명',
      size: 100,
    }),
    columnHelper.accessor('recruitmentUnit', {
      header: '모집단위',
    }),
    columnHelper.accessor('typeName', {
      header: '전형명',
    }),
    columnHelper.accessor('evaluation', {
      id: '평가항목A',
      header: '평가항목A',
      cell: evaluationCell(0),
    }),
    columnHelper.accessor('evaluation', {
      id: '평가항목B',
      header: '평가항목B',
      cell: evaluationCell(1),
    }),
    columnHelper.accessor('evaluation', {
      id: '평가항목C',
      header: '평가항목C',
      cell: evaluationCell(2),
    }),
    columnHelper.accessor('evaluation', {
      id: '평가항목D',
      header: '평가항목D',
      cell: evaluationCell(3),
    }),
    columnHelper.accessor('totalScore', {
      header: '총점 (7.0)',
      size: 30,
    }),
    columnHelper.accessor('riskDeGyoGwaScore', {
      id: '위험도',
      header: '위험도',
      cell: (info) => <RiskIndicator riskScore={Number(info.getValue())} />,
      size: 80,
    }),
  ]

  const filtered = useMemo(
    () =>
      data?.list.filter((x) => {
        if (filterState === '전체') return true
        return filterState === getRiskText(x.riskDeGyoGwaScore)
      }) ?? [],
    [data, filterState]
  )

  const chartData = preprocessChartData(filtered)

  const title = (
    <Typography variant="H4_Exbold, 24, 32" mb={3} fontWeight={700}>
      나의 일반적인 비교과 등급
      {/* <Typography variant="H3_Bold, 28, 36" ml={1.5} sx={{ color: '#FF5600' }}>
        A(6.26)
      </Typography> */}
    </Typography>
  )

  const isAnyRowSelected = getSelected(rowSelection).length > 0

  return (
    <SubtitleSection titleSection={title} mt={'50px'}>
      <FilterBox title="위험도" buttonInfos={buttonInfos} />
      <TurtlePaper sx={{ px: '13px', py: '19px', mt: 4 }}>
        <StackX justifyContent={'space-between'} mb={4}>
          <Typography variant="H6_Bold, 22, 30">비교과 기준 유리한 대학</Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <InfoBadge
              tooltipContent={
                <Box width={'315px'}>
                  <Typography variant="H4_Exbold, 24, 32">평가항목 A? B?</Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 700, lineHeight: '18px', mt: 2, color: '#656565' }}>
                    평가항목 A, B, C, D는 각 대학별 평가요소에요. 대학별로 생활기록부를 평가할 때, 활용하는 평가요소가
                    달라요. 어떤 대학은 3개평가, 다른 대학은 4개평가를 활용해요. 거북스쿨은 독자적인 노하우로 대학별로
                    생활기록부 점수를 채점해요.
                  </Typography>
                </Box>
              }
              mr={1}
            />
            <Typography variant="D5_Regular, 14, 20" sx={{ color: '#9A9A9A' }}>
              *총점을 1000점 만점 기준으로 환산하여 산출한 차트입니다.
            </Typography>
          </Box>
        </StackX>
        {isLoading ? (
          <ChartSkeleton height={'450px'} />
        ) : chartData.length === 0 ? (
          <MessageCard title={'데이터가 존재하지 않습니다.'} />
        ) : (
          <Step7Chart data={chartData} />
        )}
      </TurtlePaper>
      <TurtlePaper mt={4} sx={{ px: '30px', py: '30px' }}>
        <BaseTable
          isLoading={isLoading}
          getRowId={(row) => {
            return String(row.recruitmentUnitId)
          }}
          columns={columns}
          data={filtered}
          enablePagination
          enableSelection
          setRowSelection={setRowSelection}
          rowSelection={rowSelection}
          searchInfo={{ label: '대학명 검색', value: 'collegeName', mode: true }}
        />
      </TurtlePaper>
      <ConditionMessage show={!isAnyRowSelected} text="체크 박스를 선택해주십시오." />
      <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />
    </SubtitleSection>
  )
}

export type 학종_대학별유불리_ChartDataItem = {
  collegeName: string
  typeName: string
  score: number
}

function preprocessChartData(data: Step7학종_DataItem[]): 학종_대학별유불리_ChartDataItem[] {
  return data
    .map((item) => {
      const evaluations = item.evaluation
      let score = 0

      for (const evaluation of evaluations) {
        const percentageStr = evaluation.itemName.split(' ')[1]?.replaceAll('%', '')

        if (!percentageStr) return null

        const ratio = Number(percentageStr) / 100
        score += evaluation.score * ratio
      }

      return {
        collegeName: item.collegeName,
        typeName: item.typeName,
        score: score,
      }
    })
    .filter((x: any) => x !== null) as 학종_대학별유불리_ChartDataItem[]
}
export default Step7
