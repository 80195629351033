import { ReactComponent as Risk1 } from 'assets/images/riskIndicator/risk1.svg'
import { ReactComponent as Risk2 } from 'assets/images/riskIndicator/risk2.svg'
import { ReactComponent as Risk3 } from 'assets/images/riskIndicator/risk3.svg'
import { ReactComponent as Risk4 } from 'assets/images/riskIndicator/risk4.svg'
import { ReactComponent as Risk5 } from 'assets/images/riskIndicator/risk5.svg'

import { getClampedRiskScore, getRiskColor, riskLabelMap } from 'utils/util'
import Center from './Center'
import { Typography, TypographyProps } from '@mui/material'

const RiskLabel = ({ riskScore, ...rest }: { riskScore: number | string } & TypographyProps) => {
  const labelMap: Record<number, string> = riskLabelMap
  let value = getClampedRiskScore(riskScore)

  return (
    <Typography variant="D1_regular, 18, 26" color={getRiskColor(riskScore)} {...rest}>
      {labelMap[value]}
    </Typography>
  )
}

export default RiskLabel
