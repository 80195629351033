import {
  authReissueAPI,
  emailLoginAPI,
  logoutAPI,
  newEmailPwAPI,
  signUpAPI,
  socialLoginAPI,
  socialSignUpAPI,
} from 'api/urls'
import { clientAxios, memberAxios } from 'api/instance'

interface socailLoginParams {
  oauthId: string
}

interface socialLoginReturn {
  accessToken: {
    accessToken: string
    refreshToken: string
  }
  message: string | null
  tokenType: 'Bearer'
  status: boolean
}

export const socialLoginFetch = async ({ oauthId }: socailLoginParams): Promise<socialLoginReturn> => {
  const res = await clientAxios.post(socialLoginAPI, {
    oauthId,
  })

  console.log('res [ ', res, ' ] ')

  return res.data
}

export interface emailLoginParams {
  email: string | null
  password: string | null
}

export const emailLoginFetch = async ({ email, password }: emailLoginParams) => {
  const res = await clientAxios.post(emailLoginAPI, {
    email,
    password,
  })

  console.log('emailLoginFetch : res [ ', res, ' ] ')

  return res.data
}

export interface emailSignUpParams {
  email: string
  password: string
  phone: string
  username: string
  ckSmsAgree?: boolean
  isMajor?: string
  graduateYear?: string
  hstTypeId?: number
}

export const emailSignUpFetch = async ({
  email,
  password,
  username,
  phone,
  ckSmsAgree,
  isMajor,
  hstTypeId,
  graduateYear,
}: emailSignUpParams) => {
  const res = await clientAxios.post(signUpAPI, {
    email,
    password,
    username,
    phone,
    ckSmsAgree,
    isMajor,
    hstTypeId,
    graduateYear,
  })
  console.log('')
  console.log('res [ ', res, ' ] ')

  return res.data
}

export interface socialSignUpParams {
  username?: string
  accessToken?: string
  idToken?: string
  email: string
  oauthId: string
  phone: string
  providerName: string
  isMajor?: string
  hstTypeId?: number
  graduateYear?: string
}

export interface signUpReturn {
  accessToken: {
    accessToken: string
    refreshToken: string
  }
  message: string | null
  status: boolean
  tokenType: null | string
}

export const socialSignUpFetch = async ({
  username,
  accessToken,
  idToken,
  email,
  oauthId,
  phone,
  providerName,
  isMajor,
  hstTypeId,
  graduateYear,
}: socialSignUpParams): Promise<signUpReturn> => {
  const res = await clientAxios.post(socialSignUpAPI, {
    username,
    accessToken,
    idToken,
    email,
    oauthId,
    phone,
    providerName,
    isMajor,
    hstTypeId,
    graduateYear,
  })

  return res.data
}

interface tokenRefetchReturn {
  accessToken: {
    accessToken: string
    refreshToken: null
  }
  message: null
  status: boolean
  tokenType: null
}

export const tokenReissueFetch = async (refreshToken: string): Promise<tokenRefetchReturn> => {
  const res = await clientAxios.get(authReissueAPI, {
    params: {
      refreshToken,
    },
  })

  console.log('tokenReissueFetch : ', res)

  return res.data
}

export const logoutFetch = async () => {
  const res = await memberAxios.post(logoutAPI)

  return res.data
}

export interface newEmailPwParams {
  email: string
  password: string
}

export const newEmailPwFetch = async ({ email, password }: newEmailPwParams) => {
  const res = await clientAxios.post(newEmailPwAPI, {
    email,
    password,
  })

  return res.data
}
