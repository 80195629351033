import { useContext } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
} from 'recharts'
import { SubjectDetailModalContext } from './K_상세보기_교과모달_내용'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import TurtlePaper from 'components/common/TurtlePaper'

type Props = {}

const K_상세보기_동일대학타학과_차트 = () => {
  const isSmallDeviceWidth = useMediaQuery({ maxWidth: 800 })
  const { earlydSubjectSameCollegeOtherRecruitmentUnitList: data } = useContext(SubjectDetailModalContext)

  const windowSize = isSmallDeviceWidth ? 4 : 4
  let indexedData = data.map((item, index) => ({ ...item, index, myScore: Number(item.myScore.toFixed(2)) }))
  console.log(data)

  return (
    <Box height={'100%'}>
      <TurtlePaper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="D5_Bold, 14, 18" paddingBottom={1}>
          전형총점 기준
        </Typography>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={indexedData}>
            <XAxis tickLine={false} height={40} dataKey="recruitmentUnit" interval={0} />
            <CartesianGrid vertical={false} color="#D9D9D9" z={1} />
            <Bar dataKey="myScore" fill="#FF5600" barSize={32} name={'점수'} />
            <Tooltip />
            {indexedData.length > windowSize && (
              <Brush startIndex={0} endIndex={windowSize - 1} stroke={'#000'} fontSize={0} height={18} />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </TurtlePaper>
    </Box>
  )
}

export default K_상세보기_동일대학타학과_차트
