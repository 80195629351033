import tokensStates from 'atoms/tokensStates'
import axios from 'axios'
import { config } from 'config'
import { getRecoil, setRecoil } from 'recoil-nexus'
import { authReissueAPI, logoutAPI } from './urls'
import { tokenReissueFetch } from './fetches/auth'

let isPayWarning = false

export const clientAxios = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? config.devBaseUrl : config.devBaseUrl,
  withCredentials: true,
})

clientAxios.interceptors.request.use(
  function (config) {
    const { refreshToken } = getRecoil(tokensStates)
    if (config.url?.includes(authReissueAPI)) {
      config.headers.refreshToken = 'Bearer ' + refreshToken
    }

    return config
  },
  function (error) {
    //console.log('error heck')
    console.error(error)
    //console.log(error.response)
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error)
  }
)

clientAxios.interceptors.response.use(
  function (res: any) {
    //console.log('clientAxios : ', res)

    return res
  },
  function (error) {
    console.log(error)
    if (error.config && error.response && error.response.status === 401 && error.response.data.detailCode === 'C999') {
      localStorage.clear()
      location.href =
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:3000/user/signIn' ?? ''
          : config.frontUrl + 'user/signIn' ?? ''
    }
  }
)

export const memberAxios = axios.create({
  baseURL: config.devBaseUrl,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': process.env.NODE_ENV === 'production' ? config.frontUrl : 'http://localhost:3000',
    'Access-Control-Allow-Credentials': 'true',
  },
  validateStatus: (status) => {
    // if (status === 403 && isPayWarning === false) {
    //   isPayWarning = true
    //   setTimeout(() => {
    //     isPayWarning = false
    //   }, 500)
    //   alert('결제가 필요합니다.')
    //   location.href = '/store/product/single'
    // }

    return status >= 200 && status < 300 // default
  },
})

const turnOnLog = true

memberAxios.interceptors.request.use(
  function (config) {
    const isLog = (window as any)['log'] ?? turnOnLog

    const { accessToken, refreshToken } = getRecoil(tokensStates)

    const isTokenExist = accessToken !== null

    if (isLog) {
      //console.log(config.url, config.params, config.data)
    }

    if (isTokenExist) {
      config.headers.Authorization = 'Bearer ' + accessToken
      if (config.url?.includes(logoutAPI)) {
        config.headers.refreshToken = 'Bearer ' + refreshToken
      }
      return config
    } else {
      return Promise.reject(new Error('Access Token is null'))
    }
  },
  function (error) {
    //console.log('error heck')
    console.error(error)
    //console.log(error.response)
    return Promise.reject(error)
  }
)

const onErrorMemebrResponse = (error: any) => {
  /**
   * 토큰 만료
   */

  const isLog = (window as any)['log'] ?? turnOnLog
  if (isLog) {
    console.log('onErrorMemebrResponse', error)
  }
  if (error.config && error.response && error.response.status === 401 && error.response.data.detailCode === 'C5050') {
    alert(error.response.data.message)
    localStorage.clear()
    location.href =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/user/signIn' ?? ''
        : config.frontUrl + 'user/signIn' ?? ''
  }

  if (error.config && error.response && error.response.status === 401 && error.response.data.detailCode === 'C401') {
    const tokens = getRecoil(tokensStates)
    return tokenReissueFetch(tokens.refreshToken ?? '')
      .then((token) => {
        if (token.status) {
          setRecoil(tokensStates, { ...tokens, accessToken: token.accessToken.accessToken })
          return memberAxios.request(error.config)
        } else {
          localStorage.clear()
          location.href =
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:3000/user/signIn' ?? ''
              : config.frontUrl + 'user/signIn' ?? ''
        }
      })
      .catch((error) => {
        console.log('errorrrrr', error)
      })
  } else if (
    error.config &&
    error.response &&
    error.response.status === 401 &&
    error.response.data.detailCode === 'C999'
  ) {
    localStorage.clear()
    location.href =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/user/signIn' ?? ''
        : config.frontUrl + 'user/signIn' ?? ''
  }

  /**
   * 결제 체크
   */
  //   if (error.config && error.response && error.response.status === 403 && isPayWarning === false) {
  //     if (error.response.status === 403 && ) {
  //       isPayWarning = true;
  //       setTimeout(() => {
  //         isPayWarning = false;
  //       }, 500);
  //       alert("결제가 필요합니다.");
  //       location.href = "/store/product/single";
  //     }
  //   }
  return Promise.reject(error)
}

memberAxios.interceptors.response.use(function (res: any) {
  const isLog = (window as any)['log'] ?? turnOnLog

  if (isLog) {
    //console.log('response :', res.data)
  }
  return res
}, onErrorMemebrResponse)

// memberAxios.interceptors.request.use(
//   function (res: any) {
//     return res
//   },
//   function (error) {
//     console.error(error)
//   }
// )
