import use학종_이용가능_체크 from 'hooks/use학종_이용가능_체크'
import StepPages from './common/StepPages'
import Compatibility from './compatibility'

type Props = {}

function index({}: Props) {
  const { isAuthorizationLoading } = use학종_이용가능_체크()

  if (isAuthorizationLoading) return <></>

  //   const pages = [
  //     {
  //       label: '진로역량',
  //       page: <Compatibility />,
  //     },
  //   ]

  return <Compatibility />
}

export default index
