import { clientAxios, memberAxios } from 'api/instance'
import {
  gradeListAPI,
  highSchoolsAPI,
  mockScheduleListAPI,
  settingInfoAPI,
  studentTypeAPI,
  subjectMenuAPI
} from 'api/urls'

export interface grade {
  bottomCodeType: string
  bottomName: string
  id: number
  topCodeType: string
  topName: '학년타입'
}
;[]

export const gradeListFetch = async (): Promise<grade[]> => {
  const res = await memberAxios.get(gradeListAPI)
  console.log('res [ ', res, ' ] ')

  return res.data
}

export interface studentType {
  bottomCodeType: string
  bottomName: string
  id: number
  topCodeType: string
  topName: '학생타입'
}

export const studentTypesFetch = async (): Promise<studentType[]> => {
  const res = await memberAxios.get(studentTypeAPI)

  return res.data
}

export interface highschool {
  id: number
  highschoolName: string
  highschoolType: string
  highschoolRegion: string
}

export const highSchoolsFetch = async (): Promise<highschool[]> => {
  const res = await clientAxios.get(highSchoolsAPI, {
    params: {
      page: 0,
      pageSize: 3000,
      searchWord: '',
    },
  })

  return res.data
}

type subjectMenuReturn = {
  id: string
  collegeSubject: '국어' | '수학' | '영어' | '한국사' | '사회탐구' | '과학탐구' | '제2외국어'
  collegeSubSubject: string
  collegeCode: string
}[]


export const getDisplayFlagFetch = async (settingCode:string): Promise<number> => {
  const res = await clientAxios.get(settingInfoAPI,{params:{settingCode}})


  return res.data
}
