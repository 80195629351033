import StackX from 'components/common/StackX'
import './style.scss'
import { Stack } from '@mui/material'
import { Setter } from 'types/utilType'
import _ from 'lodash'

export type K_문과_교차지원_필터_타입 = {
  '수1+수2': boolean
  언어논술포함이과논술: boolean
}

type Props<T extends Record<string, boolean>> = {
  bigTitle: string
  filterState: T
  setFilterState: Setter<T>
  filterInfos: {
    buttonsType?: 'NoneOrRadioSelect'
    title: string
    items: {
      label: string
    }[]
  }[]
}

const K_논술필터 = <T extends Record<string, boolean>>({
  bigTitle,
  filterState,
  setFilterState,
  filterInfos,
}: Props<T>) => {
  return (
    <>
      <div className="selectUnivConditionContainer">
        <div className="title">
          <p>{bigTitle}</p>
        </div>
        <Stack className="selectBox" sx={{ flexDirection: 'column !important' }}>
          {filterInfos.map((info) => {
            return (
              <div>
                <div className="selectLine">
                  <p>{info.title}</p>
                  {info.items.map((item, index) => (
                    <button
                      className={filterState[item.label] ? 'select' : ''}
                      onClick={() => {
                        setFilterState((prev: any) => {
                          if (info.buttonsType === 'NoneOrRadioSelect') {
                            const ret = { ...prev }
                            for (const label of info.items.map((x) => x.label)) {
                              ret[label] = false
                            }
                            ret[item.label] = !prev[item.label]
                            return ret
                          } else {
                            return { ...prev, [item.label]: !prev[item.label] }
                          }
                        })
                      }}
                      key={index}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
              </div>
            )
          })}
        </Stack>
      </div>
      <div className="divider"></div>
    </>
  )
}

export default K_논술필터
