import { Skeleton } from '@mui/material'

const TableSkeleton = () => {
  return (
    <>
      {new Array(9).fill('.').map((_, index) => {
        console.log(index)
        return (
          <Skeleton
            style={{ marginBottom: 5 }}
            key={'sketelon ' + index}
            className="skeleton"
            sx={{ bgcolor: 'gray.900' }}
            variant="rectangular"
            width="100%"
            height={40}
          />
        )
      })}
    </>
  )
}

export default TableSkeleton
