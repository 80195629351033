import {
  Box,
  ButtonBase,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { memberAxios } from 'api/instance'
import userInfoStates from 'atoms/userInfoStates'
import RiskIndicator from 'components/common/RiskIndicator'
import StackX from 'components/common/StackX'
import TurtlePaper from 'components/common/TurtlePaper'
import _ from 'lodash'
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { Setter } from 'types/utilType'
import { getRiskColor, getSelected } from 'utils/util'
import BaseTable, { baseTableStyle2, getDefaultTurtleTableColumnModel } from '../../common/BaseTable'
import { StepPagesContext } from '../../common/StepPagesContext'
import InfoBadge from 'components/InfoBadge'
import { createColumnHelper } from '@tanstack/table-core'
import TitleSection from 'components/layout/TitleSection'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { mainColor } from 'constants/style'
import { CloseOutlined, DateRange } from '@mui/icons-material'
import { AiOutlineSearch } from 'react-icons/ai'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
export const options = {
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

type Props = {}

function Step1({}: Props) {
  const userInfo = useRecoilValue(userInfoStates)
  const is문과 = userInfo.major === 'LiberalArts'

  const { setBundle } = useContext(StepPagesContext)
  const analysisResultContentRef = useRef<HTMLDivElement>()

  const handleScrollToElement = () => {
    if (analysisResultContentRef.current) {
      analysisResultContentRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const [isRiskMeasureButtonClicked, setIsRiskMeasureButtonClicked] = React.useState<boolean>(false)

  useEffect(() => {
    if (isRiskMeasureButtonClicked) {
      setTimeout(() => {
        setShowAnalysisResult(true)
        setIsRiskMeasureButtonClicked(false)
      }, 1000)
    }
  }, [isRiskMeasureButtonClicked])

  const [showAnalysisResult, setShowAnalysisResult] = React.useState<boolean>(false)
  useEffect(() => {
    if (showAnalysisResult) {
      handleScrollToElement()
    }
  }, [showAnalysisResult])

  const [selectedScoreClass, setSelectedScoreClass] = useState<string>('')

  const [aClass, setAClass] = React.useState('')
  const [bClass, setBClass] = React.useState('')
  const [cClass, setCClass] = React.useState('')
  const [regionRangeDisable, setRegionRangeDisable] = useState<any>()
  const [dataCnt, setDatacnt] = useState<any>()
  // 개발 용으로 state 설정해놓음 위에 주석 풀어야함
  // const [aClass, setAClass] = React.useState('공학계열')
  // const [bClass, setBClass] = React.useState('전기. 전자. 컴퓨터')
  // const [cClass, setCClass] = React.useState('응용소프트웨어공학')

  const [autoCompSeriesList, setAutoCompSeriesList] = React.useState([{ seriesNm: '검색어를 입력해주세요.' }])
  const [keyword, setKeyword] = React.useState('')
  const [univLevelList, setUnivLevelList] = React.useState([])
  const [univLevel, setUnivLevel] = React.useState(0)
  const [majorCd, setMajorCd] = React.useState('')
  const [grandSeries, setGrandSeries] = React.useState('')
  const [middleSeries, setMiddleSeries] = React.useState('')
  const [rowSeries, setRowSeries] = React.useState('')
  const [requiredList, setRequiredList] = React.useState<any>([])
  const [encoList, setEncoList] = React.useState<any>([])
  const [importantList, setImportantList] = React.useState<any>([])
  const [referenceList, setReferenceList] = React.useState<any>([])
  const [recommandGrade, setRecommandGrade] = React.useState(0)
  const [viewFlag, setViewFlag] = React.useState(false)
  const [analysisResultList, setAnalysisResultList] = React.useState<any>([])
  const [requiredResultList, setRequiredResultList] = React.useState<any>([])
  const [chartViewFlag, setChartViewFlag] = React.useState(false)
  const [chartData, setCahrtData] = React.useState<any>({})

  const fnAutoCompleteSeries = (e: any) => {
    let keyword = e.target.value
    setKeyword(keyword)
    memberAxios
      .post('/compatibility/searchSeries', {
        keyword: keyword,
      })
      .then((res) => {
        if (res.data.data != null) {
          setAutoCompSeriesList(res.data.data)
        } else {
          setAutoCompSeriesList([{ seriesNm: '검색어를 입력해주세요.' }])
        }
      })
  }

  //셀렉트 박스 초기화
  const fnInitSeriesSelect = () => {
    setAClass('')
    setBClass('')
    setCClass('')
    setKeyword('')
    setGrandSeries('')
    setMiddleSeries('')
    setRowSeries('')
    setUnivLevel(0)
    setViewFlag(false)
  }

  const fnSelectAutoSeries = (event: any, series: any, reason: any) => {
    if (series != null) {
      let seriesTrim = series.trim().split('>')
      console.log(seriesTrim[1])
      setGrandSeries(seriesTrim[0])
      setMiddleSeries(seriesTrim[1])
      setRowSeries(seriesTrim[2])
      setKeyword(series)
    }
    console.log(reason)
  }

  useEffect(() => {
    fnSelectUnivLevelList()
  }, [])

  const fnSelectUnivLevelList = () => {
    memberAxios.post('/compatibility/searchUnivLevelList', {}).then((res) => {
      console.log(res.data.data)
      if (res.data.data != null) {
        setUnivLevelList(res.data.data)
      } else {
        setUnivLevelList([])
      }
    })
  }

  const fnSelectMajorCd = () => {
    memberAxios
      .post('/compatibility/searchMajorCd', {
        grandSeries: grandSeries,
        middleSeries: middleSeries,
        rowSeries: rowSeries,
      })
      .then((res) => {
        if (res.data.data != null) {
          setMajorCd(res.data.data.majorCd)
        } else {
        }
      })
  }

  useEffect(() => {
    fnSelectMajorCd()
  }, [cClass, keyword])

  const fnSearchCompatibility = () => {
    setViewFlag(false)
    setChartViewFlag(false)
    memberAxios
      .post('/compatibility/searchCompatibility', {
        grandSeries: grandSeries || aClass,
        middleSeries: middleSeries || bClass,
        rowSeries: rowSeries || cClass,
        univLevel: univLevel,
      })
      .then((res) => {
        if (res.data.data != null) {
          setRequiredList(res.data.data[0])
          setEncoList(res.data.data[1])
          setImportantList(res.data.data[2])
          setReferenceList(res.data.data[3])
          setAnalysisResultList(res.data.data[4])
          setViewFlag(true)
        } else {
          alert('데이터 조회에 실패했습니다. 관리자에 문의하세요.')
        }
      })
  }

  useEffect(() => {
    if (viewFlag == true) {
      fnCalResultCompatibility()
      setChartViewFlag(true)
    }
  }, [viewFlag])

  const fnCalResultCompatibility = () => {
    //필수,장려 과목 평균 내기
    let requiredSum = 0
    let encoSum = 0
    let importantSum = 0
    let referenceSum = 0

    for (let i = 0; i < requiredList.length; i++) {
      let calVar = recommandGrade - requiredList[i].ranking
      if (requiredList[i].ranking == undefined) {
        if (univLevel == 7) {
          requiredSum = requiredSum + 0
        } else if (univLevel == 6) {
          requiredSum = requiredSum + 0
        } else if (univLevel == 5) {
          requiredSum = requiredSum + 0
        } else if (univLevel == 4) {
          requiredSum = requiredSum + 1
        } else if (univLevel == 3) {
          requiredSum = requiredSum + 1
        } else if (univLevel == 2) {
          requiredSum = requiredSum + 2
        } else if (univLevel == 1) {
          requiredSum = requiredSum + 4
        }
      } else if (requiredList[i].ranking != undefined) {
        if (univLevel == 7) {
          if (calVar >= 0.2) {
            requiredSum = requiredSum + 7
          } else if (calVar >= 0.1 && calVar < 0.2) {
            requiredSum = requiredSum + 6
          } else if (calVar >= 0 && calVar < 0.1) {
            requiredSum = requiredSum + 5
          } else if (calVar >= -0.1 && calVar < 0) {
            requiredSum = requiredSum + 4
          } else if (calVar >= -0.2 && calVar < 0.1) {
            requiredSum = requiredSum + 3
          } else if (calVar >= -1 && calVar < -0.2) {
            requiredSum = requiredSum + 2
          } else if (calVar < -1) {
            requiredSum = requiredSum + 1
          }
        } else if (univLevel == 6) {
          if (calVar >= 0.4) {
            requiredSum = requiredSum + 7
          } else if (calVar >= 0.2 && calVar < 0.4) {
            requiredSum = requiredSum + 6
          } else if (calVar >= 0 && calVar < 0.2) {
            requiredSum = requiredSum + 5
          } else if (calVar >= -0.2 && calVar < 0) {
            requiredSum = requiredSum + 4
          } else if (calVar >= -1 && calVar < -0.2) {
            requiredSum = requiredSum + 3
          } else if (calVar >= -2 && calVar < -1) {
            requiredSum = requiredSum + 2
          } else if (calVar < -2) {
            requiredSum = requiredSum + 1
          }
        } else if (univLevel == 5) {
          if (calVar >= 1) {
            requiredSum = requiredSum + 7
          } else if (calVar >= 0.5 && calVar < 1) {
            requiredSum = requiredSum + 6
          } else if (calVar >= 0 && calVar < 0.5) {
            requiredSum = requiredSum + 5
          } else if (calVar >= -0.5 && calVar < 0) {
            requiredSum = requiredSum + 4
          } else if (calVar >= -1 && calVar < -0.5) {
            requiredSum = requiredSum + 3
          } else if (calVar < -1) {
            requiredSum = requiredSum + 2
          }
        } else if (univLevel == 4) {
          if (calVar >= 1) {
            requiredSum = requiredSum + 7
          } else if (calVar >= 0.5 && calVar < 1) {
            requiredSum = requiredSum + 6
          } else if (calVar >= 0 && calVar < 0.5) {
            requiredSum = requiredSum + 5
          } else if (calVar >= -0.5 && calVar < 0) {
            requiredSum = requiredSum + 4
          } else if (calVar >= -1 && calVar < -0.5) {
            requiredSum = requiredSum + 3
          } else if (calVar < -1) {
            requiredSum = requiredSum + 2
          }
        } else if (univLevel == 3) {
          if (calVar >= 3) {
            requiredSum = requiredSum + 7
          } else if (calVar >= 2 && calVar < 3) {
            requiredSum = requiredSum + 6
          } else if (calVar >= 1 && calVar < 2) {
            requiredSum = requiredSum + 5
          } else if (calVar >= 0 && calVar < 1) {
            requiredSum = requiredSum + 4
          } else if (calVar >= -1 && calVar < 0) {
            requiredSum = requiredSum + 3
          } else if (calVar <= -2) {
            requiredSum = requiredSum + 2
          }
        } else if (univLevel == 2) {
          if (calVar >= 2) {
            requiredSum = requiredSum + 7
          } else if (calVar >= 1 && calVar < 2) {
            requiredSum = requiredSum + 6
          } else if (calVar >= 0 && calVar < 1) {
            requiredSum = requiredSum + 5
          } else if (calVar >= -1 && calVar < 0) {
            requiredSum = requiredSum + 4
          } else if (calVar <= -1) {
            requiredSum = requiredSum + 3
          }
        } else if (univLevel == 1) {
          if (calVar >= 1) {
            requiredSum = requiredSum + 7
          } else if (calVar >= 0 && calVar < 1) {
            requiredSum = requiredSum + 6
          } else if (calVar < 0) {
            requiredSum = requiredSum + 5
          }
        }
      }
    }

    for (let i = 0; i < encoList.length; i++) {
      let calVar = recommandGrade - encoList[i].ranking
      if (encoList[i].ranking == undefined) {
        if (univLevel == 7) {
          encoSum = encoSum + 0
        } else if (univLevel == 6) {
          encoSum = encoSum + 0
        } else if (univLevel == 5) {
          encoSum = encoSum + 0
        } else if (univLevel == 4) {
          encoSum = encoSum + 1
        } else if (univLevel == 3) {
          encoSum = encoSum + 1
        } else if (univLevel == 2) {
          encoSum = encoSum + 2
        } else if (univLevel == 1) {
          encoSum = encoSum + 4
        }
      } else if (encoList[i].ranking != undefined) {
        if (univLevel == 7) {
          if (calVar >= 0.2) {
            encoSum = encoSum + 7
          } else if (calVar >= 0.1 && calVar < 0.2) {
            encoSum = encoSum + 6
          } else if (calVar >= 0 && calVar < 0.1) {
            encoSum = encoSum + 5
          } else if (calVar >= -0.1 && calVar < 0) {
            encoSum = encoSum + 4
          } else if (calVar >= -0.2 && calVar < 0.1) {
            encoSum = encoSum + 3
          } else if (calVar >= -1 && calVar < -0.2) {
            encoSum = encoSum + 2
          } else if (calVar < -1) {
            encoSum = encoSum + 1
          }
        } else if (univLevel == 6) {
          if (calVar >= 0.4) {
            encoSum = encoSum + 7
          } else if (calVar >= 0.2 && calVar < 0.4) {
            encoSum = encoSum + 6
          } else if (calVar >= 0 && calVar < 0.2) {
            encoSum = encoSum + 5
          } else if (calVar >= -0.2 && calVar < 0) {
            encoSum = encoSum + 4
          } else if (calVar >= -1 && calVar < -0.2) {
            encoSum = encoSum + 3
          } else if (calVar >= -2 && calVar < -1) {
            encoSum = encoSum + 2
          } else if (calVar < -2) {
            encoSum = encoSum + 1
          }
        } else if (univLevel == 5) {
          if (calVar >= 1) {
            encoSum = encoSum + 7
          } else if (calVar >= 0.5 && calVar < 1) {
            encoSum = encoSum + 6
          } else if (calVar >= 0 && calVar < 0.5) {
            encoSum = encoSum + 5
          } else if (calVar >= -0.5 && calVar < 0) {
            encoSum = encoSum + 4
          } else if (calVar >= -1 && calVar < -0.5) {
            encoSum = encoSum + 3
          } else if (calVar < -1) {
            encoSum = encoSum + 2
          }
        } else if (univLevel == 4) {
          if (calVar >= 1) {
            encoSum = encoSum + 7
          } else if (calVar >= 0.5 && calVar < 1) {
            encoSum = encoSum + 6
          } else if (calVar >= 0 && calVar < 0.5) {
            encoSum = encoSum + 5
          } else if (calVar >= -0.5 && calVar < 0) {
            encoSum = encoSum + 4
          } else if (calVar >= -1 && calVar < -0.5) {
            encoSum = encoSum + 3
          } else if (calVar < -1) {
            encoSum = encoSum + 2
          }
        } else if (univLevel == 3) {
          if (calVar >= 3) {
            encoSum = encoSum + 7
          } else if (calVar >= 2 && calVar < 3) {
            encoSum = encoSum + 6
          } else if (calVar >= 1 && calVar < 2) {
            encoSum = encoSum + 5
          } else if (calVar >= 0 && calVar < 1) {
            encoSum = encoSum + 4
          } else if (calVar >= -1 && calVar < 0) {
            encoSum = encoSum + 3
          } else if (calVar <= -2) {
            encoSum = encoSum + 2
          }
        } else if (univLevel == 2) {
          if (calVar >= 2) {
            encoSum = encoSum + 7
          } else if (calVar >= 1 && calVar < 2) {
            encoSum = encoSum + 6
          } else if (calVar >= 0 && calVar < 1) {
            encoSum = encoSum + 5
          } else if (calVar >= -1 && calVar < 0) {
            encoSum = encoSum + 4
          } else if (calVar <= -1) {
            encoSum = encoSum + 3
          }
        } else if (univLevel == 1) {
          if (calVar >= 1) {
            encoSum = encoSum + 7
          } else if (calVar >= 0 && calVar < 1) {
            encoSum = encoSum + 6
          } else if (calVar < 0) {
            encoSum = encoSum + 5
          }
        }
      }
    }

    for (let i = 0; i < importantList.length; i++) {
      let calVar = importantList[i].recommandGrade - importantList[i].avg
      if (univLevel == 7) {
        if (calVar >= 0.3) {
          importantSum = importantSum + 7
        } else if (calVar >= 0.2 && calVar < 0.3) {
          importantSum = importantSum + 6
        } else if (calVar >= 0.1 && calVar < 0.2) {
          importantSum = importantSum + 5
        } else if (calVar >= 0 && calVar < 0.1) {
          importantSum = importantSum + 4
        } else if (calVar >= -0.1 && calVar < 0) {
          importantSum = importantSum + 3
        } else if (calVar >= -0.5 && calVar < -0.1) {
          importantSum = importantSum + 2
        } else if (calVar < -0.5) {
          importantSum = importantSum + 1
        }
      } else if (univLevel == 6) {
        if (calVar >= 0.3) {
          importantSum = importantSum + 7
        } else if (calVar >= 0.2 && calVar < 0.3) {
          importantSum = importantSum + 6
        } else if (calVar >= 0.1 && calVar < 0.2) {
          importantSum = importantSum + 5
        } else if (calVar >= 0 && calVar < 0.1) {
          importantSum = importantSum + 4
        } else if (calVar >= -0.1 && calVar < 0) {
          importantSum = importantSum + 3
        } else if (calVar >= -0.5 && calVar < -0.1) {
          importantSum = importantSum + 2
        } else if (calVar < -0.5) {
          importantSum = importantSum + 1
        }
      } else if (univLevel == 5) {
        if (calVar >= 0.6) {
          importantSum = importantSum + 7
        } else if (calVar >= 0.4 && calVar < 0.6) {
          importantSum = importantSum + 6
        } else if (calVar >= 0.2 && calVar < 0.4) {
          importantSum = importantSum + 5
        } else if (calVar >= 0 && calVar < 0.2) {
          importantSum = importantSum + 4
        } else if (calVar >= -0.2 && calVar < 0) {
          importantSum = importantSum + 3
        } else if (calVar >= -1 && calVar < -0.2) {
          importantSum = importantSum + 2
        } else if (calVar < -1) {
          importantSum = importantSum + 1
        }
      } else if (univLevel == 4) {
        if (calVar >= 1) {
          importantSum = importantSum + 7
        } else if (calVar >= 0.5 && calVar < 1) {
          importantSum = importantSum + 6
        } else if (calVar >= 0.2 && calVar < 0.5) {
          importantSum = importantSum + 5
        } else if (calVar >= 0 && calVar < 0.2) {
          importantSum = importantSum + 4
        } else if (calVar >= -0.2 && calVar < 0) {
          importantSum = importantSum + 3
        } else if (calVar >= -1 && calVar < -0.2) {
          importantSum = importantSum + 2
        } else if (calVar < -1) {
          importantSum = importantSum + 1
        }
      } else if (univLevel == 3) {
        if (calVar >= 2) {
          importantSum = importantSum + 7
        } else if (calVar >= 1 && calVar < 2) {
          importantSum = importantSum + 6
        } else if (calVar >= 0.3 && calVar < 1) {
          importantSum = importantSum + 5
        } else if (calVar >= 0 && calVar < 0.3) {
          importantSum = importantSum + 4
        } else if (calVar >= -0.3 && calVar < 0) {
          importantSum = importantSum + 3
        } else if (calVar >= -1 && calVar < -0.3) {
          importantSum = importantSum + 2
        } else if (calVar < -1) {
          importantSum = importantSum + 1
        }
      } else if (univLevel == 2) {
        if (calVar >= 2) {
          importantSum = importantSum + 7
        } else if (calVar >= 1 && calVar < 2) {
          importantSum = importantSum + 6
        } else if (calVar >= 0 && calVar < 1) {
          importantSum = importantSum + 5
        } else if (calVar >= -1 && calVar < 0) {
          importantSum = importantSum + 4
        } else if (calVar >= -2 && calVar < -1) {
          importantSum = importantSum + 3
        } else if (calVar >= -3 && calVar < -2) {
          importantSum = importantSum + 2
        } else if (calVar >= -4 && calVar < -3) {
          importantSum = importantSum + 1
        }
      } else if (univLevel == 1) {
        if (calVar >= 1) {
          importantSum = importantSum + 7
        } else if (calVar >= 0 && calVar < 1) {
          importantSum = importantSum + 6
        } else if (calVar >= -1 && calVar < 0) {
          importantSum = importantSum + 5
        } else if (calVar >= -2 && calVar < -1) {
          importantSum = importantSum + 4
        } else if (calVar >= -3 && calVar < -2) {
          importantSum = importantSum + 3
        } else if (calVar >= -4 && calVar < -3) {
          importantSum = importantSum + 2
        } else if (calVar >= -5 && calVar < -4) {
          importantSum = importantSum + 1
        }
      }
    }

    for (let i = 0; i < referenceList.length; i++) {
      let calVar = referenceList[i].recommandGrade - referenceList[i].avg
      if (univLevel == 7) {
        if (calVar >= 0.3) {
          referenceSum = referenceSum + 7
        } else if (calVar >= 0.2 && calVar < 0.3) {
          referenceSum = referenceSum + 6
        } else if (calVar >= 0.1 && calVar < 0.2) {
          referenceSum = referenceSum + 5
        } else if (calVar >= 0 && calVar < 0.1) {
          referenceSum = referenceSum + 4
        } else if (calVar >= -0.1 && calVar < 0) {
          referenceSum = referenceSum + 3
        } else if (calVar >= -0.5 && calVar < -0.1) {
          referenceSum = referenceSum + 2
        } else if (calVar < -0.5) {
          referenceSum = referenceSum + 1
        }
      } else if (univLevel == 6) {
        if (calVar >= 0.3) {
          referenceSum = referenceSum + 7
        } else if (calVar >= 0.2 && calVar < 0.3) {
          referenceSum = referenceSum + 6
        } else if (calVar >= 0.1 && calVar < 0.2) {
          referenceSum = referenceSum + 5
        } else if (calVar >= 0 && calVar < 0.1) {
          referenceSum = referenceSum + 4
        } else if (calVar >= -0.1 && calVar < 0) {
          referenceSum = referenceSum + 3
        } else if (calVar >= -0.5 && calVar < -0.1) {
          referenceSum = referenceSum + 2
        } else if (calVar < -0.5) {
          referenceSum = referenceSum + 1
        }
      } else if (univLevel == 5) {
        if (calVar >= 0.6) {
          referenceSum = referenceSum + 7
        } else if (calVar >= 0.4 && calVar < 0.6) {
          referenceSum = referenceSum + 6
        } else if (calVar >= 0.2 && calVar < 0.4) {
          referenceSum = referenceSum + 5
        } else if (calVar >= 0 && calVar < 0.2) {
          referenceSum = referenceSum + 4
        } else if (calVar >= -0.2 && calVar < 0) {
          referenceSum = referenceSum + 3
        } else if (calVar >= -1 && calVar < -0.2) {
          referenceSum = referenceSum + 2
        } else if (calVar < -1) {
          referenceSum = referenceSum + 1
        }
      } else if (univLevel == 4) {
        if (calVar >= 1) {
          referenceSum = referenceSum + 7
        } else if (calVar >= 0.5 && calVar < 1) {
          referenceSum = referenceSum + 6
        } else if (calVar >= 0.2 && calVar < 0.5) {
          referenceSum = referenceSum + 5
        } else if (calVar >= 0 && calVar < 0.2) {
          referenceSum = referenceSum + 4
        } else if (calVar >= -0.2 && calVar < 0) {
          referenceSum = referenceSum + 3
        } else if (calVar >= -1 && calVar < -0.2) {
          referenceSum = referenceSum + 2
        } else if (calVar < -1) {
          referenceSum = referenceSum + 1
        }
      } else if (univLevel == 3) {
        if (calVar >= 2) {
          referenceSum = referenceSum + 7
        } else if (calVar >= 1 && calVar < 2) {
          referenceSum = referenceSum + 6
        } else if (calVar >= 0.3 && calVar < 1) {
          referenceSum = referenceSum + 5
        } else if (calVar >= 0 && calVar < 0.3) {
          referenceSum = referenceSum + 4
        } else if (calVar >= -0.3 && calVar < 0) {
          referenceSum = referenceSum + 3
        } else if (calVar >= -1 && calVar < -0.3) {
          referenceSum = referenceSum + 2
        } else if (calVar < -1) {
          referenceSum = referenceSum + 1
        }
      } else if (univLevel == 2) {
        if (calVar >= 2) {
          referenceSum = referenceSum + 7
        } else if (calVar >= 1 && calVar < 2) {
          referenceSum = referenceSum + 6
        } else if (calVar >= 0 && calVar < 1) {
          referenceSum = referenceSum + 5
        } else if (calVar >= -1 && calVar < 0) {
          referenceSum = referenceSum + 4
        } else if (calVar >= -2 && calVar < -1) {
          referenceSum = referenceSum + 3
        } else if (calVar >= -3 && calVar < -2) {
          referenceSum = referenceSum + 2
        } else if (calVar >= -4 && calVar < -3) {
          referenceSum = referenceSum + 1
        }
      } else if (univLevel == 1) {
        if (calVar >= 1) {
          referenceSum = referenceSum + 7
        } else if (calVar >= 0 && calVar < 1) {
          referenceSum = referenceSum + 6
        } else if (calVar >= -1 && calVar < 0) {
          referenceSum = referenceSum + 5
        } else if (calVar >= -2 && calVar < -1) {
          referenceSum = referenceSum + 4
        } else if (calVar >= -3 && calVar < -2) {
          referenceSum = referenceSum + 3
        } else if (calVar >= -4 && calVar < -3) {
          referenceSum = referenceSum + 2
        } else if (calVar >= -5 && calVar < -4) {
          referenceSum = referenceSum + 1
        }
      }
    }

    let result1 = Math.floor((requiredSum * 2 + encoSum) / (requiredList.length * 2 + encoList.length))
    let result2 = Math.floor((importantSum * 2 + referenceSum) / (importantList.length * 2 + referenceList.length))
    let result3 = Math.floor((result1 + result2) / 2)

    if (requiredList.length == 0 && encoList.length == 0) {
      result1 = 4
      result3 = result2
    }
    if (importantList.length == 0 && referenceList.length == 0) {
      result2 = 4
      result3 = result1
    }

    let resultDataset1 = [0]
    let resultDataset2 = [0]
    let resultDataset3 = [0]
    let resultDataset4 = [0]
    let resultDataset5 = [0]
    let resultDataset6 = [0]
    let resultDataset7 = [0]

    for (let j = 0; j < 7; j++) {
      if (j < result1) {
        if (j == 0) {
          resultDataset1[0] = 1
        } else if (j == 1) {
          resultDataset2[0] = 1
        } else if (j == 2) {
          resultDataset3[0] = 1
        } else if (j == 3) {
          resultDataset4[0] = 1
        } else if (j == 4) {
          resultDataset5[0] = 1
        } else if (j == 5) {
          resultDataset6[0] = 1
        } else if (j == 6) {
          resultDataset7[0] = 1
        }
      }
    }

    for (let j = 0; j < 7; j++) {
      if (j < result2) {
        if (j == 0) {
          resultDataset1[1] = 1
        } else if (j == 1) {
          resultDataset2[1] = 1
        } else if (j == 2) {
          resultDataset3[1] = 1
        } else if (j == 3) {
          resultDataset4[1] = 1
        } else if (j == 4) {
          resultDataset5[1] = 1
        } else if (j == 5) {
          resultDataset6[1] = 1
        } else if (j == 6) {
          resultDataset7[1] = 1
        }
      }
    }

    for (let j = 0; j < 7; j++) {
      if (j < result3) {
        if (j == 0) {
          resultDataset1[2] = 1
        } else if (j == 1) {
          resultDataset2[2] = 1
        } else if (j == 2) {
          resultDataset3[2] = 1
        } else if (j == 3) {
          resultDataset4[2] = 1
        } else if (j == 4) {
          resultDataset5[2] = 1
        } else if (j == 5) {
          resultDataset6[2] = 1
        } else if (j == 6) {
          resultDataset7[2] = 1
        }
      }
    }

    //const labels = ['필수과목', '장려과목', '주요교과', '참조교과', '종합']
    const labels = ['필수과목/장려과목', '주요교과/참조교과', '종합']
    const data = {
      labels,
      datasets: [
        {
          label: '1칸',
          data: resultDataset1,
          backgroundColor: 'rgb(230, 60, 60)',
          stack: 'Stack 0',
        },
        {
          label: '2칸',
          data: resultDataset2,
          backgroundColor: 'rgb(230, 125, 60)',
          stack: 'Stack 0',
        },
        {
          label: '3칸',
          data: resultDataset3,
          backgroundColor: 'rgb(230, 219, 60)',
          stack: 'Stack 0',
        },
        {
          label: '4칸',
          data: resultDataset4,
          backgroundColor: 'rgb(66, 214, 56)',
          stack: 'Stack 0',
        },
        {
          label: '5칸',
          data: resultDataset5,
          backgroundColor: 'rgb(60, 148, 230)',
          stack: 'Stack 0',
        },
        {
          label: '6칸',
          data: resultDataset6,
          backgroundColor: 'rgb(60, 60, 230)',
          stack: 'Stack 0',
        },
        {
          label: '7칸',
          data: resultDataset7,
          backgroundColor: 'rgb(137, 60, 230)',
          stack: 'Stack 0',
        },
      ],
    }
    setCahrtData(data)
  }

  useEffect(() => {}, [chartViewFlag])

  const handleChangeUnivLevel = (event: SelectChangeEvent<typeof univLevel>) => {
    setUnivLevel(Number(event.target.value))
    if (event.target.value == 1) {
      setRecommandGrade(5.5)
    }
    if (event.target.value == 2) {
      setRecommandGrade(4.5)
    }
    if (event.target.value == 3) {
      setRecommandGrade(3.5)
    }
    if (event.target.value == 4) {
      setRecommandGrade(2.5)
    }
    if (event.target.value == 5) {
      setRecommandGrade(2)
    }
    if (event.target.value == 6) {
      setRecommandGrade(1.6)
    }
    if (event.target.value == 7) {
      setRecommandGrade(1.3)
    }
  }

  useEffect(() => {
    setShowAnalysisResult(false)
    setIsRiskMeasureButtonClicked(false)
  }, [cClass, selectedScoreClass])

  useEffect(() => {
    setBClass('')
    setCClass('')
  }, [aClass])
  useEffect(() => {
    setCClass('')
  }, [bClass])
  useEffect(() => {
    setShowAnalysisResult(false)
  }, [cClass])

  let { data: aClasses } = useQuery(
    '학종-step1-대분류',
    () =>
      memberAxios
        .post('/compatibility/searchSeriesCdList', {
          series: '',
          type: 'G',
        })
        .then((res) => {
          return res.data.data as string[]
        }),
    { initialData: [] }
  )

  let { data: bClasses } = useQuery(
    ['학종-step1-중분류', aClass],
    () =>
      memberAxios
        .post('/compatibility/searchSeriesCdList', {
          series: aClass,
          type: 'M',
        })
        .then((res) => {
          return res.data.data as string[]
        }),
    { enabled: aClass !== '' }
  )
  const { data: cClasses, isFetching: isCClassFetching } = useQuery(
    ['학종-step1-소분류', bClass],
    () =>
      memberAxios
        .post('/compatibility/searchSeriesCdList', {
          series: bClass,
          type: 'R',
        })
        .then((res) => {
          return res.data.data as string[]
        }),
    { enabled: bClass !== '' }
  )

  let { data: recruitmentUnitData, isFetching } = useQuery(
    ['학종-step1-모집단위리스트', aClass, bClass, cClass],
    () =>
      memberAxios
        .post('/pearlyd/sr/rq/list', {
          grandSeries: aClass,
          middleSeries: bClass,
          rowSeries: cClass,
        })
        .then((res) => _.uniq(res.data.map((x: any) => x.recruitmentUnit) as string[])),
    { enabled: cClass !== '' }
  )

  recruitmentUnitData = recruitmentUnitData ?? []

  const [pickedUnits, setPickedUnits] = useState<Record<string, boolean>>({})
  const numPickedUnits = getSelected(pickedUnits).length
  const pickedRecruit = getSelected(pickedUnits)

  /**
   * 모집단위가 변경될때마다 조회하는 갯수가 달라짐
   */
  useEffect(() => {
    const keys = Object.keys(pickedUnits)
    const recruitmentUnitDataArray: any = []
    keys.forEach((key) => {
      if (pickedUnits[key]) {
        recruitmentUnitDataArray.push(key)
      }
    })
    //체크 초기화
    setSelectedScoreClass('')
    checkDataList(recruitmentUnitDataArray)
    //갯수체크
  }, [pickedUnits])
  const checkDataList = (recruitmentUnitDataArray: any) => {
    memberAxios
      .post('/pearlyd/sr/rq/ck/data', {
        grandSeries: aClass,
        middleSeries: bClass,
        rowSeries: cClass,
        recruitmentUnitList: recruitmentUnitDataArray,
      })
      .then((res) => {
        const result: any = {}
        const result2: any = {}
        if (res.data) {
          const data: [] = res.data
          data?.forEach((x: any): any => {
            const level: string = x.level
            result[level] = true
            result2[level] = x.cnt
          })
        }
        // result[]
        setRegionRangeDisable(result)
        setDatacnt(result2)
      })
  }

  useEffect(() => {
    const obj: any = {}
    for (const item of recruitmentUnitData ?? []) {
      obj[item] = true
    }
    setPickedUnits(obj)
  }, [cClass, recruitmentUnitData.length])

  let { data: step1ResultData, isSuccess: step1ResultDataSuccess } = useQuery(
    ['학종-step1-테이블데이터', cClass, selectedScoreClass, pickedRecruit.join('$')],
    () =>
      memberAxios
        .post('/pearlyd/subject/rist/info', {
          grandSeries: aClass,
          middleSeries: bClass,
          rowSeries: cClass,
          regionRange: selectedScoreClass.replaceAll(' ', ''),
          recruitmentUnitList: pickedRecruit,
        })
        .then((res) => {
          console.log(res.data)
          return res.data as {
            list: {
              essentialFlag: '1' | '2'
              essentialGrade: number
              isCheck: 0 | 1 | 2
              mainSubjectName: string
              myGrade: string
              riskScore: string
              subjectName: string
            }[]
            totalScoreSum: number
            byCollegeLevelList: {
              collegeName: string
              recruitmentUnit: string
              typeName: string
            }[]
          }
        }),
    { enabled: cClass !== '' && selectedScoreClass !== '' }
  )

  const riskScore = is문과 ? 5 : Number(step1ResultData?.totalScoreSum)

  let { data: officerReviewData } = useQuery('학종-진로역량-평가', () =>
    memberAxios
      .get('/pearlyd/sr/officer-student-evaludate/avg-score/jinro/details')
      .then((x) => x.data as 학종_사정관평가데이터_형식)
  )

  const tableDataList = step1ResultData?.list ?? []

  useEffect(() => {
    setBundle((prev) => {
      const 종합진단_계열적합성 = is문과
        ? {}
        : {
            '필수과목 선택 과목 수': tableDataList.reduce(
              (acc, cur) => (cur.essentialFlag === '2' && cur.isCheck === 1 ? acc + 1 : acc),
              0
            ),
            '필수과목 미선택 과목 수': tableDataList.reduce(
              (acc, cur) => (cur.essentialFlag === '2' && cur.isCheck === 0 ? acc + 1 : acc),
              0
            ),
            '장려과목 선택 과목 수': tableDataList.reduce(
              (acc, cur) => (cur.essentialFlag === '1' && cur.isCheck === 1 ? acc + 1 : acc),
              0
            ),
            '장려과목 미선택 과목 수': tableDataList.reduce(
              (acc, cur) => (cur.essentialFlag === '1' && cur.isCheck === 0 ? acc + 1 : acc),
              0
            ),
            위험도: step1ResultData?.totalScoreSum,
          }

      const newObj = _.cloneDeep(prev)
      Object.assign(newObj, {
        종합진단_계열적합성,
        recruitmentUnitData: pickedRecruit,
        seriesRiskScore: riskScore,
        aClass,
        cClass,
        regionRange: selectedScoreClass,
      })
      return newObj
    })
  }, [step1ResultData, recruitmentUnitData.join('$')])

  const table = getTable(tableDataList)

  const isScoreClassSelected = selectedScoreClass !== ''
  const numTotalUnits = recruitmentUnitData.length

  const isNoTableData = !(tableDataList.length > 0)
  const isAnalysisResultButtonEnabled = isScoreClassSelected && cClass.length > 0

  const isNextButtonEnabled = showAnalysisResult && numPickedUnits > 0

  const preTableData = _.uniqBy(step1ResultData?.byCollegeLevelList ?? [], (x) => {
    return `${x.collegeName}__${x.typeName}__${x.recruitmentUnit}`
  })
  //const columnHelper = createColumnHelper<(typeof preTableData)[0]>()

  return (
    <>
      <TitleSection
        title="계열 적합성 진단"
        //subtitle="*이미 관심대학에 저장된 대학>모집단위는 아래 리스트에 나오지 않습니다."
        sx={{ pl: '40px', pr: '40px', mb: '20px' }}
      >
        <TurtlePaper sx={{ px: '30px', py: '25px', mt: '20px' }}>
          <Typography variant="h6" sx={{ mb: 4 }}>
            계열 선택, 또는 계열 검색 후, 대학레벨을 선택하고, '계열적합성 진단 버튼'을 클릭해 주세요
          </Typography>

          <Typography variant="D3_bold, 16, 24">계열 선택</Typography>
          <StackX mt={2} mb={2} gap={1}>
            <TurtleSelectBox
              value={aClass}
              setValue={setAClass}
              label="대분류"
              menuList={aClasses}
              disabled={keyword != '' ? true : false}
            />
            <TurtleSelectBox
              disabled={aClass === ''}
              value={bClass}
              setValue={setBClass}
              label="중분류"
              menuList={bClasses}
            />
            <TurtleSelectBox
              disabled={bClass === ''}
              value={cClass}
              setValue={setCClass}
              //setValue={fnSetrowClassforSelectBox}
              label="소분류"
              menuList={cClasses}
            />
            <ButtonBase
              sx={{
                px: 5,
                justifySelf: 'end',
                color: '#fff',
                bgcolor: mainColor,
                py: 1,
                borderRadius: '7px',
                fontWeight: 700,
                boxShadow: '0px 2px 3px #c8c8c8',
              }}
              onClick={() => fnInitSeriesSelect()}
            >
              초기화
            </ButtonBase>
          </StackX>
          <Typography variant="D3_bold, 16, 24">계열 검색</Typography>
          <StackX mt={2} gap={1}>
            <Autocomplete
              clearIcon={
                <IconButton
                  onClick={() => setKeyword('')}
                  sx={{
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                >
                  <CloseOutlined />
                </IconButton>
              }
              value={keyword}
              sx={{ width: '74.5%' }}
              id="free-solo-demo"
              freeSolo
              disabled={aClass != '' ? true : false}
              options={autoCompSeriesList.map((option) =>
                autoCompSeriesList.length > 0 ? option.seriesNm : '검색어를 입력해 주세요.'
              )}
              renderInput={(params) => <TextField {...params} />}
              onKeyUp={(e) => fnAutoCompleteSeries(e)}
              onChange={(e, value, reason) => {
                fnSelectAutoSeries(e, value, reason)
              }}
            />
          </StackX>
          <Box sx={{ borderBottom: '1px solid #efefef', mt: 3, mb: 3 }}></Box>
          <Typography variant="D3_bold, 16, 24">대학레벨 선택</Typography>
          <StackX mt={2} gap={1}>
            <Select
              size="medium"
              sx={{ width: '74.5%' }}
              // onChange={(e: any) => {
              //   fnSetUnivLevel(e.target.value)
              // }}
              onChange={handleChangeUnivLevel}
              value={univLevel}
              disabled={ (keyword == '') && (cClass == '')}
            >
              {univLevelList.map((data: any, index) => {
                return (
                  <MenuItem value={data.grade} key={index}>
                    {'레벨' + (index + 1) + ' - ' + data.collegeRange}
                  </MenuItem>
                )
              })}
            </Select>
          </StackX>
          <Stack style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3%' }}>
            <Box>
              <ButtonBase
                disabled={
                  (univLevel == 0 && keyword == '') || (univLevel == 0 && aClass == '' && bClass == '' && cClass == '')
                }
                sx={{
                  px: 5,
                  justifySelf: 'end',
                  color:
                    (univLevel != 0 && keyword != '') ||
                    (univLevel != 0 && aClass != '' && bClass != '' && cClass != '')
                      ? '#fff'
                      : '#a3a3a3',
                  bgcolor:
                    (univLevel != 0 && keyword != '') ||
                    (univLevel != 0 && aClass != '' && bClass != '' && cClass != '')
                      ? mainColor
                      : '#e5e5e5',
                  py: 1,
                  borderRadius: '7px',
                  fontWeight: 700,
                  boxShadow: '0px 2px 3px #c8c8c8',
                }}
                onClick={() => {
                  fnSearchCompatibility()
                }}
              >
                계열 적합성 진단
                <AiOutlineSearch fontSize={17} style={{ marginLeft: 5 }} />
              </ButtonBase>
            </Box>
          </Stack>
        </TurtlePaper>
      </TitleSection>
      {viewFlag == false ? null : (
        <>
          <Box
            sx={(theme) => ({
              px: 5,
              width: '100%',
              paddingBottom: '40px',
              [theme.breakpoints.down('md')]: {
                px: 4,
              },
              [theme.breakpoints.down('sm')]: { px: 2 },
              [theme.breakpoints.down('xs')]: { px: 1 },
            })}
          >
            <TurtlePaper sx={{ px: '30px', py: '25px', mt: '20px' }}>
              <Table sx={{ minWidth: 650, border: '1px solid' }}>
                <TableHead style={{ border: '1px solid' }}>
                  <TableRow>
                    <TableCell align="center" rowSpan={2} style={{ border: '1px solid' }}>
                      구분
                    </TableCell>
                    <TableCell align="center" rowSpan={2} style={{ border: '1px solid' }}>
                      과목
                    </TableCell>
                    <TableCell align="center" rowSpan={2} style={{ border: '1px solid' }}>
                      이수현황
                    </TableCell>
                    <TableCell align="center" rowSpan={2} style={{ border: '1px solid' }}>
                      내등급
                    </TableCell>
                    <TableCell align="center" rowSpan={2} style={{ border: '1px solid' }}>
                      권장등급
                    </TableCell>
                    <TableCell align="center" colSpan={2} style={{ border: '1px solid' }}>
                      진단
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" style={{ border: '1px solid' }}>
                      결격/부합
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid' }}>
                      차이
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requiredList.length == 0 ? (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="center" style={{ border: '1px solid' }}>
                        필수과목
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid' }} colSpan={6}>
                        필수과목이 없습니다.
                      </TableCell>
                    </TableRow>
                  ) : requiredList.length == 1 ? (
                    requiredList.map((data: any, index: any) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          필수과목
                        </TableCell>

                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.subjectName}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking == undefined ? '미이수' : '이수'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking != undefined ? data.ranking : 'X'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking == undefined ? 'X' : recommandGrade}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: '1px solid #000',
                            color: data.ranking == undefined ? 'red' : 'green',
                            fontWeight: 'bold',
                          }}
                        >
                          {data.ranking == undefined ? '결격' : '부합'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking != undefined && data.ranking > recommandGrade
                            ? '-' + (data.ranking - recommandGrade)
                            : data.ranking != undefined && data.ranking < recommandGrade
                            ? recommandGrade - data.ranking
                            : data.ranking != undefined && data.ranking == recommandGrade
                            ? 0
                            : 'X'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : requiredList.length > 1 ? (
                    requiredList.map((data: any, index: any) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {index == 0 ? (
                          <TableCell align="center" style={{ border: '1px solid' }} rowSpan={requiredList.length}>
                            {index == 0 ? '필수과목' : ''}
                          </TableCell>
                        ) : null}

                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.subjectName}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking == undefined ? '미이수' : '이수'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking != undefined ? data.ranking : 'X'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking == undefined ? 'X' : recommandGrade}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: '1px solid #000',
                            color: data.ranking == undefined ? 'red' : 'green',
                            fontWeight: 'bold',
                          }}
                        >
                          {data.ranking == undefined ? '결격' : '부합'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking != undefined && data.ranking > recommandGrade
                            ? '-' + (data.ranking - recommandGrade)
                            : data.ranking != undefined && data.ranking < recommandGrade
                            ? recommandGrade - data.ranking
                            : data.ranking != undefined && data.ranking == recommandGrade
                            ? 0
                            : 'X'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : null}
                  {encoList.length == 0 ? (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="center" style={{ border: '1px solid' }}>
                        장려과목
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid' }} colSpan={6}>
                        장려과목이 없습니다.
                      </TableCell>
                    </TableRow>
                  ) : encoList.length == 1 ? (
                    encoList.map((data: any, index: any) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          장려과목
                        </TableCell>

                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.subjectName}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking == undefined ? '미이수' : '이수'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking != undefined ? data.ranking : 'X'}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: '1px solid #000',
                            color: data.ranking == undefined ? 'red' : 'green',
                            fontWeight: 'bold',
                          }}
                        >
                          {data.ranking == undefined ? 'X' : recommandGrade}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking == undefined ? '결격' : '부합'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking != undefined && data.ranking > recommandGrade
                            ? '-' + (data.ranking - recommandGrade)
                            : data.ranking != undefined && data.ranking < recommandGrade
                            ? recommandGrade - data.ranking
                            : data.ranking != undefined && data.ranking == recommandGrade
                            ? 0
                            : 'X'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : encoList.length > 1 ? (
                    encoList.map((data: any, index: any) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {index == 0 ? (
                          <TableCell align="center" style={{ border: '1px solid' }} rowSpan={encoList.length}>
                            {index == 0 ? '장려과목' : ''}
                          </TableCell>
                        ) : null}

                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.subjectName}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking == undefined ? '미이수' : '이수'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking != undefined ? data.ranking : 'X'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking == undefined ? 'X' : recommandGrade}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: '1px solid #000',
                            color: data.ranking == undefined ? 'red' : 'green',
                            fontWeight: 'bold',
                          }}
                        >
                          {data.ranking == undefined ? '결격' : '부합'}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.ranking != undefined && data.ranking > recommandGrade
                            ? '-' + (data.ranking - recommandGrade)
                            : data.ranking != undefined && data.ranking < recommandGrade
                            ? recommandGrade - data.ranking
                            : data.ranking != undefined && data.ranking == recommandGrade
                            ? 0
                            : 'X'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : null}
                </TableBody>
              </Table>
              <Table sx={{ minWidth: 650, border: '1px solid', mt: 7 }}>
                <TableHead style={{ border: '1px solid' }}>
                  <TableRow>
                    <TableCell align="center" style={{ border: '1px solid' }}>
                      구분
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid' }}>
                      교과
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid' }}>
                      내평균등급
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid' }}>
                      권장등급
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid' }}>
                      차이
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {importantList.length == 0 ? (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="center" style={{ border: '1px solid' }}>
                        주요교과
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid' }} colSpan={4}>
                        주요교과가 없습니다.
                      </TableCell>
                    </TableRow>
                  ) : importantList.length == 1 ? (
                    importantList.map((data: any, index: any) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {index == 0 ? (
                          <TableCell align="center" style={{ border: '1px solid' }}>
                            주요교과
                          </TableCell>
                        ) : null}

                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.mainSubjectName}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.avg}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.recommandGrade}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.avg != undefined && data.avg > data.recommandGrade
                            ? '-' + (data.avg - data.recommandGrade).toFixed(2)
                            : data.avg != undefined && data.avg < data.recommandGrade
                            ? (data.recommandGrade - data.avg).toFixed(2)
                            : data.avg != undefined && data.avg == data.recommandGrade
                            ? 0
                            : 'X'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : importantList.length > 1 ? (
                    importantList.map((data: any, index: any) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {index == 0 ? (
                          <TableCell align="center" style={{ border: '1px solid' }} rowSpan={index == 0 ? 2 : 1}>
                            {index == 0 ? '주요교과' : ''}
                          </TableCell>
                        ) : null}

                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.mainSubjectName}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.avg}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.recommandGrade}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.avg != undefined && data.avg > data.recommandGrade
                            ? '-' + (data.avg - data.recommandGrade).toFixed(2)
                            : data.avg != undefined && data.avg < data.recommandGrade
                            ? (data.recommandGrade - data.avg).toFixed(2)
                            : data.avg != undefined && data.avg == data.recommandGrade
                            ? 0
                            : 'X'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : null}
                  {referenceList.length == 0 ? (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="center" style={{ border: '1px solid' }}>
                        참조교과
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid' }} colSpan={4}>
                        참조교과가 없습니다.
                      </TableCell>
                    </TableRow>
                  ) : referenceList.length == 1 ? (
                    referenceList.map((data: any, index: any) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {index == 0 ? (
                          <TableCell align="center" style={{ border: '1px solid' }}>
                            참조교과
                          </TableCell>
                        ) : null}

                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.mainSubjectName}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.avg}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.recommandGrade}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.avg != undefined && data.avg > data.recommandGrade
                            ? '-' + (data.avg - data.recommandGrade).toFixed(2)
                            : data.avg != undefined && data.avg < data.recommandGrade
                            ? (data.recommandGrade - data.avg).toFixed(2)
                            : data.avg != undefined && data.avg == data.recommandGrade
                            ? 0
                            : 'X'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : referenceList.length > 1 ? (
                    referenceList.map((data: any, index: any) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {index == 0 ? (
                          <TableCell align="center" style={{ border: '1px solid' }} rowSpan={index == 0 ? 2 : 1}>
                            {index == 0 ? '참조교과' : ''}
                          </TableCell>
                        ) : null}

                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.mainSubjectName}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.avg}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.recommandGrade}
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid' }}>
                          {data.avg != undefined && data.avg > data.recommandGrade
                            ? '-' + (data.avg - data.recommandGrade).toFixed(2)
                            : data.avg != undefined && data.avg < data.recommandGrade
                            ? (data.recommandGrade - data.avg).toFixed(2)
                            : data.avg != undefined && data.avg == data.recommandGrade
                            ? 0
                            : 'X'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : null}
                </TableBody>
              </Table>
            </TurtlePaper>
          </Box>
        </>
      )}
      {chartViewFlag == true ? (
        <Box
          sx={(theme) => ({
            px: 5,
            width: '100%',
            paddingBottom: '40px',
            [theme.breakpoints.down('md')]: {
              px: 4,
            },
            [theme.breakpoints.down('sm')]: { px: 2 },
            [theme.breakpoints.down('xs')]: { px: 1 },
          })}
        >
          <TurtlePaper style={{ padding: '30px' }}>
            <Bar options={options} data={chartData} />
          </TurtlePaper>
        </Box>
      ) : null}
    </>
  )
}

const TurtleSelectBox = ({
  value,
  menuList,
  setValue,
  label,
  disabled,
}: {
  label: string
  value: any
  menuList?: string[]
  setValue: Setter<any>
  disabled?: boolean
}) => {
  const ref = useRef<HTMLElement>(null)

  return (
    <FormControl sx={{ minWidth: '200px' }} size="small">
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        ref={ref}
        disabled={disabled}
        labelId={label}
        value={value}
        label={label}
        onChange={(e) => {
          setValue(e.target.value)
        }}
      >
        {menuList?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default Step1

export const getGrade = (n: number, officerReviewData: any) => officerReviewData?.list[n]?.avgGrade ?? '-'

function getTable(tableData: any[] | null | undefined) {
  if (!tableData) return <></>

  const subjects = tableData?.map((x) => x.subjectName) ?? []
  const rows = []
  const addi = [
    ['essentialFlag', '조건'],
    ['isCheck', '이수현황'],
    ['essentialGrade', '권장등급'],
    ['myGrade', '내 등급'],
    ['riskScore', '위험도'],
  ]

  for (const [key, label] of addi) {
    const item: Record<string, any> = {
      과목: label,
    }
    for (let [i, field] of Object.entries(tableData.map((x) => x[key as keyof typeof x]))) {
      const itemKey = subjects[Number(i)] as string

      switch (label) {
        case '조건':
          field = Number(field)
          if (field === 1) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#1C4BC3' }}>
                장려
              </Typography>
            )
          } else if (field === 2) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#FF5600' }}>
                필수
              </Typography>
            )
          } else if (field === 0) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#252525' }}>
                무관
              </Typography>
            )
          } else {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: 'red' }}>
                -
              </Typography>
            )
          }
          break
        case '이수현황':
          field = Number(field)
          if (field === 2) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#1C4BC3' }}>
                무관
              </Typography>
            )
          } else if (field === 1) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#FF5600' }}>
                이수
              </Typography>
            )
          } else if (field === 0) {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: '#252525' }}>
                미이수
              </Typography>
            )
          } else {
            item[itemKey] = (
              <Typography variant="D5_Bold, 14, 18" sx={{ color: 'red' }}>
                -
              </Typography>
            )
          }
          break
        case '위험도':
          item[itemKey] = <RiskIndicator riskScore={field} height={20} />
          break
        default:
          item[subjects[Number(i)] as string] = <Typography>{field}</Typography>
          break
      }
    }
    rows.push(item)
  }
  subjects.unshift('과목')
  const columns = getDefaultTurtleTableColumnModel(subjects)
  const table = <BaseTable columns={columns} data={rows} enableSelection={false} tableStyle={baseTableStyle2} sx={{}} />
  return table
}

export type 학종_사정관평가데이터_형식 = {
  list: any[]
  mainAvgScoreList: number[]
  mainAvgScoreConversionList: string[]
  middleAvgScoreList: number[]
  middleAvgScoreConversionList: string[]
  commentList: string[]
  evaluation3TypesAvgGrade: null
  evaluation3TypesAvgScore: null
  evaluation4TypesAvgGrade: null
  evaluation4TypesAvgScore: null
  status: boolean
  message: string
  myAverageRating?: string
}

export const RiskExplainInfoBadge = () => {
  return (
    <InfoBadge
      tooltipContent={
        <Box>
          <Typography variant="H4_Exbold, 24, 32" color={'#252525'}>
            위험도는 합격예측 구간이에요.
          </Typography>
          <Typography mt={1} color={'#656565'} fontWeight={700} fontSize={'14px'} lineHeight={'18px'}>
            칸이 찰수록 안정적이에요.
          </Typography>
          <Stack mt={2} gap={'12px'}>
            {[
              ['안정', '안정권에 들어가는 수준이에요.'],
              ['적정', '성적에 맞는 적당한 수준이에요.'],
              ['소신', '위험요소가 있지만 지원할만 해요.'],
              ['도전', '가능성이 적어 위험할 수 있어요.'],
              ['위험', '성적보다 너무 높은 대학이에요.'],
            ].map(([label, explainText], index) => {
              const content = (
                <Stack direction={'row'} alignItems={'center'} key={index * 2}>
                  <RiskIndicator riskScore={5 - index} />
                  <Typography
                    color={getRiskColor(5 - index)}
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    fontWeight={800}
                    ml={'12px'}
                  >
                    {label}
                  </Typography>
                  <Typography variant="D6_bold, 12, 18" color={'#9A9A9A'} ml={'10px'}>
                    {explainText}
                  </Typography>
                </Stack>
              )
              const divider = <Box height={'1px'} bgcolor={'#E9E9E9'} key={index * 2 + 1}></Box>

              if (index === 0) {
                return content
              }
              return (
                <>
                  {divider}
                  {content}
                </>
              )
            })}
          </Stack>
        </Box>
      }
    />
  )
}
