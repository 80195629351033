import React from 'react'
import { IoMdSearch } from 'react-icons/io'

type Props = {
  onClick?: (e: React.MouseEvent) => void
}

const DetailButton = ({ onClick }: Props) => (
  <button
    style={{
      width: 30,
      backgroundColor: '#9a9a9a',
      padding: 5,
      borderRadius: 6,
      color: 'white',
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
    }}
    onClick={onClick}
  >
    <IoMdSearch fontSize={18} />
  </button>
)

export default DetailButton
