import { Box, SxProps, Tab, Tabs, Typography } from '@mui/material'
import { mainFontColor } from 'constants/style'
import React, { ReactNode, useState } from 'react'

export type TabInfo = {
  tabName: React.ReactNode
  tabContent: React.ReactNode
}

type Props<T extends { tabInfos?: TabInfo[] }> = {
  tabIndexState?: [number, React.Dispatch<React.SetStateAction<number>>]
  headerFontSize?: number | string
  sx?: SxProps
} & T

const TurtleTabs = <T extends { tabInfos?: TabInfo[] }>({
  tabIndexState,
  headerFontSize: tabLabelFontSize,
  sx = {},
  tabInfos, // tabContent,
}: Props<T> & (T extends { tabInfos: TabInfo[] } ? {} : { tabContent: ReactNode })) => {
  let [tabIndex, setTabIndex] = useState(0)

  tabInfos = tabInfos ?? []
  tabIndex = tabIndexState ? tabIndexState[0] : tabIndex
  setTabIndex = tabIndexState ? tabIndexState[1] : setTabIndex

  const onTabChange = (event: React.SyntheticEvent, nextTabIndex: number) => {
    setTabIndex(nextTabIndex)
  }

  let tabButtons = tabInfos.map(({ tabName }, index) => (
    <Tab label={tabName} id={`tab-${index}`} key={index} aria-controls={`tabpanel-${index}`} />
  ))

  const tabHeader = (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={tabIndex}
        onChange={onTabChange}
        aria-label="basic tabs example"
        sx={{
          '& button.Mui-selected': {
            fontWeight: 700,
            fontSize: tabLabelFontSize ?? 16,
          },
          '& .MuiTabs-indicator': {
            // backgroundColor: 'black',
          },
        }}
      >
        {tabButtons}
      </Tabs>
    </Box>
  )

  const tabContents = tabInfos.map(({ tabContent }, index) => (
    <TabPanel value={tabIndex} index={index} key={index}>
      <Box className="tab-contents-wrapper">{tabContent}</Box>
    </TabPanel>
  ))

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {tabHeader}
      {tabContents}
    </Box>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

export default TurtleTabs
