import { Box, Button, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import TurtleTabs from 'components/common/TurtleTabs'
import TitleSection from 'components/layout/TitleSection'
import { mainColor } from 'constants/style'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Setter } from 'types/utilType'
import K_관심대학_교과탭 from './K_관심대학_교과탭'
import K_관심대학_논술탭 from './K_관심대학_논술탭'
import K_관심대학_학종탭 from './K_관심대학_학종탭'
import './style.scss'
import { AiOutlinePlus } from 'react-icons/ai'

type NumTotalRowMap = {
  교과: number
  학종: number
  논술: number
}

const defaultNumRowMapState = { 교과: 0, 논술: 0, 학종: 0 }

export const 관심대학페이지_컨텍스트 = React.createContext<{
  numTotalRowMap: NumTotalRowMap
  setNumTotalRowMap: Setter<NumTotalRowMap>
}>({
  numTotalRowMap: defaultNumRowMapState,
  setNumTotalRowMap: () => {},
})

const InterestUniv = () => {
  const [numTotalRowMap, setNumTotalRowMap] = useState<NumTotalRowMap>(defaultNumRowMapState)

  const numTotalRow = numTotalRowMap['교과'] + numTotalRowMap['학종'] + numTotalRowMap['논술']

  const minimumRequire = 6

  return (
    <관심대학페이지_컨텍스트.Provider value={{ numTotalRowMap, setNumTotalRowMap }}>
      <TitleSection title="관심대학">
        <Box mt={'28px'}>
          <TurtleTabs
            tabInfos={[
              {
                tabName: <Typography variant="H8_bold, 20, 28">교과</Typography>,
                tabContent: <K_관심대학_교과탭 />,
              },
              {
                tabName: <Typography variant="H8_bold, 20, 28">학종</Typography>,
                tabContent: <K_관심대학_학종탭 />,
              },
              {
                tabName: <Typography variant="H8_bold, 20, 28">논술</Typography>,
                tabContent: <K_관심대학_논술탭 />,
              },
            ]}
          />
          <Stack mt={'41px'} alignItems={'center'}>
            <Link to="/suSi/strategy/combination">
              <Button
                hidden={numTotalRow < minimumRequire}
                style={{
                  backgroundColor: mainColor,
                  color: '#fff',
                  paddingLeft: 10,
                  paddingRight: 10,
                  width: '228px',
                  height: '48px',
                }}
              >
                <Typography variant="D3_bold, 16, 24">조합 생성하러 가기</Typography>
                <AiOutlinePlus fontSize={22} style={{ marginLeft: 4 }} />
              </Button>
            </Link>
          </Stack>
        </Box>
      </TitleSection>
    </관심대학페이지_컨텍스트.Provider>
  )
}

export default InterestUniv
