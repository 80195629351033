import { ButtonBase } from '@mui/material'
import { Stack, SxProps } from '@mui/system'
import { MouseEventHandler, useContext } from 'react'
import { StepPagesContext } from './StepPagesContext'
type Props = {
  sx?: SxProps
  onNextClick?: MouseEventHandler
  onPrevClick?: MouseEventHandler
  isNextButtonEnabled?: boolean
  isPrevButtonEnabled?: boolean
}

const StepMoveButtonGroup = ({
  sx,
  onNextClick,
  onPrevClick,
  isNextButtonEnabled = true,
  isPrevButtonEnabled = true,
}: Props) => {
  const { step, numSteps, stepState, goPrevStep, goNextStep, initializeStepState } = useContext(StepPagesContext)

  const onPrevButtonClick = (e: React.MouseEvent) => {
    if (onPrevClick) {
      onPrevClick(e)
    } else {
      initializeStepState()
      goPrevStep()
    }
  }

  const onNextButtonClick = (e: React.MouseEvent) => {
    const callback = onNextClick
    if (callback) {
      callback(e)
    } else {
      goNextStep()
    }
  }
  const blueColor = '#1C4BC3'
  const borderColor = `2px solid ${blueColor}`
  const disabledBorder = '2px solid #D9D9D9'

  const normalStyle: SxProps = {
    alignSelf: 'center',
    padding: '10px 20px',
    borderRadius: 20,
    border: borderColor,
    fontWeight: 700,
    color: blueColor,
    transitionDuration: '0.15s',
    height: '48px',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: blueColor,
      opacity: 0.92,
    },
  }

  const disabledStyle = {
    alignSelf: 'center',
    padding: '10px 20px',
    borderRadius: 20,
    height: '48px',
    border: disabledBorder,
    color: '#D9D9D9',
  }

  return (
    <Stack direction="column" justifyContent={'center'} alignItems={'center'} mt={4}>
      <Stack direction={'row'} justifyContent={'center'} gap={2} sx={sx} mb={4}>
        {step > 1 && (
          <ButtonBase
            disabled={!isPrevButtonEnabled ?? false}
            disableRipple
            onClick={onPrevButtonClick}
            sx={isPrevButtonEnabled ? normalStyle : disabledStyle}
          >
            이전 단계
          </ButtonBase>
        )}
        <ButtonBase
          disabled={!isNextButtonEnabled}
          disableRipple
          onClick={onNextButtonClick}
          sx={isNextButtonEnabled ? normalStyle : disabledStyle}
        >
          {step === numSteps ? '관심대학 저장' : '다음 단계'}
        </ButtonBase>
      </Stack>
    </Stack>
  )
}

export default StepMoveButtonGroup
