import { useContext } from 'react'

import { K_상세보기_타대학_동일계열차트공통 } from 'DetailModal/common'
import { SubjectDetailModalContext } from './K_상세보기_교과모달_내용'

const K_상세보기_타대학_동일계열 = () => {
  const { earlydSubjectOtherCollegeSameRowSeriesList: data, recruitmentUnitInfo } =
    useContext(SubjectDetailModalContext)
  if (!data) return null

  console.log(data)

  const chartData = data
    .filter((x) => !isNaN(Number(x.cut50)))
    .map((x) => ({
      grade: Number(Number(x.cut50).toFixed(2)),
      collegeName: x.collegeName,
      major: x.recruitmentUnitIds,
    }))

  return <K_상세보기_타대학_동일계열차트공통 data={chartData} grade={recruitmentUnitInfo.myAverageRating} />
}

export default K_상세보기_타대학_동일계열
