import ContentsBox from 'components/block/contentsBox'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import './style.css'
import { Box, CardMedia, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import LogoImg from 'assets/images/logo.png'
import SeriesComponent from 'components/pages/suSi/components/series'
import Grid from '@mui/material/Grid'
import ProductItem from '../../../store/product/productitem'
import CloseIcon from '@mui/icons-material/Close'
import { getProfileInfoFetch, saveProfileUpdateFetch } from '../../../../../api/fetches/officer'

interface series {
  grandSeries: string
  middleSeries: string
  rowSeries: string
}
;[]

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget
}
const ProFile = () => {
  const [seriesItem, setSeriesItem] = React.useState<series[]>([])
  const imageInput = useRef() as React.MutableRefObject<HTMLInputElement>
  const [image, setImage] = useState<undefined | string>()
  const [name, setName] = useState<string>()
  const [university, setUniversity] = useState<string>()
  const [education, setEducation] = useState<string>()
  const [imgFile, setImgFile] = useState<HTMLInputElement>()

  useEffect(() => {
    getProfileInfo()
  }, [])

  const getProfileInfo = async () => {
    const _myProfileInfo = await getProfileInfoFetch()
    console.log(_myProfileInfo)
    setName(_myProfileInfo.officerName)
    setImage(_myProfileInfo.officerProfileImage)
    setUniversity(_myProfileInfo.university)
    setEducation(_myProfileInfo.education)
    setSeriesItem(_myProfileInfo.seriesList)
  }

  const addSeries = (series: any) => {
    //해당 series가존재하면 에러
    const checkArray = seriesItem.filter((item) => {
      return item.rowSeries === series.rowSeries
    })
    if (checkArray.length > 0) {
      alert('중복된 리스트가 있습니다.')
      return
    }

    if (series.rowSeries == '소분류') {
      return
    }
    console.log('asdasd se', series)
    setSeriesItem([...seriesItem, series])
  }

  const removeSeries = (key: any) => {
    setSeriesItem(
      seriesItem.filter((item) => {
        return item.rowSeries !== key
      })
    )
  }

  const handleImage = () => {
    console.log(imageInput)
    imageInput.current.click()
  }

  const handleImageData = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const fileReader = new FileReader()
      let file = event.target?.files[0]
      setImgFile(event.target)
      uploadImage(file)
    }

    //setImage(file);
  }

  const uploadImage = async (file: any) => {
    const base64 = await convertBase64(file)
    setImage(base64)
  }

  const convertBase64 = (file: any): any => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }
  /**
   * 프로파일 저장
   */
  const saveProfile = async () => {
    if (imgFile?.files) {
      const files = (imgFile?.files as FileList)[0]
      if (files != undefined) {
        const _data = await saveProfileUpdateFetch(name, university, education, seriesItem, files)
        if (_data.status) {
          alert('저장되었습니다')
        } else {
          alert('저장에 실패했습니다')
        }
      }
    } else {
      const _data = await saveProfileUpdateFetch(name, university, education, seriesItem, null)
      if (_data.status) {
        alert('저장되었습니다')
      } else {
        alert('저장에 실패했습니다')
      }
    }
  }

  return (
    <ContentsBox id="saJungDan">
      <div className="officerProfileContainer">
        <span className="officerProfileTitle">프로필 관리</span>
        <Paper className="officerProfilePage">
          <div className="officerProfileImage">
            <img src={image} onClick={handleImage}></img>
            <input
              type="file"
              id="my_file"
              onChange={(event) => handleImageData(event)}
              ref={imageInput}
              className="inputHidden"
            />
          </div>
          <div className="officerProfileWrap">
            <div className="officerInputWrap">
              <label className="officerLabel">성함</label>
              <input className="officerInput" onChange={(event) => setName(event.target.value)} value={name}></input>
            </div>
            <div className="officerInputWrap">
              <label className="officerLabel">출신대학</label>
              <input
                className="officerInput"
                onChange={(event) => setUniversity(event.target.value)}
                value={university}
              ></input>
            </div>
            <div className="officerInputWrap">
              <label className="officerLabel">학과 / 학력</label>
              <input
                className="officerInput"
                onChange={(event) => setEducation(event.target.value)}
                value={education}
              ></input>
            </div>
            <div className="officerInputWrap">
              <label className="officerLabel">계열선택</label>
              <SeriesComponent callbackFunc={addSeries} type={0} />
            </div>
            <div className="officerInputWrap2">
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {seriesItem.map((item: any, index: number) => {
                  return (
                    <Grid item xs={2} sm={4} md={6} key={item.rowSeries}>
                      <div className="seriesItem">
                        <span className="seriesSpan">
                          {item.grandSeries + '>' + item.middleSeries + '>' + item.rowSeries}
                        </span>
                        <div className="seriesIcon">
                          <CloseIcon onClick={() => removeSeries(item.rowSeries)} />
                        </div>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          </div>
        </Paper>
        <div className="officerProfileButtonWrap">
          <button className="officerProfileButton" onClick={() => saveProfile()}>
            저장
          </button>
        </div>
      </div>
    </ContentsBox>
  )
}

export default ProFile
