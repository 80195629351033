import { useEffect, useState } from 'react'
import './style.scss'
import {
  essayAbleInternalListFetch,
  essayAbleNaturalMathListFetch,
  essayAbleNaturalScienceListFetch,
  essayAddInterestFetch,
  essayAllListFetch,
  essayLowestGradeListFetch,
  essayOtherListFetch,
  liGwaMathAbleUniv,
  lowestGradeOfDepartment,
  otherDepartment,
  scienceAbleDepartment,
} from 'api/fetches/essay'
// import { IoIosArrowForward } from "react-icons/io";
import SuriNonsulSelectFilter from '../component/filter/suriNonsulSelectFilter'
import _ from 'lodash'
import OtherNonsulTypeTable from '../component/table/otherNonsulTypeTable'
import ContentsBox from 'components/block/contentsBox'
import MathableTable from '../component/table/mathAbletable'
import NaesinAdvantageDepartmentTable from '../component/table/naesinAdvantageDepartmentTable'
import InputNaesinFilter from '../component/filter/inputNaesinFilter'
import LowestGradeOfUnivListTable from '../component/table/lowestGradeOfUnivTable'
import SelectDepartmentTable from '../component/table/selectDepartmentTable'
import ApplyDateTable from '../component/table/applyDateTable'
import { mockCheckInputFetch } from 'api/fetches/mock'
import { useNavigate } from 'react-router-dom'
import ScienceFilter from '../component/filter/scienceFilter'
import ScienceAbleTable from '../component/table/scienceAbleTable'
import { useCheckUserInputGrade } from 'hooks/useCheckUserInputGrade'
import TableSkeleton from 'components/block/skeletons/tableSkeleton'
import Steps from 'components/block/Steps'
import TitleSection from 'components/layout/TitleSection'
import StepIndicator from 'components/common/StepIndicator'

const stepList = [
  { label: '수학가능과목' },
  { label: '과학가능과목' },
  { label: '약식 논술' },
  { label: '내신 유불리' },
  { label: '최저 등급 확인' },
  { label: '모집단위 선택' },
  { label: '전형 일자 확인' },
]

interface univInfo {
  collegeCode: string
  collegeName: string
}

const NonSulLiGwa = () => {
  const [departmentList, setDepartmentList] = useState<number[]>([])
  const navigate = useNavigate()
  const { scheduleId } = useCheckUserInputGrade()

  const [step, setStep] = useState(0)
  const [suriNonsulFilter, setSuriNonsulFilter] = useState<string[]>(['ES2'])
  const [scienceFilter, setScienceFilter] = useState<string[]>([])

  // 수학가능과목
  const [mathAbleUnivList, setMathAbleUnivList] = useState<liGwaMathAbleUniv[]>([])
  const [selectedMathAbleUnivList, setSelectedMathAbleUnivList] = useState<string[]>([])

  // 과학가능과목
  const [scienceAbleUnivList, setScienceAbleUnivList] = useState<{ collegeCode: string; collegeName: string }[]>([])
  const [scienceAbleDepartmentList, setScienceAbleDepartmentList] = useState<scienceAbleDepartment[]>([])
  const [selectedScienceDepartmentList, setSelectedScienceDepartmentList] = useState<number[]>([])

  // 그 외 논술 전형
  const [otherUnivList, setOtherUnviList] = useState<{ collegeCode: string; collegeName: string }[]>([])
  const [otherDepartmentList, setOtherDepartmentList] = useState<otherDepartment[]>([])
  const [selectedOtherDepartmentIdList, setSelectedOtherDepartmentIdList] = useState<number[]>([])

  const [nowTableType, setNowTableType] = useState<'recommend' | 'notRecommend'>('recommend')
  const [openUnivList, setOpenUnivList] = useState<string[]>([])

  // 내신 유불리
  const [naesinAdvantageUnivList, setNaesingAdvantageUnivList] = useState<any[]>([])
  const [selectedNaesinAdvantageUnivIdList, setSelectedNaesinAdvantageUnivIdList] = useState<string[]>([])
  const [naesinFilter, setNasesinFilter] = useState(3)

  // 최저등급확인
  const [lowestGradeOfUnivList, setLowestGradeOfUnivList] = useState<{ collegeCode: string; collegeName: string }[]>([])
  const [lowestGradeOfDepartmentList, setLowestGradeOfDepartmentList] = useState<lowestGradeOfDepartment[]>([])
  const [checkedLowestUnivList, setCheckedLowestUnivList] = useState<univInfo[]>([])
  const [checkedLowestRateDepartmentList, setCheckedLowestRateDepartmentList] = useState<lowestGradeOfDepartment[]>([])

  const [applyDateUnivList, setapplyDateUnivList] = useState<univInfo[]>([])
  const [applyDateDepartmentList, setapplyDateDepartmentList] = useState<lowestGradeOfDepartment[]>([])

  // 학과선택 체크
  const [interestDepartmentList, setInterestDepartmentList] = useState<number[]>([])

  const [isFetching, setIsFetching] = useState<boolean>(false)

  useEffect(() => {
    if (step === 0) {
      getAllDepartmentList()
      getOtherUnviListFetch()
    } else if (step === 1) {
      getScienceAbleList()
    } else if (step === 3) {
      getEssayAbleInteralList()
    } else if (step === 4) {
      if (selectedNaesinAdvantageUnivIdList.length === 0) {
        alert('대학을 선택해주세요.')
        setStep((prev) => prev - 1)
      } else {
        checkLowestGrade()
      }
    } else if (step === 5) {
      if (checkedLowestUnivList.length === 0) {
        alert('대학을 선택해주세요.')
        setStep((prev) => prev - 1)
      }
      console.log('-')
    } else if (step === 6) {
      if (applyDateUnivList.length === 0) {
        alert('학과를 선택해주세요.')
        setStep((prev) => prev - 1)
      }
    }
  }, [step])

  /**
   * 내신유불리에서 내신 등급이 변경될경우
   */
  useEffect(() => {
    const result = naesinAdvantageUnivList.sort((a: any, b: any): any => {
      return a['levelGrade' + naesinFilter] - b['levelGrade' + naesinFilter]
    })

    setNaesingAdvantageUnivList([...result])
  }, [naesinFilter])

  useEffect(() => {
    getMathAbleList()
  }, [suriNonsulFilter])

  const getOtherUnviListFetch = async () => {
    setIsFetching(true)
    const res = await essayOtherListFetch('R1')

    const _univList = _.unionBy(
      res.list.map((department) => {
        return { collegeCode: department.collegeCode, collegeName: department.collegeName }
      }),
      'collegeCode'
    )

    setOtherUnviList(_univList)
    setOtherDepartmentList(res.list)
    setIsFetching(false)
  }

  /**
   * 내신 유불리 데이터 가져오기
   * 필터링된 모집단위 값이 필요하다
   */
  const getEssayAbleInteralList = async () => {
    setIsFetching(true)
    const selectRecruitmentUnitList = getFilteredDepartmentList()
    console.log('passedDepartmentList : ', selectRecruitmentUnitList)
    const res = await essayAbleInternalListFetch({ admissionSeriesCode: 'R1', selectRecruitmentUnitList })

    const _res: any = {}

    // res.list.forEach((department) => {
    //   if (department.gradeType === '1000점 환산점수') {
    //     _res[department.collegeCode] = {
    //       ...department,
    //     }
    //   } else {
    //     _res[department.collegeCode] = {
    //       ..._res[department.collegeCode],
    //       levelGrade1: department.grade1,
    //       levelGrade2: department.grade2,
    //       levelGrade3: department.grade3,
    //       levelGrade4: department.grade4,
    //       levelGrade5: department.grade5,
    //       levelGrade6: department.grade6,
    //       levelGrade7: department.grade7,
    //       levelGrade8: department.grade8,
    //       levelGrade9: department.grade9,
    //     }
    //   }
    // })
    res.list.forEach((department) => {
      _res[department.collegeCode] = {
        ..._res[department.collegeCode],
        collegeName: department.collegeName,
        collegeCode: department.collegeCode,
        levelGrade1: department.grade1,
        levelGrade2: department.grade2,
        levelGrade3: department.grade3,
        levelGrade4: department.grade4,
        levelGrade5: department.grade5,
        levelGrade6: department.grade6,
        levelGrade7: department.grade7,
        levelGrade8: department.grade8,
        levelGrade9: department.grade9,
      }
    })
    const advantageDepartmet = Object.values(_res)

    setNaesingAdvantageUnivList(advantageDepartmet)
    setIsFetching(false)
  }

  /**
   * 과학가능과목 가져오기
   */
  const getScienceAbleList = async () => {
    try {
      setIsFetching(true)
      console.log('과학가능과목 가져오기 리스트 : ')
      if (selectedMathAbleUnivList.length > 0) {
        console.log('최소조건 통과')
        const res = await essayAbleNaturalScienceListFetch('R1', selectedMathAbleUnivList)
        console.log('과학가능과목 가져오기 리스트 : ', res)
        const scienceList = res.sciencelist

        const _univList =
          scienceList !== null
            ? _.unionBy(
                scienceList.map((science) => {
                  return { collegeCode: science.collegeCode, collegeName: science.collegeName }
                }),
                'collegeCode'
              )
            : []
        //데이터가 있을경우 첫 리스트를 오픈한다
        // if(_univList.length!==0){
        //   const temp:any = _univList[0]?.collegeCode;
        //   setOpenUnivList(temp);
        // }
        setScienceAbleUnivList(_univList)
        setScienceAbleDepartmentList(scienceList)
        setScienceAbleDepartmentList(res.sciencelist)
        setIsFetching(false)
      } else {
        alert('최소 하나 이상의 대학을 선택해주세요.')
        setStep(step - 1)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getAllDepartmentList = async () => {
    const codeList = await essayAllListFetch('R1')
    console.log('codeList  ', codeList)
    setDepartmentList(codeList)
  }

  /**
   *  첫번째 스텝 수학강능과목 리스트
   * */
  const getMathAbleList = async () => {
    if (suriNonsulFilter.length > 0) {
      const { list, status } = await essayAbleNaturalMathListFetch(suriNonsulFilter, 'R1')
      if (status) {
        console.log('--------------------------------------')
        console.log(list)
        setMathAbleUnivList(list)
      }
    }
  }

  const onClickNonsulFilterButton = (type: string) => {
    switch (step) {
      case 0: {
        if (type === '') {
          suriNonsulFilter.length === 4 ? setSuriNonsulFilter([]) : setSuriNonsulFilter(['ES2', 'ES3', 'ES4', 'ES4'])
        } else {
          let _suriNonsulFilter = [...suriNonsulFilter]
          if (_suriNonsulFilter.includes(type)) {
            _suriNonsulFilter = _suriNonsulFilter.filter((filter) => filter !== type)
          } else {
            _suriNonsulFilter.push(type)
          }
          setSuriNonsulFilter(_suriNonsulFilter)
        }
        break
      }
      case 1: {
        if (type === '') {
          suriNonsulFilter.length === 9
            ? setScienceFilter([])
            : setScienceFilter(['mulOne', 'hwaOne', 'saengOne', 'jiOne', 'totalScience', 'mul', 'hwa', 'saeng', 'ji'])
        } else {
          let _scienceFilter = [...scienceFilter]
          if (_scienceFilter.includes(type)) {
            _scienceFilter = _scienceFilter.filter((filter) => filter !== type)
          } else {
            _scienceFilter.push(type)
          }
          console.log('__scienceFilter : ', _scienceFilter)
          setScienceFilter(_scienceFilter)
        }
        break
      }
      default:
        break
    }
  }

  const onClickStep = (isNext: boolean) => {
    if (isNext) {
      if (step < 6) {
        setStep((prev) => prev + 1)
      } else {
        saveInterestDepartment()
      }
    } else {
      step !== 0 && setStep((prev) => prev - 1)
    }
  }

  const saveInterestDepartment = async () => {
    const res = await essayAddInterestFetch(interestDepartmentList)

    console.log('res : ', res)
    if (res.status) {
      alert('관심대학에 저장되었습니다.')
      navigate('/suSi/strategy/interestUniv')
    }
  }

  const onClickTableType = (tableType: 'recommend' | 'notRecommend') => setNowTableType(tableType)

  /**
   * 대학 체크박스 클릭 이벤트
   * @param event
   * @param id 모집단위 아이디
   */
  const onClickUnivCheckBox = (univCode: string) => {
    switch (step) {
      case 0: {
        let _selectedMathAbleUnivList = [...selectedMathAbleUnivList]

        if (_selectedMathAbleUnivList.includes(univCode)) {
          _selectedMathAbleUnivList = _selectedMathAbleUnivList.filter((_univCode) => _univCode !== univCode)
        } else {
          _selectedMathAbleUnivList.push(univCode)
        }

        setSelectedMathAbleUnivList(_selectedMathAbleUnivList)
        break
      }
      case 3: {
        let _selectedNaesinAdvantageUnivIdList = [...selectedNaesinAdvantageUnivIdList]

        if (_selectedNaesinAdvantageUnivIdList.includes(univCode)) {
          _selectedNaesinAdvantageUnivIdList = _selectedNaesinAdvantageUnivIdList.filter(
            (_univCode) => _univCode !== univCode
          )
        } else {
          _selectedNaesinAdvantageUnivIdList.push(univCode)
        }

        setSelectedNaesinAdvantageUnivIdList(_selectedNaesinAdvantageUnivIdList)
        break
      }
      default:
        break
    }
  }

  /**
   * 대학 체크박스 전체선택 클릭 이벤트
   * @param isTotal 전체선택인지 해제인지
   * @param univCodeList 모집단위 아이디
   */
  const onClickUnivTotalCheckBox = (isTotal: boolean, univCodeList: string[]) => {
    switch (step) {
      case 0: {
        console.log('isTotal : ', isTotal)
        let _selectedMathAbleUnivList = [...selectedMathAbleUnivList]
        if (isTotal) {
          _selectedMathAbleUnivList = [..._selectedMathAbleUnivList, ...univCodeList]
        } else {
          console.log('제거해')
          _selectedMathAbleUnivList = _selectedMathAbleUnivList.filter((id) => !univCodeList.includes(id))
        }

        setSelectedMathAbleUnivList(_selectedMathAbleUnivList)
        break
      }

      case 3: {
        isTotal ? setSelectedNaesinAdvantageUnivIdList(univCodeList) : setSelectedNaesinAdvantageUnivIdList([])

        break
      }
      default:
        break
    }
  }

  /**
   * 모집단위 체크박스 클릭 이벤트
   * @param event
   * @param id 모집단위 아이디
   */
  const onClickDepartmentCheckBox = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    // 과학선택과목
    if (step === 1) {
      let _selectedScienceDepartmentList = [...selectedScienceDepartmentList]

      if (event.target.checked) {
        _selectedScienceDepartmentList.push(id)
      } else {
        _selectedScienceDepartmentList = _selectedScienceDepartmentList.filter((_id) => _id !== id)
      }

      setSelectedScienceDepartmentList(_selectedScienceDepartmentList)
    }

    // 이외 과목
    if (step === 2) {
      let _selectedOtherDepartmentIdList = [...selectedOtherDepartmentIdList]

      if (event.target.checked) {
        _selectedOtherDepartmentIdList.push(id)
      } else {
        _selectedOtherDepartmentIdList = _selectedOtherDepartmentIdList.filter((_id) => _id !== id)
      }

      setSelectedOtherDepartmentIdList(_selectedOtherDepartmentIdList)
    }
  }

  /**
   * 모집단위 체크박스 클릭 이벤트
   * @param event
   * @param id 모집단위 아이디
   */
  const onClickTotalDepartmentCheckBox = (isTotal: boolean, selectedIdList: number[]) => {
    if (step === 1) {
      let _selectedScienceDepartmentList = [...selectedScienceDepartmentList]

      if (isTotal) {
        _selectedScienceDepartmentList = [..._selectedScienceDepartmentList, ...selectedIdList]
      } else {
        _selectedScienceDepartmentList = _selectedScienceDepartmentList.filter(
          (id) => !_selectedScienceDepartmentList.includes(id)
        )
      }
      setSelectedScienceDepartmentList(_selectedScienceDepartmentList)
    }

    if (step === 2) {
      let _selectedOtherDepartmentIdList = [...selectedOtherDepartmentIdList]

      if (isTotal) {
        _selectedOtherDepartmentIdList = [..._selectedOtherDepartmentIdList, ...selectedIdList]
      } else {
        _selectedOtherDepartmentIdList = _selectedOtherDepartmentIdList.filter(
          (id) => !_selectedOtherDepartmentIdList.includes(id)
        )
      }
      setSelectedOtherDepartmentIdList(_selectedOtherDepartmentIdList)
    }
  }

  /**
   * 학과 선택 탭에서 학과를 선택하는 경우
   * @param lowestGradeOfDepartment
   */
  const onClickDateDepartmentCheckBox = (lowestGradeOfDepartment: lowestGradeOfDepartment) => {
    let _applyDateDepartmentList = [...applyDateDepartmentList]
    const checkTheDateDepartmentIdList = applyDateDepartmentList.map((department) => department.id)

    if (checkTheDateDepartmentIdList.includes(lowestGradeOfDepartment.id)) {
      _applyDateDepartmentList = _applyDateDepartmentList.filter(
        (department) => department.id !== lowestGradeOfDepartment.id
      )
    } else {
      _applyDateDepartmentList.push(lowestGradeOfDepartment)
    }

    const _univList = _.unionBy(
      _applyDateDepartmentList.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName }
      }),
      'collegeCode'
    )

    setapplyDateUnivList(_univList)
    setapplyDateDepartmentList(_applyDateDepartmentList)
  }

  const onClickDateDepartmentTotalCheckBox = (
    isTotal: boolean,
    lowestGradeDepartmentList: lowestGradeOfDepartment[]
  ) => {
    let _applyDateDepartmentList = [...applyDateDepartmentList]
    const checkTheDateDepartmentIdList = applyDateDepartmentList.map((department) => department.id)

    if (isTotal) {
      _applyDateDepartmentList = _.uniqBy([..._applyDateDepartmentList, ...lowestGradeDepartmentList], 'id')
    } else {
      const selectedIdList = lowestGradeDepartmentList.map((department) => department.id)
      _applyDateDepartmentList = _applyDateDepartmentList.filter(
        (department) => !checkTheDateDepartmentIdList.includes(department.id)
      )
    }
    const _univList = _.unionBy(
      _applyDateDepartmentList.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName }
      }),
      'collegeCode'
    )

    setapplyDateUnivList(_univList)
    setapplyDateDepartmentList(_applyDateDepartmentList)
  }

  const getFilteredDepartmentList = () => {
    let unSelectedIdList: number[] = []

    for (let i = 0; i <= step; i++) {
      unSelectedIdList = [...unSelectedIdList, ...getUnSelectedDepartmentIdList(i)]
    }

    return getSelectedDepartmentList(unSelectedIdList)
  }

  const getUnSelectedDepartmentIdList = (step: number) => {
    if (step === 0) {
      return []
    } else if (step === 1) {
      return scienceAbleDepartmentList !== null
        ? scienceAbleDepartmentList
            .filter((department) => !selectedScienceDepartmentList.includes(department.id))
            .map((department) => department.id)
        : []
    } else if (step === 2) {
      return otherDepartmentList !== null
        ? otherDepartmentList
            .filter((department) => !selectedOtherDepartmentIdList.includes(department.id))
            .map((department) => department.id)
        : []
    } else if (step === 4) {
      return []
    }
    return []
  }

  const getSelectedDepartmentList = (unSelectedIdList: number[]) => {
    return departmentList.filter((univCode) => !unSelectedIdList.includes(univCode))
  }

  const onClickLowestRateDepartmentCheckBox = (lowestGradeOfDepartment: lowestGradeOfDepartment) => {
    console.log('최저에서 고른 대학 : ', lowestGradeOfDepartment)

    let _finalChoiceDepartment = [...checkedLowestRateDepartmentList]
    const finalDepartmentIdList = checkedLowestRateDepartmentList.map((department) => department.id)

    if (finalDepartmentIdList.includes(lowestGradeOfDepartment.id)) {
      _finalChoiceDepartment = _finalChoiceDepartment.filter(
        (department) => department.id !== lowestGradeOfDepartment.id
      )
    } else {
      _finalChoiceDepartment.push(lowestGradeOfDepartment)
    }

    const _univList = _.unionBy(
      _finalChoiceDepartment.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName }
      }),
      'collegeCode'
    )
    console.log(_univList)
    console.log(_finalChoiceDepartment)
    setCheckedLowestUnivList(_univList)
    setCheckedLowestRateDepartmentList(_finalChoiceDepartment)
  }

  const onClickLowestRateTotalCheckBox = (isTotal: boolean, lowestGradeDepartmentList: lowestGradeOfDepartment[]) => {
    let _checkedLowestRateDepartmentList = [...checkedLowestRateDepartmentList]
    if (isTotal) {
      _checkedLowestRateDepartmentList = _.uniqBy(
        [..._checkedLowestRateDepartmentList, ...lowestGradeDepartmentList],
        'id'
      )
    } else {
      const selectedIdList = lowestGradeDepartmentList.map((department) => department.id)
      _checkedLowestRateDepartmentList = _checkedLowestRateDepartmentList.filter(
        (department) => !selectedIdList.includes(department.id)
      )
    }
    const _univList = _.unionBy(
      _checkedLowestRateDepartmentList.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName }
      }),
      'collegeCode'
    )

    setCheckedLowestUnivList(_univList)
    setCheckedLowestRateDepartmentList(_checkedLowestRateDepartmentList)
  }

  /**
   * 이과 논술 최저등급 확인
   */
  const checkLowestGrade = async () => {
    if (typeof scheduleId === 'number') {
      setIsFetching(true)
      const lowestGrade = await essayLowestGradeListFetch({
        admissionSeriesCode: 'R1',
        scheduleId,
        selectRecruitmentUnitList: getFilteredDepartmentList(),
        selectUniversityList: [...selectedNaesinAdvantageUnivIdList],
      })

      console.log('lowestGrade : ', lowestGrade)

      if (lowestGrade.status) {
        const _univList = _.unionBy(
          lowestGrade.list.map((department) => {
            return { collegeCode: department.collegeCode, collegeName: department.collegeName }
          }),
          'collegeCode'
        )
        setLowestGradeOfUnivList(_univList)
        setLowestGradeOfDepartmentList(lowestGrade.list)
      }
      setIsFetching(false)
    }
  }

  const onClickInterestDepartmnet = (departmentId: number) => {
    let _interestDepartment = [...interestDepartmentList]

    if (_interestDepartment.includes(departmentId)) {
      _interestDepartment = _interestDepartment.filter((department) => department !== departmentId)
    } else {
      _interestDepartment.push(departmentId)
    }

    setInterestDepartmentList(_interestDepartment)
  }

  const onClickUnivRow = (univCode: string) => {
    console.log('univCode : ', univCode)
    let _openUnivList = [...openUnivList]

    if (_openUnivList.includes(univCode)) {
      _openUnivList = _openUnivList.filter((_univCode) => _univCode !== univCode)
    } else {
      _openUnivList.push(univCode)
    }

    setOpenUnivList(_openUnivList)
  }

  return (
    <TitleSection title="이과논술">
      <div id="liGwaNonSul">
        <StepIndicator infos={stepList} currentStep={step + 1} />

        {[
          '* 수리논술의 범위를 확인하고 선택하는 페이지입니다.  의대, 치대, 약대, 수의대 등은 이과논술이 아닌, \n   의치한약수 논술에서 별도로 다룹니다. 아울러, 단답식 약식논술 또한, 다다음 단계에서 별도로 다룹니다. ',
          '* 이과논술에서 과학을 보는 대학은 연대와 서울여대만 남았습니다. 연대는 모집단위별로 과학논술과목이 지정이며, \n   서울여대는 수리논술 없이 공통과학 한과목만 출제합니다.',
          '* 수리논술을 보지 않고, 국어, 수학 과목을 단답식으로 시험보는 대학들입니다. ',
          '* 논술 전형에서 내신의 실질 비중은 크지 않습니다. 하지만, 대학마다 어느 등급부터는 격차가 너무 커져서 논술시험만으로 커버가 어려운 등급이 존재하기도 하고, \n   또 같은 등급이라도 대학마다 감점은 천차만별입니다. 나의 내신등급을 입력하면, 나에게 가장 유리한 대학순으로 배열되니 참고하세요. ',
          '',
          '',
          '* 아직 전형 시간은 발표하지 않은 대학들이 있습니다. 바로 업데이트 하겠습니다. 겹치는 날이 없는지 꼭 확인하세요.\n   그리고, 교과와 학종까지 지원했다면, 전형일 확인은 지원과 전략 페이지에서 확인 가능합니다.',
        ].map((guide, index) => {
          return step === index && guide !== '' ? (
              <div key={guide} className="guide">
                <p>{guide}</p>
              </div>
          ) : (
              <></>
          )
        })}

        {step === 0 && (
          <SuriNonsulSelectFilter
            suriNonsulFilter={suriNonsulFilter}
            onClickNonsulFilterButton={onClickNonsulFilterButton}
          />
        )}
        {step === 1 && <ScienceFilter scienceFilter={scienceFilter} onClickFilterButton={onClickNonsulFilterButton} />}

        {step === 3 && <InputNaesinFilter onChangeNaesin={(naesin) => setNasesinFilter(naesin)} />}
        {/* ------------------------------------   table   ------------------------------------ */}
        {isFetching ? (
          <TableSkeleton />
        ) : (
          <>
            {[0, 1, 4].includes(step) && (
              <div className="d-flex mt-3">
                <button
                  onClick={() => onClickTableType('recommend')}
                  className={`recUnivBtn ${nowTableType === 'recommend' && 'selected'}`}
                >
                  해당 대학
                </button>
                <button
                  onClick={() => onClickTableType('notRecommend')}
                  className={`recUnivBtn ${nowTableType === 'notRecommend' && 'selected'}`}
                >
                  비해당 대학
                </button>
              </div>
            )}
            <div className="tableContainer">
              {step === 0 && (
                <MathableTable
                  univList={mathAbleUnivList}
                  selectedUnivList={selectedMathAbleUnivList}
                  nowTableType={nowTableType}
                  suriNonsulFilter={suriNonsulFilter}
                  onClickUnivCheckBox={onClickUnivCheckBox}
                  onClickUnivTotalCheckBox={onClickUnivTotalCheckBox}
                />
              )}
              {step === 1 &&
                (scienceAbleUnivList.length != 0 ? (
                  <ScienceAbleTable
                    univList={scienceAbleUnivList}
                    departmentList={scienceAbleDepartmentList}
                    selectedDepartmentList={selectedScienceDepartmentList}
                    openUnivList={openUnivList}
                    nowTableType={nowTableType}
                    filter={scienceFilter}
                    onClickUnivRow={onClickUnivRow}
                    onClickTotalCheckBox={onClickTotalDepartmentCheckBox}
                    onClickDepartmentCheckBox={onClickDepartmentCheckBox}
                  />
                ) : (
                  <p className="warning">해당하는 대학이 없습니다. 다음 단계로 넘어가주세요.</p>
                ))}
              {step === 2 &&
                (otherUnivList.length != 0 ? (
                  <OtherNonsulTypeTable
                    otherUnivList={otherUnivList}
                    otherDepartmentList={otherDepartmentList}
                    openUnivList={openUnivList}
                    selectedDepartmentList={selectedOtherDepartmentIdList}
                    onClickUnivRow={onClickUnivRow}
                    onClickDepartmentCheckBox={onClickDepartmentCheckBox}
                    onClickTotalCheckBox={onClickTotalDepartmentCheckBox}
                  />
                ) : (
                  <p className="warning">해당하는 대학이 없습니다. 다음 단계로 넘어가주세요.</p>
                ))}
              {step === 3 &&
                (naesinAdvantageUnivList.length != 0 ? (
                  <NaesinAdvantageDepartmentTable
                    advantageDepartmentList={naesinAdvantageUnivList}
                    nasesinFilter={naesinFilter}
                    selectedUnivList={selectedNaesinAdvantageUnivIdList}
                    onClickUnivCheckBox={onClickUnivCheckBox}
                    onClickTotalUnivCheckBox={onClickUnivTotalCheckBox}
                  />
                ) : (
                  <p className="warning">해당하는 대학이 없습니다. 다음 단계로 넘어가주세요.</p>
                ))}
              {step === 4 && (
                <LowestGradeOfUnivListTable
                  lowestGradeOfDepartmentList={lowestGradeOfDepartmentList}
                  lowestGradeOfUnivList={lowestGradeOfUnivList}
                  selectedDepartmentList={checkedLowestRateDepartmentList}
                  openUnivList={openUnivList}
                  nowTableType={nowTableType}
                  onClickUnivRow={onClickUnivRow}
                  //   onClickCheckBox={onClickDepartmentCheckBox}
                  onClickCheckBox={onClickLowestRateDepartmentCheckBox}
                  onClickTotalUnivCheckBox={onClickLowestRateTotalCheckBox}
                />
              )}
              {step === 5 && (
                <SelectDepartmentTable
                  checkedLowestRateDepartmentList={checkedLowestRateDepartmentList}
                  checkedLowestUnivList={checkedLowestUnivList}
                  applyDateDepartmentList={applyDateDepartmentList}
                  openUnivList={openUnivList}
                  onClickUnivRow={onClickUnivRow}
                  onClickCheckDateDepartment={onClickDateDepartmentCheckBox}
                  onClickTotalCheckBox={onClickDateDepartmentTotalCheckBox}
                />
              )}
              {step === 6 && (
                <ApplyDateTable
                  applyDateUnivList={applyDateUnivList}
                  openUnivList={openUnivList}
                  applyDateDepartmentList={applyDateDepartmentList}
                  interestDepartmentList={interestDepartmentList}
                  onClickInterestDepartmnet={onClickInterestDepartmnet}
                  onClickUnivRow={onClickUnivRow}
                />
              )}
            </div>
          </>
        )}
        <div className="stepBtnBox">
          <button className="stepBtn" onClick={() => onClickStep(false)}>
            이전 단계
          </button>
          <button className="stepBtn" onClick={() => onClickStep(true)}>
            다음 단계
          </button>
        </div>
      </div>
    </TitleSection>
  )
}

export default NonSulLiGwa
