import { PureComponent } from 'react'

export default class TurtleTowLineAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props as {
      x: number
      y: number
      stroke: string
      payload: any
    }
    const [title, subTitle] = payload.value.split('/')

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject className="node" x="-50" y="0" width="100" height="50">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
            }}
          >
            <p style={{ fontWeight: 800 }}>{title}</p>
            <p style={{ marginTop: 4, fontWeight: 700, color: '#9a9a9a' }}>{subTitle}</p>
          </div>
        </foreignObject>
      </g>
    )
  }
}
