
import OrderPage from "components/pages/store/order";
import DetailListPage from "components/pages/store/orderlist";
import Details from "components/pages/store/orderlist/details";
import ProductPage from "components/pages/store/product";
import { Route, Routes } from "react-router-dom";
import "./style.css";
const Store = () => {
  return (
    <Routes>
      <Route path="/product/:productPaymentType" element={<ProductPage />}></Route>
      <Route path="/order/:productId" element={<OrderPage />}></Route>
      <Route path="/details" element={<DetailListPage />}></Route>
      <Route path="/details/:orderDetailId" element={<Details />}></Route>
    </Routes>
  );
};

export default Store;
