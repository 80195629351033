import { useEffect, useState } from 'react'
import './style.scss'
import {
  essayAbleInternalListFetch,
  essayAddInterestFetch,
  essayAllListFetch,
  essayCrossApplyListFetch,
  essayLowestGradeListFetch,
  essayMunMathListFetch,
  essayOtherListFetch,
  lowestGradeOfDepartment,
  munCrossableMathDepartment,
  munMathAbleDepartment,
  otherDepartment,
} from 'api/fetches/essay'
import _ from 'lodash'

import SuriNonSulTable from '../component/table/suriNonSulTable'
import NaesinAdvantageDepartmentTable from '../component/table/naesinAdvantageDepartmentTable'
import OtherNonsulTypeTable from '../component/table/otherNonsulTypeTable'
import LowestGradeOfUnivListTable from '../component/table/lowestGradeOfUnivTable'
import SuriNonsulAndEngFilter from '../component/filter/suriNonsulAndEngFilter'
import SuriNonsulSelectFilter from '../component/filter/suriNonsulSelectFilter'
import InputNaesinFilter from '../component/filter/inputNaesinFilter'
import ApplyDateTable from '../component/table/applyDateTable'
import LigwaCrossableTable from '../component/table/ligwaCrossableTable'
import SelectDepartmentTable from '../component/table/selectDepartmentTable'
import ContentsBox from 'components/block/contentsBox'

import { useCheckUserInputGrade } from 'hooks/useCheckUserInputGrade'
import { Skeleton, Typography } from '@mui/material'
import TableSkeleton from 'components/block/skeletons/tableSkeleton'
import { useNavigate } from 'react-router-dom'
import TitleSection from 'components/layout/TitleSection'
import { Box } from '@mui/system'
import StepIndicator from 'components/common/StepIndicator'
import K_약식논술필터 from '../component/filter/K_약식논술필터'
import K_논술필터, { K_문과_교차지원_필터_타입 } from '../component/filter/K_논술필터'
import K_문과_약식논술테이블 from '../component/table/K_문과_약식논술테이블'
const mungwaNonsulStepList = [
  { label: '논술과목선택' },
  { label: '이과 교차지원' },
  { label: '약식 논술' },
  { label: '내신 유불리' },
  { label: '최저 등급 확인' },
  { label: '모집단위 선택' },
  { label: '전형 일자 확인' },
]

const checkNowStep = (nowStep: number, index: number) => {
  if (nowStep > index) {
    return 'before'
  }

  if (nowStep < index) {
    return 'after'
  }

  return 'now'
}

interface univInfo {
  collegeCode: string
  collegeName: string
}

export type 문과_교차지원필터_State = {
  '수리 범위가 수1+수2': boolean
  '언어논술 포함 이과논술': boolean
}

export type 문과_약식논술필터_State = {
  문과: boolean
  이과: boolean
}

const NonSulMunGwa = () => {
  const { scheduleId } = useCheckUserInputGrade()
  const navigate = useNavigate()
  const [step, setStep] = useState(0)

  const [openUnivList, setOpenUnivList] = useState<string[]>([])
  const [nowTableType, setNowTableType] = useState<'recommend' | 'notRecommend'>('recommend')

  const [suriNonsulFilter, setSuriNonsulFilter] = useState<string[]>([])
  const [engFilter, setEngFilter] = useState<string[]>([])

  const [crossApplyFilter, setCrossApplyFilter] = useState<문과_교차지원필터_State>({
    '수리 범위가 수1+수2': false,
    '언어논술 포함 이과논술': false,
  })

  const [yaksikFilter, setYaksikFilter] = useState<문과_약식논술필터_State>({
    문과: false,
    이과: false,
  })

  const [crossAbleFilter, setCrossAbleFilter] = useState<string[]>([])

  const [departmentList, setDepartmentList] = useState<number[]>([])
  const [selectedDepartmentList, setSelectedDepartmentList] = useState<number[]>([])
  const [selectedUnivList, setSelectedUnivList] = useState<string[]>([])

  const [mathableUnivList, setMathableUnivList] = useState<univInfo[]>([])

  const [mathableDepartmentList, setMathableDepartmentList] = useState<munMathAbleDepartment[]>([])

  const [munCrossableUnivList, setMunCrossableUnivList] = useState<{ collegeCode: string; collegeName: string }[]>([])
  const [munCrossableDepartmentList, setMunCrossableDepartmentList] = useState<munCrossableMathDepartment[]>([])

  const [otherUnivList, setOtherUnviList] = useState<{ collegeCode: string; collegeName: string }[]>([])
  const [otherDepartmentList, setOtherDepartmentList] = useState<otherDepartment[]>([])
  const [문과이과_학과데이터리스트, set문과이과_학과데이터리스트] = useState<any[]>([])

  const [advantageDepartmentList, setAdvantageDepartmentList] = useState<any[]>([])
  const [naesinFilter, setNasesinFilter] = useState(3)

  const [lowestGradeOfUnivList, setLowestGradeOfUnivList] = useState<{ collegeCode: string; collegeName: string }[]>([])
  const [lowestGradeOfDepartmentList, setLowestGradeOfDepartmentList] = useState<lowestGradeOfDepartment[]>([])
  const [checkedLowestUnivList, setCheckedLowestUnivList] = useState<univInfo[]>([])
  const [checkedLowestRateDepartmentList, setCheckedLowestRateDepartmentList] = useState<lowestGradeOfDepartment[]>([])

  const [applyDateUnivList, setapplyDateUnivList] = useState<univInfo[]>([])
  const [applyDateDepartmentList, setapplyDateDepartmentList] = useState<lowestGradeOfDepartment[]>([])

  const [interestDepartmentList, setInterestDepartmentList] = useState<number[]>([])

  const [isFetching, setIsFetching] = useState<boolean>(false)

  useEffect(() => {
    getAllDepartmentList()
    getMunMathEssayDepartmentList()
    getOtherUnviListFetch()
  }, [])

  useEffect(() => {
    setOpenUnivList([])
    if (step === 1) {
      getNonCheckedUnivIdList()
    } else if (step === 3) {
      getEssayAbleInteralList()
    } else if (step === 4) {
      if (selectedUnivList.length === 0) {
        alert('대학을 선택해주세요.')
        setStep((prev) => prev - 1)
      } else {
        checkLowestGrade()
      }
    } else if (step === 5) {
      if (checkedLowestUnivList.length === 0) {
        alert('대학을 선택해주세요.')
        setStep((prev) => prev - 1)
      }
      console.log('-')
    } else if (step === 6) {
      if (applyDateUnivList.length === 0) {
        alert('학과를 선택해주세요.')
        setStep((prev) => prev - 1)
      }
    }
  }, [step])
  /**
   * 내신유불리에서 내신 등급이 변경될경우
   */
  useEffect(() => {
    const result = advantageDepartmentList.sort((a: any, b: any): any => {
      return a['levelGrade' + naesinFilter] - b['levelGrade' + naesinFilter]
    })

    setAdvantageDepartmentList([...result])
  }, [naesinFilter])

  /**
   * 문과 논술 내신 유불리 데이터 가져오기
   * 필터링된 모집단위 값이 필요하다
   */
  const getEssayAbleInteralList = async () => {
    setIsFetching(true)
    const selectRecruitmentUnitList = getFilteredDepartmentList()
    console.log('passedDepartmentList : ', selectRecruitmentUnitList)
    const res = await essayAbleInternalListFetch({ admissionSeriesCode: 'R2', selectRecruitmentUnitList })

    const _res: any = {}

    // res.list.forEach((department) => {
    //   if (department.gradeType === '1000점 환산점수') {
    //     _res[department.collegeCode] = {
    //       ...department,
    //     }
    //   } else {
    //     _res[department.collegeCode] = {
    //       ..._res[department.collegeCode],
    //       levelGrade1: department.grade1,
    //       levelGrade2: department.grade2,
    //       levelGrade3: department.grade3,
    //       levelGrade4: department.grade4,
    //       levelGrade5: department.grade5,
    //       levelGrade6: department.grade6,
    //       levelGrade7: department.grade7,
    //       levelGrade8: department.grade8,
    //       levelGrade9: department.grade9,
    //     }
    //   }
    // })
    res.list.forEach((department) => {
      _res[department.collegeCode] = {
        ..._res[department.collegeCode],
        collegeName: department.collegeName,
        collegeCode: department.collegeCode,
        levelGrade1: department.grade1,
        levelGrade2: department.grade2,
        levelGrade3: department.grade3,
        levelGrade4: department.grade4,
        levelGrade5: department.grade5,
        levelGrade6: department.grade6,
        levelGrade7: department.grade7,
        levelGrade8: department.grade8,
        levelGrade9: department.grade9,
      }
    })
    const advantageDepartmet = Object.values(_res)

    setAdvantageDepartmentList(advantageDepartmet)
    setIsFetching(false)
  }

  const getAllDepartmentList = async () => {
    const codeList = await essayAllListFetch('R2')
    console.log('passedDepartmentList  ', codeList)
    setDepartmentList(codeList)
  }

  /**
   * 수학 가능과목 선택 대학 리스트
   */
  const getMunMathEssayDepartmentList = async () => {
    setIsFetching(true)
    const munMathList = await essayMunMathListFetch()
    console.log(munMathList)

    const _univList = _.unionBy(
      munMathList.map((munMath) => {
        return {
          collegeCode: munMath.collegeCode,
          collegeName: munMath.collegeName,
          admissionSeriesCode: munMath.admissionSeriesCode,
        }
      }),
      'collegeCode'
    )
    setMathableUnivList(_univList)

    setMathableDepartmentList(munMathList)
    setIsFetching(false)
  }

  const getOtherUnviListFetch = async () => {
    const res = await essayOtherListFetch('R2')
    const res2 = await essayOtherListFetch('R1')
    console.log(res2)

    if (res.status) {
      const _univList = _.unionBy(
        res.list.map((department) => {
          return { collegeCode: department.collegeCode, collegeName: department.collegeName }
        }),
        'collegeCode'
      )
      setOtherUnviList(_univList)
      setOtherDepartmentList(res.list)
      console.log(res.list)
    }
  }

  /**
   * 문과 논술 최저등급 확인
   */
  const checkLowestGrade = async () => {
    if (typeof scheduleId === 'number') {
      setIsFetching(true)

      const selectRecruitmentUnitList = getPassedUnivList()

      const lowestGrade = await essayLowestGradeListFetch({
        admissionSeriesCode: 'R2',
        scheduleId,
        selectRecruitmentUnitList,
        selectUniversityList: selectedUnivList,
      })

      if (lowestGrade.status) {
        const _univList = _.unionBy(
          lowestGrade.list.map((department) => {
            return { collegeCode: department.collegeCode, collegeName: department.collegeName }
          }),
          'collegeCode'
        )
        setLowestGradeOfUnivList(_univList)
        setLowestGradeOfDepartmentList(lowestGrade.list)
      }
      setIsFetching(false)
    }
  }

  // ----------------------------   handler   ----------------------------

  const onClickUnivRow = (collegeCode: string) => {
    console.log('onClickUnivRow')

    const _tableOepnCollegeList = [...openUnivList]

    if (_tableOepnCollegeList.includes(collegeCode)) {
      const delIndex = _tableOepnCollegeList.indexOf(collegeCode)
      _tableOepnCollegeList.splice(delIndex, 1)
    } else {
      _tableOepnCollegeList.push(collegeCode)
    }

    setOpenUnivList(_tableOepnCollegeList)
  }

  const onClickTableType = (tableType: 'recommend' | 'notRecommend') => setNowTableType(tableType)

  const onClickCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    { id, isTotal, collegeCode }: { id: number; isTotal: boolean; collegeCode?: string }
  ) => {
    let _checkedUnivIdIndex = [...selectedDepartmentList]
    const filterTargetList = [
      mathableDepartmentList,
      munCrossableDepartmentList,
      otherDepartmentList,
      advantageDepartmentList,
    ]

    if (isTotal && collegeCode) {
      const selectedCollegeIdList = filterTargetList[step]
        .filter((munMath) => munMath.collegeCode === collegeCode)
        .map((filteredMunMath) => filteredMunMath.id)

      if (event.target.checked) {
        _checkedUnivIdIndex.push(...selectedCollegeIdList)
      } else {
        _checkedUnivIdIndex = _checkedUnivIdIndex.filter((id) => !selectedCollegeIdList.includes(id))
      }
    } else {
      if (event.target.checked) {
        _checkedUnivIdIndex.push(id)
      } else {
        _checkedUnivIdIndex = _checkedUnivIdIndex.filter((_id) => _id !== id)
      }
    }

    setSelectedDepartmentList(_checkedUnivIdIndex)
  }

  const onClickDepartmentCheckBox = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let _checkedUnivIdIndex = [...selectedDepartmentList]

    if (event.target.checked) {
      _checkedUnivIdIndex.push(id)
    } else {
      _checkedUnivIdIndex = _checkedUnivIdIndex.filter((_id) => _id !== id)
    }

    setSelectedDepartmentList(_checkedUnivIdIndex)
  }

  const onClickTotalDepartmentCheckBox = (isTotal: boolean, selectedIdList: number[]) => {
    let _selectedDepartmentList = [...selectedDepartmentList]

    if (isTotal) {
      _selectedDepartmentList = [..._selectedDepartmentList, ...selectedIdList]
    } else {
      _selectedDepartmentList = _selectedDepartmentList.filter((id) => !selectedIdList.includes(id))
    }

    setSelectedDepartmentList(_selectedDepartmentList)
  }

  const onClickTotalUnivCheckBox = (isTotal: boolean, univList: string[]) => {
    isTotal ? setSelectedUnivList([...univList]) : setSelectedUnivList([])
  }

  const onClickUnivCheckBox = (univCode: string) => {
    let _selectedUnivList = [...selectedUnivList]

    if (_selectedUnivList.includes(univCode)) {
      _selectedUnivList = _selectedUnivList.filter((_univCode) => _univCode !== univCode)
    } else {
      _selectedUnivList.push(univCode)
    }

    setSelectedUnivList(_selectedUnivList)
  }

  const onClickNonsulFilterButton = (type: string) => {
    console.log('nonsulclick : ', type, suriNonsulFilter)
    if (step === 0) {
      switch (type) {
        case 'N3':
          engFilter.includes(type) ? setEngFilter([]) : setEngFilter(['N3'])
          break
        case 'N':
          suriNonsulFilter.length === 3 ? setSuriNonsulFilter([]) : setSuriNonsulFilter(['N1', 'N2', 'N4'])
          break
        default: {
          let updatedSuriNonsulFilter = [...suriNonsulFilter]
          if (updatedSuriNonsulFilter.includes(type)) {
            updatedSuriNonsulFilter = updatedSuriNonsulFilter.filter((_type) => _type !== type)
          } else {
            updatedSuriNonsulFilter.push(type)
          }
          setSuriNonsulFilter(updatedSuriNonsulFilter)
          break
        }
      }
    } else {
      console.log('step is : ', step)

      if (type === '') {
        crossAbleFilter.length === 4 ? setCrossAbleFilter([]) : setCrossAbleFilter(['ES2', 'ES3', 'ES4', 'ES5'])
      } else {
        let updatedCrossAbleFilter = [...crossAbleFilter]
        if (updatedCrossAbleFilter.includes(type)) {
          updatedCrossAbleFilter = updatedCrossAbleFilter.filter((_type) => _type !== type)
        } else {
          updatedCrossAbleFilter.push(type)
        }
        setCrossAbleFilter(updatedCrossAbleFilter)
      }
    }
  }

  const onClickStep = (isNext: boolean) => {
    if (isNext) {
      if (step < 6) {
        setStep((prev) => prev + 1)
      } else {
        saveInterestDepartment()
      }
    } else {
      step !== 0 && setStep((prev) => prev - 1)
    }
  }

  const saveInterestDepartment = async () => {
    const res = await essayAddInterestFetch(interestDepartmentList)
    if (res.status) {
      alert('관심대학에 저장되었습니다.')
      navigate('/suSi/strategy/interestUniv')
    }
  }

  /**
   * 학과 선택 탭에서 학과를 선택하는 경우
   * @param lowestGradeOfDepartment
   */
  const onClickLowestRateDepartmentCheckBox = (lowestGradeOfDepartment: lowestGradeOfDepartment) => {
    console.log('최저에서 고른 대학 : ', lowestGradeOfDepartment)

    let _finalChoiceDepartment = [...checkedLowestRateDepartmentList]
    const finalDepartmentIdList = checkedLowestRateDepartmentList.map((department) => department.id)

    if (finalDepartmentIdList.includes(lowestGradeOfDepartment.id)) {
      _finalChoiceDepartment = _finalChoiceDepartment.filter(
        (department) => department.id !== lowestGradeOfDepartment.id
      )
    } else {
      _finalChoiceDepartment.push(lowestGradeOfDepartment)
    }

    const _univList = _.unionBy(
      _finalChoiceDepartment.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName }
      }),
      'collegeCode'
    )
    console.log(_univList)
    console.log(_finalChoiceDepartment)
    setCheckedLowestUnivList(_univList)
    setCheckedLowestRateDepartmentList(_finalChoiceDepartment)
  }

  const onClickLowestRateTotalCheckBox = (isTotal: boolean, lowestGradeDepartmentList: lowestGradeOfDepartment[]) => {
    let _checkedLowestRateDepartmentList = [...checkedLowestRateDepartmentList]
    if (isTotal) {
      _checkedLowestRateDepartmentList = _.uniqBy(
        [..._checkedLowestRateDepartmentList, ...lowestGradeDepartmentList],
        'id'
      )
    } else {
      const selectedIdList = lowestGradeDepartmentList.map((department) => department.id)
      _checkedLowestRateDepartmentList = _checkedLowestRateDepartmentList.filter(
        (department) => !selectedIdList.includes(department.id)
      )
    }
    const _univList = _.unionBy(
      _checkedLowestRateDepartmentList.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName }
      }),
      'collegeCode'
    )

    setCheckedLowestUnivList(_univList)
    setCheckedLowestRateDepartmentList(_checkedLowestRateDepartmentList)
  }

  /**
   * 학과 선택 탭에서 학과를 선택하는 경우
   * @param lowestGradeOfDepartment
   */
  const onClickCheckDateDepartment = (lowestGradeOfDepartment: lowestGradeOfDepartment) => {
    let _applyDateDepartmentList = [...applyDateDepartmentList]
    const checkTheDateDepartmentIdList = applyDateDepartmentList.map((department) => department.id)

    if (checkTheDateDepartmentIdList.includes(lowestGradeOfDepartment.id)) {
      _applyDateDepartmentList = _applyDateDepartmentList.filter(
        (department) => department.id !== lowestGradeOfDepartment.id
      )
    } else {
      _applyDateDepartmentList.push(lowestGradeOfDepartment)
    }

    const _univList = _.unionBy(
      _applyDateDepartmentList.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName }
      }),
      'collegeCode'
    )

    setapplyDateUnivList(_univList)
    setapplyDateDepartmentList(_applyDateDepartmentList)
  }

  const onClickTotalCheckBox = (isTotal: boolean, lowestGradeDepartmentList: lowestGradeOfDepartment[]) => {
    let _applyDateDepartmentList = [...applyDateDepartmentList]
    const checkTheDateDepartmentIdList = applyDateDepartmentList.map((department) => department.id)

    if (isTotal) {
      _applyDateDepartmentList = _.uniqBy([..._applyDateDepartmentList, ...lowestGradeDepartmentList], 'id')
    } else {
      const selectedIdList = lowestGradeDepartmentList.map((department) => department.id)
      _applyDateDepartmentList = _applyDateDepartmentList.filter(
        (department) => !checkTheDateDepartmentIdList.includes(department.id)
      )
    }
    const _univList = _.unionBy(
      _applyDateDepartmentList.map((deparment) => {
        return { collegeCode: deparment.collegeCode, collegeName: deparment.collegeName }
      }),
      'collegeCode'
    )

    setapplyDateUnivList(_univList)
    setapplyDateDepartmentList(_applyDateDepartmentList)
  }

  const onClickCheckDepartment = (id: string) => {
    console.log('id : id ', id)
  }

  const onClickInterestDepartmnet = (departmentId: number) => {
    let _interestDepartment = [...interestDepartmentList]

    if (_interestDepartment.includes(departmentId)) {
      _interestDepartment = _interestDepartment.filter((department) => department !== departmentId)
    } else {
      _interestDepartment.push(departmentId)
    }

    setInterestDepartmentList(_interestDepartment)
  }

  // ----------------------------   handler   ----------------------------

  /**
   * 이과 교차지원 리스트
   * 모든 모집단위 id중 수리논술에서 선택되지 못한 id를 제거하고 조회한다.
   */
  const getNonCheckedUnivIdList = async () => {
    setIsFetching(true)

    const passedDepartmentList = getFilteredDepartmentList()

    const { list, status } = await essayCrossApplyListFetch(passedDepartmentList)
    console.log('list status : ', status)
    console.log('list', list)
    if (status) {
      const _univList = _.unionBy(
        list.map((munCrossableDepartment) => {
          return { collegeCode: munCrossableDepartment.collegeCode, collegeName: munCrossableDepartment.collegeName }
        }),
        'collegeCode'
      )

      setMunCrossableUnivList(_univList)
      setMunCrossableDepartmentList(list)
    }
    setIsFetching(false)
  }

  const getFilteredDepartmentList = () => {
    let unSelectedIdList: number[] = []

    for (let i = 0; i <= step; i++) {
      unSelectedIdList = [...unSelectedIdList, ...getUnSelectedDepartmentIdList(i)]
    }

    return getSelectedDepartmentList(unSelectedIdList)
  }

  const getSelectedDepartmentList = (unSelectedIdList: number[]) => {
    return departmentList.filter((univCode) => !unSelectedIdList.includes(univCode))
  }

  const getUnSelectedDepartmentIdList = (step: number) => {
    const filterTargetList = [
      mathableDepartmentList,
      munCrossableDepartmentList,
      otherDepartmentList,
      advantageDepartmentList,
    ]

    return filterTargetList[step]
      .filter((department) => !selectedDepartmentList.includes(department.id))
      .map((department) => department.id)
  }

  const getPassedUnivList = () => {
    const unSelectedIdList = mathableDepartmentList
      .filter((department) => {
        return selectedDepartmentList.includes(department.id) === false
      })
      .map((department) => {
        return department.id
      })

    const passedDepartmentList = departmentList.filter((univCode) => {
      return unSelectedIdList.includes(univCode) === false
    })

    return passedDepartmentList
  }

  // [
  //   '',
  //   '*이과 논술 전형 중 문과 수학 범위(수1,2 or 수1,2 + 확통)인 대학들이 있습니다.\n수학에 자신 있고, 이과 대학으로 지원하고자 할떄 아래 과정을 체크해주세요. 원치 않으시면 건너 뛰시면 됩니다.',
  //   '*아래는 문과논술 수험생들이 잘 알지 못해서 놓치는 특이한 전형 방식의 대학들입니다.\n*관심 없는 대학이라면 스킵하고 다음 단계로 진행하세요.',

  return (
    <TitleSection title="문과논술">
      <div id="munGwaNonSul">
        <StepIndicator currentStep={step + 1} infos={mungwaNonsulStepList} />
        {[
          '* 문과논술은 언어논술이 기본입니다. 언어논술외에 수리논술, 영어제시문, 언어논술 자체가 없는 대학들을 선택 혹은 제외하는 단계입니다. \n   국어/수학 단답식 문제를 출제하는 약식논술 대학은 단계3 에서 다루게 됩니다.',
          '* 논술도 교차지원이 가능합니다. 이과 논술은 거의 수리만 시험보지만, 범위가 수1+수2 만으로 한정된 대학들은 문과생들이 충분히 해볼만 합니다. \n   그리고 이과 모집단위이지만, 언어논술을 보는 대학 역시 문과생들이 도전해 볼만 합니다. 단답식 약식논술 대학은 다음단계에서 다루게 됩니다',
          '* 언어논술을 보지 않고, 국어, 수학 과목을 단답식으로 시험보는 대학들입니다. ',
          '* 논술 전형에서 내신의 실질 비중은 크지 않습니다. 하지만, 대학마다 어느 등급부터는 격차가 너무 커져서 논술시험만으로 커버가 어려운 등급이 존재하기도 하고, 또 같은 등급이라도 대학마다 감점은 천차만별입니다. \n   나의 내신등급을 입력하면, 나에게 가장 유리한 대학순으로 배열되니 참고하세요.',
          '',
          '',
          '* 아직 전형 시간은 발표하지 않은 대학들이 있습니다. 바로 업데이트 하겠습니다. 겹치는 날이 없는지 꼭 확인하세요.  \n   그리고, 교과와 학종까지 지원했다면, 전형일 확인은 지원과 전략 페이지에서 확인 가능합니다.',
        ].map((guide, index) => {
          return step === index && guide !== '' ? (
            <div key={guide} className="guide">
              <p>{guide}</p>
            </div>
          ) : (
            <></>
          )
        })}

        {/* ------------------------------------   filter   ------------------------------------ */}

        {step === 0 && (
          <SuriNonsulAndEngFilter
            suriNonsulFilter={suriNonsulFilter}
            engFilter={engFilter}
            onClickNonsulFilterButton={onClickNonsulFilterButton}
          />
        )}
        {step === 1 && (
          <K_논술필터
            bigTitle="수학 가능과목 선택"
            filterState={crossApplyFilter}
            setFilterState={setCrossApplyFilter}
            filterInfos={[
              {
                title: '이과 수리논술 대학',
                items: [
                  {
                    label: '수리 범위가 수1+수2',
                  },
                ],
              },
              {
                title: '이과 모집단위이지만 언어논술 보는 대학',
                items: [
                  {
                    label: '언어논술 포함 이과논술',
                  },
                ],
              },
            ]}
          />
        )}
        {step === 2 && (
          <K_논술필터
            bigTitle="부연설명"
            filterState={yaksikFilter}
            setFilterState={setYaksikFilter}
            filterInfos={[
              {
                title: '계열',
                items: [
                  {
                    label: '문과',
                  },
                  {
                    label: '이과',
                  },
                ],
                buttonsType: 'NoneOrRadioSelect',
              },
            ]}
          />
        )}
        {step === 3 && <InputNaesinFilter onChangeNaesin={(naesin) => setNasesinFilter(naesin)} />}
        {step === 3 && (
          <Typography mb={2} borderTop={0} sx={{ color: '#555', fontSize: 16 }}>
            * 한양대는 내신을 출결, 봉사 등을 위주로, 학생의 성실성을 정성평가합니다 (총점 1000점 만점 중 100점)
          </Typography>
        )}

        {/* ------------------------------------   filter   ------------------------------------ */}
        {/* ------------------------------------   table   ------------------------------------ */}

        {isFetching ? (
          <TableSkeleton />
        ) : (
          <>
            {[0, 1, 4].includes(step) && (
              <div className="tableTypeSelectContainer">
                <button
                  onClick={() => onClickTableType('recommend')}
                  className={`recUnivBtn ${nowTableType === 'recommend' && 'selected'}`}
                >
                  해당 대학
                </button>
                <button
                  onClick={() => onClickTableType('notRecommend')}
                  className={`recUnivBtn ${nowTableType === 'notRecommend' && 'selected'}`}
                >
                  비해당 대학
                </button>
              </div>
            )}

            <div className="tableContainer">
              {step === 0 && (
                <SuriNonSulTable
                  univList={mathableUnivList}
                  openUnivList={openUnivList}
                  departmentList={mathableDepartmentList}
                  suriNonsulFilter={suriNonsulFilter}
                  engFilter={engFilter}
                  selectedDepartmentList={selectedDepartmentList}
                  nowTableType={nowTableType}
                  onClickTotalCheckBox={onClickTotalDepartmentCheckBox}
                  onClickCheckBox={onClickCheckBox}
                  onClickUnivRow={onClickUnivRow}
                  onClickDepartmentCheckBox={onClickDepartmentCheckBox}
                />
              )}
              {step === 1 && munCrossableDepartmentList.length === 0 ? (
                <p className="warning">해당하는 대학이 없습니다. 다음 단계로 넘어가주세요.</p>
              ) : step === 1 ? (
                <LigwaCrossableTable
                  munCrossableUnivList={munCrossableUnivList}
                  munCrossableDepartmentList={munCrossableDepartmentList}
                  selectedDepartmentList={selectedDepartmentList}
                  openUnivList={openUnivList}
                  nowTableType={nowTableType}
                  onClickDepartmentCheckBox={onClickDepartmentCheckBox}
                  onClickTotalCheckBox={onClickTotalDepartmentCheckBox}
                  crossApplyFilter={crossApplyFilter}
                  onClickUnivRow={onClickUnivRow}
                />
              ) : null}
              {step === 2 && otherDepartmentList.length === 0 ? (
                <p className="warning">해당하는 대학이 없습니다. 다음 단계로 넘어가주세요.</p>
              ) : step === 2 ? (
                <K_문과_약식논술테이블
                  filtersState={yaksikFilter}
                  otherUnivList={otherUnivList}
                  otherDepartmentList={otherDepartmentList}
                  openUnivList={openUnivList}
                  selectedDepartmentList={selectedDepartmentList}
                  onClickDepartmentCheckBox={onClickDepartmentCheckBox}
                  onClickTotalCheckBox={onClickTotalDepartmentCheckBox}
                  onClickUnivRow={onClickUnivRow}
                />
              ) : null}
              {step === 3 && (
                <NaesinAdvantageDepartmentTable
                  advantageDepartmentList={advantageDepartmentList}
                  nasesinFilter={naesinFilter}
                  selectedUnivList={selectedUnivList}
                  onClickUnivCheckBox={onClickUnivCheckBox}
                  onClickTotalUnivCheckBox={onClickTotalUnivCheckBox}
                />
              )}
              {step === 4 && (
                <LowestGradeOfUnivListTable
                  lowestGradeOfDepartmentList={lowestGradeOfDepartmentList}
                  lowestGradeOfUnivList={lowestGradeOfUnivList}
                  selectedDepartmentList={checkedLowestRateDepartmentList}
                  openUnivList={openUnivList}
                  nowTableType={nowTableType}
                  onClickUnivRow={onClickUnivRow}
                  onClickCheckBox={onClickLowestRateDepartmentCheckBox}
                  onClickTotalUnivCheckBox={onClickLowestRateTotalCheckBox}
                />
              )}
              {step === 5 && (
                <SelectDepartmentTable
                  checkedLowestRateDepartmentList={checkedLowestRateDepartmentList}
                  checkedLowestUnivList={checkedLowestUnivList}
                  applyDateDepartmentList={applyDateDepartmentList}
                  openUnivList={openUnivList}
                  onClickUnivRow={onClickUnivRow}
                  onClickCheckDateDepartment={onClickCheckDateDepartment}
                  onClickTotalCheckBox={onClickTotalCheckBox}
                />
              )}
              {step === 6 && (
                <ApplyDateTable
                  applyDateUnivList={applyDateUnivList}
                  openUnivList={openUnivList}
                  applyDateDepartmentList={applyDateDepartmentList}
                  interestDepartmentList={interestDepartmentList}
                  onClickInterestDepartmnet={onClickInterestDepartmnet}
                  onClickUnivRow={onClickUnivRow}
                />
              )}
            </div>
          </>
        )}
        {/* ------------------------------------   table   ------------------------------------ */}

        <div className="stepBtnBox">
          <button className="stepBtn" onClick={() => onClickStep(false)}>
            이전 단계
          </button>
          <button className="stepBtn" onClick={() => onClickStep(true)}>
            {step < 6 ? '다음 단계' : '관심 대학 저장'}
          </button>
        </div>
      </div>
    </TitleSection>
  )
}

export default NonSulMunGwa
