import ServiceTitle from 'components/atom/text/serviceTitle'
import SusiTitle from 'components/atom/text/susiTitle'
import ContentsBox from 'components/block/contentsBox'
import React from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import TitleSection from 'components/layout/TitleSection'
import { ButtonBase } from '@mui/material'
const nonSulServices = [
  {
    image: require('../../../../../assets/images/susiMain/munGwa.png'),
    label: '문과 논술',
    route: '/suSi/nonSul/munGwa',
    target: '문과학생 대상',
  },
  {
    image: require('../../../../../assets/images/susiMain/liGwa.png'),
    label: '이과 논술',
    route: '/suSi/nonSul/liGwa',
    target: '이과학생 대상',
  },
  {
    image: require('../../../../../assets/images/susiMain/uiChiHanYac.png'),
    label: '의치한약수 논술',
    route: '/suSi/nonSul/uiChiHanYacSu',
    target: '이과학생 대상',
  },
  {
    image: require('../../../../../assets/images/susiMain/yakSik.png'),
    label: '약식논술 실전훈련반',
    route: 'notReady',
    target: '문이과학생 대상',
  },
]

const NonSul = () => {
  const navigate = useNavigate()

  const onClickServiceCard = (route: string) => {
    if (route === 'notReady') {
      alert('헤당 서비스는 9월 오픈 예정입니다.')
    } else {
      navigate(route)
    }
  }

  return (
    <ContentsBox id="nonSulMain">
      <TitleSection title="논술">
        <div className="divider"></div>
        <p
          className="information"
          style={{
            color: '#000',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '27px',
          }}
        >
          논술전형은 내신의 비중이 작고, 논술 비중이 높아서, 예측이 의미가 없으므로,
          <br />
          나에게 유리한 논술 대학을 찾아주는 방식으로 구성되어 있습니다.
          <br />
          내신의 비중이 적기는 하지만, 내 내신에 유리한 대학은 있습니다. 그뿐만 아니라, 시험과목, 교차지원, 전형일 등
          복잡한 조건들이 많습니다.
          <br />
          거북스쿨은 논술의 많은 조건을 포괄해서, 몇 분 만에 나에게 가장 유리한 논술 대학을 찾아줍니다
        </p>
        <div className="serviceBox">
          {nonSulServices.map((service) => {
            if (service.label === '약식논술 실전훈련반') {
              return (
                <button
                  onClick={() => {
                    alert('9월 서비스 예정입니다.')
                  }}
                  className="serviceCard"
                  key={service.image + '약식'}
                >
                  <img src={service.image} style={{ borderRadius: 6 }} />
                  <div>
                    <p className="title" style={{ textAlign: 'left' }}>
                      {service.label}
                      <br />
                      <p style={{ fontSize: 16 }}>{`(9월 서비스 예정)`}</p>
                    </p>

                    <div className="goButton">
                      <p>입장하기</p>
                    </div>
                  </div>
                  <p className="target">{service.target}</p>
                </button>
              )
            }

            return (
              <button onClick={() => onClickServiceCard(service.route)} className="serviceCard" key={service.image}>
                <img src={service.image} />
                <div>
                  <p className="title">{service.label}</p>
                  <div className="goButton">
                    <p>입장하기</p>
                  </div>
                </div>
                <p className="target">{service.target}</p>
              </button>
            )
          })}
        </div>
      </TitleSection>
    </ContentsBox>
  )
}

export default NonSul
