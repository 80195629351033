import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'H1_Exbold, 36, 54': React.CSSProperties
    'H2_Bold, 36, 42': React.CSSProperties
    'H3_Bold, 28, 36': React.CSSProperties
    'H4_Exbold, 24, 32': React.CSSProperties
    'H5_regular, 24, 32': React.CSSProperties
    'H6_Bold, 22, 30': React.CSSProperties
    'H7_ExBold, 20, 28': React.CSSProperties
    'H8_bold, 20, 28': React.CSSProperties
    'H9_regular, 20, 28': React.CSSProperties
    'D1_bold, 18, 26': React.CSSProperties
    'D1_regular, 18, 26': React.CSSProperties
    'D2_exBold, 16, 24': React.CSSProperties
    'D3_bold, 16, 24': React.CSSProperties
    'D4_regular, 16, 24': React.CSSProperties
    'D5_Exbold, 14, 18': React.CSSProperties
    'D1_light, 18, 26': React.CSSProperties
    'D5_Bold, 14, 18': React.CSSProperties
    'D5_Regular, 14, 20': React.CSSProperties
    'D6_Exbold, 12, 18': React.CSSProperties
    'D6_bold, 12, 18': React.CSSProperties
    'D6 regular, 12, 18': React.CSSProperties
    'D7_bold, 10, 18': React.CSSProperties
    'D7_regular, 10, 18': React.CSSProperties
    'D8_Regular, 9, 16': React.CSSProperties
    'D9_Regular, 8, 14': React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'H1_Exbold, 36, 54'?: React.CSSProperties
    'H2_Bold, 36, 42'?: React.CSSProperties
    'H3_Bold, 28, 36'?: React.CSSProperties
    'H4_Exbold, 24, 32'?: React.CSSProperties
    'H5_regular, 24, 32'?: React.CSSProperties
    'H6_Bold, 22, 30'?: React.CSSProperties
    'H7_ExBold, 20, 28'?: React.CSSProperties
    'H8_bold, 20, 28'?: React.CSSProperties
    'H9_regular, 20, 28'?: React.CSSProperties
    'D1_bold, 18, 26'?: React.CSSProperties
    'D1_regular, 18, 26'?: React.CSSProperties
    'D2_exBold, 16, 24'?: React.CSSProperties
    'D3_bold, 16, 24'?: React.CSSProperties
    'D4_regular, 16, 24'?: React.CSSProperties
    'D5_Exbold, 14, 18'?: React.CSSProperties
    'D1_light, 18, 26'?: React.CSSProperties
    'D5_Bold, 14, 18'?: React.CSSProperties
    'D5_Regular, 14, 20'?: React.CSSProperties
    'D6_Exbold, 12, 18'?: React.CSSProperties
    'D6_bold, 12, 18'?: React.CSSProperties
    'D6 regular, 12, 18'?: React.CSSProperties
    'D7_bold, 10, 18'?: React.CSSProperties
    'D7_regular, 10, 18'?: React.CSSProperties
    'D8_Regular, 9, 16'?: React.CSSProperties
    'D9_Regular, 8, 14'?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    poster: true
    'H1_Exbold, 36, 54': true
    'H2_Bold, 36, 42': true
    'H3_Bold, 28, 36': true
    'H4_Exbold, 24, 32': true
    'H5_regular, 24, 32': true
    'H6_Bold, 22, 30': true
    'H7_ExBold, 20, 28': true
    'H8_bold, 20, 28': true
    'H9_regular, 20, 28': true
    'D1_bold, 18, 26': true
    'D1_regular, 18, 26': true
    'D2_exBold, 16, 24': true
    'D3_bold, 16, 24': true
    'D4_regular, 16, 24': true
    'D5_Exbold, 14, 18': true
    'D1_light, 18, 26': true
    'D5_Bold, 14, 18': true
    'D5_Regular, 14, 20': true
    'D6_Exbold, 12, 18': true
    'D6_bold, 12, 18': true
    'D6 regular, 12, 18': true
    'D7_bold, 10, 18': true
    'D7_regular, 10, 18': true
    'D8_Regular, 9, 16': true
    'D9_Regular, 8, 14': true
  }
}

export const muiTheme = createTheme({
  spacing: 8,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: '#e5e5e5',
            color: '#fff',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': {
            background: '#e5e5e5',
            color: '#fff',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: '#e5e5e5',
            color: '#fff',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 1000,
          color: '#252525',
          backgroundColor: '#fff',
          padding: '16px',
          borderRadius: '10px',
          borderTop: '1px solid #f6f6f6',
          boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.25)',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#ff5600',
      900: '#FF5600',
    },
    secondary: {
      main: '#3360ff',
    },
    text: {
      primary: '#212121',
      secondary: '#9A9A9A',
      disabled: '#666',
    },
  },
  typography: {
    fontFamily: [
      'nanumsquare_regular',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    'H1_Exbold, 36, 54': { fontSize: '36px', lineHeight: '54px', fontFamily: 'nanumsquare_exbold' },
    'H2_Bold, 36, 42': { fontSize: '36px', lineHeight: '42px', fontFamily: 'nanumsquare_bold' },
    'H3_Bold, 28, 36': { fontSize: '28px', lineHeight: '36px', fontFamily: 'nanumsquare_bold' },
    'H4_Exbold, 24, 32': { fontSize: '24px', lineHeight: '32px', fontFamily: 'nanumsquare_exbold' },
    'H5_regular, 24, 32': { fontSize: '24px', lineHeight: '32px', fontFamily: 'nanumsquare_regular' },
    'H6_Bold, 22, 30': { fontSize: '22px', lineHeight: '30px', fontFamily: 'nanumsquare_bold' },
    'H7_ExBold, 20, 28': { fontSize: '20px', lineHeight: '28px', fontFamily: 'nanumsquare_exbold' },
    'H8_bold, 20, 28': { fontSize: '20px', lineHeight: '28px', fontFamily: 'nanumsquare_bold' },
    'H9_regular, 20, 28': { fontSize: '20px', lineHeight: '28px', fontFamily: 'nanumsquare_regular' },
    'D1_bold, 18, 26': { fontSize: '18px', lineHeight: '26px', fontFamily: 'nanumsquare_bold' },
    'D1_regular, 18, 26': { fontSize: '18px', lineHeight: '26px', fontFamily: 'nanumsquare_regular' },
    'D2_exBold, 16, 24': { fontSize: '16px', lineHeight: '24px', fontFamily: 'nanumsquare_exbold' },
    'D3_bold, 16, 24': { fontSize: '16px', lineHeight: '24px', fontFamily: 'nanumsquare_bold' },
    'D4_regular, 16, 24': { fontSize: '16px', lineHeight: '24px', fontFamily: 'nanumsquare_regular' },
    'D5_Exbold, 14, 18': { fontSize: '14px', lineHeight: '18px', fontFamily: 'nanumsquare_exbold' },
    'D1_light, 18, 26': { fontSize: '18px', lineHeight: '26px', fontFamily: 'nanumsquare_light' },
    'D5_Bold, 14, 18': { fontSize: '14px', lineHeight: '18px', fontFamily: 'nanumsquare_bold' },
    'D5_Regular, 14, 20': { fontSize: '14px', lineHeight: '20px', fontFamily: 'nanumsquare_regular' },
    'D6_Exbold, 12, 18': { fontSize: '12px', lineHeight: '18px', fontFamily: 'nanumsquare_exbold' },
    'D6_bold, 12, 18': { fontSize: '12px', lineHeight: '18px', fontFamily: 'nanumsquare_bold' },
    'D6 regular, 12, 18': { fontSize: '12px', lineHeight: '18px', fontFamily: 'nanumsquare_regular' },
    'D7_bold, 10, 18': { fontSize: '10px', lineHeight: '18px', fontFamily: 'nanumsquare_bold' },
    'D7_regular, 10, 18': { fontSize: '10px', lineHeight: '18px', fontFamily: 'nanumsquare_regular' },
    'D8_Regular, 9, 16': { fontSize: '9px', lineHeight: '16px', fontFamily: 'nanumsquare_regular' },
    'D9_Regular, 8, 14': { fontSize: '8px', lineHeight: '15px', fontFamily: 'nanumsquare_regular' },
  },
})
