import { Button, FormControl } from '@mui/base'
import { Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useState } from 'react'
import { Setter } from 'types/utilType'

type Props = {
  typeState: 전형
  setTypeState: Setter<전형>
}

export type 전형 = '일반전형' | '고른기회 특별전형' | '특성화고교 졸업자전형' | '농어촌 학생전형'

function AdmissionTypeSelectForm({ typeState, setTypeState }: Props) {
  return (
    <Box>
      <FormControl>
        <RadioGroup aria-labelledby="전형 선택그룹" defaultValue="female" name="radio-buttons-group" value={typeState}>
          <Grid container m={0}>
            {(['일반전형', '고른기회 특별전형', '특성화고교 졸업자전형', '농어촌 학생전형'] as const).map(
              (label, index) => (
                <Grid item key={index} xs={6} md={4} pr={2} pb={2}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => {
                      setTypeState(label)
                    }}
                  >
                    <Stack
                      direction={'row'}
                      sx={{ border: '1px solid #9A9A9A', boxShadow: '2px 2px 12px 0px rgba(0, 0, 0, 0.14)' }}
                      pr={2}
                      py={1}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Radio value={label} name={label} />
                      <Typography fontWeight={700}>{label}</Typography>
                    </Stack>
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default AdmissionTypeSelectForm
