type Props = {}

import { Box, ButtonBase, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { saveSurveyListFetch } from 'api/fetches/officer'
import TurtlePaper from 'components/common/TurtlePaper'
import { mainColor } from 'constants/style'

import { Paper } from '@mui/material'
import ContentsBox from 'components/block/contentsBox'
import { useParams } from 'react-router'
import { getStudentInfoFetch, getSurveyListFetch, getSurveyScoreListFetch } from '../../../../../api/fetches/officer'
import EvaluationItem from './evalutaionitem'
import './style.css'
import GradeClassTable from '../../common/GradeClassTable'
import { memberAxios } from 'api/instance'
import { useQuery } from 'react-query'
import { getCommentForms, getEvaluationScoreList } from '../../inputAndAnalysis/K_자가평가_페이지'
import MessageCard from 'components/layout/EmptyPageLayout'
interface officerSurveyInfo {
  evaluateContent: string
  surveyId: string
}
;[]

interface officerSurveyScoreInfo {
  surveyId: string
  score: string
}
;[]

interface officerCommentInfo {
  comment: string
  mainSurveyType: string
}
;[]
interface studentInfo {
  studentName: string
  series: string
}
const Evaluation = () => {
  const params = useParams()
  const studentId = params.studentId

  const [rerenderState, rerender] = React.useReducer(() => ({}), {})
  const [userInfo, setUserInfo] = useState<studentInfo>()

  useEffect(() => {
    ;(async () => setUserInfo(await getStudentInfoFetch(studentId)))()
  }, [])

  const { data: questions, isSuccess: isSuccessQuestions } = useQuery({
    queryKey: ['surveylist', studentId],
    queryFn: () =>
      memberAxios
        .get('/officer/survey/list', {})
        .then(({ data }) => data as { surveyId: string; evaluateContent: string }[]),
  })

  const { data: prevEvaluation, isSuccess: isSuccessPrevEvaluation } = useQuery({
    queryKey: ['surveylist2', studentId],
    queryFn: () =>
      memberAxios
        .get('/officer/survey/score/list', {
          params: { studentId },
        })
        .then(({ data }) => data),
    enabled: studentId !== undefined,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const prevComment = prevEvaluation?.officerCommentList as {
    comment: string
    mainSurveyType: string
  }[]

  const prevScore = prevEvaluation?.officerSurveyList as { surveyId: number; score: number }[]

  const [comments, setComments] = useState<Record<string, string>>({
    jinro: '',
    hakup: '',
    gongdong: '',
    etc: '',
  })

  const commentsKeyMap: Record<number, string> = {
    0: 'jinro',
    1: 'hakup',
    2: 'gongdong',
    3: 'etc',
  }

  const scoreMapRef = useRef<Record<string, string>>({})

  useEffect(() => {
    if (!prevScore) return

    const scoreMap = scoreMapRef.current
    for (const { score, surveyId } of prevScore) {
      scoreMap[String(surveyId)] = String(score)
    }
  }, [prevEvaluation])

  useEffect(() => {
    if (!prevComment) return
    const newObj: any = {}

    prevComment.forEach(({ mainSurveyType, comment }) => {
      newObj[mainSurveyType.toLowerCase()] = comment
    })
    setComments(newObj)
  }, [prevEvaluation])

  useEffect(() => {
    if (!prevScore) return

    for (const { score, surveyId } of prevScore) {
      scoreMapRef.current[surveyId] = String(score)
    }
    rerender()
  }, [prevScore, scoreMapRef])

  const evaluationTuple = (questions ?? []).map(({ surveyId, evaluateContent }) => ({
    surveyId,
    evaluateContent,
    score: String(prevScore?.find((scoreData: any) => String(scoreData.surveyId) === surveyId)?.score),
  }))

  const evaluationScoreList = useMemo(() => {
    return getEvaluationScoreList(evaluationTuple, scoreMapRef, rerender)
  }, [rerenderState, questions, prevEvaluation])

  const commentForm = useMemo(() => {
    return getCommentForms(comments, commentsKeyMap, setComments)
  }, [questions, prevEvaluation, comments])

  if (!isSuccessPrevEvaluation || !isSuccessQuestions || !questions) {
    return <MessageCard title={'Loading'}></MessageCard>
  }

  const onTempSave = async () => {
    const scoreMap = scoreMapRef.current

    const requestCommentsParam = Object.keys(comments).map((type) => ({
      mainSurveyType: type,
      comment: comments[type],
    }))

    const requestScoresParam: {
      surveyId: string
      score: string
    }[] = []

    for (const surveyId in scoreMap) {
      requestScoresParam.push({
        surveyId: surveyId,
        score: scoreMap[surveyId],
      })
    }

    const _data = await saveSurveyListFetch(
      studentId,
      requestScoresParam,
      requestCommentsParam,
      0,
      false,
      userInfo?.series || ''
    )
    if (_data.status) {
      alert('임시저장이 완료되었습니다.')
    } else {
      alert(_data.message)
    }
  }

  const onSave = async () => {
    const scoreMap = scoreMapRef.current
    let numSelected = 0
    for (const key of Object.keys(scoreMap)) {
      if (scoreMap[key as keyof typeof scoreMap]?.length > 0) {
        numSelected += 1
      }
    }

    if (numSelected != questions.length && questions.length > 0) {
      alert('설문 작성을 완료할수없습니다. 모든 설문 내용을 입력해주세요.')
      return
    }

    for (const key in comments) {
      if (comments[key].length < 10) {
        alert('설문 작성을 완료할수없습니다. 평가자 주석이 짧은 항목이 존재합니다.')
        return
      }
    }

    const requestCommentsParam = Object.keys(comments).map((type) => ({
      mainSurveyType: type,
      comment: comments[type],
    }))

    const requestScoresParam: {
      surveyId: string
      score: string
    }[] = []

    for (const surveyId in scoreMap) {
      requestScoresParam.push({
        surveyId: surveyId,
        score: scoreMap[surveyId],
      })
    }

    const studentId = params.studentId
    const _data = await saveSurveyListFetch(
      studentId,
      requestScoresParam,
      requestCommentsParam,
      1,
      false,
      userInfo?.series || ''
    )
    if (_data.status) {
      alert('최종 평가저장이 완료되었습니다.')
      location.href = '/suSi/officer/applylist'
    }
  }

  return (
    <Box px={2}>
      <Typography variant="H2_Bold, 36, 42">평가</Typography>
      <TurtlePaper mt={2}>
        <div className="officerApplyWrap1">
          <span>이름</span>
          <span>목표계열</span>
        </div>
        <div className="officerApplyWrap2">
          <span>{userInfo?.studentName}</span>
          <span>{userInfo?.series}</span>
        </div>
      </TurtlePaper>
      <TurtlePaper sx={{ mt: 5 }}>
        <Typography variant="H6_Bold, 22, 30">평가기준</Typography>
        <GradeClassTable />
      </TurtlePaper>
      <TurtlePaper sx={{ width: '100%', mt: 5, display: 'flex', flexDirection: 'column' }}>
        {evaluationScoreList}
      </TurtlePaper>
      {commentForm}
      <Stack alignItems={'end'} direction={'row'} gap={1} justifyContent={'end'}>
        <ButtonBase
          className="officerEvaluationButton2"
          onClick={onTempSave}
          sx={{ width: '220px', height: '42px', bgcolor: '#999', color: 'white', borderRadius: 1, mt: 2, mb: 6 }}
        >
          임시 저장
        </ButtonBase>
        <ButtonBase
          className="officerEvaluationButton2"
          onClick={onSave}
          sx={{ width: '220px', height: '42px', bgcolor: mainColor, color: 'white', borderRadius: 1, mt: 2, mb: 6 }}
        >
          평가 완료
        </ButtonBase>

        {/* <button className="officerEvaluationButton1" onClick={onClicksaveTempSurveyScore}></button>
        <button className="officerEvaluationButton2" onClick={onClicksaveSurveyScore}>평가 완료</button> */}
      </Stack>
    </Box>
  )

  // return (
  //   <ContentsBox id="saJungDan">
  //     <div className="officerProfileContainer">
  //       <span className="officerProfileTitle">평가</span>
  //       <Paper className="officerEvalutaionPage1">
  //         <div className="officerApplyWrap1">
  //           <span>이름</span>
  //           <span>목표계열</span>
  //         </div>
  //         <div className="officerApplyWrap2">
  //           <span>{userInfo?.studentName}</span>
  //           <span>{userInfo?.series}</span>
  //         </div>
  //       </Paper>
  //       <Paper className="officerEvalutaionPage2">
  //         <span className="officerEvalutaionTableTitle">평가기준</span>
  //         <table className="officerEvalutaionTable">
  //           <thead>
  //             <tr>
  //               <td>A+</td>
  //               <td>A</td>
  //               <td>B+</td>
  //               <td>B</td>
  //               <td>C+</td>
  //               <td>C</td>
  //               <td>D</td>
  //               <td>F</td>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             <tr>
  //               <td>의치한약수</td>
  //               <td>서울상위권</td>
  //               <td>서울중위권</td>
  //               <td>
  //                 서울하위권
  //                 <br />
  //                 경기상위권
  //               </td>
  //               <td>
  //                 경기중위권
  //                 <br />
  //                 지방상위권
  //               </td>
  //               <td>
  //                 경기하위권
  //                 <br />
  //                 지방중위권
  //               </td>
  //               <td>지방하위권</td>
  //               <td>낙제</td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </Paper>
  //       <Paper className="officerEvalutaionItemPage">
  //         <div className="EvalutaionItemTopContainer">
  //           <span>평가항목</span>
  //           <div className="EvalutaionItemTopWrap">
  //             <span>A+</span>
  //             <span>A</span>
  //             <span>B+</span>
  //             <span>B</span>
  //             <span>C+</span>
  //             <span>C</span>
  //             <span>D</span>
  //             <span>F</span>
  //           </div>
  //         </div>
  //         {survey.length != 0
  //           ? survey.map((item, index) => {
  //               return (
  //                 <EvaluationItem
  //                   key={item.surveyId}
  //                   evaluateContent={item.evaluateContent}
  //                   surveyId={item.surveyId}
  //                   setSurveyScore={setSurveyScore}
  //                   surveyScore={surveyScore}
  //                 />
  //               )
  //             })
  //           : ''}
  //       </Paper>

  //       <div className="officerEvaluationContainer">
  //         <span className="officerProfileContentTitle">진로역량</span>
  //         <textarea
  //           name="jinro"
  //           className="officerEvalutaionTextArea"
  //           value={comment['jinro']}
  //           onChange={(event) => onChangeTextArea(event)}
  //           placeholder="평가요소별 주석 및 부연 설명을 500자 이내로 작성해 주세요."
  //         />
  //       </div>

  //       <div className="officerEvaluationContainer">
  //         <span className="officerProfileContentTitle">학업역량</span>
  //         <textarea
  //           name="hakup"
  //           className="officerEvalutaionTextArea"
  //           value={comment['hakup']}
  //           onChange={(event) => onChangeTextArea(event)}
  //           placeholder="평가요소별 주석 및 부연 설명을 500자 이내로 작성해 주세요."
  //         />
  //       </div>

  //       <div className="officerEvaluationContainer">
  //         <span className="officerProfileContentTitle">공동체역량</span>
  //         <textarea
  //           name="gongdong"
  //           className="officerEvalutaionTextArea"
  //           value={comment['gongdong']}
  //           onChange={(event) => onChangeTextArea(event)}
  //           placeholder="평가요소별 주석 및 부연 설명을 500자 이내로 작성해 주세요."
  //         />
  //       </div>

  //       <div className="officerEvaluationContainer">
  //         <span className="officerProfileContentTitle">그 외 평가</span>
  //         <textarea
  //           name="etc"
  //           className="officerEvalutaionTextArea"
  //           value={comment['etc']}
  //           onChange={(event) => onChangeTextArea(event)}
  //           placeholder="평가요소별 주석 및 부연 설명을 500자 이내로 작성해 주세요."
  //         />
  //       </div>

  //       <div className="officerEvaluationButtonWrap">
  //         <button className="officerEvaluationButton1" onClick={onClicksaveTempSurveyScore}>
  //           임시 저장
  //         </button>
  //         <button className="officerEvaluationButton2" onClick={onClicksaveSurveyScore}>
  //           평가 완료
  //         </button>
  //       </div>
  //     </div>
  //   </ContentsBox>
  // )
}

export default Evaluation
