import { Box, Button, CSSObject } from '@mui/material'
import React from 'react'

type Props = {
  text?: string
  fontWeight?: number | string
  fontSize?: number | string
  onClick?: (event: React.MouseEvent) => void
  sx?: CSSObject
}

const TextButton = ({ text, fontWeight, fontSize, onClick, sx }: Props) => {
  return (
    <Box
      component={'button'}
      sx={{
        fontSize: fontSize ?? 16,
        textAlign: 'center',
        fontWeight: fontWeight ?? 500,
        fontFamily: 'Pretendard-Light sans-serif',
        ...sx,
      }}
      onClick={onClick}
    >
      {text}
    </Box>
  )
}

export default TextButton
