import { Box, Stack, Typography } from '@mui/material'
import { memberAxios } from 'api/instance'
import TurtlePaper from 'components/common/TurtlePaper'
import { useQuery } from 'react-query'
import AlphabetGradeChart from './AlphabetGradeChart'
import { getGrade, 학종_사정관평가데이터_형식 } from './Step1'
import { ExplainSection } from './common'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import GradeCard from './GradeCard'
import { useContext } from 'react'
import { StepPagesContext } from '../common/StepPagesContext'
type Props = {}

function Step3({}: Props) {
  // let { data: officerReviewData } = useQuery('학종-공동체-평가', () =>
  //   memberAxios
  //     .get('/pearlyd/sr/officer-student-evaludate/avg-score/gongdong/details')
  //     .then((x) => x.data as 학종_사정관평가데이터_형식)
  // )

  const { setBundle, commentList, scoreList, avg, setStep3Avg } = useContext(StepPagesContext)

  const convertTotalScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 15; i < 24; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 10
    setStep3Avg(scoreAvg)
    if (scoreAvg >= 6) {
      return 'A+'
    } else if (scoreAvg > 5) {
      return 'A'
    } else if (scoreAvg > 4) {
      return 'B+'
    } else if (scoreAvg > 3) {
      return 'B'
    } else if (scoreAvg > 2) {
      return 'C+'
    } else if (scoreAvg > 1) {
      return 'C'
    } else if (scoreAvg > 0) {
      return 'D'
    } else {
      return '-'
    }
  }

  const convertSection1ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 15; i < 18; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 3
    return Number(scoreAvg).toFixed(0)
  }

  const convertSection2ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 18; i < 21; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 3
    return Number(scoreAvg).toFixed(0)
  }

  const convertSection3ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 21; i < 23; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 2
    return Number(scoreAvg).toFixed(0)
  }

  const convertSection4ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 23; i < 25; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 2
    return Number(scoreAvg).toFixed(0)
  }

  let totalGrade = convertTotalScoreToGrade()
  let section1Grade = convertSection1ScoreToGrade()
  let section2Grade = convertSection2ScoreToGrade()
  let section3Grade = convertSection3ScoreToGrade()
  let section4Grade = convertSection4ScoreToGrade()

  const data = [
    {
      name: '협업과 소통능력',
      grade: Number(section1Grade),
    },
    {
      name: '나눔과 배려',
      grade: Number(section2Grade),
    },
    {
      name: '성실성과 규칙준수',
      grade: Number(section3Grade),
    },
    {
      name: '리더십',
      grade: Number(section4Grade),
    },
  ]

  return (
    <>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px', height: '350px' }}>
        <AlphabetGradeChart data={data} />
      </TurtlePaper>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px' }}>
        <Typography variant="H3_Bold, 28, 36" sx={{ color: '#000' }}>
          공동체역량
        </Typography>
        <Stack mt={5} gap={'30px'}>
          <ExplainSection
            title="협업과 소통능력"
            items={[
              {
                text: '단체 활동 과정에서 서로 돕고 함께 행동하는 모습이 보이는가?',
                grade: JSON.stringify(scoreList[15].grade),
              },
              {
                text: '구성원들과 협력을 통하여 공동의 과제를 수행하고 완성한 경험이 있는가?',
                grade: JSON.stringify(scoreList[16].grade),
              },
              {
                text: '타인의 의견에 공감하고 수용하는 태도를 보이며, 자신의 정보와 생각을 잘 전달하는가?',
                grade: JSON.stringify(scoreList[17].grade),
              },
            ]}
          />
          <ExplainSection
            title="나눔과 배려"
            items={[
              {
                text: '학교생활 속에서 나눔을 생활화한 경험이 있는가?',
                grade: JSON.stringify(scoreList[18].grade),
              },
              {
                text: '타인을 위하여 양보하거나 배려를 실천한 구체적 경험이 있는가?',
                grade: JSON.stringify(scoreList[19].grade),
              },
              {
                text: '상대를 이해하고 존중하는 노력을 기울이고 있는가?',
                grade: JSON.stringify(scoreList[20].grade),
              },
            ]}
          />
          <ExplainSection
            title="성실성과 규칙준수"
            items={[
              {
                text: '교내 활동에서 자신이 맡은 역할에 최선을 다하려고 노력한 경험이 있는가?',
                grade: JSON.stringify(scoreList[21].grade),
              },
              {
                text: '자신이 속한 공동체가 정한 규칙과 규정을 준수하고 있는가?',
                grade: JSON.stringify(scoreList[22].grade),
              },
            ]}
          />
          <ExplainSection
            title="리더십"
            items={[
              {
                text: '공동체의 목표를 달성하기 위해 계획하고 실행을 주도한 경험이 있는가?',
                grade: JSON.stringify(scoreList[23].grade),
              },
              {
                text: '구성원들의 인정과 신뢰를 바탕으로 참여를 이끌어내고 조율한 경험이 있는가?',
                grade: JSON.stringify(scoreList[24].grade),
              },
            ]}
          />
        </Stack>
      </TurtlePaper>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px' }}>
        <Stack>
          <Typography variant="D2_exBold, 16, 24" mb={'10px'}>
            사정관 주석
          </Typography>
          <Typography variant="D4_regular, 16, 24">{commentList[2].comment}</Typography>
        </Stack>
      </TurtlePaper>
      <GradeCard title="공동체역량" grade={totalGrade} />
      <StepMoveButtonGroup />
    </>
  )
}

export default Step3
