export const 지역 = [
  '전국',
  '서울',
  '경기',
  '인천',
  '대전',
  '세종',
  '충남',
  '충북',
  '광주',
  '전남',
  '전북',
  '대구',
  '경북',
  '경남',
  '부산',
  '울산',
  '강원',
  '제주',
] as const
export type 지역_타입 = (typeof 지역)[number]

export const 교과_세부전형 = ['지역인재', '지역균형', '그 외 일반'] as const
export type 교과_세부전형_타입 = (typeof 교과_세부전형)[number]

export const 교과_전형 = ['일반전형', '고른기회 특별전형', '특성화고교 졸업자전형', '농어촌 학생전형']
export type 교과_전형_타입 = (typeof 교과_전형)[number]
