import { Box, ButtonBase, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material'
import { createColumnHelper } from '@tanstack/react-table'
import { memberAxios } from 'api/instance'
import { FilterBoxLayout } from 'components/common/FilterBox'
import IconO from 'components/common/IconO'
import IconX from 'components/common/IconX'
import StackX from 'components/common/StackX'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import useSmoothLoading from 'hooks/useSmoothLoading'
import { useContext, useMemo, useState } from 'react'
import { GrPowerReset } from 'react-icons/gr'
import { useQuery } from 'react-query'
import BaseTable, { baseTableStyle2 } from '../common/BaseTable'
import ConditionMessage from '../common/ConditionMessage'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import { getSelected } from 'utils/util'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import InfoBadge from 'components/InfoBadge'

type Props = {}

type EarlydSubjectNonSubjectActive = {
  recruitmentUnitIds: string
  collegeName: string
  typeName: string
  nonSubjectRatio: string
  attendance: 0 | 1
  volunteer: 0 | 1
  lifeRecord: 0 | 1
}

const Step3 = (props: Props) => {
  const { stepState, pageSize } = useContext(StepPagesContext)

  const [filter, setFilter] = useState<{
    출결: 'All' | 0 | 1
    봉사활동: 'All' | 0 | 1
    정성평가: 'All' | 0 | 1
    비교과: 'All' | 0 | 1
  }>({
    출결: 'All',
    봉사활동: 'All',
    정성평가: 'All',
    비교과: 'All',
  })

  const recruitmentUnitIdList = stepState[2].bundle.selected?.flatMap((x: number) => x)

  const { data, isFetching } = useQuery(['step3-비교과', recruitmentUnitIdList.join(',')], () => {
    return memberAxios
      .post('/pearlyd/subject/nonsubject-active/colleges', {
        recruitmentUnitIdList,
        nonSubjectTypeList: [0, 1, 2, 3],
      })
      .then((x) => x.data.earlydSubjectNonSubjectActiveList as EarlydSubjectNonSubjectActive[])
  })

  const isLoadingSmooth = useSmoothLoading({ isLoading: isFetching })

  let dataList = useMemo(() => {
    return (data ?? []).filter((row) => {
      if (filter['비교과'] !== 'All' && row.nonSubjectRatio === '0%') return false
      if (filter['출결'] !== 'All' && row.attendance !== filter['출결']) return false
      if (filter['봉사활동'] !== 'All' && row.volunteer !== filter['봉사활동']) return false
      if (filter['정성평가'] !== 'All' && row.lifeRecord !== filter['정성평가']) return false
      return true
    })
  }, [filter, data])

  const [rowSelection, setRowSelection] = useBundledRowSelection(dataList, (_, identifier) => {
    return identifier
  })

  const columnHelper = createColumnHelper<EarlydSubjectNonSubjectActive>()

  const makeOXIcon = (x: 1 | 0) => (x === 1 ? <IconO size={18} /> : <IconX size={18} />)

  const columns = [
    columnHelper.accessor('recruitmentUnitIds', {
      id: 'recruitmentUnitIds',
    }),
    columnHelper.accessor('collegeName', {
      header: () => '대학명',
      minSize: 150,
    }),
    columnHelper.accessor('typeName', {
      header: () => '전형명',
    }),
    columnHelper.accessor('nonSubjectRatio', {
      header: () => '비교과비율',
    }),
    columnHelper.accessor('attendance', {
      cell: (info) => makeOXIcon(info.getValue()),
      header: () => '출결',
      filterFn: 'equals',
    }),
    columnHelper.accessor('volunteer', {
      cell: (info) => makeOXIcon(info.getValue()),
      header: () => '봉사',
      filterFn: 'equals',
    }),
    columnHelper.accessor('lifeRecord', {
      cell: (info) => makeOXIcon(info.getValue()),
      header: () => '정성평가',
      filterFn: 'equals',
    }),
  ]

  const filterButtons = (['비교과', '출결', '봉사활동', '정성평가'] as const).map((text) => (
    <Box
      key={text}
      sx={{
        pt: 1,
        pr: 1.5,
        '& .MuiInputBase-root::after, .MuiInputBase-root::before': {
          border: 0,
        },
        '& .MuiInputBase-root:hover': {
          border: '0 !important',
        },
      }}
    >
      <Typography variant="D4_regular, 16, 24" sx={{}}>
        <FormControl variant="standard">
          <StackX
            sx={{
              '& .MuiInputBase-formControl': { ml: '4px !important' },
            }}
          >
            <Typography>{text}</Typography>
            <Select
              value={filter[text] as any}
              sx={{
                ml: 1,
                fontFamily: 'Roboto',
                fontSize: '14px',
                '& .MuiSelect-select svg': { marginBottom: '3px' },
              }}
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  [text]: e.target.value,
                }))
              }}
            >
              <MenuItem value={'All'} sx={{ bgcolor: '#fff' }}>
                All
              </MenuItem>
              <MenuItem value={1}>{text === '비교과' ? <비교과_옵션 isInclude /> : <IconO />}</MenuItem>
              <MenuItem value={0}>{text === '비교과' ? <비교과_옵션 isInclude={false} /> : <IconX />}</MenuItem>
            </Select>
          </StackX>
        </FormControl>
      </Typography>
    </Box>
  ))

  filterButtons.push(
    <ButtonBase
      component="div"
      key={'reset'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pt: 1,
        gap: 0.2,
        textDecoration: 'underline',
        mb: -0.5,
        ml: 2,
      }}
      onClick={() => {
        setFilter({
          봉사활동: 'All',
          비교과: 'All',
          정성평가: 'All',
          출결: 'All',
        })
      }}
    >
      <Typography fontSize={'15px'}>초기화</Typography>
      <GrPowerReset />
    </ButtonBase>
  )

  const isAnyRowSelected = getSelected(rowSelection).length > 0

  return (
    <SubtitleSection
      titleSection={
        <Stack mb={3} direction={'row'}>
          <Typography variant="H4_Exbold, 24, 32"> 비교과</Typography>
          <InfoBadge
            ml={2}
            tooltipContent={
              <Box width={'280px'}>
                <Typography variant="H4_Exbold, 24, 32">학생부교과에서 비교과?</Typography>
                <Typography mt={1} fontSize={'14px'} lineHeight={'24px'} color={'#656565'}>
                  학생부교과 전형임에도 비교과를 포함하는 경우가 있어요. 보통 출결, 봉사활동, 세부적인 생기부 내용, 세
                  가지 방식이에요.
                </Typography>
                <Stack direction={'row'} mt={3}>
                  <Typography color={'#FF5600'} variant="D3_bold, 16, 24">
                    {'ex)'}
                  </Typography>
                  <Box ml={1}>
                    <Typography color={'#252525'} variant="D3_bold, 16, 24">
                      서강대 : 출결
                    </Typography>
                    <br />
                    <Typography color={'#252525'} variant="D3_bold, 16, 24">
                      성균관대 : 학업수월성 + 학업충실성
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            }
            placement="right"
          />
        </Stack>
      }
    >
      <FilterBoxLayout title="비교과" mb={4}>
        <StackX pl={2}>{filterButtons}</StackX>
      </FilterBoxLayout>
      <TurtlePaper
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <BaseTable
          isLoading={isLoadingSmooth}
          enablePagination
          pageSize={pageSize}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          tableStyle={baseTableStyle2}
          columns={columns}
          data={dataList}
          enableSelection
          columnVisibility={{ recruitmentUnitIds: false }}
          getRowId={(row) => {
            return row.recruitmentUnitIds
          }}
          searchInfo={{ label: '대학명 검색', value: 'collegeName', mode: true }}
        />
      </TurtlePaper>
      <ConditionMessage show={!isAnyRowSelected} text="체크 박스를 선택해주십시오." />
      <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />
    </SubtitleSection>
  )
}

export default Step3

const 비교과_옵션 = ({ isInclude }: { isInclude: boolean }) => (
  <Typography sx={{ fontFamily: 'nanumsquare_regular', fontSize: '1rem', marginBottom: '-1px', fontWeight: 700 }}>
    {isInclude ? '포함' : '미포함'}
  </Typography>
)
