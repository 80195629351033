import './style.scss'

interface Props {
  scienceFilter: string[]
  onClickFilterButton: (type: string) => void
}

const ScienceFilter = ({ scienceFilter, onClickFilterButton }: Props) => {
  return (
    <>
      <div className="selectUnivConditionContainer">
        <div className="title">
          <p>과학 가능과목 선택</p>
        </div>
        <div className="selectBox">
          <div>
            <div className="selectLine">
              <p>수리논술</p>
              {[
                { label: '전체', code: '' },
                { label: '물1', code: 'mulOne' },
                { label: '화1', code: 'hwaOne' },
                { label: '생1', code: 'saengOne' },
                { label: '지1', code: 'jiOne' },
                { label: '통합과학', code: 'totalScience' },
                { label: '물1+물2', code: 'mul' },
                { label: '화1+화2', code: 'hwa' },
                { label: '생1+생2', code: 'saeng' },
                { label: '지1+지2', code: 'ji' },
              ].map((cond) => {
                return (
                  <button
                    onClick={() => onClickFilterButton(cond.code)}
                    className={
                      scienceFilter.length === 9 ? 'select' : scienceFilter.includes(cond.code) ? 'select' : ''
                    }
                    key={cond.label}
                  >
                    {cond.label}
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </>
  )
}

export default ScienceFilter
