import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import './style.scss'
import { subjectsCode } from 'constants/subjectCode'
import { Button } from '@mui/material'
import { useGradeInput } from './useGradeInput'
import TitleSection from 'components/layout/TitleSection'

const uploadCourse = [
  {
    main: '업로드할 업체를 선택해주세요.',
    sub: '',
  },
  {
    main: 'html 파일을 다운받아 성적을 입력해주세요.',
    sub: '이미 파일이 있다면 PASS!!',
  },
  {
    main: '입력하고 저장한 html 파일을 선택해주세요.',
    sub: '',
  },
  {
    main: '업로드 해주세요.',
    sub: '기존에 입력된 파일이 있다면 자동 삭제됩니다.',
  },
]

const tagGooList = [
  {
    label: '사회탐구',
    value: 'society',
  },
  {
    label: '과학탐구',
    value: 'science',
  },
]

const GradeInput = () => {
  let {
    // inputType,
    scores,
    // file,
    subjectCodes,
    isCanSaveScore,
    tamGooList,
    gradeType,
    markScores,
    examInfo,
    mockScoreFlag,
    onChangeTamgooType,
    onChagneScore,
    onChangeSelectSubject,
    onClickSaveScore,
    onClickExamMonth,
    // onChagneFileUpload,
    // onClickFileUpload,
  } = useGradeInput()

  return (
    // <TitleSection title={examInfo !== null ? `모의고사 ${examInfo.mockexamMonth}월` : '모의고사'}>
    <TitleSection title={'모의고사'}>
      <div id="gradeInput">
        {/* -------------------------------------- */}
        <p className="title"></p>
        {/* <div className="inputDataTypeButtonBox off">
        <button onClick={() => onClickInputTypeBtn("score")} className={`${inputType === "file" && "off"}`}>
          성적 직접 입력
        </button>
      
      </div> */}
        {/* -------------------------------------------------------- 파일 업로드 --------------------------------------------------------*/}
        {/* 디자인 변경으로 사용 안함 */}
        {/* {inputType === "file" && (
        <div className="uploadScreen">
          <p className="title">내신 성적 파일 업로드</p>
          <div className="uploadCourseBox">
            {uploadCourse.map((course, index) => {
              return (
                <div className="stepRow" key={"uploadCours" + course.main}>
                  <div className="step">{index + 1} Step</div>
                  <div className="info">
                    <p className="main">{course.main}</p>
                    {course.sub !== "" && <p className="sub">{course.sub}</p>}
                  </div>
                  <div className="events">
                    {index === 0 && (
                      <>
                        <a href="https://www.jinhak.com/" target="_blank" rel="noreferrer" className="company">
                          진학사
                        </a>
                        <a href="http://www.uway.com/" target="_blank" rel="noreferrer" className="company">
                          유웨이
                        </a>
                      </>
                    )}
                
                    {index === 2 && <input type="file" accept="html" onChange={(e) => onChagneFileUpload(e.target.files)} />}
                    {index === 3 && (
                      <Button onClick={onClickFileUpload} disabled={file === null} className={`upload ${file === null && "disable"}`} variant="contained">
                        저장하기
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <p className="title">거북스쿨만의 내신 분석</p>
          <div className="uploadInfoBox">
            <p>
              수시의 모든 전형을 Z점수로 내신 반영하는 대학이 연세대, 고대, 시립대 등 밖에 없지만, 학생부종합 전형에서는 많은 대학들이 z점수와 내신을 적절하게 조합해서 사용하고 있습니다. 그렇기
              때문에, 학생의 z 점수 분석은 필수이며, 이 z 점수를 파악하기 위해서는 내신 입력시 단위수와 평점만 입력하는 것이 아니라, 원점수, 평균, 편차, 성취도 등 아래 입력난을 모두 입력하셔야 합니다.
              진학사든 유웨이든 어느 한곳에 이런 모든것을 입력하셨다면, 그것을 받아서 입력하시면 되며, 반대로 거북스쿨에서 입력하시면 다운 받아서 다른곳에 입력도 가능합니다.
            </p>
          </div>
          <div className="uploadInfoBox">
            <p>
              <b> 업로드 실패 이유 TOP7</b>
              <br />
              <br /> 1. 실제 학생부에 있는 과목명이 아니라 약식으로 과목명을 입력한 경우 <br />
              2. 성적에 소수점 대신 콤마 또는 문자가 입력되어 있는 경우 <br />
              3. 학년 입력 하나씩 빼먹은 경우
              <br /> 4. 각각 1학기, 2학기에 이수한 두 개의 과목을 한 줄에 입력한 경우
              <br /> 5. 소수점 첫째 자리 이상 입력되어 있는 경우 (평균점수/표준편차를 제외하고는 정수, 평균점수/표준편차는 소수점 첫째 자리까지만 입력)
              <br /> 6. 동일한 과목을 두 줄에 입력한 경우 (ex. 국어 1-2학기 이수 {`->`} 한 줄에 1학기, 한 줄에 2학기 각각 입력)
              <br /> 7. 파일형식이 xls인 경우 (엑셀파일은 반드시 xlsx로 저장하여야 합니다.)
            </p>
          </div>
        </div>
      )} */}
        {/* -------------------------------------------------------- 파일 업로드 --------------------------------------------------------*/}
        {/* /-- 원점수 성적 입력 --/*/}
        <>
          <div className="scoreInputContainer">
            <div className="monthButtonBox">
              {mockScoreFlag === 0 ? (
                <button key={'nowExamMonthMbtn : 원점수'} className={`on`} onClick={() => onClickExamMonth('원점수')}>
                  원점수
                </button>
              ) : (
                <button
                  key={'nowExamMonthMbtn : 표점/등급'}
                  className={`on`}
                  onClick={() => onClickExamMonth('표점/등급')}
                >
                  표점/등급
                </button>
              )}
              {/*{["원점수", "표점/등급"].map((type) => {*/}
              {/*  return (*/}
              {/*    <button key={"nowExamMonthMbtn : " + type} className={`${type === gradeType && "on"}`} onClick={() => onClickExamMonth(type)}>*/}
              {/*      {type}*/}
              {/*    </button>*/}
              {/*  );*/}
              {/*})}*/}
            </div>
            {mockScoreFlag === 0 ? (
              <table className="inputTable">
                <tbody>
                  <tr>
                    <th>국어</th>
                    <th>수학</th>
                    <th>영어</th>
                    <th>한국사</th>
                    <th>탐구1</th>
                    <th>탐구2</th>
                    <th>제2외국어</th>
                  </tr>
                  <tr>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          name="kor"
                          className="select"
                          value={subjectCodes[1].subjectCode}
                          onChange={(e) => {
                            if (e.target.value) onChangeSelectSubject(e.target.value, 1)
                          }}
                        >
                          {subjectsCode.kor.select.map((subject) => {
                            return (
                              <MenuItem key={subject.subjectCode} value={subject.subjectCode} {...subject}>
                                {subject.label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          className="select"
                          value={subjectCodes[3].subjectCode}
                          onChange={(e) => {
                            if (e.target.value) onChangeSelectSubject(e.target.value, 3)
                          }}
                        >
                          {subjectsCode.math.select.map((subject) => {
                            return (
                              <MenuItem key={subject.subjectCode} value={subject.subjectCode}>
                                {subject.label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={tamGooList[0]}
                          onChange={(e) => {
                            if (e.target.value === 'society' || e.target.value === 'science')
                              onChangeTamgooType(e.target.value, 0)
                          }}
                        >
                          {tagGooList.map(({ label, value }) => {
                            return (
                              <MenuItem key={'first_tamgoo_ ' + value} value={value}>
                                {label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={tamGooList[1]}
                          onChange={(e) => {
                            if (e.target.value === 'society' || e.target.value === 'science')
                              onChangeTamgooType(e.target.value, 1)
                          }}
                        >
                          {tagGooList.map(({ label, value }) => {
                            return (
                              <MenuItem key={'first_tamgoo_ ' + value} value={value}>
                                {label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        onChange={(e) => {
                          onChagneScore(e.target.value, 0)
                        }}
                        placeholder="공통 원점수"
                        value={scores[0] ?? ''}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(e) => {
                          onChagneScore(e.target.value, 2)
                        }}
                        placeholder="공통 원점수"
                        value={scores[2] ?? ''}
                      />
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={subjectCodes[6].subjectCode}
                          disabled={tamGooList[0] === null}
                          onChange={(e) => {
                            if (e.target.value) onChangeSelectSubject(e.target.value, 6)
                          }}
                        >
                          {tamGooList[0] !== null &&
                            subjectsCode[tamGooList[0]].select.map((subject) => {
                              return (
                                <MenuItem key={'the second ' + subject.subjectCode} value={subject.subjectCode}>
                                  {subject.label}
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={subjectCodes[7].subjectCode}
                          disabled={tamGooList[1] === null}
                          onChange={(e) => {
                            if (e.target.value) onChangeSelectSubject(e.target.value, 7)
                          }}
                        >
                          {tamGooList[1] !== null &&
                            subjectsCode[tamGooList[1]].select.map((subject) => {
                              return (
                                <MenuItem key={'the second ' + subject.subjectCode} value={subject.subjectCode}>
                                  {subject.label}
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={
                            subjectCodes[8].subjectCode === undefined || subjectCodes[8].subjectCode === null
                              ? 'none'
                              : subjectCodes[8].subjectCode
                          }
                          onChange={(e) => {
                            if (e.target.value) onChangeSelectSubject(e.target.value, 8)
                          }}
                        >
                          <MenuItem key={'none'} value={'none'}>
                            선택 없음
                          </MenuItem>
                          {subjectsCode.lang.select.map((subject) => {
                            return (
                              <MenuItem key={'the second ' + subject.subjectCode} value={subject.subjectCode}>
                                {subject.label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    {[1, 3, 4, 5, 6, 7, 8].map((scoreIndex, index) => {
                      return (
                        <td key={index + 'score'}>
                          <input
                            onChange={(e) => {
                              onChagneScore(e.target.value, scoreIndex)
                            }}
                            disabled={
                              subjectCodes[scoreIndex].subjectCode === null ||
                              subjectCodes[scoreIndex].subjectCode === 'none'
                            }
                            placeholder={[1, 3].includes(scoreIndex) ? '세부과목 원점수' : '원점수'}
                            value={scores[scoreIndex] ?? ''}
                          />
                        </td>
                      )
                    })}
                  </tr>
                </tbody>
              </table>
            ) : (
              <table className="inputTable">
                <tbody>
                  <tr>
                    <th>국어</th>
                    <th>수학</th>
                    <th>영어</th>
                    <th>한국사</th>
                    <th>탐구1</th>
                    <th>탐구2</th>
                    <th>제2외국어</th>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={tamGooList[0]}
                          onChange={(e) => {
                            if (e.target.value === 'society' || e.target.value === 'science')
                              onChangeTamgooType(e.target.value, 0)
                          }}
                        >
                          {tagGooList.map(({ label, value }) => {
                            return (
                              <MenuItem key={'first_tamgoo_ ' + value} value={value}>
                                {label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={tamGooList[1]}
                          onChange={(e) => {
                            if (e.target.value === 'society' || e.target.value === 'science')
                              onChangeTamgooType(e.target.value, 1)
                          }}
                        >
                          {tagGooList.map(({ label, value }) => {
                            return (
                              <MenuItem key={'first_tamgoo_ ' + value} value={value}>
                                {label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          name="kor"
                          className="select"
                          value={subjectCodes[1].subjectCode}
                          onChange={(e) => {
                            if (e.target.value) onChangeSelectSubject(e.target.value, 1)
                          }}
                        >
                          {subjectsCode.kor.select.map((subject) => {
                            return (
                              <MenuItem key={subject.subjectCode} value={subject.subjectCode} {...subject}>
                                {subject.label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          className="select"
                          value={subjectCodes[3].subjectCode}
                          onChange={(e) => {
                            if (e.target.value) onChangeSelectSubject(e.target.value, 3)
                          }}
                        >
                          {subjectsCode.math.select.map((subject) => {
                            return (
                              <MenuItem key={subject.subjectCode} value={subject.subjectCode}>
                                {subject.label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={subjectCodes[6].subjectCode}
                          disabled={tamGooList[0] === null}
                          onChange={(e) => {
                            if (e.target.value) onChangeSelectSubject(e.target.value, 6)
                          }}
                        >
                          {tamGooList[0] !== null &&
                            subjectsCode[tamGooList[0]].select.map((subject) => {
                              return (
                                <MenuItem key={'the second ' + subject.subjectCode} value={subject.subjectCode}>
                                  {subject.label}
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={subjectCodes[7].subjectCode}
                          disabled={tamGooList[1] === null}
                          onChange={(e) => {
                            if (e.target.value) onChangeSelectSubject(e.target.value, 7)
                          }}
                        >
                          {tamGooList[1] !== null &&
                            subjectsCode[tamGooList[1]].select.map((subject) => {
                              return (
                                <MenuItem key={'the second ' + subject.subjectCode} value={subject.subjectCode}>
                                  {subject.label}
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>
                    </td>
                    <td>
                      <FormControl className="subjectSelectForm">
                        <Select
                          id="kor-subject"
                          className="select"
                          value={
                            subjectCodes[8].subjectCode === undefined || subjectCodes[8].subjectCode === null
                              ? 'none'
                              : subjectCodes[8].subjectCode
                          }
                          onChange={(e) => {
                            if (e.target.value) {
                              onChangeSelectSubject(e.target.value, 8)
                            }
                          }}
                        >
                          <MenuItem key={'none'} value={'none'}>
                            선택 없음
                          </MenuItem>
                          {subjectsCode.lang.select.map((subject) => {
                            console.log(subject)

                            return (
                              <MenuItem key={'the second ' + subject.subjectCode} value={subject.subjectCode}>
                                {subject.label}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    {[1, 3, 4, 5, 6, 7, 8].map((scoreIndex, index) => {
                      return (
                        <td key={index + 'score'}>
                          <input
                            onChange={(e) => {
                              onChagneScore(e.target.value, scoreIndex)
                            }}
                            disabled={
                              subjectCodes[scoreIndex].subjectCode === null ||
                              subjectCodes[scoreIndex].subjectCode === 'none'
                            }
                            placeholder={
                              gradeType === '원점수'
                                ? [4, 5].includes(scoreIndex)
                                  ? '등급'
                                  : '세부과목 표준점수'
                                : [4, 5, 8].includes(scoreIndex)
                                ? '등급'
                                : '세부과목 표준점수'
                            }
                            value={markScores[scoreIndex] ?? ''}
                          />
                        </td>
                      )
                    })}
                  </tr>
                </tbody>
              </table>
            )}
          </div>

          {/* /-- 표준점수 성적 입력 --/*/}

          <Button
            disabled={!isCanSaveScore}
            onClick={onClickSaveScore}
            className={`saveBtn ${isCanSaveScore ? 'able' : 'disable'}`}
            variant="contained"
          >
            저장하기
          </Button>
        </>
        {/* -------------------------------------------------------- 성적 입력 --------------------------------------------------------*/}
      </div>
    </TitleSection>
  )
}

export default GradeInput
