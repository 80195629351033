import { createColumnHelper } from '@tanstack/react-table'
import { TextBox, TitledItem, 수시전형_상세보기_테이블스타일 } from 'DetailModal/common'
import TurtleTable2 from 'components/common/TurtleTable2'
import { useContext } from 'react'
import { SubjectDetailModalContext as EssayDetailModalContext } from './K_상세보기_논술모달_내용'

type Props = {}

const 기본정보테이블 = () => {
  const { region, competitionRate, centerType, admissionSeriesName, essayType, studentRecruitmentNum } =
    useContext(EssayDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('region', {
      header: '지역',
    }),
    columnHelper.accessor('competitionRate', {
      header: '작년 경쟁률',
    }),
    columnHelper.accessor('centerType', {
      header: '기본전형',
    }),
    columnHelper.accessor('admissionSeriesName', {
      header: '논술유형',
    }),
    columnHelper.accessor('essayType', {
      header: '전형명',
    }),
    columnHelper.accessor('studentRecruitmentNum', {
      header: '모집인원',
    }),
  ]

  return (
    <TurtleTable2
      columns={columns}
      data={[
        {
          region,
          competitionRate,
          centerType,
          admissionSeriesName,
          essayType,
          studentRecruitmentNum,
        },
      ]}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const 전형방법테이블 = () => {
  const { selectionModel, typeRate } = useContext(EssayDetailModalContext)

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('selectionModel', {
      header: '선발모형',
    }),
    columnHelper.accessor('typeRate', {
      header: '전형방법',
    }),
  ]

  const data = [
    {
      selectionModel,
      typeRate,
    },
  ]

  return (
    <TurtleTable2
      columns={columns}
      sx={{ '& td:first-of-type': { bgcolor: '#e9e9e9' } }}
      data={data}
      tableStyle={수시전형_상세보기_테이블스타일}
    />
  )
}

const K_상세보기_전형정보탭 = (props: Props) => {
  const { lowestText } = useContext(EssayDetailModalContext)

  return (
    <>
      <TitledItem title="기본정보">
        <기본정보테이블 />
      </TitledItem>
      <TitledItem title="지원자격">
        <TextBox>{'TODO : 지원자격 프로퍼티 알아내기'}</TextBox>
      </TitledItem>
      <TitledItem title="전형방법">
        <전형방법테이블 />
      </TitledItem>
      <TitledItem title="최저등급">
        <TextBox>{lowestText === '-' ? '없음' : lowestText}</TextBox>
      </TitledItem>
    </>
  )
}

export default K_상세보기_전형정보탭
