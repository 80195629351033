import { Stack } from '@mui/material'
import { Box } from '@mui/system'
import HeaderMenu from 'components/block/headermenu/HeaderMenu'
import CombinationPage from 'components/pages/strategy/CombinationPage/CombinationPage'
import InterestUniv from 'components/pages/strategy/InterestUniversityPage/InterestUniversityPage'
import SideNav from 'components/pages/suSi/components/sideNav/SideNavigation'
import HakJong from 'components/pages/suSi/hakJong'
import 자가평가_페이지 from 'components/pages/suSi/inputAndAnalysis/K_자가평가_페이지'
import InputAndAnalysis from 'components/pages/suSi/inputAndAnalysis/gradeInput'
import HakJongInput from 'components/pages/suSi/inputAndAnalysis/hakJongInput'
import SaJungDan from 'components/pages/suSi/inputAndAnalysis/saJungDan'
import K_비교과성적페이지 from 'components/pages/suSi/inputAndAnalysis/비교과/K_비교과성적페이지'
import NonSulLiGwa from 'components/pages/suSi/nonSul/ligwa'
import NonSul from 'components/pages/suSi/nonSul/main'
import NonSulMunGwa from 'components/pages/suSi/nonSul/mungwa'
import UiChiHanYacSu from 'components/pages/suSi/nonSul/uiChiHanYacSu'
import Officer from 'components/pages/suSi/officer'
import K_교과_페이지 from 'components/pages/suSi/교과/K_교과'
import K_학종_페이지 from 'components/pages/suSi/학종/K_학종'
import { pageContentConstraint } from 'constants/style'
import { Route, Routes } from 'react-router-dom'
import LocationIndicator from './LocationIndicator'
import K_거북스쿨사용법_페이지 from 'components/pages/suSi/사용법/K_거북스쿨사용법_페이지'
import Compatibility from 'components/pages/suSi/inputAndAnalysis/departmentCompatibility/index'

const SuSiRoutes = () => {
  return (
    <Stack sx={{ width: '100vw', flex: 1 }}>
      {/* <HeaderMenu /> */}
      <LocationIndicator sx={{}} />
      <Stack alignItems={'center'}>
        <Stack
          className="susi"
          sx={{
            ...pageContentConstraint,
            padding: '0px !important',
            justifyContent: 'center',
          }}
        >
          <Stack
            sx={(theme) => ({ display: 'flex', [theme.breakpoints.down('md')]: { flexDirection: 'column' } })}
            direction={'row'}
            alignItems={'stretch'}
          >
            <SideNav />
            <Box sx={{ overflowX: 'auto', flex: 1 }}>
              <Routes>
                <Route path="inputAndAnalysis/howTo" element={<K_거북스쿨사용법_페이지 />}></Route>
                <Route path="inputAndAnalysis/gradeInput" element={<InputAndAnalysis />}></Route>
                <Route path="inputAndAnalysis/departmentCompatibility" element={<Compatibility />}></Route>
                <Route path="inputAndAnalysis/saJungDan" element={<SaJungDan />}></Route>
                <Route path="inputAndAnalysis/nonSubject" element={<K_비교과성적페이지 />}></Route>
                <Route path="inputAndAnalysis/hakJongInput" element={<HakJongInput />}></Route>
                <Route path="inputAndAnalysis/self" element={<자가평가_페이지 />}></Route>

                <Route path="nonSul" element={<NonSul />}></Route>
                <Route path="nonSul/munGwa" element={<NonSulMunGwa />}></Route>
                <Route path="nonSul/liGwa" element={<NonSulLiGwa />}></Route>
                <Route path="nonSul/uiChiHanYacSu" element={<UiChiHanYacSu />}></Route>

                <Route path="strategy/interestUniv" element={<InterestUniv />}></Route>
                <Route path="strategy/combination" element={<CombinationPage />}></Route>

                <Route path="officer/*" element={<Officer />}></Route>
                <Route path="gyoGwa/searchUniv/*" element={<K_교과_페이지 />}></Route>
                <Route path="hakJong/*" element={<K_학종_페이지 />}></Route>
                {/* <Route path="test/*" element={<HakJong />}></Route> */}
              </Routes>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SuSiRoutes
