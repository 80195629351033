import { clientAxios, memberAxios } from 'api/instance'
import {
  gradeListAPI,
  highSchoolsAPI,
  menuCodeLineListAPI,
  menuCodeSubjectListAPI,
  mockScheduleListAPI,
  studentTypeAPI,
  subjectMenuAPI,
} from 'api/urls'

export interface grade {
  bottomCodeType: string
  bottomName: string
  id: number
  topCodeType: string
  topName: '학년타입'
}
;[]

export const gradeListFetch = async (): Promise<grade[]> => {
  const res = await memberAxios.get(gradeListAPI)
  console.log('res [ ', res, ' ] ')

  return res.data
}

export interface studentType {
  bottomCodeType: string
  bottomName: string
  id: number
  topCodeType: string
  topName: '학생타입'
}

export const studentTypesFetch = async (): Promise<studentType[]> => {
  const res = await memberAxios.get(studentTypeAPI)

  return res.data
}

export interface highschool {
  id: number
  highschoolName: string
  highschoolType: string
  highschoolRegion: string
}

export const highSchoolsFetch = async (): Promise<highschool[]> => {
  const res = await clientAxios.get(highSchoolsAPI, {
    params: {
      page: 0,
      pageSize: 3000,
      searchWord: '',
    },
  })

  return res.data
}

type subjectMenuReturn = {
  id: string
  collegeSubject: '국어' | '수학' | '영어' | '한국사' | '사회탐구' | '과학탐구' | '제2외국어'
  collegeSubSubject: string
  collegeCode: string
}[]

export const subjectMenuFetch = async (): Promise<subjectMenuReturn> => {
  const res = await clientAxios.get(subjectMenuAPI)

  return res.data
}

interface schedule {
  id: number
  mockexamMonth: string
  mockexamYear: string
}

export const mockScheduleListFetch = async (): Promise<schedule[]> => {
  const res = await clientAxios.get(mockScheduleListAPI)

  console.log('res : ', res)
  return res.data
}

/**
 * 대중소 계열 리스트
 */
export const codeLineListFetch = (type: string, series: string) => {
  // const res = await
  console.log
}

/**
 * 학종 성적입력 일반교과목 , 진로선택과목 가져오기
 */
export const menuCodeSubjectListFetch = async (mainSubjectCode: string, subjectType: 0 | 1) => {
  const res = await memberAxios.get(menuCodeSubjectListAPI, { params: { mainSubjectCode, subjectType } })

  console.log('menuCodeSubjectListFetch : ', res.data)
}

/**
 * 대중소 계열 리스트
 */
export const menuCodeLineListFetch = async (params: { series: string; type: 'G' | 'M' | 'R' }): Promise<string[]> => {
  const res = await memberAxios.post(menuCodeLineListAPI, params)
  return res.data
}
