import './style.scss'

interface Props {
  suriNonsulFilter: string[]
  onClickNonsulFilterButton: (type: string) => void
}

const SuriNonsulSelectFilter = ({ suriNonsulFilter, onClickNonsulFilterButton }: Props) => {
  return (
    <>
      <div className="selectUnivConditionContainer">
        <div className="title">
          <p>수학 가능과목 선택</p>
        </div>
        <div className="selectBox">
          <div>
            <div className="selectLine">
              <p>수리논술</p>
              {[
                { label: '전체', code: '' },
                { label: '수1,수2', code: 'ES2' },
                { label: '확통', code: 'ES3' },
                { label: '미적', code: 'ES4' },
                { label: '기하', code: 'ES5' },
              ].map((cond) => {
                return (
                  <button
                    onClick={() => onClickNonsulFilterButton(cond.code)}
                    className={
                      suriNonsulFilter.length === 4 ? 'select' : suriNonsulFilter.includes(cond.code) ? 'select' : ''
                    }
                    key={cond.label}
                  >
                    {cond.label}
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
    </>
  )
}

export default SuriNonsulSelectFilter
