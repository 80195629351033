import { Typography, SxProps, StackProps } from '@mui/material'
import Stack from '@mui/material/Stack'
import StackX from 'components/common/StackX'
import React, { PropsWithChildren, ReactNode } from 'react'

type Props = {
  title?: string
  titleSection?: ReactNode
  subtitle?: string
  children?: ReactNode
} & StackProps

const SubtitleSection = ({ title, titleSection, children, subtitle, ...rest }: PropsWithChildren<Props>) => {
  const stackProps = rest as StackProps
  const { sx } = stackProps
  return (
    <Stack sx={sx} {...stackProps}>
      <StackX alignItems={'end'} gap={'10px'}>
        {titleSection ? (
          titleSection
        ) : (
          <Typography variant="H4_Exbold, 24, 32" mb={3} fontWeight={700}>
            {title}
          </Typography>
        )}

        <Typography
          sx={{
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '14px',
            color: '#9a9a9a',
          }}
          mb={3}
        >
          {subtitle}
        </Typography>
      </StackX>

      {children}
    </Stack>
  )
}

export default SubtitleSection
