import { mockScheduleListFetch } from 'api/fetches/menu'
import { mockCheckInputFetch } from 'api/fetches/mock'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useCheckUserInputGrade = () => {
  const navigate = useNavigate()
  const [scheduleId, setScheduleId] = useState<null | number>(2)

  useEffect(() => {
    checkIsInputGradeDone()
  }, [])

  const checkIsInputGradeDone = async () => {
    const schedule = await mockScheduleListFetch()
    const res = await mockCheckInputFetch(schedule[0].mockexamMonth)
    if (res.status === false) {
      alert('해당 서비스는 성적 입력 후 이용하실 수 있습니다.')
      navigate('/suSi/inputAndAnalysis/gradeInput')
    } else {
      setScheduleId(res.scheduleId)
    }
  }

  return { scheduleId }
}
