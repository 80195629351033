import { Box, ButtonBase, Paper, Stack, Typography } from '@mui/material'
import { earlydSubjectListFetch } from 'api/fetches/earlyd'
import Title from 'components/atom/text/title'
import TitleSection from 'components/layout/TitleSection'
import { useEffect, useState } from 'react'
import { GiSaveArrow } from 'react-icons/gi'
import SimpleBackdrop from '../../../../common/LoadingBlock'
import './style.scss'
import { useHakJongInput } from './useHakJongInput'

import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import helpIconSrc from 'assets/icon/Help.png'
import htmlGuide from 'assets/images/html_guide.png'
import pdfGuide from 'assets/images/pdf_guide.png'
interface gyoGwaCode {
  [key: string]: {
    label: string
    value: string
    list?: { label: string; value: string; type: string }[]
  }
}
import arrowForwardIcon from 'assets/icon/arrow_forward_2.png'

import StackX from 'components/common/StackX'
import { mainColor } from 'constants/style'
import CourseGyogwaLine from './CourseGyoGwaLine'
import NormalGyoGwaInput from './NormalGyoGwaInput'
import { useRecoilValue } from 'recoil'
import userInfoStates from 'atoms/userInfoStates'

const HakJongInput = () => {
  const {
    schoolrecordSubjectLearningList,
    schoolrecordSelectSubjectList,
    schoolrecordAttendanceDetailList,
    isCanHtmlUpload,
    isPdfUpload: isPdfAlreadyUploaded,
    onChangeHTMlFile,
    isCanPdfUpload,
    onChangeNormalGyogwaValue,
    onChagneCourseGyogwaValue,
    onChagneAttendanceValue,
    onClickAddOrDelLine,
    onClickSaveGrade,
    onClickNormalAddOrDelLine,
    openFlag,
  } = useHakJongInput()

  console.log(isCanHtmlUpload, isCanPdfUpload, isPdfAlreadyUploaded)

  const userInfo = useRecoilValue(userInfoStates)
  useEffect(() => {
    const graduateYear = Number(userInfo?.graduateYear)
    if (Number.isNaN(graduateYear)) {
      return
    }
    if (graduateYear > new Date().getFullYear() + 1) {
      setIs재학생(false)
    }
  }, [userInfo])

  const [gyoGwaCode, setGyoGwaCode] = useState<gyoGwaCode>({
    HH1: {
      label: '국어',
      value: 'HH1',
      list: [],
    },
    HH2: {
      label: '수학',
      value: 'HH2',
      list: [],
    },
    HH3: {
      label: '영어',
      value: 'HH3',
      list: [],
    },
    HH4: {
      label: '사회(역사/도덕포함)',
      value: 'HH4',
      list: [],
    },
    HH5: {
      label: '과학',
      value: 'HH5',
      list: [],
    },
    HH6: {
      label: '기술・가정/제2외국어/한문/교양',
      value: 'HH6',
      list: [],
    },
    HH7: {
      label: '한국사',
      value: 'HH7',
      list: [],
    },
    HH8: {
      label: '체육',
      value: 'HH7',
      list: [],
    },
    HH9: {
      label: '예술',
      value: 'HH7',
      list: [],
    },
    HH10: {
      label: '기타',
      value: 'HH10',
      list: [],
    },
  })

  /**
   * 교과 , 과목 셋팅
   */
  useEffect(() => {
    const subjectListData = earlydSubjectListFetch()
    const k: any = {}
    subjectListData.then((res) => {
      res.data.forEach((item: any) => {
        if (k[item.mainSubjectCode] === undefined) {
          k[item.mainSubjectCode] = {
            label: item.mainSubjectName,
            value: item.mainSubjectCode,
            list: [{ label: item.subjectName, value: item.subjectCode, type: item.type }],
          }
        } else {
          k[item.mainSubjectCode] = {
            label: item.mainSubjectName,
            value: item.mainSubjectCode,
            list: [
              ...k[item.mainSubjectCode].list,
              { label: item.subjectName, value: item.subjectCode, type: item.type },
            ],
          }
        }
      })

      setGyoGwaCode(k)
    })
    //setGyoGwaCode(gyoGwaList);
  }, [])

  useEffect(() => {}, [openFlag])

  const [is재학생, setIs재학생] = useState(true)

  const inactiveColor = '#C2C2C2'

  return (
    <TitleSection title="생기부 입력" mt={'40px'}>
      <Box id="hakJongInput">
        <ExplainBox />
        <Stack direction={'row'} mb={'49px'}>
          <ButtonBase
            onClick={() => {
              setIs재학생(true)
            }}
            sx={{
              flex: 1,
              border: `1px solid ${is재학생 ? mainColor : inactiveColor}`,
              py: '9px',
              color: is재학생 ? mainColor : inactiveColor,
            }}
          >
            <Typography variant="H4_Exbold, 24, 32">재학생용</Typography>
          </ButtonBase>
          <ButtonBase
            onClick={() => {
              setIs재학생(false)
            }}
            sx={{
              flex: 1,
              border: `1px solid ${!is재학생 ? mainColor : inactiveColor}`,
              py: '9px',
              color: !is재학생 ? mainColor : inactiveColor,
            }}
          >
            <Typography variant="H4_Exbold, 24, 32">졸업생용</Typography>
          </ButtonBase>
        </Stack>
        {is재학생 ? (
          <재학생용_업로드폼
            onChangeHTMLFile={onChangeHTMlFile}
            isCanHtmlUpload={isCanHtmlUpload}
            isPdfAlreadyUploaded={isPdfAlreadyUploaded}
          />
        ) : (
          <졸업생용_업로드폼
            onChangeHTMLFile={onChangeHTMlFile}
            isGraduatePdfUpload={isCanPdfUpload}
            isEvaluationPdfAlreadyUploaded={isPdfAlreadyUploaded}
          />
        )}

        <Box sx={{ my: '40px', height: '1px', bgcolor: '#a8a8a8' }} />

        <Title size="medium">3학년 전체 교과성적 입력</Title>
        <Paper className="tableContainer mb-4" sx={{ fontFamily: 'nanumsquare_regular', mt: 2 }} elevation={4}>
          <Stack justifyContent={'space-between'} direction={'row'} mb={1}>
            <Typography variant="D3_bold, 16, 24">일반 교과목</Typography>
            <div className="col-1 d-flex justify-content-end">
              <button onClick={() => onClickNormalAddOrDelLine(true)}>+</button>
              <button onClick={() => onClickNormalAddOrDelLine(false)}>-</button>
            </div>
          </Stack>
          <Box className="d-flex" sx={{ fontSize: '14px', mb: 0.5 }}>
            <p className="col-1">학기</p>
            <p className="col-1">교과</p>
            <div className="col-1"></div>
            <p className="col-1">과목</p>
            <div className="col-1"></div>
            <p className="col-1">단위수</p>
            <p className="col-3">원점수/과목평균(표준편차)</p>
            <p className="col-2">성취도(수강자수)</p>
            <p className="col-1">석차등급</p>
          </Box>
          {schoolrecordSubjectLearningList.map((schoolrecordSubjectLearningItem, index) => {
            return (
              <NormalGyoGwaInput
                index={index}
                key={schoolrecordSubjectLearningItem.mainSubjectCode + 'normal' + index}
                onChangeNormalGyogwaValue={onChangeNormalGyogwaValue}
                schoolrecordSubjectLearningItem={schoolrecordSubjectLearningItem}
                gyoGwaCode={gyoGwaCode}
              />
            )
          })}

          {/* 진로선택 UI */}
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="D3_bold, 16, 24">진로선택과목</Typography>
            <div className="col-1 d-flex justify-content-end">
              <button onClick={() => onClickAddOrDelLine(true)}>+</button>
              <button onClick={() => onClickAddOrDelLine(false)}>-</button>
            </div>
          </Stack>
          <Box className="d-flex mb-2" sx={{ fontSize: '14px', mb: 0.5 }}>
            <p className="col-1">학기</p>
            <p className="col-1">교과</p>
            <div className="col-1"></div>
            <p className="col-1">과목</p>
            <p className="col-1">단위수</p>
            <p className="col-2">원점수/과목평균</p>
            <p className="col-2">
              성취도
              <br />
              (수강자수)
            </p>
            <p className="col-2">
              성취도별
              <br />
              분포비율
            </p>
          </Box>
          {schoolrecordSelectSubjectList.map((schoolrecordSelectSubjectItem, index) => {
            return (
              <CourseGyogwaLine
                key={index + 'course'}
                index={index}
                schoolrecordSelectSubjectItem={schoolrecordSelectSubjectItem}
                onChagneCourseGyogwaValue={onChagneCourseGyogwaValue}
                gyoGwaCode={gyoGwaCode}
              />
            )
          })}
        </Paper>
        {schoolrecordAttendanceDetailList.map((schoolrecordAttendanceDetailItem, index) => {
          return (
            <div key={schoolrecordAttendanceDetailItem.absentDisease + index} className="tableContainer attendance">
              <table>
                <tr className="tableHeader">
                  <td rowSpan={2}>학년</td>
                  <td rowSpan={2}>수업일수</td>
                  <td colSpan={3}>결석일수</td>
                  <td colSpan={3}>지각</td>
                  <td colSpan={3}>조퇴</td>
                  <td colSpan={3}>결과</td>
                </tr>
                <tr className="tableHeader">
                  <td>질병</td>
                  <td>무단</td>
                  <td>기타</td>
                  <td>질병</td>
                  <td>무단</td>
                  <td>기타</td>
                  <td>질병</td>
                  <td>무단</td>
                  <td>기타</td>
                  <td>질병</td>
                  <td>무단</td>
                  <td>기타</td>
                </tr>
                <tbody>
                  <tr className="inputLine">
                    <td>3</td>
                    {[
                      'classDays',
                      'absentDisease',
                      'absentUnrecognized',
                      'absentEtc',
                      'lateDisease',
                      'lateUnrecognized',
                      'lateEtc',
                      'leaveEarlyDisease',
                      'leaveEarlyUnrecognized',
                      'leaveEarlyEtc',
                      'resultDisease',
                      'resultUnrecognized',
                      'resultEarlyEtc',
                    ].map((key, index) => {
                      return key === 'grade' || key === 'etc' ? null : (
                        <td style={{ alignItems: 'center', justifyContent: 'center' }}>
                          <input
                            value={schoolrecordAttendanceDetailItem[key]}
                            onChange={(e) => onChagneAttendanceValue(0, key, e.target.value)}
                            type="text"
                            style={{ margin: 'auto' }}
                          ></input>
                        </td>
                      )
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          )
        })}
        <Stack sx={{ justifyContent: 'end' }}>
          <button className="saveBtn" onClick={onClickSaveGrade} style={{ borderRadius: 6 }}>
            저장하기
            <GiSaveArrow fontSize={12} style={{ marginTop: '2px' }} />
          </button>
        </Stack>
      </Box>
      <SimpleBackdrop openFlag={openFlag} />
    </TitleSection>
  )
}

export default HakJongInput

function 재학생용_업로드폼({
  onChangeHTMLFile,
  isCanHtmlUpload,
  isPdfAlreadyUploaded,
}: {
  onChangeHTMLFile: any
  isCanHtmlUpload: boolean
  isPdfAlreadyUploaded: boolean
}) {
  const buttonStyle = {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '5px',
    background: '#F7F7F7',
    height: '40px',
  }

  const buttonTextStyle = {
    color: '#2B2B2B',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    width: '170px',
  }

  const [htmlOpen, setHtmlOpen] = React.useState(false)
  // 이미지 원본 사이즈
  const originalWidth = 1290
  const originalHeight = 13836

  // 상태: 브라우저의 너비와 이미지의 높이
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState((window.innerWidth * originalHeight) / originalWidth)

  // 창 크기가 변경될 때마다 실행
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight((window.innerWidth * originalHeight) / originalWidth)
    }

    // 이벤트 리스너 등록
    window.addEventListener('resize', handleResize)

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleClickOpen = () => {
    setHtmlOpen(true)
  }

  const handleClose = () => {
    setHtmlOpen(false)
  }
  return (
    <Stack gap={5}>
      <Box>
        <Box mb={2}>
          <Typography variant="H4_Exbold, 24, 32">내신점수 계산용 파일</Typography>
        </Box>
        <Box mb="12px">
          <StackX width="100%">
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
                letterSpacing: '-1px',
                marginRight: '40px',
                minWidth: '180px',
              }}
            >
              생기부 HTML 파일
            </Typography>
            <StackX>
              <UploadButton
                id="재학생-계산용"
                fileType="html"
                onChange={(e: any) => onChangeHTMLFile(e, 'html', false)}
                isNotUploaded={isCanHtmlUpload}
              />

              <ButtonBase sx={[buttonStyle, { ml: 1 }]} onClick={handleClickOpen}>
                <Typography sx={buttonTextStyle}>
                  HTML 다운로드 안내
                  <img src={helpIconSrc} alt="help" style={{ width: 'auto', height: 'auto', marginLeft: '4px' }} />
                </Typography>
              </ButtonBase>
            </StackX>
          </StackX>
        </Box>
        <Box>
          <Stack>
            <Typography variant="D3_bold, 16, 24" color="#9A9A9A">
              내신점수 계산용 파일은 1회만 입력할 수 있습니다
            </Typography>
            <Typography variant="D3_bold, 16, 24" color="#9A9A9A" mt={'12px'}>
              3학년 성적은 계산되지 않습니다. 하단의 ‘교과성적 입력'란을 기입해주세요.
            </Typography>
          </Stack>
        </Box>
      </Box>
      {/* <Box>
        <Box mb={2}>
          <Typography variant="H4_Exbold, 24, 32">생기부 평가용 파일</Typography>
        </Box>
        <Box mb="12px">
          <StackX width="100%">
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
                letterSpacing: '-1px',
                marginRight: '40px',
                minWidth: '180px',
              }}
            >
              생기부 PDF 파일
            </Typography>
            <StackX>
              <UploadButton
                id="재학생용-평가용"
                fileType="pdf"
                onChange={(e: any) => onChangeHTMLFile(e, 'pdf', false)}
                isNotUploaded={!isPdfAlreadyUploaded}
                canModify
              />
              <a
                target="_blank"
                href={
                  'https://prod-imgtobucket.s3.ap-northeast-2.amazonaws.com/imageupload/%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%AE24_pdf%E1%84%87%E1%85%A1%E1%86%AF%E1%84%80%E1%85%B3%E1%86%B8.jpg'
                }
              >
                <ButtonBase sx={[buttonStyle, { ml: 1 }]}>
                  <Typography sx={buttonTextStyle}>
                    PDF 다운로드 안내
                    <img src={helpIconSrc} alt="help" style={{ width: 'auto', height: 'auto', marginLeft: '4px' }} />
                  </Typography>
                </ButtonBase>
              </a>
            </StackX>
          </StackX>
        </Box>
        <Box>
          <Stack>
            <Typography variant="D3_bold, 16, 24" color="#9A9A9A">
              위 파일은 입력횟수에 제한이 없습니다.
            </Typography>
          </Stack>
        </Box>
      </Box> */}
      <React.Fragment>
        <Dialog fullScreen open={htmlOpen} onClose={handleClose}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                HTML 다운로드 안내
              </Typography>
            </Toolbar>
          </AppBar>
          {/* htmlGuide 이미지 변경시 originalWidth와 originalHeight를 변경된 이미지 사이즈에
          맞춰서 변경해주어야함 */}
          <img src={htmlGuide} alt="help" style={{ width: width, height: height, marginLeft: '4px' }} />
        </Dialog>
      </React.Fragment>
    </Stack>
  )
}

function 졸업생용_업로드폼({
  onChangeHTMLFile,
  isEvaluationPdfAlreadyUploaded,
  isGraduatePdfUpload,
}: {
  onChangeHTMLFile: any
  isEvaluationPdfAlreadyUploaded: boolean
  isGraduatePdfUpload: boolean
}) {
  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '5px',
    background: '#F7F7F7',
    px: '10px',
    height: '40px',
  }

  const buttonTextStyle = {
    color: '#2B2B2B',
    textAlign: 'center',
    fontFamily: 'Pretendard',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    width: '170px',
  }

  const [pdfOpen, setPdfOpen] = React.useState(false)

  const handleClickOpen = () => {
    setPdfOpen(true)
  }

  const handleClose = () => {
    setPdfOpen(false)
  }

  return (
    <Stack gap={5}>
      <Box>
        <Box mb={2}>
          <Typography variant="H4_Exbold, 24, 32">내신점수 계산용 파일</Typography>
        </Box>
        <Box mb="12px">
          <StackX width="100%">
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
                letterSpacing: '-1px',
                marginRight: '40px',
                minWidth: '180px',
              }}
            >
              생기부 PDF 파일
            </Typography>
            <StackX>
              <UploadButton
                id="졸업생용-계산용"
                fileType="pdf"
                onChange={(e: any) => onChangeHTMLFile(e, 'pdf', true)}
                isNotUploaded={isGraduatePdfUpload}
              />
              <ButtonBase sx={[buttonStyle, { ml: 1 }]} onClick={handleClickOpen}>
                <Typography sx={buttonTextStyle}>
                  PDF 다운로드 안내
                  <img src={helpIconSrc} alt="help" style={{ width: 'auto', height: 'auto', marginLeft: '4px' }} />
                </Typography>
              </ButtonBase>
            </StackX>
          </StackX>
        </Box>
        <Box>
          <Stack>
            <Typography variant="D3_bold, 16, 24" color="#9A9A9A">
              내신점수 계산용 파일은 1회만 입력할 수 있습니다
            </Typography>
          </Stack>
        </Box>
      </Box>
      {/* <Box>
        <Box mb={2}>
          <Typography variant="H4_Exbold, 24, 32">생기부 평가용 파일</Typography>
        </Box>
        <Box mb="12px">
          <StackX width="100%">
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
                letterSpacing: '-1px',
                marginRight: '40px',
                minWidth: '180px',
              }}
            >
              생기부 PDF 파일
            </Typography>
            <StackX>
              <UploadButton
                id="졸업생용-평가용"
                onChange={(e: any) => onChangeHTMLFile(e, 'pdf', false)}
                isNotUploaded={!isEvaluationPdfAlreadyUploaded}
                canModify
                fileType="pdf"
              />
              <a
                target="_blank"
                href={
                  'https://prod-imgtobucket.s3.ap-northeast-2.amazonaws.com/imageupload/%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%AE24_pdf%E1%84%87%E1%85%A1%E1%86%AF%E1%84%80%E1%85%B3%E1%86%B8.jpg'
                }
              >
                <ButtonBase sx={[buttonStyle, { ml: 1 }]}>
                  <Typography sx={buttonTextStyle}>
                    PDF 다운로드 안내
                    <img src={helpIconSrc} alt="help" style={{ width: 'auto', height: 'auto', marginLeft: '4px' }} />
                  </Typography>
                </ButtonBase>
              </a>
            </StackX>
          </StackX>
        </Box>
        <Box>
          <Stack gap={'12px'}>
            <Typography variant="D3_bold, 16, 24" color="#9A9A9A">
              위 파일은 입력횟수에 제한이 없습니다.
            </Typography>
            <Typography variant="D3_bold, 16, 24" color="#9A9A9A">
              졸업생의 경우 내신점수 계산용 파일과 동일한 파일을 업로드해주세요.
            </Typography>
          </Stack>
        </Box>
      </Box> */}
      <React.Fragment>
        <Dialog fullScreen open={pdfOpen} onClose={handleClose}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                PDF 다운로드 안내
              </Typography>
            </Toolbar>
          </AppBar>
          <img src={pdfGuide} alt="help" style={{ width: 'auto', height: 'auto', marginLeft: '4px' }} />
        </Dialog>
      </React.Fragment>
    </Stack>
  )
}

function UploadButton({
  onChange,
  id,
  isNotUploaded,
  canModify = false,
  fileType,
}: {
  onChange: any
  id: string
  isNotUploaded: boolean
  fileType: 'pdf' | 'html'
  canModify?: boolean
}) {
  return (
    <ButtonBase
      sx={{
        width: '147px',
        height: '40px',
        bgcolor: isNotUploaded ? mainColor : canModify ? mainColor : '#e5e5e5',
        borderRadius: '5px',
      }}
    >
      <Box
        component={'label'}
        htmlFor={id}
        sx={{
          '&:hover': { cursor: isNotUploaded || canModify ? 'pointer' : 'default' },
          borderRadius: '4px',
          fontSize: 14,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: isNotUploaded ? '#fefefe' : '#fefefe',
          fontWeight: 800,
        }}
      >
        {isNotUploaded ? (
          <>
            업로드 <img src={arrowForwardIcon} alt="arrow" style={{ width: 'auto', height: 'auto' }} />
          </>
        ) : canModify ? (
          '업로드'
        ) : (
          '입력완료'
        )}
      </Box>
      <Box
        component={'input'}
        sx={{ width: 0, height: 0 }}
        type="file"
        id={id}
        accept={fileType === 'html' ? 'text/html' : 'application/pdf'}
        disabled={isNotUploaded === false && !canModify}
        onChange={(e) => onChange(e, 'html', false)}
      />
    </ButtonBase>
  )
}

const ExplainBox = () => (
  <Box
    mt={2}
    sx={{
      display: 'flex',
      borderRadius: '12px',
      bgcolor: 'F4F4F4',
      padding: '12px 24px',
      alignItems: 'start',
      gap: '10px',
      backgroundColor: '#F4F4F4',
    }}
    mb={'28px'}
  >
    <Typography variant="D4_regular, 16, 24" sx={{ color: '#656565' }}>
      <Stack direction={'column'} gap={'24px'}>
        <Typography component={'p'}>
          3학년 1학기 HTML 파일은 오남용 방지를 위해 <strong>파일 입력을 1회로</strong> 제한하고 있습니다. 입력 후,
          수정은 불가하며, 수정을 원하실 경우, 기타 문의를 남겨주세요.
        </Typography>
        <Typography component={'p'}>
          PDF 파일은 사정관에게 평가를 신청하실 경우, 사정관이 평가하기 위한 활용지표로 보내집니다.
        </Typography>
        <Typography component={'p'}>3학년 1학기 교과성적은, 생활기록부의 양식에 맞게 입력하여 주세요.</Typography>
      </Stack>
    </Typography>
  </Box>
)
