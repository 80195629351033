import { UserInfo } from 'api/fetches/user'
import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist()
/**
 * getMissingPetListParams page, size 값만 제외
 */

export const initialUserInfoState = {
  id: null,
  address: null,
  email: null,
  graduateYear: null,
  gtypeId: null,
  highschoolName: null,
  hstTypeId: null,
  introduction: null,
  isOfficer: null,
  isTeacher: null,
  nickname: null,
  phone: null,
  profileImageUrl: null,
  stypeId: null,
  usingService: null,
  major: null,
  ckSmsAgree: false,
  serviceRangeCode: null,
}

export default atom<UserInfo>({
  key: 'userInfo',
  default: initialUserInfoState,
  effects_UNSTABLE: [persistAtom],
})
