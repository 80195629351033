import { Box } from '@mui/system'
import { MouseEventHandler , Suspense, lazy } from 'react'
const Footer = lazy(() => import('components/block/footer/Footer'));
const Header = lazy(() => import('components/block/header/Header'));
const MessageCard = lazy(() => import('components/layout/EmptyPageLayout'));
const ExplainUse = lazy(() => import('components/pages/explainUse'));
const Redirect = lazy(() => import('components/pages/redirect'));
const Home = lazy(() => import('../components/pages/home/index'));
const User = lazy(() => import('./routes/user'));
const K_정시 = lazy(() => import('components/pages/jungsi/K_정시'));

//import Footer from 'components/block/footer/Footer'
//import Header from 'components/block/header/Header'
//import MessageCard from 'components/layout/EmptyPageLayout'
// import ExplainUse from 'components/pages/explainUse'
// import Redirect from 'components/pages/redirect'
import Store from 'components/pages/store'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import Home from '../components/pages/home/index'
import SuSiRoutes from './routes/suSi'
// import User from './routes/user'
// import K_정시 from 'components/pages/jungsi/K_정시'

const RootRoutes = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#fff',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <BrowserRouter>
        <Header />
        <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          <Suspense fallback={<div>loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/redirect" element={<Redirect />}></Route>
              <Route path="/store/*" element={<Store />}></Route>
              <Route path="/user/*" element={<User />}></Route>
              <Route path="/suSi/*" element={<SuSiRoutes />}></Route>
              <Route path="/explain/*" element={<ExplainUse />}></Route>
              <Route path="/jungsi/*" element={<K_정시 />}></Route>

              <Route
                path="/*"
                element={
                  <div style={{ width: '90%' }}>
                    <MessageCard title="잘못된 경로" contents={'관리자 문의'} />
                  </div>
                }
              ></Route>
            </Routes>
          </Suspense>
        </Box>
      </BrowserRouter>
      <Footer />
    </div>
  )
}

export default RootRoutes
