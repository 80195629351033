import React, { ReactNode } from "react";
import "./style.scss";

interface Props {
  id: string;
  children: ReactNode;
}

const ContentsBox = ({ id, children }: Props) => {
  return (
    <article id={id} className="contentsBox">
      {children}
    </article>
  );
};

export default ContentsBox;
