import { Box, CSSObject, SxProps } from '@mui/material'
import React from 'react'
import './TurtleTable.scss'
type TurtleTableProps = {
  headerLabels: string[]
  rowNodeLists: React.ReactNode[][]

  stuffIfEmpty?: boolean
  numEmptyStuffRows?: number

  stuffMinimumRows?: boolean
  numMinimumRows?: number

  sx?: SxProps
}

const TurtleTable = ({
  headerLabels,
  rowNodeLists,
  stuffMinimumRows = true,
  numMinimumRows = 1,
  sx = {},
}: TurtleTableProps) => {
  let bodyContents = (
    <>
      {rowNodeLists.map((row, i) => (
        <tr key={i}>
          {row.map((cell, j) => (
            <td key={j}>{cell}</td>
          ))}
        </tr>
      ))}
    </>
  )

  const makeBlankRows = (numRows: number) =>
    Array(numRows)
      .fill(0)
      .map((_, j) => (
        <tr key={j}>
          {Array(headerLabels.length)
            .fill('-')
            .map((emptyContent, i) => (
              <td key={i}>{emptyContent}</td>
            ))}
        </tr>
      ))

  const isNeedStuffMinimum = rowNodeLists.length < numMinimumRows && stuffMinimumRows

  if (isNeedStuffMinimum) {
    bodyContents = (
      <>
        {bodyContents}
        {makeBlankRows(numMinimumRows - rowNodeLists.length)}
      </>
    )
  }

  return (
    <Box className="turtleTable" component="table" sx={sx}>
      <thead>
        <tr>
          {headerLabels.map((title, i) => (
            <th key={i}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>{bodyContents}</tbody>
    </Box>
  )
}

export default TurtleTable
