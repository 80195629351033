import { Button, FormControl } from '@mui/base'
import { Box, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { Stack, padding } from '@mui/system'
import { createColumnHelper } from '@tanstack/table-core'
import { memberAxios } from 'api/instance'
import FilterBox, { createRadioButtonInfos } from 'components/common/FilterBox'
import MessageBox from 'components/common/MessageBox'
import StackX from 'components/common/StackX'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import ChartSkeleton from 'components/skeleton/ChartSkeleton'
import { 교과_세부전형, 교과_세부전형_타입, 교과_전형_타입, 지역, 지역_타입 } from 'constants/const'
import useSmoothLoading from 'hooks/useSmoothLoading'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import BaseTable, { baseTableStyle2 } from '../common/BaseTable'
import ConditionMessage from '../common/ConditionMessage'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import Step1Chart from './Step1Chart'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import { getSelected } from 'utils/util'
import InfoBadge from 'components/InfoBadge'

export type EarlydSubjectQualification = {
  collegeName: string
  myScore: number
  recruitmentUnitIds: string
  reflectRecruitmentUnit: string
  typeName: string
  typeTotalScore: string
  typeTotalScoreDetail: string
  cut50Detail: string
  cut70Detail: string
  cut50Min: string
  cut50Max: string
  myAverageRating: number
}

type 교과_세부전형_필터타입 = 교과_세부전형_타입 | '전체'

const Step1 = () => {
  const [admissionType, setAdmissionType] = useState<교과_전형_타입>('일반전형')
  const [detailType, setDetailType] = useState<교과_세부전형_필터타입>('전체')
  const [region, setRegion] = useState<지역_타입>('전국')
  const [tableData, setTableData] = useState<any>([])
  const preveTableData = useRef<any>()
  const [selectedUniv, setSelectedUniv] = useState<any>([])
  const preveSelectedUniv = useRef<any>()
  const is일반전형 = admissionType === '일반전형'

  useEffect(() => {
    setDetailType('전체')
  }, [is일반전형])

  //지역인재

  let applicationDetailedTypeList: any[] = []

  if (admissionType === '일반전형') {
    applicationDetailedTypeList =
      detailType === '전체'
        ? ['지역인재', '지역균형', '그외일반']
        : detailType === '그 외 일반'
          ? ['그외일반']
          : [detailType]
  }

  let { data, isFetching } = useQuery(['교과-대학검색-스텝1', region, admissionType, detailType], () => {
    return memberAxios
      .post<any>('/pearlyd/subject/qualification/colleges', {
        applicationDetailedTypeList: admissionType === '일반전형' ? applicationDetailedTypeList : [],
        basicTypeAdmission: {
          일반전형: '일반',
          '고른기회 특별전형': '고른기회전형',
          '특성화고교 졸업자전형': '특성화고교졸업자전형',
          '농어촌 학생전형': '농어촌학생전형',
        }[admissionType],
        region: region === '전국' ? 'all' : region,
      })
      .then((res) => res.data.earlydSubjectQualificationList as EarlydSubjectQualification[])
  })
  const isLoadingSmooth = useSmoothLoading({ isLoading: isFetching })

  const dataList = (data ?? [])!

  const [rowSelection, setRowSelection] = useBundledRowSelection(dataList, (dataList, id) => {
    return id
  })

  useEffect(() => {
    setRowSelection({})
  }, [admissionType])

  const columnHelper = createColumnHelper<EarlydSubjectQualification>()

  const gradeCell = (info: any) =>
    info.getValue()?.length < 1 ? (
      '-'
    ) : (
      <StackX>
        <Typography fontWeight={700}>{`${info.getValue()}`}</Typography>
        <Typography ml={0.1} fontSize={14} fontWeight={700}>
          등급
        </Typography>
      </StackX>
    )

  const columns = [
    columnHelper.accessor('recruitmentUnitIds', {
      id: 'recruitmentUnitIds',
    }),
    columnHelper.accessor('collegeName', {
      header: () => '대학명',
    }),
    columnHelper.accessor('typeName', {
      header: () => '전형명',
    }),
    columnHelper.accessor('reflectRecruitmentUnit', {
      header: () => '반영 모집단위',
      maxSize: 90,
    }),
    columnHelper.accessor('cut50Min', {
      header: () => '최초합컷',
      cell: gradeCell,
      maxSize: 90,
    }),
    columnHelper.accessor('cut50Max', {
      header: () => '추합컷',
      maxSize: 90,
      cell: gradeCell,
    }),
    columnHelper.accessor('myAverageRating', {
      header: () => '내 등급',
      cell: gradeCell,
      size: 65,
    }),
  ]

  const admissionTypeSelectForm = (
    <div>
      <FormControl>
        <RadioGroup
          aria-labelledby="전형 선택그룹"
          defaultValue="female"
          name="radio-buttons-group"
          value={admissionType}
        >
          <Grid container m={0}>
            {(['일반전형', '고른기회 특별전형', '특성화고교 졸업자전형', '농어촌 학생전형'] as const).map(
              (label, index) => (
                <Grid item key={index} xs={6} md={4} pr={2} pb={2}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => {
                      setAdmissionType(label)
                    }}
                  >
                    <Stack
                      direction={'row'}
                      sx={{ border: '1px solid #9A9A9A', boxShadow: '2px 2px 12px 0px rgba(0, 0, 0, 0.14)' }}
                      pr={2}
                      py={1}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Radio value={label} name={label} />
                      <Typography fontWeight={700}>{label}</Typography>
                    </Stack>
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </RadioGroup>
      </FormControl>
    </div>
  )

  const regionsFilterButtonInfo = createRadioButtonInfos(지역 as unknown as any, region, setRegion)
  const detailFilterButtonInfo = createRadioButtonInfos(['전체', ...교과_세부전형], detailType, setDetailType)

  const chartData = useMemo(() => {
    return dataList.map((item) => ({
      collegeName: item.collegeName,
      typeName: item.typeName,
      reflectRecruitmentUnit: item.reflectRecruitmentUnit,
      myGrade: item.myAverageRating,
      range: item.cut50Detail !== null ? item.cut50Detail?.trim().split('~').map(Number) : [0, 0],
    }))
  }, [region, admissionType, detailType, data])

  const fnClickChartX = (value: any) => {
    let itemList = [...preveTableData.current]
    let univList = [...preveSelectedUniv.current]
    if (itemList.length == 0) {
      for (let i = 0; i < dataList.length; i++) {
        if (dataList[i].collegeName == value) {
          itemList.push(dataList[i])
        }
      }
      univList.push(value)
      setSelectedUniv(univList)
      setTableData(itemList)
    } else {
      let findKey = false
      for (let j = 0; j < itemList.length; j++) {
        if (itemList[j].collegeName == value) findKey = true
      }
      if (findKey) {
        let newList = itemList.filter((param: any) => param.collegeName != value)
        let newUnivList = univList.filter((param: any) => param != value)
        setTableData(newList)
        setSelectedUniv(newUnivList)
      } else {
        for (let i = 0; i < dataList.length; i++) {
          if (dataList[i].collegeName == value) {
            itemList.push(dataList[i])
          }
        }
        univList.push(value)
        setSelectedUniv(univList)
        setTableData(itemList)
      }
    }
  }

  const fnSearchSelectedUniv = (title: any) => {
    return selectedUniv.includes(title)
  }

  useEffect(() => {
    preveTableData.current = tableData
  }, [tableData])

  useEffect(() => {
    preveSelectedUniv.current = selectedUniv
  }, [selectedUniv])

  // const chart = <Step1Chart data={chartData} />
  const chart = useMemo(
    () => <Step1Chart data={chartData} fnClickChartX={fnClickChartX} fnSearchSelectedUniv={fnSearchSelectedUniv} />,
    [chartData, selectedUniv]
  )

  const isAnyRowSelected = getSelected(rowSelection).length > 0
  return (
    <>
      <SubtitleSection title="전형선택" sx={{ mb: 4 }}>
        {admissionTypeSelectForm}
      </SubtitleSection>
      <SubtitleSection title="지역선택" sx={{ mb: 4 }}>
        <FilterBox
          title="지역 선택"
          buttonInfos={regionsFilterButtonInfo}
          sx={is일반전형 ? { borderBottom: '0' } : {}}
        />
        {is일반전형 && (
          <FilterBox
            title="세부 전형"
            buttonInfos={detailFilterButtonInfo}
            infoBadge={
              <InfoBadge
                mt={0.5}
                tooltipContent={
                  <Box width={'270px'}>
                    <Typography variant="H4_Exbold, 24, 32">지역인재? 지역균형?</Typography>
                    <Box mt={2}>
                      <Typography variant="D3_bold, 16, 24">지역인재</Typography>
                      <Typography fontSize={'12px'} mt={1}>
                        본인의 고등학교 소재지와 같은 지역의 대학에 지역인재 전형이 있으면 가산점을 받을 수 있어요
                      </Typography>
                    </Box>
                    <Box height={'1px'} bgcolor={'#E9E9E9'} my={'12px'}></Box>
                    <Box mt={2}>
                      <Typography variant="D3_bold, 16, 24">지역균형</Typography>
                      <Typography fontSize={'12px'} mt={1}>
                        2021년부터 학교장 추천을 지원자격으로 요구하는 전형들은 ‘지역균형전형’으로 명칭을 통일했어요.
                        지역균형 = 학교장 추천 으로 외우면 알기 쉬워요.
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            }
          />
        )}
      </SubtitleSection>
      <SubtitleSection title="대학선택" sx={{ mb: 4 }}>
        <TurtlePaper sx={{ mb: 4, height: '620px', pt: 0, pb: 1 }}>
          {isLoadingSmooth ? (
            <ChartSkeleton />
          ) : chartData.length === 0 ? (
            <MessageBox title="해당 데이터가 존재하지 않습니다." />
          ) : (
            <>
              <Typography sx={{ padding: 3, paddingBottom: 1 }}>대학명 클릭 시 아래 표에 추가 됩니다.</Typography>
              {chart}
            </>
          )}
        </TurtlePaper>
        <TurtlePaper sx={{ mb: 6, px: 4, py: 2 }}>
          <BaseTable
            isLoading={isLoadingSmooth}
            getRowId={(row) => {
              return String(row.recruitmentUnitIds)
            }}
            enablePagination
            pageSize={20}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            tableStyle={baseTableStyle2}
            columns={columns}
            data={tableData}
            enableSelection
            columnVisibility={{
              recruitmentUnitIds: false,
            }}
            searchInfo={{ label: '대학명 검색', value: 'collegeName', mode: true }}
          />
        </TurtlePaper>
        <ConditionMessage show={!isAnyRowSelected} text="체크 박스를 선택해주십시오." />
        <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />
      </SubtitleSection>
    </>
  )
}

export default Step1
