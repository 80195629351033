import { CSSObject } from '@mui/material'

export const mainColor = '#FF5600'
export const greyFontColor = '#9A9A9A'

export const fontSize1 = 36
export const fontSize2 = 32
export const fontSize4 = 20

export const mainFontColor = '#212121'

export const pageContentConstraint: CSSObject = {
  width: 'clamp(320px, 100%, 1200px)',
  paddingLeft: '5px',
  paddingRight: '5px',
}

export const borderGreyColor1 = '#C2C2C2'

const tabletMaxWidth = 1200
export const tabletMediaQuery = `@media (max-width: ${tabletMaxWidth}px)`

const mobileMaxWidth = 900
export const mobileMediaQuery = `@media (max-width: ${mobileMaxWidth}px)`
