import exp from 'constants'
import { earlydSubjectListFetch } from './fetches/earlyd'

// auth
export const socialLoginAPI = 'auth/login/oauth2'
export const signUpAPI = 'auth/signup'
export const socialSignUpAPI = 'auth/signup/oauth2'
export const emailLoginAPI = 'auth/login'
export const authReissueAPI = 'auth/reissue'
export const logoutAPI = 'auth/logout'
export const newEmailPwAPI = 'auth/findpw'

// sms
export const smsAuthSendAPI = 'sms/auth/send'
export const smsAuthCheckAPI = 'sms/auth/check'
export const smsAuthPwFindSendAPI = 'sms/auth/pwfind/send'

// earlyd 수시
export const earlydLifeRecordUploadAPI = 'earlyd/lr/fileupload'
export const earlydThreeGradeHtmlFileUploadAPI = 'earlyd/three/grade/html/fileupload'
export const earlydThreeGradePdfFileUploadAPI = 'earlyd/three/grade/pdf/fileupload'

export const earlydThreeGradePdfFileUploadGrduateAPI = 'earlyd/three/graduate/pdf/fileupload'

export const earlydStudentSchoolRecordFileUploadCheckAPI = 'earlyd/student/schoolrecord/fileupload/check'
export const earlydAddThreerdAPI = 'earlyd/add/threerd'
export const earlydSrSearchListAPI = 'earlyd/sr/search/list'
export const earlydStudentSchoolrecordListAPI = 'earlyd/student/schoolrecord/list'
export const earlydSubjectCodeAllAPI = 'menu/code/subject/all/list'

// pearlyd 수시학종 유료조회
export const pearlydSrRqListAPI = 'pearlyd/sr/rq/list'
export const pearlydSrSearchListAPI = 'pearlyd/sr/search/list'
export const pearlySubjectRistInfoAPI = 'pearlyd/subject/rist/info'
export const pearlySrGyoListAPI = 'pearlyd/sr/gyo/list'
export const pearlydSrDepartInfoAPI = 'pearlyd/sr/depart/info'
export const pearlydSrTotalListAPI = 'pearlyd/sr/total/list'
export const pearlydSrCiListAPI = 'pearlyd/sr/ci/list'
export const pearlydSrLowestListAPI = 'pearlyd/sr/lowest/list'
export const pearlydSrInterestAddAPI = 'pearlyd/sr/interest/add'

// user
export const userAPI = 'user/me'
export const userUpdateAPI = 'user/update'

//mock
export const mockCheckAPI = 'mock/check'
export const mockAddListAPI = 'mock/add/list'
export const mockAddScoreAPI = 'mock/add/score'
export const mockAddMarksAPI = 'mock/add/marks'
export const mockCheckInputAPI = 'mock/check/input'
export const mockListRawAPI = 'mock/list/raw'
export const mockListMarksAPI = 'mock/list/marks'

// menu
export const gradeListAPI = 'menu/code/gt'
export const studentTypeAPI = 'menu/code/st'
export const highSchoolsAPI = 'menu/code/hst'
export const subjectMenuAPI = 'menu/college/subject/list'
export const mockScheduleListAPI = 'menu/mock/schedule/list'
export const codeLineListAPI = 'menu/code/line/list'
export const menuCodeSubjectListAPI = 'menu/code/subject/list'
export const menuCodeLineListAPI = 'menu/code/line/list'

//결제정보 fetch url
export const productListAPI = '/order/product/list'
export const productInfoAPI = '/order/product/info'
export const paymentDatailsAPI = '/pay/detail/list'
export const couponInfoAPI = '/pay/valid/coupon'
export const preRegiPaymentAPI = '/pay/payments/preregi'
export const getStodeCodeAPI = '/auth/store/code'
export const preAfterPaymentAPI = '/pay/payments/validate'
export const preAfterPaymentZeroAPI = '/pay/payments/zero/validate'
export const refundPaymentAPI = '/pay/payments/cancel/request'
export const orderDetailsInfoAPI = '/pay/detail/'

//결제 관련 새로 만든 API
export const selectProductList = '/order/selectProductList'
export const selectProductInfo = '/order/selectProductInfo'
export const newPreRegiPaymentAPI = '/newPay/preRegist'
export const newPayInsertOrder = '/newPay/insertOrder'
export const newPayInsertContract = '/newPay/insertContract'
export const newUpdateCouponUsed = '/newPay/updateCouponused'
export const newUpsertTicketCount = '/newPay/upsertTicketCount'

//essay
export const essayMockMarkListAPI = '/nessay/mock/marks/list'
export const essayAllListAPI = '/essay/all/list'
export const essayMunMathListAPI = '/essay/mun/mathen/list'
export const essayOtherListAPI = '/essay/other/list'
export const essayAbleInternalListAPI = '/essay/able/internal/list'
export const essayLowestGradeListAPI = '/essay/lowest/grade/list'
export const essayCrossApplyListAPI = '/essay/cross/apply/list'
export const essayAbleNaturalMathListAPI = '/essay/able/natural/math/list'
export const essayAbleScienceListAPI = '/essay/able/science/list'
export const essayAddInterestAPI = '/essay/add/interest'
export const essayInterestListAPI = '/essay/interest/list'
export const essayMedicalEtcListAPI = '/essay/medical/etc/list'
export const essayMedicalRegionListAPI = '/essay/medical/region/list'
export const essayAbleMedicalMathListAPI = '/essay/able/medical/math/list'
export const essayAbleScienceMedicalListAPI = '/essay/able/science/medical/list'

export const officerSeriesListAPI = '/menu/code/line/list'
export const officerProfileUpdateAPI = '/officer/profile/save'
export const officerProfileInfoAPI = '/officer/profile/info'
export const officerListAPI = '/officer/choose/list'
export const officerTicketCountAPI = '/officer/ticket/count'
export const officerUseTicketAPI = '/officer/map/record'
export const officerApplicationListAPI = '/officer/apply/list'
export const officerCancelTicketAPI = '/officer/cancel/ticket'
export const officerApplyStudentAPI = '/officer/apply/student/info'
export const officerSurveyListAPI = '/officer/survey/list'
export const officerSurveyAddAPI = '/officer/add/result'
export const officerSurveyAddAPIByMySelf = '/officer/self/add/result'
export const officerEvaluationListAPI = '/officer/survey/score/list'
export const officerStudentInfoAPI = '/officer/survey/student/info'
export const officerSurveyCompleteAPI = '/officer/survey/complete/list'

export const settingInfoAPI = '/setting/info'

export const officerFileDownloadAPI = '/officer/file/list'

//officer관련 새 함수 url
export const selectEvaluationList = '/newPearlyd/officer/selectEvaluationList'
export const selectEvaluationAvgAndGrade = '/newPearlyd/officer/selectEvaluationAvgAndGrade'
export const selectCommentAndScoreList = '/newPearlyd/officer/selectCommentAndScoreList'

//earlyd 새 함수
export const selectUnivList = '/newPearlyd/pearlyd/sr/gyo/list'
