import ContentsBox from 'components/block/contentsBox'
import React, { useEffect, useState } from 'react'
import './style.css'
import { Box, Button, ButtonBase, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import SaJungDanItem from './item'
import SeriesComponent from '../../components/series'
import {
  getApplicationListFetch,
  getOfficerListFetch,
  getTicketCountFetch,
  useTicketFetch,

} from '../../../../../api/fetches/officer'
import ApplicationList from './applyList'
import TitleSection from 'components/layout/TitleSection'
import OfficerProfileCard from './OfficerProfileCard'
import StackX from 'components/common/StackX'
import MessageBox from 'components/common/MessageBox'
import MessageCard from 'components/layout/EmptyPageLayout'
import { fetch_My사정관리스트 } from '../비교과/service'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { Stack } from '@mui/system'
import Center from 'components/common/Center'
export interface OfficerProfile {
  memberId: string
  // 출신대학
  university: string
  // 학과/학력
  education: string
  // 평가자이름
  officerName: string
  // 평가자프로파일
  officerProfileImage: string
  //대기자 수
  readyCount: string
}
;[]
interface officerApplicationInfo {
  officerId: string
  // 평가자이름
  officerName: string
  // 평가자프로파일
  progressStatus: string
  //대기자 수
  readyCount: string
}
;[]
const SaJungDan = () => {
  let navigate = useNavigate()
  const [officerList, setOfficerList] = useState<OfficerProfile[]>([])
  const [ticketCount, setTicketCount] = useState<any>(0)
  const [applicationList, setApplicationList] = useState<officerApplicationInfo[]>([])
  const [grandSeries, setGrandSeries] = React.useState<string | undefined>('대분류')
  const [middleSeries, setMiddleSeries] = React.useState('중분류')
  const [rowSeries, setRowSeries] = React.useState('소분류')

  const [searchButtonClicked, setSearchButtonClicked] = useState(false)

  const { data: evaluators } = useQuery('My사정관리스트', fetch_My사정관리스트)
  const isSelfEvaluationDone = false //evaluators?.some((x: any) => x.officerId === null) ?? true

  useEffect(() => {
    //getOfficerList('','','')
    getTicketCount()
    getApplicationList()
  }, [])

  const getOfficerList = async (grandSeries: string | undefined, middleSeries: string, rowSeries: string) => {
    const data = await getOfficerListFetch(grandSeries, middleSeries, rowSeries)
    const _list = data.officerProfileSearchList
    setOfficerList(_list)
  }
  const getTicketCount = async () => {
    const count = await getTicketCountFetch()
    setTicketCount(count)
  }
  //신청내역 리스트 출력
  const getApplicationList = async () => {
    const _data = await getApplicationListFetch()
    setApplicationList(_data)
  }


  const setSeries = (series: any) => {
    setGrandSeries(series.grandSeries)
    setMiddleSeries(series.middleSeries)
    setRowSeries(series.rowSeries)
  }

  const onClickSearchOfficerList = () => {
    if (grandSeries != '대분류' && middleSeries != '중분류' && rowSeries != '소분류') {
      getOfficerList(grandSeries, middleSeries, rowSeries)
      setSearchButtonClicked(true)
    } else {
      alert('계열 정보를 모두 입력해주세요')
    }
  }

  console.log(officerList, searchButtonClicked)

  const isNoEvaluators = !(officerList?.length >= 1) && searchButtonClicked

  return (
    <TitleSection title="사정관 평가신청">
      <Box
        mt={2}
        sx={{
          display: 'flex',
          borderRadius: '12px',
          bgcolor: 'F4F4F4',
          padding: '12px 24px',
          alignItems: 'start',
          gap: '10px',
          backgroundColor: '#F4F4F4',
        }}
      >
        <Typography variant="D4_regular, 16, 24" sx={{ color: '#656565' }}>
          <Stack direction={'column'} gap={1}>
            <Typography component={'p'}>
              비교과 분석은 전공별 전문 선생님이 직접 학생 개개인의 생기부, 자소서를 보고 평가합니다.
            </Typography>
            <Typography component={'p'}>
              평가는 평가 항목별로 A+, A, B+, B, C+, C, D, F 8단계로 평가되며, 결과에 대한 주석까지 함께 제공됩니다.
            </Typography>
            <Typography component={'p'}>
              이용절차는 전공별 선생님을 검색한후 선생님을 선택하고 아래 결제창에서 결제 후,
            </Typography>
            <Typography component={'p'}>
              세부 내용 입력창을 통해 구체적인 사항을 입력한 후 제출하시면 됩니다.
            </Typography>
          </Stack>
        </Typography>
      </Box>

      <Box mt="28px">
        <StackX alignItems={'end'}>
          <Typography variant="H4_Exbold, 24, 32" mr={5}>
            신청내역
          </Typography>
        </StackX>
        {applicationList.length > 0 ? (
          applicationList.map((item, index) => {
            return <ApplicationList key={index} applicationItem={item} />
          })
        ) : (
          <MessageCard title={'신청 내역이 없습니다.'}></MessageCard>
        )}
      </Box>

      <Box mt="28px">
        <StackX alignItems={'end'}>
          <Typography variant="H4_Exbold, 24, 32" mr={5}>
            목표계열
          </Typography>
          <Typography sx={{ color: '#9a9a9a' }} variant="D3_bold, 16, 24">
            목표계열을 선택하시면 해당 계열 사정관을 추천해 드려요!
          </Typography>
        </StackX>
        <StackX mt={2} border={'1px solid #C2C2C2'} borderTop={`2px solid #252525`}>
          <Box py="27px" px="38px" sx={{ color: '#252525', borderRight: '1px solid #C2C2C2' }}>
            <Typography sx={{ wordBreak: 'keep-all' }} variant="D2_exBold, 16, 24">
              계열 선택
            </Typography>
          </Box>
          <Box pl={2}>
            <SeriesComponent callbackFunc={setSeries} type={1} />
          </Box>
        </StackX>
        <StackX mt={2} justifyContent={'end'}>
          {/* <Box mr={2}>
            <Link to="/suSi/inputAndAnalysis/self">
              <Button variant="contained">스스로 평가하기</Button>
            </Link>
          </Box> */}
          <Button variant="contained" sx={{ px: '40px' }} onClick={onClickSearchOfficerList}>
            선택 조건으로 검색하기
          </Button>
        </StackX>
      </Box>
      <Box mt={'36px'} height={'1px'} bgcolor={'#D9D9D9'}></Box>

      <Box>
        <StackX justifyContent={'space-between'} mt={'25px'}>
          <Typography variant="H4_Exbold, 24, 32">검색 결과 사정관 리스트</Typography>
          <Typography>
            <Typography sx={{ fontWeight: 700 }} component={'span'}>
              사정관 평가 이용권&nbsp;
            </Typography>
            <Typography component={'span'} variant="H4_Exbold, 24, 32" sx={{ color: '#FF5600' }}>
              {ticketCount}장
            </Typography>
          </Typography>
        </StackX>
        <Box>
          <Stack flexWrap={'wrap'} mt={2} gap={4} flexDirection={'row'} alignItems={'stretch'}>
            {!isSelfEvaluationDone && (
              <OfficerProfileCard
                key={`self`}
                name={'자가평가'}
                label={'스스로 비교과를 평가합니다.'}
                imageUrl={''}
                readyCount={0}
                isSelfEvaluate
                onButtonClick={async () => {
                  if (grandSeries != '대분류' && middleSeries != '중분류' && rowSeries != '소분류') {
                    navigate('/suSi/inputAndAnalysis/self', {
                      state: { series: grandSeries + '>' + middleSeries + '>' + rowSeries },
                    })
                  } else {
                    alert('계열 정보를 모두 입력해주세요')
                  }
                }}
              />
            )}
            {officerList?.map((officerInfo: any, index) => (
              <OfficerProfileCard
                key={`${index}-officer`}
                name={officerInfo.officerName}
                label={officerInfo.university}
                imageUrl={officerInfo.officerProfileImage}
                readyCount={officerInfo.readyCount}
                onButtonClick={async () => {
                  if (parseInt(ticketCount) < 1) {
                    alert('사용권 갯수가 부족합니다. 구매 해주시길 바랍니다.')
                    window.location.href = '/store/product/single'
                    return
                  }
                  if (!confirm('평가신청을 진행하시겠습니까?(이용권이 감소합니다.).')) {
                    console.log('_')
                  } else {
                    const series = grandSeries + '>' + middleSeries + '>' + rowSeries
                    const _data = await useTicketFetch(officerInfo.memberId, series)
                    if (_data.status) {
                      alert('신청이 완료되었습니다.')
                      location.reload()
                    } else {
                      alert(_data.message)
                    }
                  }
                }}
              />
            ))}
            {isNoEvaluators && (
              <Center
                sx={{
                  width: '240px',
                  borderRadius: '12px',
                  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.08)',
                  px: '42px',
                  wordBreak: 'keep-all',
                }}
              >
                <Typography variant="D5_Bold, 14, 18">추가로 신청할 수 있는 사정관이 없습니다</Typography>
              </Center>
            )}
          </Stack>
        </Box>
      </Box>
    </TitleSection>
  )
}

export default SaJungDan
