import React from 'react'
import { cancelTicketFetch, useTicketFetch } from '../../../../../../api/fetches/officer'
import { Box, ButtonBase, Typography } from '@mui/material'
import StackX from 'components/common/StackX'

const ApplicationList = ({ applicationItem }: any) => {
  const onClickCancelEvaluation = async () => {
    if (applicationItem.progressStatus !== '대기중') {
      alert('진행중인 평가는 취소할수 없습니다.')
      return
    }
    if (!confirm('평가신청을 취소하시겠습니까?')) {
    } else {
      const _data = await cancelTicketFetch(applicationItem.officerId)
      if (_data.status) {
        alert('평가신청 취소를 완료했습니다.')
        location.reload()
      } else {
        alert('실패.')
      }
    }
  }

  return (
    <StackX
      justifyContent={'space-between'}
      px={'36px'}
      py={'26px'}
      border={'1px solid #C2C2C2'}
      borderTop={'2px solid #252525'}
      mt={2}
    >
      <Typography variant="D2_exBold, 16, 24" sx={{ color: '#252525' }}>
        {applicationItem.officerName}
      </Typography>
      <Typography variant="D2_exBold, 16, 24" sx={{ color: '#9A9A9A' }}>
        {applicationItem.progressStatus}
      </Typography>
      <Typography variant="D2_exBold, 16, 24" sx={{ color: '#9A9A9A' }}>
        현재 대기순위&nbsp;:&nbsp;{applicationItem.readyCount}위
      </Typography>
      <Box height={'1px'} width={'100px'}></Box>
      {/* <ButtonBase
        // onClick={onClickCancelEvaluation}
        sx={{ borderRadius: '16px', px: '24px', py: '7px', bgcolor: '#F4F4F4' }}
      >
        <Typography variant="D2_exBold, 16, 24">취소요청</Typography>
      </ButtonBase> */}
    </StackX>
  )
}

export default ApplicationList
