import { Box, Checkbox, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

interface Props {
  advantageDepartmentList: any[]
  selectedUnivList: string[]
  nasesinFilter: number
  onClickUnivCheckBox: (univCode: string) => void
  onClickTotalUnivCheckBox: (isTotal: boolean, univList: string[]) => void
}

const NaesinAdvantageDepartmentTable = ({
  advantageDepartmentList,
  selectedUnivList,
  nasesinFilter,
  onClickUnivCheckBox,
  onClickTotalUnivCheckBox,
}: Props) => {
  const isTotalChecked = advantageDepartmentList.length === selectedUnivList.length
  const collegeCodeList = advantageDepartmentList.map((department) => department.collegeCode)
  const [_advantageDepartmentList, _setAdvantageDepartmentList] = useState<any>()

  useEffect(() => {}, [advantageDepartmentList, nasesinFilter])
  return (
    <Box>
      <table className="univTable">
        <tbody>
          <tr>
            <th>대학명</th>
            <th>구분</th>
            {new Array(9).fill('').map((_, index) => {
              return <th key={'thGrade' + index}>{index + 1}등급</th>
            })}
            <th>
              <div className="col">
                전체선택
                <Checkbox
                  checked={isTotalChecked}
                  onClick={() =>
                    isTotalChecked
                      ? onClickTotalUnivCheckBox(false, collegeCodeList)
                      : onClickTotalUnivCheckBox(true, collegeCodeList)
                  }
                />
              </div>
            </th>
          </tr>

          {advantageDepartmentList.map((department: any, index: any) => {
            return (
              <>
                <tr key={`${department.collegeCode}_point_${index.toString()}`}>
                  <td rowSpan={2}>{department.collegeName}</td>
                  <td>등급별 감점</td>
                  {[
                    department.levelGrade1,
                    department.levelGrade2,
                    department.levelGrade3,
                    department.levelGrade4,
                    department.levelGrade5,
                    department.levelGrade6,
                    department.levelGrade7,
                    department.levelGrade8,
                    department.levelGrade9,
                  ].map((levelGarde, index) => {
                    return (
                      <td
                        key={`levelGrade${department.collegeCode}${levelGarde}${index}`}
                        className={`${Math.floor(nasesinFilter) === index + 1 ? 'selectedGrade' : ''}`}
                      >
                        {levelGarde}
                      </td>
                    )
                  })}

                  <td rowSpan={2}>
                    <Checkbox
                      checked={selectedUnivList.includes(department.collegeCode)}
                      onClick={() => onClickUnivCheckBox(department.collegeCode)}
                    />
                  </td>
                </tr>
                <tr key={`${department.collegeCode}_grade_${index.toString()}`}>
                  {/*<td>1000점 만점</td>*/}
                  {/*{[department.grade1, department.grade2, department.grade3, department.grade4, department.grade5, department.grade6, department.grade7, department.grade8, department.grade9].map(*/}
                  {/*  (grade, index) => {*/}
                  {/*    return (*/}
                  {/*      <td key={`grade${department.collegeCode}${grade}${index}`} className={`${Math.floor(nasesinFilter) === index + 1 ? "selectedGrade" : ""}`}>*/}
                  {/*        {grade}*/}
                  {/*      </td>*/}
                  {/*    );*/}
                  {/*  }*/}
                  {/*)}*/}
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
    </Box>
  )
}

export default NaesinAdvantageDepartmentTable
