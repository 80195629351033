import { Checkbox } from "@mui/material";
import { lowestGradeOfDepartment } from "api/fetches/essay";
import { IoIosArrowForward } from "react-icons/io";

interface Props {
  lowestGradeOfDepartmentList: lowestGradeOfDepartment[];
  lowestGradeOfUnivList: { collegeCode: string; collegeName: string }[];
  selectedDepartmentList: lowestGradeOfDepartment[];
  openUnivList: string[];
  nowTableType: "recommend" | "notRecommend";
  onClickUnivRow: (collegeCode: string) => void;
  onClickCheckBox: (lowestGradeOfDepartment: lowestGradeOfDepartment) => void;
  onClickTotalUnivCheckBox: (isTotal: boolean, univList: lowestGradeOfDepartment[]) => void;
}

const LowestGradeOfUnivListTable = ({
  lowestGradeOfDepartmentList,
  lowestGradeOfUnivList,
  selectedDepartmentList,
  openUnivList,
  nowTableType,
  onClickUnivRow,
  onClickCheckBox,
  onClickTotalUnivCheckBox,
}: Props) => {
  console.log("---------------------------------------------------------------");
  console.log("selectedDDepartmentLis ;", selectedDepartmentList);
  console.log("lowestGradeOfDepartmentList ;", lowestGradeOfDepartmentList);

  const selectedDepartmentIdList = selectedDepartmentList.map((department) => department.id);

  return (
    <>
      {lowestGradeOfUnivList.map((univ) => {
        const recDepartmentList = lowestGradeOfDepartmentList.filter((department) => department.collegeCode === univ.collegeCode && department.available);
        const notRecDepartmentList = lowestGradeOfDepartmentList.filter((department) => department.collegeCode === univ.collegeCode && !department.available);

        const isCheckTotal =
          nowTableType === "recommend"
            ? recDepartmentList.every((department) => selectedDepartmentIdList.includes(department.id))
            : notRecDepartmentList.every((department) => selectedDepartmentIdList.includes(department.id));

        /**
         * 추천 데이터 및 비추천데이터가없으면,대학 이름이 나오면안됨.
         */
        if(nowTableType === "recommend"&&recDepartmentList.length<1){
          return ;
        }else if(nowTableType !== "recommend"&&notRecDepartmentList.length<1){
          return ;
        }

        return (
          <div key={"crossableUniv : " + univ.collegeCode}>
            <div onClick={() => onClickUnivRow(univ.collegeCode)} className="univListRow">
              {univ.collegeName}
              <IoIosArrowForward className={openUnivList.includes(univ.collegeCode) ? "iconOpen" : ""} />
            </div>
            {openUnivList.includes(univ.collegeCode) && (
              <table className="univTable">
                <tbody>
                  <tr>
                    <th style={{ width: "13%" }}>모집단위</th>
                    <th style={{ width: "60%" }}>최저</th>
                    <th style={{ width: "13%" }}>최저요건</th>
                    <th style={{ width: "14%" }}>
                      <div className="col">
                        전체선택
                        <Checkbox checked={isCheckTotal} onChange={() => onClickTotalUnivCheckBox(!isCheckTotal, nowTableType === "recommend" ? recDepartmentList : notRecDepartmentList)} />
                      </div>
                    </th>
                  </tr>
                  {nowTableType === "recommend" &&
                    recDepartmentList.map((department) => {
                      return (
                        univ.collegeCode === department.collegeCode && (
                          <tr key={department.id + "crossable"}>
                            <td>{department.recruitmentUnit}</td>
                            <td>{department.content}</td>
                            <td>{department.available ? "합" : "불"}</td>

                            <td>
                              <Checkbox checked={selectedDepartmentList.map((department) => department.id)?.includes(department.id) ?? false} onChange={() => onClickCheckBox(department)} />
                            </td>
                          </tr>
                        )
                      );
                    })}
                  {nowTableType === "notRecommend" &&
                    notRecDepartmentList.map((department) => {
                      return (
                        univ.collegeCode === department.collegeCode && (
                          <tr key={department.id + "crossable"}>
                            <td>{department.recruitmentUnit}</td>
                            <td>{department.content}</td>
                            <td>{department.available ? "합" : "불"}</td>

                            <td>
                              <Checkbox checked={selectedDepartmentList.map((department) => department.id)?.includes(department.id) ?? false} onChange={() => onClickCheckBox(department)} />
                            </td>
                          </tr>
                        )
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        );
      })}
    </>
  );
};

export default LowestGradeOfUnivListTable;
