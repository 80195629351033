import { useRecoilState } from 'recoil'

type Props = {}

import { Box, ButtonBase, Radio, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { saveSurveyListFetch } from 'api/fetches/officer'
import { memberAxios } from 'api/instance'
import TurtlePaper from 'components/common/TurtlePaper'
import MessageCard from 'components/layout/EmptyPageLayout'
import { mainColor } from 'constants/style'
import { useQuery } from 'react-query'
import userInfoStates from '../../../../atoms/userInfoStates'
import GradeClassTable from '../common/GradeClassTable'

const 자가평가_페이지 = () => {
  let locationDom = useLocation()
  let { series } = locationDom.state || {}

  const [userInfo, setUserInfo] = useRecoilState(userInfoStates)
  const [rerenderState, rerender] = React.useReducer(() => ({}), {})

  const { data: questions, isSuccess: isSuccessQuestions } = useQuery({
    queryKey: 'surveylist',
    queryFn: () =>
      memberAxios
        .get('/officer/survey/list', {})
        .then(({ data }) => data as { surveyId: string; evaluateContent: string }[]),
  })

  const studentId = userInfo.id

  const { data: prevEvaluation, isSuccess: isSuccessPrevEvaluation } = useQuery({
    queryKey: 'surveylist2',
    queryFn: () =>
      memberAxios
        .get('/officer/student/survey/score/list', {
          params: { memberId: studentId },
        })
        .then(({ data }) => data),
    enabled: studentId !== undefined,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const prevComment = prevEvaluation?.officerCommentList as {
    comment: string
    mainSurveyType: string
  }[]
  const prevScore = prevEvaluation?.officerSurveyList as { surveyId: number; score: number }[]

  const [comments, setComments] = useState<Record<string, string>>({
    jinro: '',
    hakup: '',
    gongdong: '',
    etc: '',
  })

  const commentsKeyMap: Record<number, string> = {
    0: 'JINRO',
    1: 'HAKUP',
    2: 'GONGDONG',
    3: 'ETC',
  }

  const scoreMapRef = useRef<Record<string, string>>({})

  useEffect(() => {
    if (!prevScore) return

    const scoreMap = scoreMapRef.current
    for (const { score, surveyId } of prevScore) {
      scoreMap[String(surveyId)] = String(score)
    }
  }, [prevEvaluation])

  useEffect(() => {
    if (!prevComment) return
    const newObj: any = {}

    prevComment.forEach(({ mainSurveyType, comment }) => {
      newObj[mainSurveyType] = comment
    })
    setComments(newObj)
  }, [prevEvaluation])

  useEffect(() => {
    if (!prevScore) return

    for (const { score, surveyId } of prevScore) {
      scoreMapRef.current[surveyId] = String(score)
    }
    rerender()
  }, [prevScore, scoreMapRef])

  const evaluationTuple = (questions ?? []).map(({ surveyId, evaluateContent }) => ({
    surveyId,
    evaluateContent,
    score: String(prevScore?.find((scoreData: any) => String(scoreData.surveyId) === surveyId)?.score),
  }))

  const evaluationScoreList = useMemo(() => {
    return getEvaluationScoreList(evaluationTuple, scoreMapRef, rerender)
  }, [rerenderState, questions, prevEvaluation])

  console.log(comments, prevEvaluation)

  const commentForm = useMemo(() => {
    return getCommentForms(comments, commentsKeyMap, setComments)
  }, [questions, prevEvaluation, comments])

  if (!isSuccessPrevEvaluation || !isSuccessQuestions || !questions) {
    return <MessageCard title={'Loading'}></MessageCard>
  }

  const onSubmit = async () => {
    const scoreMap = scoreMapRef.current
    let numSelected = 0
    for (const key of Object.keys(scoreMap)) {
      if (scoreMap[key as keyof typeof scoreMap]?.length > 0) {
        numSelected += 1
      }
    }

    if (numSelected != questions.length && questions.length > 0) {
      alert('설문 작성을 완료할수없습니다. 모든 설문 내용을 입력해주세요.')
      return
    }

    const requestCommentsParam = Object.keys(comments).map((type) => ({
      mainSurveyType: type,
      comment: comments[type],
    }))

    const requestScoresParam: {
      surveyId: string
      score: string
    }[] = []

    for (const surveyId in scoreMap) {
      requestScoresParam.push({
        surveyId: surveyId,
        score: scoreMap[surveyId],
      })
    }

    const _data = await saveSurveyListFetch(studentId, requestScoresParam, requestCommentsParam, 1, true, series)
    if (_data.status) {
      alert('최종 평가저장이 완료되었습니다.')
      location.href = '/suSi/inputAndAnalysis/nonSubject'
    }
  }

  return (
    <Box px={1}>
      <Typography variant="H2_Bold, 36, 42">자가평가</Typography>
      <TurtlePaper sx={{ mt: 5 }}>
        <Typography variant="H6_Bold, 22, 30">평가기준</Typography>
        <GradeClassTable />
      </TurtlePaper>
      <TurtlePaper sx={{ width: '100%', mt: 5, display: 'flex', flexDirection: 'column' }}>
        {evaluationScoreList}
      </TurtlePaper>
      {commentForm}
      <Stack alignItems={'end'}>
        <ButtonBase
          className="officerEvaluationButton2"
          onClick={onSubmit}
          sx={{ width: '220px', height: '42px', bgcolor: mainColor, color: 'white', borderRadius: 1, mt: 2, mb: 6 }}
        >
          평가 완료
        </ButtonBase>
      </Stack>
    </Box>
  )
}

export default 자가평가_페이지

export function getEvaluationScoreList(
  evaluationTuple: { surveyId: string; evaluateContent: string; score: string }[],
  scoreMapRef: React.MutableRefObject<Record<string, string>>,
  rerender: React.DispatchWithoutAction
) {
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="1fr repeat(7, 50px)"
        gap={2}
        sx={{
          '& > *': {
            padding: '20px',
          },
        }}
        borderBottom={'1px solid black'}
      >
        <Typography variant="H8_bold, 20, 28">평가항목</Typography>
        {['A+', 'A', 'B+', 'B', 'C+', 'C', 'D'].map((x, i) => (
          <Typography variant="H7_ExBold, 20, 28" textAlign={'center'} key={i}>
            {x}
          </Typography>
        ))}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr repeat(7, 50px)"
        gap={2}
        sx={{
          '& > *': {
            px: '25px',
            py: '20px',
          },
        }}
      >
        {evaluationTuple.map((tuple, rowIndex) => {
          return (
            <React.Fragment key={rowIndex}>
              {/* todo */}
              <span key={`content-${rowIndex}`}>{tuple.evaluateContent}</span>
              {[7, 6, 5, 4, 3, 2, 1].map((val, columnIndex) => {
                const selectedScore = scoreMapRef.current[tuple.surveyId]
                const isChecked = String(selectedScore) === String(val)
                return (
                  <Radio
                    key={`${rowIndex}-${columnIndex}-${isChecked}`}
                    value={val}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 24,
                      },
                      '&.Mui-checked': {
                        color: '#1986d2',
                      },
                      '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                        color: '#bebebe',
                      },
                    }}
                    checked={isChecked}
                    name={'evalutaionItem' + tuple.surveyId}
                    onChange={({ target: { value } }) => {
                      scoreMapRef.current[tuple.surveyId] = value
                      rerender()
                    }}
                  />
                )
              })}
            </React.Fragment>
          )
        })}
      </Box>
    </>
  )
}

export function getCommentForms(
  comments: Record<string, string>,
  commentsKeyMap: Record<number, string>,
  setComments: React.Dispatch<React.SetStateAction<Record<string, string>>>
) {
  return (
    <Stack gap={4} mt={8}>
      {['진로역량', '학업역량', '공동체역량', '그 외 평가'].map((title, index) => (
        <Stack key={index}>
          <Typography variant="H3_Bold, 28, 36">{title}</Typography>
          <textarea
            name={title}
            style={{
              marginTop: '8px',
              height: '196px',
              resize: 'none',
              padding: '10px',
            }}
            value={comments[commentsKeyMap[index]]}
            onChange={({ target: { value } }) =>
              setComments((prev) => ({
                ...prev,
                [commentsKeyMap[index]]: value,
              }))
            }
            placeholder="평가요소별 주석 및 부연 설명을 500자 이내로 작성해 주세요."
          />
        </Stack>
      ))}
    </Stack>
  )
}
