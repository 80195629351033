import { Box, Stack, StackProps, Tooltip } from '@mui/material'
import { ReactNode } from 'react'
import { PiQuestionLight } from 'react-icons/pi'

type TooltipPlacement =
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined

export default function InfoBadge({
  tooltipContent,
  placement = 'left',
  ...stackProps
}: { tooltipContent: ReactNode; placement?: TooltipPlacement } & StackProps) {
  return (
    <Tooltip title={tooltipContent} placement={placement}>
      <Stack alignItems={'center'} justifyContent={'center'} {...stackProps} sx={{ cursor: 'help' }}>
        <PiQuestionLight fontSize={33} color="#9A9A9A" />
      </Stack>
    </Tooltip>
  )
}
