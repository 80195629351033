import { Checkbox } from "@mui/material";
import { essayMedicalEtcDepartment, liGwaMathAbleUniv } from "api/fetches/essay";
import "./style.scss";

interface Props {
  departmentList: essayMedicalEtcDepartment[];
  selectedDepartmentList: number[];
  nowTableType: "recommend" | "notRecommend";
  filter: string[];
  //   onClickUnivTotalCheckBox: (isTotal: boolean, univList: string[]) => void;
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
}

/**
 *
 * @returns 기타 가능 선택
 */
const MedicalEtcTable = ({ departmentList, selectedDepartmentList, nowTableType, filter, onClickDepartmentCheckBox }: Props) => {
  console.log("이거 지금 렌더링 됨?");
  const recUnivList = departmentList.filter((univ) => {
    if (filter.includes("munGwaNonSul") && univ.munNonSul === 0) return false;
    if (filter.includes("medicalNonSul") && univ.medicalNonSul === 0) return false;
    if (filter.includes("eng") && univ.english === 0) return false;
    return true;
  });

  const recUnivIdList = recUnivList.map((univ) => univ.id);

  const notRecUnivList = departmentList.filter((univ) => {
    return !recUnivIdList.includes(univ.id);
  });

  //   const notRecUnivIdList = notRecUnivList.map((univ) => univ.id);

  console.log("--------");
  console.log(notRecUnivList);
  //   const isCheckTotal =
  //     nowTableType === "recommend" ? recUnivList.every((univ) => selectedUnivList.includes(univ.collegeCode)) : notRecUnivList.every((univ) => selectedUnivList.includes(univ.collegeCode));
  /**
   * 추천 데이터 및 비추천데이터가없으면,대학 이름이 나오면안됨.
   */
  if(nowTableType === "recommend"&&recUnivList.length<1){
    return <div></div>;
  }else if(nowTableType !== "recommend"&&notRecUnivList.length<1){
    return <div></div>;
  }
  return (
    <table className="univTable">
      <tbody>
        <tr>
          <th>대학명</th>
          <th>모집단위명</th>
          <th>문과논술</th>
          <th>의학논술</th>
          <th>영어</th>

          <th>
            <div className="col">
              선택
              {/* 전체선택
              <Checkbox checked={isCheckTotal} onChange={() => onClickUnivTotalCheckBox(!isCheckTotal, nowTableType === "recommend" ? recUnivIdList : notRecUnivIdList)} /> */}
            </div>
          </th>
        </tr>

        <TableRows univList={nowTableType === "recommend" ? recUnivList : notRecUnivList} selectedUnivList={selectedDepartmentList} onClickDepartmentCheckBox={onClickDepartmentCheckBox}></TableRows>
      </tbody>
    </table>
  );
};

export default MedicalEtcTable;

interface TableRowProps {
  univList: essayMedicalEtcDepartment[];
  selectedUnivList: number[];
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
}

const TableRows = ({ univList, selectedUnivList, onClickDepartmentCheckBox }: TableRowProps) => {
  return (
    <>
      {univList.map((univ) => {
        return (
          <tr key={univ.id + "crossable"}>
            <td>{univ.collegeName}</td>
            <td>{univ.recruitmentUnit}</td>
            <td>{univ.munNonSul === 1 ? "✓" : "-"}</td>
            <td>{univ.medicalNonSul === 1 ? "✓" : "-"}</td>
            <td>{univ.english === 1 ? "✓" : "-"}</td>

            <td>
              <Checkbox checked={selectedUnivList?.includes(univ.id) ?? false} onChange={(e) => onClickDepartmentCheckBox(e, univ.id)} />
            </td>
          </tr>
        );
      })}
    </>
  );
};
