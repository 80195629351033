
import React, {useEffect, useState} from 'react';
import "./style.css"
import OrderItem from "components/pages/store/components/detail";
import {getPaymentDeatailListFetch} from "../../../../api/fetches/store";
import {Box, CardMedia, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import { pageContentConstraint } from 'constants/style';

function DetailListPage() {


    const [detailList,setDetailList] = useState<{
        orderId:number;
        productName:string;
        paidAmount:string;
        paidDate:string;
        productImage:string;
    }[]|null
    >([{
        orderId:0,
        productName:"0",
        paidAmount:"0",
        paidDate:"0",
        productImage:"0",
    }]);
    useEffect(()=>{
        getDetailList();
    },[]);

    const getDetailList = async () =>{
        const _details = await getPaymentDeatailListFetch();
        setDetailList(_details.data);
    }


    return (
        <Box className="MainContainer" sx={{ ...pageContentConstraint, mt:'20px' }}>
            <div className="bottom_border">
                결제 내역

            </div>
            <hr/>
            <div>
                {detailList?detailList.map((item)=>{
                    return <OrderItem  key={item.orderId} product={item} paidDate={item.paidDate} productName={item.productName} paidAmount={item.paidAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                }): <Paper className="orderPaymentDetailPage">
                    <div className="orderNotContentMessage">
                        결제 내역이 없습니다.
                    </div>


                </Paper>}


            </div>

        </Box>
    );
}

export default DetailListPage;