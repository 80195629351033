import { Box, BoxProps, Typography } from '@mui/material'
import StackX from 'components/common/StackX'
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { gradeMap } from 'utils/util'

type Props = {}

function GradeChart({
  data,
  ...boxProps
}: {
  data: Record<string, number>
} & BoxProps) {
  const copied = { ...data }
  console.log(copied)

  const itemKey = Object.keys(copied)

  for (const key of itemKey) {
    if (Object.prototype.hasOwnProperty.call(copied, key)) {
      copied[key] = Number(copied[key]) / itemKey.length
    }
  }

  const barColors = ['#8AA6EE', '#5D84E8', '#3162E1', '#1C4BC3']
  const colorOffset = itemKey.length <= 3 ? 1 : 0

  const bars = itemKey.map((x: string, index) => {
    return (
      <Bar
        key={x}
        dataKey={x}
        fill={barColors[index + colorOffset] ?? '#103abf'}
        barSize={220}
        stackId={'a'}
        shape={<BarRect title={x} grade={gradeMap[Math.round(data[x])]} />}
      ></Bar>
    )
  })

  return (
    <Box position={'relative'} {...boxProps}>
      <Box
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          height: '100%',
        }}
      >
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <ComposedChart data={[copied]} barCategoryGap={8} style={{ stroke: '#fff', strokeWidth: 2 }}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" fontWeight={700} dy={10} stroke="0" />
            <YAxis
              stroke="0"
              domain={[1, 7]}
              width={40}
              textAnchor="left"
              dx={-20}
              tickFormatter={(x) => (x <= 0 ? `` : `${gradeMap[Number(x)] ?? ''}`)}
              allowDecimals={false}
              tickCount={8}
              color="#9A9A9A"
              tickLine={false}
              interval={0}
            />
            {bars}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

const BarRect = ({ title, grade, ...rest }: any & { title: string; grade: string }) => {
  const { fill, x, y, width, height } = rest
  return (
    <foreignObject x={x} y={y} width={width} height={height} style={{ backgroundColor: fill ?? '#1C4BC3' }}>
      <StackX
        px={'25px'}
        py={'21px'}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}
        borderBottom={'2px solid white'}
      >
        <Typography variant="H7_ExBold, 20, 28" sx={{ color: 'white' }}>
          {title}
        </Typography>
        <Typography variant="H7_ExBold, 20, 28" sx={{ color: 'white' }}>
          {grade}
        </Typography>
      </StackX>
    </foreignObject>
  )

  return <rect x={x} y={y} width={width} height={height} fill={fill ?? '#1C4BC3'} />
}

export default GradeChart
