import { ReactComponent as ArrowCircleRight } from 'assets/icon/arrow_circle_right.svg'
import React, { Fragment, useEffect } from 'react'

import SouthEastIcon from '@mui/icons-material/SouthEast'
import { ButtonBase, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import StackX from 'components/common/StackX'
import StackY from 'components/common/StackY'
import { NavLink } from 'react-router-dom'
import './style.css'
const ProductItem = ({ products }: any) => {
  const [open, setOpen] = React.useState(false)
  useEffect(() => {}, [])
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  //const price = products.productPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  //const discountPrice = products.discountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return (
    <>
      <StackX
        sx={{
          boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.08)',
          width: '100%',
          maxWidth: '1100px',
          padding: '30px 60px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <StackY gap={1}>
          <StackX>
            <Typography variant="H3_Bold, 28, 36">{products.productNm}</Typography>
            <StackX
              sx={{
                marginLeft: '10px',
                width: '110px',
                height: '50px',
                justifyContent: 'center',
              }}
            >
              <Chip
                sx={{ height: '35px', fontWeight: 'bold' }}
                label={products.productTypeNm}
                color={
                  products.productTypeCode == 'FIXEDTERM'
                    ? 'primary'
                    : products.productTypeCode == 'TERM'
                    ? 'secondary'
                    : products.productTypeCode == 'TICKET'
                    ? 'success'
                    : 'error'
                }
              />
              {products.discountPrice > 0 ? (
                <Chip sx={{ height: '35px', fontWeight: 'bold', marginLeft: '10px' }} label={'할인중'} color={'info'} />
              ) : null}
            </StackX>
            <StackX>
              <ButtonBase
                sx={{
                  alignSelf: 'end',
                  color: '#C2C2C2',
                }}
                onClick={handleClickOpen}
              >
                <Typography variant="D6 regular, 12, 18" ml={2}>
                  세부내용
                  <SouthEastIcon style={{ fontSize: 12 }} />
                </Typography>
              </ButtonBase>
            </StackX>
          </StackX>
          <>
            {products.explainComment.split('\\n').map((item: any) => (
              <Typography color="#9A9A9A" variant="D4_regular, 16, 24">{`- ${item}`}</Typography>
            ))}
          </>
        </StackY>
        <StackY>
          <StackX justifyContent={'end'}>
            {products.discountPrice > 0 ? (
              <StackX flexDirection={'column'}>
                <StackX>
                  <Typography
                    fontSize={'24px'}
                    lineHeight={'54px'}
                    fontWeight={400}
                    color={'#c2c2c2'}
                    style={{ textDecoration: 'line-through' }}
                  >
                    {products.productPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    <Typography variant="D1_regular, 18, 26">원</Typography>
                  </Typography>
                </StackX>
                <StackX flexDirection={'row'} width={'100%'} justifyContent={'end'}>
                  <Typography fontSize={'34px'} lineHeight={'54px'} fontWeight={600}>
                    {(products.productPrice - products.discountPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Typography>
                  <Typography variant="D1_regular, 18, 26">원</Typography>
                </StackX>
              </StackX>
            ) : (
              <Fragment>
                <Typography fontSize={'34px'} lineHeight={'54px'} fontWeight={600}>
                  {products.productPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
                <Typography variant="D1_regular, 18, 26">원</Typography>
              </Fragment>
            )}
          </StackX>

          <NavLink to={`/store/order/` + products.id}>
            <ButtonBase
              sx={{
                bgcolor: '#FF5600',
                width: '228px',
                height: '48px',
                borderRadius: '5px',
              }}
            >
              <Typography
                sx={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily: 'Pretendard',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  mr: 0.5,
                }}
              >
                구매
              </Typography>
              <ArrowCircleRight />
            </ButtonBase>
          </NavLink>
        </StackY>
      </StackX>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'이용안내'}</DialogTitle>
        <DialogContent>
          <div className="productDialogDiv">
            <span className="productDialogProductNmSpan">{products.productNm}</span>
            <br />
            <br />
            <span>[환불정책]</span>
            <br />
            {products.refundPolicy.split('\\n').map((item: any, index: any) => {
              return (
                <>
                  <span key={item}>{item}</span>
                  <br />
                </>
              )
            })}
            <br />
            <span>[상품문의]</span>
            <br />
            <span>유선 문의 02-501-3357 </span>
            <br />
            <span>기타 문의는 고객센터에 등록해주세요.</span>
            <br />
          </div>
        </DialogContent>
        <DialogActions>
          {/*<Button onClick={addCouponInfo} autoFocus>*/}
          {/*  쿠폰 등록*/}
          {/*</Button>*/}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ProductItem
