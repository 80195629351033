import ContentsBox from 'components/block/contentsBox'
import { useUiChiHanYacSu } from './useUiChiHanYacSu'
import './style.scss'
import SuriNonsulSelectFilter from '../component/filter/suriNonsulSelectFilter'
import ScienceFilter from '../component/filter/scienceFilter'
import LowestGradeOfUnivListTable from '../component/table/lowestGradeOfUnivTable'
import ApplyDateTable from '../component/table/applyDateTable'
import NonSulFilter from '../component/filter/nonSulFilter'
import MedicalEtcTable from '../component/table/medicalEtcTable'
import RegionTalentTable from '../component/table/regionTalentTable'
import MedicalMathableTable from '../component/table/medicalMathableTable'
import MedicalScienceAbleTable from '../component/table/mediclaScienceAbleDepartment'
import TableSkeleton from 'components/block/skeletons/tableSkeleton'
import Steps from 'components/block/Steps'
import TitleSection from 'components/layout/TitleSection'
import StepIndicator from 'components/common/StepIndicator'
const stepList = [
  //   { label: "6월 모평 등급 입력" },
  //   { label: "최저 등급 확인" },
  { label: '수학 가능 과목 선택' },
  { label: '과학 가능 과목 선택' },
  { label: '기타 가능 선택' },
  { label: '지역인재' },
  { label: '최저 등급 확인' },
  { label: '전형 일자 확인' },
]

const UiChiHanYacSu = () => {
  const {
    nowStep,
    suriNonsulFilter,
    medicalMathablDepartmentList,
    selectedMedicalMathablDepartmentList,
    nowTableType,
    scienceAbleUnivList,
    scienceAbleDepartmentList,
    selectedScienceDepartmentList,
    scienceFilter,
    openUnivList,
    checkedLowestRateDepartmentList,
    applyDateDepartmentList,
    lowestGradeOfDepartmentList,
    lowestGradeOfUnivList,
    applyDateUnivList,
    interestDepartmentList,
    medicalEtcDepartmentList,
    etcFilter,
    selectedMedicalEtcDepartmentList,
    regionDepartmentList,
    selectedRegionDepartmentList,
    regionFilter,
    isFetching,
    onClickStep,
    onClickFilterButton,
    onClickTableType,
    onClickUnivRow,
    onClickDepartmentCheckBox,
    onClickTotalDepartmentCheckBox,
    onClickLowestRateDepartmentCheckBox,
    onClickLowestRateTotalCheckBox,
    onClickInterestDepartmnet,
  } = useUiChiHanYacSu()

  return (
    <TitleSection title="의치한약수">
      <div id="uiChiHanYac">
        <StepIndicator currentStep={nowStep + 1} infos={stepList} />

        {nowStep === 0 && (
          <SuriNonsulSelectFilter suriNonsulFilter={suriNonsulFilter} onClickNonsulFilterButton={onClickFilterButton} />
        )}
        {nowStep === 1 && <ScienceFilter scienceFilter={scienceFilter} onClickFilterButton={onClickFilterButton} />}

        {nowStep === 2 && (
          <NonSulFilter
            nowFilter={etcFilter}
            title="기타 가능 선택"
            type="medicalEtc"
            onClickFilterButton={onClickFilterButton}
          />
        )}
        {nowStep === 3 && (
          <NonSulFilter
            nowFilter={regionFilter}
            title="고등학교 소재지"
            type="region"
            onClickFilterButton={onClickFilterButton}
          />
        )}

        {isFetching ? (
          <TableSkeleton />
        ) : (
          <>
            {[0, 1, 2, 4].includes(nowStep) && (
              <div className="d-flex mt-3">
                <button
                  onClick={() => onClickTableType('recommend')}
                  className={`recUnivBtn ${nowTableType === 'recommend' && 'selected'}`}
                >
                  추천 대학
                </button>
                <button
                  onClick={() => onClickTableType('notRecommend')}
                  className={`recUnivBtn ${nowTableType === 'notRecommend' && 'selected'}`}
                >
                  비추천 대학
                </button>
              </div>
            )}

            <div className="tableContainer">
              {nowStep === 0 && (
                <MedicalMathableTable
                  departmentList={medicalMathablDepartmentList}
                  selectedDepartmentList={selectedMedicalMathablDepartmentList}
                  nowTableType={nowTableType}
                  suriNonsulFilter={suriNonsulFilter}
                  onClickDepartmentCheckBox={onClickDepartmentCheckBox}
                  onClickTotalCheckBox={onClickTotalDepartmentCheckBox}
                />
              )}

              {nowStep === 1 && scienceAbleDepartmentList.length === 0 ? (
                <p className="warning">해당하는 대학이 없습니다. 다음 단계로 넘어가주세요.</p>
              ) : nowStep === 1 ? (
                <MedicalScienceAbleTable
                  univList={scienceAbleUnivList}
                  departmentList={scienceAbleDepartmentList}
                  selectedDepartmentList={selectedScienceDepartmentList}
                  openUnivList={openUnivList}
                  nowTableType={nowTableType}
                  filter={scienceFilter}
                  onClickUnivRow={onClickUnivRow}
                  onClickTotalCheckBox={onClickTotalDepartmentCheckBox}
                  onClickDepartmentCheckBox={onClickDepartmentCheckBox}
                />
              ) : null}

              {nowStep === 2 && medicalEtcDepartmentList.length === 0 ? (
                <p className="warning">해당하는 대학이 없습니다. 다음 단계로 넘어가주세요.</p>
              ) : nowStep === 2 ? (
                <MedicalEtcTable
                  departmentList={medicalEtcDepartmentList}
                  selectedDepartmentList={selectedMedicalEtcDepartmentList}
                  nowTableType={nowTableType}
                  filter={etcFilter}
                  onClickDepartmentCheckBox={onClickDepartmentCheckBox}
                />
              ) : null}

              {nowStep === 3 && regionDepartmentList.length === 0 ? (
                <p className="warning">해당하는 대학이 없습니다. 다음 단계로 넘어가주세요.</p>
              ) : nowStep === 3 ? (
                <RegionTalentTable
                  departmentList={regionDepartmentList}
                  selectedDepartmentList={selectedRegionDepartmentList}
                  filter={regionFilter}
                  onClickDepartmentCheckBox={onClickDepartmentCheckBox}
                />
              ) : null}

              {nowStep === 4 && (
                <LowestGradeOfUnivListTable
                  lowestGradeOfDepartmentList={lowestGradeOfDepartmentList}
                  lowestGradeOfUnivList={lowestGradeOfUnivList}
                  selectedDepartmentList={checkedLowestRateDepartmentList}
                  openUnivList={openUnivList}
                  nowTableType={nowTableType}
                  onClickUnivRow={onClickUnivRow}
                  onClickCheckBox={onClickLowestRateDepartmentCheckBox}
                  onClickTotalUnivCheckBox={onClickLowestRateTotalCheckBox}
                />
              )}

              {nowStep === 5 && (
                <ApplyDateTable
                  applyDateUnivList={applyDateUnivList}
                  openUnivList={openUnivList}
                  applyDateDepartmentList={applyDateDepartmentList}
                  interestDepartmentList={interestDepartmentList}
                  onClickInterestDepartmnet={onClickInterestDepartmnet}
                  onClickUnivRow={onClickUnivRow}
                />
              )}
            </div>
          </>
        )}
        <div className="stepBtnBox">
          <button
            className="stepBtn"
            onClick={() => {
              onClickStep(false)
            }}
          >
            이전 단계
          </button>
          <button
            className="stepBtn"
            onClick={() => {
              onClickStep(true)
            }}
          >
            {nowStep < 5 ? '다음 단계' : '관심 대학 저장'}
          </button>
        </div>
      </div>
    </TitleSection>
  )
}

export default UiChiHanYacSu
