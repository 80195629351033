import Box, { BoxProps } from '@mui/material/Box'
import TurtlePaper from 'components/common/TurtlePaper'
import MessageCard from 'components/layout/EmptyPageLayout'
import { fontSize4 } from 'constants/style'
import CommentItem from './CommentItem'

const EvaluationContent = ({
  questions,
  questionScoreData,
  ...boxProps
}: { questionScoreData: { officerCommentList: any[]; officerSurveyList: any[] }; questions: any } & BoxProps) => {
  const evaluateItem = questions as {
    surveyId: number
    evaluateContent: string
  }[]

  if (!questionScoreData || !evaluateItem) return <></>

  const { officerCommentList, officerSurveyList } = questionScoreData
  if (!officerCommentList || !officerSurveyList) return <></>
  // return <MessageCard title="코멘트가 없습니다." contents="평가자 데이터가 없습니다." />

  const comments = (officerCommentList ?? []).map(
    ({ mainSurveyType, comment }: { mainSurveyType: string; comment: string }, index) => {
      const title = {
        JINRO: '진로',
        HAKUP: '학업',
        GONGDONG: '공동',
        ETC: '기타',
      }[mainSurveyType] as string

      return <CommentItem key={index} title={title} comment={comment} disabled />
    }
  )

  const surveyList = (officerSurveyList ?? []).map(({ bottomSurveyId, score: surveyScore }: any, index) => {
    const item = evaluateItem.find(({ surveyId: id }) => String(id) === String(bottomSurveyId))
    //console.log(surveyId)
    //console.log(id)
    return (
      <Box
        key={index}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr repeat(7, 50px)',
          gridTemplateRows: 'auto',
          padding: '15px 0',
          borderBottom: '1px solid silver',
        }}
      >
        <div style={{ wordBreak: 'keep-all' }}>{item?.evaluateContent}</div>
        {[7, 6, 5, 4, 3, 2, 1].map((score, index) => {
          return (
            <input
              key={index}
              readOnly
              type="radio"
              name={`score_${bottomSurveyId}_${score}`}
              checked={String(score) === String(surveyScore)}
              value={score}
              style={{ width: 22, height: 22, margin: 'auto auto' }}
            />
          )
        })}
      </Box>
    )
  })

  return (
    <Box {...boxProps}>
      <div>{comments}</div>
      <TurtlePaper sx={{ marginTop: '40px', padding: '20px' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr repeat(7, 50px)',
            gridTemplateRows: 'auto',
            borderBottom: '1px solid black',
            paddingBottom: 15,
          }}
        >
          <div style={{ fontSize: fontSize4, fontWeight: 700 }}>평가 항목</div>
          <span style={{ textAlign: 'center' }}>A+</span>
          <span style={{ textAlign: 'center' }}>A</span>
          <span style={{ textAlign: 'center' }}>B+</span>
          <span style={{ textAlign: 'center' }}>B</span>
          <span style={{ textAlign: 'center' }}>C</span>
          <span style={{ textAlign: 'center' }}>C+</span>
          <span style={{ textAlign: 'center' }}>D</span>
        </div>
        {surveyList}
      </TurtlePaper>
    </Box>
  )
}

export default EvaluationContent
