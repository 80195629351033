import { Box, ButtonBase, Modal, Stack, Typography } from '@mui/material'
import { createColumnHelper } from '@tanstack/react-table'
import { memberAxios } from 'api/instance'
import FilterBox, { createRadioButtonInfos } from 'components/common/FilterBox'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import useSmoothLoading from 'hooks/useSmoothLoading'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import BaseTable, { baseTableStyle2 } from '../common/BaseTable'
import ConditionMessage from '../common/ConditionMessage'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import Center from 'components/common/Center'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import { getSelected } from 'utils/util'

type Step2RowDataType = {
  collegeName: string
  collegeType: string
  lowest: number
  lowestText: string
  recruitmentUnitIds: string
  typeName: string
}

const Step2 = () => {
  const { stepState, pageSize } = useContext(StepPagesContext)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [filter, setFilter] = useState<'전체' | '최저가능대학' | '최저불가능대학' | '최저없음'>('전체')
  const [modalInfoText, setModalInfoText] = useState<ReactNode>('-')

  const recruitmentUnitIdList = stepState[1].bundle.selected?.flatMap((x: any) => x.split(',').map(Number))

  const { data, isFetching } = useQuery(['교과-대학검색-스텝2', recruitmentUnitIdList?.join('$')], () => {
    return memberAxios
      .post('/pearlyd/subject/lowest/colleges', {
        recruitmentUnitIdList,
      })
      .then((data) => {
        return data.data as Step2RowDataType[]
      })
  })

  const isLoadingSmooth = useSmoothLoading({ isLoading: isFetching })

  const dataList = useMemo(() => {
    return (data ?? []).filter((x) => {
      if (filter === '최저가능대학' && x.lowest === 2) return true
      if (filter === '최저불가능대학' && x.lowest === 1) return true
      if (filter === '최저없음' && x.lowest === 0) return true
      if (filter === '전체') return true
      return false
    })
  }, [filter, data])

  const [rowSelection, setRowSelection] = useBundledRowSelection(dataList, (_, identifier) => {
    return identifier.split(',').map(Number)
  })

  const columnHelper = createColumnHelper<Step2RowDataType>()

  const columns = [
    columnHelper.accessor('recruitmentUnitIds', {
      id: 'recruitmentUnitIds',
    }),
    columnHelper.accessor('collegeName', {
      size: 40,
      header: () => '대학명',
    }),
    columnHelper.accessor('typeName', {
      maxSize: 60,
      header: () => '전형명',
    }),
    columnHelper.accessor('collegeType', {
      size: 50,
      header: () => '반영 모집단위',
    }),
    columnHelper.accessor('lowest', {
      size: 30,
      cell: (info) => (info.getValue() === 2 ? '가능' : info.getValue() === 1 ? '불가능' : '없음'),
      header: () => '최저',
    }),
    columnHelper.accessor('lowestText', {
      cell: (info) => {
        const rowIndex = info.row.index

        const textLines = info
          .getValue()
          .trim()
          .replaceAll('＼', '/')
          .replaceAll('￭', '\\n￭')
          .replaceAll('[단', '\\n[단')
          .replaceAll('※', '\\n※')
          .replaceAll('-', '\\n-')
          .split('\\n')
          .filter((x) => x.length >= 1)

        const lineBreakedTexts: any[] = []
        for (let idx = 0; idx < textLines.length; idx++) {
          if (textLines[idx].length < 1) {
            continue
          }
          lineBreakedTexts.push(<span key={`${rowIndex}-text-${idx}`}>{textLines[idx]}</span>)
          if (idx < textLines.length - 1) {
            lineBreakedTexts.push(<br key={`${rowIndex}-br-${idx}`} />)
          }
        }

        return (
          <Box
            onClick={() => {
              handleOpen()
              setModalInfoText(<>{lineBreakedTexts}</>)
            }}
            sx={{
              textAlign: 'left',
              WebkitLineClamp: '2',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineClamp: 2,
              display: '-webkit-box',
              ' -webkit-box-orient': 'vertical',
              transitionDelay: '2s',
              '&:hover': {
                cursor: 'zoom-in',
              },
            }}
          >
            {lineBreakedTexts}
          </Box>
        )
      },
      header: () => '최저학력기준내용',
    }),
  ]

  const lowestFilterButtonInfo = createRadioButtonInfos(
    ['전체', '최저가능대학', '최저불가능대학', '최저없음'],
    filter,
    setFilter
  )

  const isAnyRowSelected = getSelected(rowSelection).length > 0

  return (
    <SubtitleSection title="최저등급">
      <FilterBox title="최저등급" buttonInfos={lowestFilterButtonInfo} sx={{ mb: 5 }}></FilterBox>
      <TurtlePaper
        sx={{
          px: 4,
          py: 2,
        }}
      >
        <BaseTable
          isLoading={isLoadingSmooth}
          getRowId={(row) => row.recruitmentUnitIds}
          sx={{}}
          enablePagination
          pageSize={pageSize}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          tableStyle={baseTableStyle2}
          columns={columns}
          data={dataList}
          enableSelection
          columnVisibility={{ recruitmentUnitIds: false }}
          searchInfo = {{label:"대학명 검색",value:"collegeName",mode:true}}
        />
      </TurtlePaper>
      <ConditionMessage show={!isAnyRowSelected} text="체크 박스를 선택해주십시오." />
      <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ alignItems: 'center', display: 'center' }}
      >
        <Stack width={'fit-content'} margin={'0 auto'} justifyContent={'start'}>
          <TurtlePaper bgcolor={'#fff'} minWidth={'380px'} minHeight={'110px'} sx={{ px: 6, py: 4 }}>
            <Typography variant="H5_regular, 24, 32">최저학력 기준 내용</Typography>
            <br />
            <Typography variant="D3_bold, 16, 24">{modalInfoText}</Typography>
          </TurtlePaper>
        </Stack>
      </Modal>
    </SubtitleSection>
  )
}

export default Step2
