import { memberAxios } from 'api/instance'
import { selectCommentAndScoreList, selectEvaluationAvgAndGrade, selectEvaluationList } from 'api/urls'

export const fetch_My사정관리스트 = () => {
  return memberAxios.get('/officer/student/selected/list').then(
    (data) =>
      data.data as {
        memberId: string
        officerId: string | null
        officerName: string
      }[]
  )
}

export const fetch_사정관_평가총점 = () => {
  return memberAxios.get('/officer/student/scoring/list').then((data) => data.data)
}

export const fetch_사정관_평가질문별_점수목록 = (memberId: number) => {
  return memberAxios
    .get(`/officer/student/survey/score/list`, {
      params: {
        memberId: memberId,
      },
    })
    .then((data) => {
      return data.data
    })
}

export const fetch_사정관_평가질문목록 = () => {
  return memberAxios.get('/officer/survey/list').then((data) => {
    return data.data
  })
}

export const getEvaluatioinList = async (memberId: any) => {
  const res = await memberAxios.post(selectEvaluationList, {
    memberId: memberId,
  })
  return res.data.data
}

export const getEvaluationAvgAndGrade = async (officerRelationId: any) => {
  const res = await memberAxios.post(selectEvaluationAvgAndGrade, {
    officerRelationId: officerRelationId,
  })
  return res.data.data
}

export const getCommentAndScoreList = async (officerRelationId: any) => {
  const res = await memberAxios.post(selectCommentAndScoreList, {
    officerRelationId: officerRelationId,
  })
  return res.data.data
}
