import { Box, BoxProps, Typography } from '@mui/material'
import React from 'react'

type Props = {
  title: string
}

const TitleSection = ({ title, children, ...boxProp }: React.PropsWithChildren<Props> & BoxProps) => {
  return (
    <Box
      sx={(theme) => ({
        px: 5,
        width: '100%',
        paddingBottom: '40px',
        [theme.breakpoints.down('md')]: {
          px: 4,
        },
        [theme.breakpoints.down('sm')]: { px: 2 },
        [theme.breakpoints.down('xs')]: { px: 1 },
      })}
      {...boxProp}
    >
      <Box>
        <Typography variant="H2_Bold, 36, 42">{title}</Typography>
      </Box>
      {children}
    </Box>
  )
}

export default TitleSection
