import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { createColumnHelper } from '@tanstack/table-core'
import { memberAxios } from 'api/instance'
import Center from 'components/common/Center'
import FilterBox, { createRadioButtonInfos } from 'components/common/FilterBox'
import RiskIndicator from 'components/common/RiskIndicator'
import TurtlePaper from 'components/common/TurtlePaper'
import MessageCard from 'components/layout/EmptyPageLayout'
import SubtitleSection from 'components/layout/SubtitleSection'
import ChartSkeleton from 'components/skeleton/ChartSkeleton'
import useSmoothLoading from 'hooks/useSmoothLoading'
import { useContext, useMemo, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { allRegions, getClampedRiskScore, getSelected, riskLabelMap } from 'utils/util'
import AdmissionTypeSelectForm, { 전형 } from '../common/AdmissionTypeSelectForm'
import BaseTable from '../common/BaseTable'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import Step6Chart from './Step6Chart'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import ConditionMessage from '../common/ConditionMessage'
import InfoBadge from 'components/InfoBadge'
import { getEarlydUnivList } from 'api/fetches/earlyd'

type Props = {}
export type Step6DataItem = {
  applicationDetailedType: string
  basicTypeAdmission: string
  collegeCode: string
  collegeName: string
  cut50: string
  cut70: string
  line: string
  myScore: string
  recruitmentUnit: string
  recruitmentUnitId: string
  riskScore: string
  typeName: string
}

function Step6({}: {}) {
  const { stepState, grandSeries, middleSeries, rowSeries } = useContext(StepPagesContext)

  const recruitmentUnitList = stepState[1].bundle.recruitmentUnitData ?? []
  const [typeState, setTypeState] = useState<전형>('일반전형')
  const is일반전형 = typeState === '일반전형'

  const choosenType = {
    일반전형: '일반',
    '고른기회 특별전형': '고른기회전형',
    '특성화고교 졸업자전형': '특성화고교졸업자전형',
    '농어촌 학생전형': '농어촌학생전형',
  }[typeState]

  const [regionState, setRegionState] = useState<string>('전국')
  const [riskState, setRiskState] = useState<string>('전체')

  const [subType, setSubType] = useState<string>('전체')

  const regionButtonInfos = createRadioButtonInfos(allRegions, regionState, setRegionState)

  let applicationDetailedTypeList =
    subType === '전체'
      ? ['지역인재', '지역균형', '종합일반', '교과일반']
      : subType === '그 외 일반'
      ? ['종합일반', '교과일반']
      : [subType]

  if (choosenType !== '일반') {
    applicationDetailedTypeList = []
  }

  const body = {
    applicationDetailedTypeList: applicationDetailedTypeList,
    basicTypeAdmission: choosenType,
    recruitmentUnitList,
    regionList: regionState === '전국' ? allRegions.filter((x) => x !== '전국') : [regionState],
    //regionRange: grandSeries + middleSeries + rowSeries,
    grandSeries: grandSeries,
    middleSeries: middleSeries,
    rowSeries: rowSeries,
  }

  //console.log(body)

  // useEffect(() => {
  //   fnSelectUnivList()
  // }, [])

  const fnSelectUnivList = async () => {
    const _univList = await getEarlydUnivList(body)
    _univList.data?.recruitmentUnitList as Step6DataItem[]
  }

  let {
    data,
    isFetching,
    isSuccess,
    isLoading: isDataLoading,
  } = useQuery(
    ['학종6-대학선택', choosenType, regionState, subType, riskState],

    () => {
      return memberAxios.post('/newPearlyd/pearlyd/sr/gyo/list', body).then((res) => {
        console.log(res)
        return res.data.recruitmentUnitList as Step6DataItem[]
      })
      //{ enabled: recruitmentUnitList.length >= 1 }
    }
  )

  const isLoading = useSmoothLoading({ isLoading: isFetching })

  const [rowSelection, setRowSelection] = useBundledRowSelection(data ?? [], (dataList, id) => {
    return dataList.find((x) => String(x.recruitmentUnitId) === String(id))
  })

  const columnHelper = createColumnHelper<Step6DataItem>()
  const columns = [
    columnHelper.accessor('recruitmentUnitId', {
      id: 'recruitmentUnitId',
    }),
    columnHelper.accessor('collegeName', {
      header: '대학명',
    }),
    columnHelper.accessor('typeName', {
      header: '전형명',
    }),
    columnHelper.accessor('recruitmentUnit', {
      header: '모집단위',
      id: 'recruitmentUnit',
    }),
    columnHelper.accessor('cut50', {
      header: '교과 기준컷(등급)',
    }),
    // columnHelper.accessor('cut70', {
    //   header: '추합컷',
    // }),
    columnHelper.accessor('myScore', {
      header: '내 점수(등급)',
    }),
    columnHelper.accessor('riskScore', {
      header: '위험도',
      size: 60,
      cell: (x) => (
        <Center>
          <RiskIndicator riskScore={Number(x.getValue())} />
        </Center>
      ),
    }),
  ]

  const riskButtonInfos = createRadioButtonInfos(
    ['전체', '안정', '적정', '소신', '도전', '위험'],
    riskState,
    setRiskState
  )

  const subTypeButtonInfos = createRadioButtonInfos(['전체', '지역인재', '지역균형', '그 외 일반'], subType, setSubType)

  const tableData = useMemo(
    () =>
      (data ?? []).filter(
        (x) =>
          riskState === '전체' ||
          riskState === riskLabelMap[getClampedRiskScore(x.riskScore) as keyof typeof riskLabelMap]
      ),
    [regionState, subType, riskState, choosenType, data]
  )

  const chartData = useMemo(() => {
    console.log(data)
    return data?.filter((x) => !isNaN(Number(x.cut50)) && Number(x.cut50) > 0)
  }, [data])

  const isAnyRowSelected = getSelected(rowSelection).length > 0

  return (
    <>
      <SubtitleSection title="전형 선택" subtitle="*자격을 택하여 주십시오." mt={'50px'}>
        <AdmissionTypeSelectForm typeState={typeState} setTypeState={setTypeState} />
      </SubtitleSection>
      <SubtitleSection title="대학 검색" subtitle="*지역을 택하여 주십시오." mt={5}>
        <Box
          sx={{
            '& .filter-box': {
              borderBottom: 'none !important',
            },
            '& .filter-box:last-child': {
              borderBottom: '1px solid #C2C2C2 !important',
            },
          }}
        >
          <FilterBox title="지역 선택" buttonInfos={regionButtonInfos}></FilterBox>
          {is일반전형 && (
            <FilterBox
              title="세부 전형"
              buttonInfos={subTypeButtonInfos}
              infoBadge={
                <InfoBadge
                  tooltipContent={
                    <Box width={'305px'}>
                      <Typography variant="H4_Exbold, 24, 32">지역인재? 지역균형?</Typography>
                      <Typography variant="D5_Exbold, 14, 18" component={'p'} mt={2}>
                        지역인재
                      </Typography>
                      <Typography fontSize={'12px'} fontWeight={700} color="#656565" mt={1}>
                        본인의 고등학교 소재지와 같은 지역의 대학에 지역인재 전형이 있으면 가산점을 받을 수 있어요
                      </Typography>
                      <Box height={'1px'} my={'12px'} bgcolor={'#E9E9E9'}></Box>
                      <Typography variant="D5_Exbold, 14, 18" component={'p'} mt={2}>
                        지역균형
                      </Typography>
                      <Typography fontSize={'12px'} fontWeight={700} color="#656565" mt={1}>
                        2021년부터 학교장 추천을 지원자격으로 요구하는 전형들은 ‘지역균형전형’으로 명칭을 통일했어요.
                        지역균형 = 학교장 추천 으로 외우면 알기 쉬워요.
                      </Typography>
                    </Box>
                  }
                />
              }
            />
          )}
          <FilterBox title="위험도" buttonInfos={riskButtonInfos}></FilterBox>
        </Box>
      </SubtitleSection>

      {!isSuccess && !isDataLoading && <MessageCard title="오류가 발생하였습니다. 관리자에게 문의해주십시오." />}
      {isSuccess && !isLoading && data && data.length < 1 ? (
        <MessageCard title="데이터가 존재하지 않습니다. 다른 선택지를 선택해주십시오." minHeight={'400px'} />
      ) : (
        <>
          <TurtlePaper sx={{ mt: 4, height: '550px' }}>
            {isLoading ? <ChartSkeleton /> : <Step6Chart data={chartData as any} />}
          </TurtlePaper>
          <TurtlePaper sx={{ mt: 4, px: 4, py: 6 }}>
            <Typography variant="D5_Regular, 14, 20" sx={{ color: '#9A9A9A' }}>
              *선택을 끝내셨다면, 다음 단계로 가주십시오.
            </Typography>
            <Box height={'10px'}></Box>
            <BaseTable
              isLoading={isLoading}
              columnVisibility={{
                recruitmentUnitId: false,
              }}
              getRowId={(row) => {
                return String(row.recruitmentUnitId)
              }}
              columns={columns}
              data={tableData}
              enableSelection
              enablePagination
              pageSize={10}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              searchInfo={{ label: '대학명 검색', value: 'collegeName', mode: true }}
              sx={{
                'th:first-of-type': {
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  color: '#9A9A9A',
                  pl: 3,
                },
                th: { py: '12px !important' },
                'td:first-of-type': {
                  pl: 3,
                },
              }}
            />
          </TurtlePaper>
        </>
      )}
      <ConditionMessage show={!isAnyRowSelected} text="체크 박스를 선택해주십시오." />
      <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />
    </>
  )
}

export default Step6
