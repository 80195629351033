import { Grid, GridProps, Skeleton } from '@mui/material'
import React from 'react'

type Props = {}

const TableSkeleton = (props: GridProps) => {
  const rows = []
  for (let r = 0; r < 6; r++) {
    const isFirstRow = r === 0
    const row = [1, 3, 2, 1, 1, 2, 2].map((w, column) => (
      <Grid item xs={w} key={`${r}-${column}`}>
        <Skeleton variant="rounded" sx={{ bgcolor: isFirstRow ? 'gre.200' : 'grey.100' }} />
      </Grid>
    ))
    rows.push(row)
  }

  return (
    <Grid height={'100px'} container spacing={2} {...props}>
      {rows}
    </Grid>
  )
}

export default TableSkeleton
