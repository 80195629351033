import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Paper,
    Typography
} from '@mui/material';
import React from 'react';
import "./style.css"
import { refundPaymentFetch} from "../../../../../api/fetches/store";

import {Link} from "react-router-dom";
import TextField from "@mui/material/TextField";

function OrderItem({product,productName,paidDate,paidAmount}:any) {

    const [open, setOpen] = React.useState(false);
    const [refundContent,setRefundContent] = React.useState("");
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRefund = async () =>{
        console.log(refundContent)
        const _refund = await refundPaymentFetch(product.orderId,refundContent);
        console.log(_refund);
    }

    return (
        <>
                <Paper className="orderPaymentDetailPage">
                    <div className="orderDetailImage">
                        {/*<CardMedia*/}
                        {/*    component="img"*/}
                        {/*    sx={{ width: 151 }}*/}
                        {/*    image={product.productImage}*/}
                        {/*    alt="Live from space album cover"*/}
                        {/*/>*/}

                    <div className="PayContainer">
                        <Typography variant="caption" display="block" gutterBottom>
                            {paidDate}
                        </Typography>
                        <Typography component="div" variant="h6">
                            {productName}
                        </Typography>
                        <Typography component="div" variant="h6">
                            {paidAmount} 원
                        </Typography>
                    </div>
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                        <button className="refundButton"  onClick={()=>alert("아직 지원하지 않습니다.")}>리뷰 쓰기</button>
                        <Link to={"/store/details/"+product.orderId}>
                            <button className="basic2Button" >상세보기</button>
                        </Link>
                    </Box>


                </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"환불신청"}
                </DialogTitle>
                <DialogContent>
                    <TextField id="outlined-basic" label="Outlined" variant="outlined" onChange={(e)=>setRefundContent(e.target.value)} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRefund} autoFocus>
                        환불 신청
                    </Button>
                </DialogActions>
            </Dialog>
            </>
    );
}

export default OrderItem;