import { Checkbox } from "@mui/material";
import { medicalMathableDepartment, medicalScienceDepartment, scienceAbleDepartment } from "api/fetches/essay";
import { IoIosArrowForward } from "react-icons/io";

interface Props {
  univList: {
    collegeCode: string;
    collegeName: string;
  }[];
  departmentList: medicalScienceDepartment[];
  filter: string[];
  nowTableType: "recommend" | "notRecommend";
  selectedDepartmentList: number[];
  openUnivList: string[];
  onClickUnivRow: (collegeCode: string) => void;
  onClickTotalCheckBox: (isTotal: boolean, idList: number[]) => void;
  onClickDepartmentCheckBox: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
}

const MedicalScienceAbleTable = ({ univList, departmentList, selectedDepartmentList, filter, openUnivList, nowTableType, onClickDepartmentCheckBox, onClickTotalCheckBox, onClickUnivRow }: Props) => {
  return (
    <>
      {univList.map((univ) => {
        const getFilteredDepartmentList = (filterFn: (department: medicalScienceDepartment) => boolean) =>
          departmentList.filter((department) => filterFn(department) && department.collegeCode === univ.collegeCode);

        const recDepartmentList = getFilteredDepartmentList((department) => {
          if (filter.includes("mulOne") && department.mulone === 0) return false;
          if (filter.includes("hwaOne") && department.hwaone === 0) return false;
          if (filter.includes("saengOne") && department.sangone === 0) return false;
          if (filter.includes("jiOne") && department.jiown === 0) return false;
          if (filter.includes("totalScience") && department.commonScience === 0) return false;
          if (filter.includes("mul") && department.mulonetwo === 0) return false;
          if (filter.includes("hwa") && department.hwaonetwo === 0) return false;
          if (filter.includes("saeng") && department.sangonetwo === 0) return false;
          if (filter.includes("ji") && department.jiowntwo === 0) return false;
          return true;
        });
        const recIdList = recDepartmentList.map((department) => department.id);

        const notRecDepartmentList = departmentList.filter((department) => {
          return department.collegeCode === univ.collegeCode && recIdList.includes(department.id) === false;
        });

        const notRecIdList = notRecDepartmentList.map((department) => department.id);

        const isCheckTotal =
          nowTableType === "recommend"
            ? recDepartmentList.every((department) => selectedDepartmentList.includes(department.id))
            : notRecDepartmentList.every((department) => selectedDepartmentList.includes(department.id));

        /**
         * 추천 데이터 및 비추천데이터가없으면,대학 이름이 나오면안됨.
         */
        if(nowTableType === "recommend"&&recDepartmentList.length<1){
          return <div></div>;
        }else if(nowTableType !== "recommend"&&notRecDepartmentList.length<1){
          return <div></div>;
        }

        return (
          <div key={"crossableUniv : " + univ.collegeCode}>
            <div onClick={() => onClickUnivRow(univ.collegeCode)} className="univListRow">
              {univ.collegeName}
              <IoIosArrowForward className={openUnivList.includes(univ.collegeCode) ? "iconOpen" : ""} />
            </div>
            {openUnivList.includes(univ.collegeCode) && (
              <table className="univTable">
                <tbody>
                  <tr>
                    <th>모집단위명</th>
                    <th>통합과학</th>
                    <th>물1</th>
                    <th>화1</th>
                    <th>생1</th>
                    <th>지1</th>
                    <th>물1+물2</th>
                    <th>화1+화2</th>
                    <th>생1+생2</th>
                    <th>지1+지1</th>
                    <th>
                      <div className="col">
                        전체선택
                        <Checkbox checked={isCheckTotal} onClick={() => onClickTotalCheckBox(!isCheckTotal, nowTableType === "recommend" ? recIdList : notRecIdList)} />
                      </div>
                    </th>
                  </tr>

                  {(nowTableType === "recommend" ? recDepartmentList : notRecDepartmentList).map(
                    (department) =>
                      univ.collegeCode === department.collegeCode && (
                        <tr key={department.id + "crossable"}>
                          <td>{department.recruitmentUnit}</td>
                          <td>{department.commonScience !== 0 && "✓"}</td>
                          <td>{department.mulone !== 0 && "✓"}</td>
                          <td>{department.hwaone !== 0 && "✓"}</td>
                          <td>{department.sangone !== 0 && "✓"}</td>
                          <td>{department.jiown !== 0 && "✓"}</td>
                          <td>{department.mulonetwo !== 0 && "✓"}</td>
                          <td>{department.hwaonetwo !== 0 && "✓"}</td>
                          <td>{department.sangonetwo !== 0 && "✓"}</td>
                          <td>{department.jiowntwo !== 0 && "✓"}</td>
                          <td>
                            <Checkbox checked={selectedDepartmentList?.includes(department.id) ?? false} onChange={(e) => onClickDepartmentCheckBox(e, department.id)} />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            )}
          </div>
        );
      })}
    </>
  );
};

export default MedicalScienceAbleTable;
