import { Box, Stack } from '@mui/system'
import FilterBox, { createMultiSelectButtonInfos, createRadioButtonInfos } from 'components/common/FilterBox'
import StackY from 'components/common/StackY'
import TurtlePaper from 'components/common/TurtlePaper'
import SubtitleSection from 'components/layout/SubtitleSection'
import React, { useContext, useEffect, useState } from 'react'
import { Setter } from 'types/utilType'
import BaseTable from '../common/BaseTable'
import { memberAxios } from 'api/instance'
import { useQuery } from 'react-query'
import { createColumnHelper } from '@tanstack/table-core'
import _ from 'lodash'
import useSmoothLoading from 'hooks/useSmoothLoading'
import { StepPagesContext } from '../common/StepPagesContext'
import useBundledRowSelection from '../common/useBundledRowSelection'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import { getSelected } from 'utils/util'
import ConditionMessage from '../common/ConditionMessage'

type Props = {}
type Step10DataItem = {
  recruitmentUnitId: number
  typeName: string
  collegeName: string
  recruitmentUnit: string
  interviewDate: string
  interviewTime: string
  lowestText: string
  lowest: number
}

function Step10({}: Props) {
  const { stepState, setBundle } = useContext(StepPagesContext)
  const recruitmentUnitIdList = stepState[9].bundle.selected.map(Number)

  const { data: scheduleId } = useQuery('모의고사_입력체크', () => {
    return memberAxios.get('/mock/check/input').then((x) => {
      return (x.data as { scheduleId: number }).scheduleId
    })
  })

  const [filterState, setFilterState] = useState<string>('전체')

  const filterParamMap = {
    전체: [0, 1, 2],
    '최저 없는 대학': [0],
    '최저 가능 대학': [1],
    '최저 불가능 대학': [2],
  }

  const lowestList = filterParamMap[filterState as keyof typeof filterParamMap]

  const body = {
    lowestList,
    recruitmentUnitIdList,
    scheduleId,
  }

  console.log('scheduleId', scheduleId)

  let { data, isFetching } = useQuery(
    ['학종-최저등급', filterState, scheduleId],
    () => {
      return memberAxios.post('/pearlyd/sr/lowest/list', body).then((res) => {
        return res.data.list as Step10DataItem[]
      })
    },
    { enabled: scheduleId !== undefined }
  )
  data = data ?? []

  const [rowSelection, setRowSelection] = useBundledRowSelection(data, (dataList, id) => {
    return dataList.find((item) => String(item.recruitmentUnitId) === String(id))
  })

  const isLoading = useSmoothLoading({ isLoading: isFetching })

  const buttonInfos = createRadioButtonInfos(
    ['전체', '최저 없는 대학', '최저 가능 대학', '최저 불가능 대학'],
    filterState,
    setFilterState
  )

  const columnHelper = createColumnHelper<Step10DataItem>()
  const columns = [
    columnHelper.accessor('recruitmentUnitId', {
      id: 'recruitmentUnitId',
    }),
    columnHelper.accessor('collegeName', {
      header: '대학명',
      size: 60,
    }),
    columnHelper.accessor('recruitmentUnit', {
      header: '모집단위',
      size: 60,
    }),
    columnHelper.accessor('typeName', {
      header: '전형명',
      size: 60,
    }),
    columnHelper.accessor('lowestText', {
      header: '최저등급',
      cell: (info) => (info.getValue() === '-' ? '최저없음' : info.getValue()),
    }),
  ]

  const isAnyRowSelected = getSelected(rowSelection).length > 0

  return (
    <SubtitleSection title="최저등급 확인" mt={'50px'}>
      <Box mb={4}>
        <FilterBox title="최저등급" buttonInfos={buttonInfos} />
      </Box>
      <TurtlePaper>
        <BaseTable
          isLoading={isLoading}
          columns={columns}
          data={data}
          enableSelection
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          getRowId={(row) => {
            return String(row.recruitmentUnitId)
          }}
          columnVisibility={{
            recruitmentUnitId: false,
          }}
          searchInfo={{ label: '모집단위 검색', value: 'recruitmentUnit', mode: true }}
        />
      </TurtlePaper>
      <ConditionMessage show={!isAnyRowSelected} text="체크 박스를 선택해주십시오." />
      <StepMoveButtonGroup isNextButtonEnabled={isAnyRowSelected} />
    </SubtitleSection>
  )
}

export default Step10
