import { Checkbox } from "@mui/material";
import { liGwaMathAbleUniv } from "api/fetches/essay";
import "./style.scss";

interface Props {
  univList: liGwaMathAbleUniv[];
  selectedUnivList: string[];
  nowTableType: "recommend" | "notRecommend";
  suriNonsulFilter: string[];
  onClickUnivTotalCheckBox: (isTotal: boolean, univList: string[]) => void;
  onClickUnivCheckBox: (univCode: string) => void;
}

/**
 *
 * @returns 수학 가능 과목 테이블
 */
const MathableTable = ({ univList, selectedUnivList, nowTableType, suriNonsulFilter, onClickUnivTotalCheckBox, onClickUnivCheckBox }: Props) => {
  console.log("univLIst : ", univList);

  const recUnivList = univList.filter((univ) => {
    if (suriNonsulFilter.includes("ES2") && univ.suoneSutwo === 0) return false;
    if (suriNonsulFilter.includes("ES3") && univ.pbSt === 0) return false;
    if (suriNonsulFilter.includes("ES4") && univ.dfIn === 0) return false;
    if (suriNonsulFilter.includes("ES5") && univ.geometry === 0) return false;
    return true;
  });

  const recUnivIdList = recUnivList.map((univ) => univ.collegeCode);

  const notRecUnivList = univList.filter((univ) => {
    return !recUnivIdList.includes(univ.collegeCode);
  });

  const notRecUnivIdList = notRecUnivList.map((univ) => univ.collegeCode);

  console.log("--------");
  console.log(notRecUnivList);
  const isCheckTotal =
    nowTableType === "recommend" ? recUnivList.every((univ) => selectedUnivList.includes(univ.collegeCode)) : notRecUnivList.every((univ) => selectedUnivList.includes(univ.collegeCode));

  return (
    <table className="univTable">
      <tbody>
        <tr>
          <th>대학명</th>
          <th>과목</th>
          <th>공통수학</th>
          <th>수1+수2</th>
          <th>확통</th>
          <th>미적</th>
          <th>기하</th>
          <th>
            <div className="col">
              전체선택
              <Checkbox checked={isCheckTotal} onChange={() => onClickUnivTotalCheckBox(!isCheckTotal, nowTableType === "recommend" ? recUnivIdList : notRecUnivIdList)} />
            </div>
          </th>
        </tr>

        <TableRows univList={nowTableType === "recommend" ? recUnivList : notRecUnivList} selectedUnivList={selectedUnivList} onClickUnivCheckBox={onClickUnivCheckBox}></TableRows>
      </tbody>
    </table>
  );
};

export default MathableTable;

interface TableRowProps {
  univList: liGwaMathAbleUniv[];
  selectedUnivList: string[];
  onClickUnivCheckBox: (univCode: string) => void;
}

const TableRows = ({ univList, selectedUnivList, onClickUnivCheckBox }: TableRowProps) => {
  return (
    <>
      {univList.map((univ) => {
        return (
          <tr key={univ.id + "crossable"}>
            <td>{univ.collegeName}</td>
            <td></td>
            <td>{univ.commonMath === 1 ? "✓" : ""}</td>
            <td>{univ.suoneSutwo === 1 ? "✓" : ""}</td>
            <td>{univ.pbSt === 1 ? "✓" : ""}</td>
            <td>{univ.dfIn === 1 ? "✓" : ""}</td>
            <td>{univ.geometry === 1 ? "✓" : ""}</td>

            <td>
              <Checkbox checked={selectedUnivList?.includes(univ.collegeCode) ?? false} onChange={() => onClickUnivCheckBox(univ.collegeCode)} />
            </td>
          </tr>
        );
      })}
    </>
  );
};
