import { Fragment, useEffect, useRef, useState } from 'react'

import { Box, ButtonBase, Paper, Typography } from '@mui/material'
import { getProductListFetch } from 'api/fetches/store'
import tokensStates from 'atoms/tokensStates'
import ProductItem from 'components/pages/store/product/productitem'
import { ReactComponent as ArrowForwardIcon } from 'assets/icon/arrow_downward.svg'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import './style.css'
import { pageContentConstraint } from 'constants/style'
import mainImage from '../../../../assets/images/수시_서비스_소개.png'
import susiTop from '../../../../assets/images/susi_top.png'
import userInfoStates from 'atoms/userInfoStates'
import { Stack, width } from '@mui/system'
import { mainColor } from 'constants/style'
import TitleSection from 'components/layout/TitleSection'
import SubtitleSection from 'components/layout/SubtitleSection'

const ProductPage = () => {
  const token = useRecoilValue(tokensStates)
  const navigate = useNavigate()
  const containerRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  const [hideGoToPurchaseButton, setHideGoToPurchaseButton] = useState(true)

  const params = useParams()
  const [productsAllList, setProductsAllList] = useState<any>([])
  const [productSList, setProductSList] = useState<any>([])
  const [productJList, setProductJList] = useState<any>([])
  const [productMList, setProductMList] = useState<any>([])
  const [productCList, setProductCList] = useState<any>([])

  const userInfo = useRecoilValue(userInfoStates)

  useEffect(() => {
    getProductList()
  }, [])

  const getProductList = async () => {
    let params: any = {}
    params.memberId = userInfo.id

    const products = await getProductListFetch(params)
    console.log(products)
    setProductsAllList(products)
    setProductSList(products[0])
    setProductJList(products[1])
    setProductMList(products[2])
    setProductCList(products[3])
  }

  const goToPurchaseButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const scrollHandler = () => {
      const container = containerRef.current
      const promotionImage = imageRef.current
      const goToPurchaseButton = goToPurchaseButtonRef.current

      if (!container) return
      if (!promotionImage) return
      if (!goToPurchaseButton) return

      const scrollY = window.scrollY || window.pageYOffset

      // const lowerLimit = 150
      const upperLimit = promotionImage.getBoundingClientRect().height - window.innerHeight

      // if (scrollY < lowerLimit) {
      //   setHideGoToPurchaseButton(true)
      // }

      if (scrollY > upperLimit) {
        setHideGoToPurchaseButton(true)
      } else {
        setHideGoToPurchaseButton(false)
      }
    }
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [goToPurchaseButtonRef.current, containerRef.current])

  if (!token.accessToken) {
    navigate('/user/signIn')
  }

  const [isSusi, setIsSusi] = useState(true)
  const [isJungsi, setIsJungsi] = useState(false)
  const [isMento, setIsMento] = useState(false)
  const [isConsul, setIsConsul] = useState(false)

  const inactiveColor = '#C2C2C2'

  return (
    <div style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '80%', position: 'relative', height: 'fit-content' }} ref={containerRef}>
        <Box
          component={'a'}
          href="#purchase-items"
          ref={goToPurchaseButtonRef}
          sx={{
            display: 'flex',
            left: '50%',
            opacity: hideGoToPurchaseButton ? 0 : 1,
            position: 'fixed',
            bottom: '20px',
            width: '400px',
            height: '65px',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: '#5F667DA5',
            backdropFilter: 'blur(14px)',
            color: 'white',
            transform: 'translate(-50%, 0)',
            fontSize: '20px',
            borderRadius: '8px',
            ':hover': {
              cursor: 'pointer',
            },
            transition: 'opacity 0.1s ease-in-out',
          }}
        >
          <Typography variant="H7_ExBold, 20, 28" color="white">
            이용권 구매하러가기
            <ArrowForwardIcon style={{ marginLeft: '4px' }} fill="#00CA8D !important" />
          </Typography>
        </Box>
        <Box>
          <Stack direction={'row'} mb={'49px'} mt={'20px'}>
            <ButtonBase
              onClick={() => {
                setIsSusi(true), setIsJungsi(false), setIsMento(false), setIsConsul(false)
              }}
              sx={{
                flex: 1,
                border: `1px solid ${isSusi ? mainColor : inactiveColor}`,
                py: '9px',
                color: isSusi ? mainColor : inactiveColor,
              }}
            >
              <Typography variant="H4_Exbold, 24, 32">수시예측</Typography>
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                setIsSusi(false), setIsJungsi(true), setIsMento(false), setIsConsul(false)
              }}
              sx={{
                flex: 1,
                border: `1px solid ${isJungsi ? mainColor : inactiveColor}`,
                py: '9px',
                color: isJungsi ? mainColor : inactiveColor,
              }}
            >
              <Typography variant="H4_Exbold, 24, 32">정시예측</Typography>
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                setIsSusi(false), setIsJungsi(false), setIsMento(true), setIsConsul(false)
              }}
              sx={{
                flex: 1,
                border: `1px solid ${isMento ? mainColor : inactiveColor}`,
                py: '9px',
                color: isMento ? mainColor : inactiveColor,
              }}
            >
              <Typography variant="H4_Exbold, 24, 32">맨토링</Typography>
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                setIsSusi(false), setIsJungsi(false), setIsMento(false), setIsConsul(true)
              }}
              sx={{
                flex: 1,
                border: `1px solid ${isConsul ? mainColor : inactiveColor}`,
                py: '9px',
                color: isConsul ? mainColor : inactiveColor,
              }}
            >
              <Typography variant="H4_Exbold, 24, 32">컨설팅</Typography>
            </ButtonBase>
          </Stack>
        </Box>
        {isSusi ? (
          <Fragment>
            <img ref={imageRef} src={susiTop} style={{ objectFit: 'contain', height: '100%' }} alt="수시 서비스 소개" />
            <Box
              id="purchase-items"
              gap={4}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              mt="80px"
              mb={'50px'}
              flexDirection={'column'}
            >
              <h2>수시예측 서비스</h2>
              {productSList != undefined && productSList != null && productSList.length != 0 ? (
                productSList.map((item: any, index: number) => {
                  return <ProductItem key={item.productId} products={item} />
                })
              ) : (
                <Paper elevation={2} className="productInfoContainer">
                  <p className="fs-6 text-muted">이용할수 있는 수시 예측 서비스가 없습니다.</p>
                </Paper>
              )}
            </Box>
          </Fragment>
        ) : isJungsi ? (
          <Box
            id="purchase-items"
            gap={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            mt="80px"
            mb={'50px'}
            flexDirection={'column'}
          >
            <h2>정시예측 서비스</h2>
            {productJList != undefined && productJList != null && productJList.length != 0 ? (
              productJList.map((item: any, index: number) => {
                return <ProductItem key={item.productId} products={item} />
              })
            ) : (
              <Paper elevation={2} className="productInfoContainer">
                <p className="fs-6 text-muted">이용할수 있는 정시 예측 서비스가 없습니다.</p>
              </Paper>
            )}
          </Box>
        ) : isMento ? (
          <Box
            id="purchase-items"
            gap={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            mt="80px"
            mb={'50px'}
            flexDirection={'column'}
          >
            <h2>맨토링 서비스</h2>
            {productMList != undefined && productMList != null && productMList.length != 0 ? (
              productMList.map((item: any, index: number) => {
                return <ProductItem key={item.productId} products={item} />
              })
            ) : (
              <Paper elevation={2} className="productInfoContainer">
                <p className="fs-6 text-muted">이용할수 있는 멘토링 서비스가 없습니다.</p>
              </Paper>
            )}
          </Box>
        ) : isConsul ? (
          <Box
            id="purchase-items"
            gap={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            mt="80px"
            mb={'50px'}
            flexDirection={'column'}
          >
            <h2>컨설팅 서비스</h2>
            {productCList != undefined && productCList != null && productCList.length != 0 ? (
              productCList.map((item: any, index: number) => {
                return <ProductItem key={item.productId} products={item} />
              })
            ) : (
              <Paper elevation={2} className="productInfoContainer">
                <p className="fs-6 text-muted">이용할수 있는 컨설팅 서비스가 없습니다.</p>
              </Paper>
            )}
          </Box>
        ) : null}
      </div>
    </div>
  )
}

export default ProductPage
