import React from 'react'
import { RxCross1 } from 'react-icons/rx'

type Props = { size?: number; color?: string }

const IconX = ({ size = 16, color }: Props) => {
  return <RxCross1 fontSize={size} color={color ?? '#D8145C'} strokeWidth={1} />
}

export default IconX
