import { Box, Stack, Typography } from '@mui/material'
import StackX from 'components/common/StackX'
import useScrollToTopOnMount from 'hooks/useScrollToTop'
import { ReactNode } from 'react'

export const ExplainSection = ({ title, items }: { title: string; items: { text: ReactNode; grade: string }[] }) => {
  return (
    <Stack>
      <Typography variant="H8_bold, 20, 28" borderBottom={'1px solid #9A9A9A'} pb={2}>
        {title}
      </Typography>
      {items.map(({ text, grade }, index) => (
        <Typography
          key={index}
          pt={'12px'}
          pb="8px"
          variant="D1_regular, 18, 26"
          sx={{ color: '#656565', borderTop: '1px soild #D9D9D9' }}
        >
          <StackX justifyContent={'space-between'} gap={'40px'}>
            <Box>{text}</Box>
            <Typography variant="H7_ExBold, 20, 28" color={'#FF5600'} sx={{ minWidth: '32px' }}>
              {grade.replaceAll('"', '')}
            </Typography>
          </StackX>
        </Typography>
      ))}
    </Stack>
  )
}

export const useStepPageCommonLogic = () => {
  useScrollToTopOnMount()
}
