import React, { MouseEventHandler } from 'react'
import { Setter } from 'types/utilType'
import { StepState } from './StepPages'

export const StepPagesContext = React.createContext<{
  initializeStepState: () => void
  setBundle: React.Dispatch<React.SetStateAction<Record<string, any>>>
  step: number
  numSteps: number
  goNextStep: () => void
  goPrevStep: () => void
  stepState: StepState
  setStepState: Setter<StepState>
  pageSize: number
  commentList: any
  scoreList: any
  setCommmentList: (List: any) => void
  serScoreList: (List: any) => void
  avg: any
  setAvg: (avg: any) => void
  step1Avg: any
  setStep1Avg: (step1Avg: any) => void
  step2Avg: any
  setStep2Avg: (step2Avg: any) => void
  step3Avg: any
  setStep3Avg: (step3Avg: any) => void
  step4Avg: any
  setStep4Avg: (step4Avg: any) => void
  grandSeries: any
  middleSeries: any
  rowSeries: any
  setGrandSeries: (grandSeries: any) => void
  setMiddleSeries: (middleSeries: any) => void
  setRowSeries: (rowSeries: any) => void
}>({
  step: 0,
  numSteps: 1,
  goNextStep: () => {},
  goPrevStep: () => {},
  stepState: {},
  setStepState: () => {},
  setBundle: () => {},
  initializeStepState: () => {},
  pageSize: 10,
  commentList: [],
  scoreList: [],
  setCommmentList: () => {},
  serScoreList: () => {},
  avg: 0,
  setAvg: () => {},
  step1Avg: 0,
  setStep1Avg: () => {},
  step2Avg: 0,
  setStep2Avg: () => {},
  step3Avg: 0,
  setStep3Avg: () => {},
  step4Avg: 0,
  setStep4Avg: () => {},
  grandSeries: null,
  middleSeries: null,
  rowSeries: null,
  setGrandSeries: () => {},
  setMiddleSeries: () => {},
  setRowSeries: () => {},
})
