
import React from "react";
import {Route, Routes} from "react-router-dom";
import ApplyList from "./applylist";
import ProFile from "./profile";
import Evaluation from "./evaluation";


const Officer = () => {



  return      <Routes>
    <Route path="/applylist" element={<ApplyList />}></Route>
    <Route path="/profile" element={<ProFile />}></Route>
    <Route path="/applylist/evaluation/:studentId" element={<Evaluation />}></Route>

  </Routes>
};

export default Officer;
