import { createColumnHelper } from '@tanstack/react-table'
import {
  delteAllInterestUniversity,
  delteInterestUniversity,
  fetchInterestUniversityEssay,
  fetchInterestUniversityStudent,
  관심대학_논술_열타입,
  관심대학_학종_열타입,
} from 'api/api'
import DeleteButton from 'components/common/DeleteButton'
import DetailButton from 'components/common/DetailButton'
import RiskIndicator from 'components/common/RiskIndicator'
import TurtleTable2 from 'components/common/TurtleTable2'
import { useQuery } from 'react-query'
import { emptyTablePlaceholder, 관심대학_테이블스타일 } from './common'
import { useContext, useEffect, useState } from 'react'
import { 관심대학페이지_컨텍스트 } from './InterestUniversityPage'
import DetailModal from 'DetailModal/DetailModal'
import K_상세보기_논술모달_내용 from 'DetailModal/Essay/K_상세보기_논술모달_내용'
import { Button, Typography } from '@mui/material'
import { mainColor } from 'constants/style'

type Props = {}

const K_관심대학_논술탭 = (props: Props) => {
  const { data, refetch } = useQuery('관심대학-논술', fetchInterestUniversityEssay, { refetchOnMount: true })
  const { setNumTotalRowMap } = useContext(관심대학페이지_컨텍스트)
  const [modalOpen, setModalOpen] = useState(false)
  const [detailRecruitmentId, setDetailRecruitmentId] = useState<number | null>(null)

  useEffect(() => {
    if (data) {
      setNumTotalRowMap((prev) => ({ ...prev, 논술: data.essayInterestList.length }))
    }
  }, [data])

  let rows: 관심대학_논술_열타입[] = []

  if (!data) {
    rows = []
  } else {
    rows = data.essayInterestList
  }

  const columnHelper = createColumnHelper<관심대학_논술_열타입>()
  const columns = [
    columnHelper.accessor('collegeName', {
      header: '대학명',
    }),
    columnHelper.accessor('recruitmentUnit', {
      header: '모집단위',
    }),
    columnHelper.accessor('typeRate', {
      header: '논술비율',
    }),
    columnHelper.accessor('admissionDate', {
      header: '전형일',
    }),
    columnHelper.display({
      id: '상세내역',
      header: '상세내역',
      cell: ({ row }) => (
        <DetailButton
          onClick={() => {
            setDetailRecruitmentId(rows[row.index].recruitmentUnitId)
            setModalOpen(true)
          }}
        />
      ),
      maxSize: 60,
    }),
    columnHelper.display({
      id: '삭제',
      header: '',
      maxSize: 40,
      cell: ({ row }) => (
        <DeleteButton
          onClick={() => {
            delteInterestUniversity('ESS', [rows[row.index].id]).then(() => {
              refetch()
            })
          }}
        />
      ),
    }),
  ]

  if (rows.length === 0) {
    return emptyTablePlaceholder
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Button
          style={{
            border: '1px solid ' + mainColor,
            color: mainColor,
            paddingLeft: 10,
            paddingRight: 10,
            width: '228px',
            height: '48px',
            marginTop: '20px',
          }}
          onClick={() => {
            delteAllInterestUniversity('STU').then(() => {
              refetch()
            })
          }}
        >
          <Typography variant="D3_bold, 16, 24">전체삭제</Typography>
          <DeleteButton onClick={() => null} />
        </Button>
      </div>
      <TurtleTable2
        sx={{ mt: 2, fontSize: '14px', fontWeight: 400, lineHeight: '24px', table: { width: '100%' } }}
        columns={columns}
        data={rows}
        tableStyle={관심대학_테이블스타일}
      />
      <DetailModal
        param={detailRecruitmentId}
        open={modalOpen}
        setOpen={setModalOpen}
        Contents={K_상세보기_논술모달_내용}
      />
    </>
  )
}

export default K_관심대학_논술탭
