import { Box, Stack, Typography } from '@mui/material'
import TurtlePaper from 'components/common/TurtlePaper'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ExplainSection } from './common'
import AlphabetGradeChart from './AlphabetGradeChart'
import { useContext } from 'react'
import { StepPagesContext } from '../common/StepPagesContext'
import useScrollToTopOnMount from 'hooks/useScrollToTop'
import { useQuery } from 'react-query'
import { memberAxios } from 'api/instance'
import { getGrade, 학종_사정관평가데이터_형식 } from './Step1'
import StepMoveButtonGroup from '../common/StepMoveButtonGroup'
import GradeCard from './GradeCard'
import _ from 'lodash'

type Props = {}

function Step4({}: Props) {
  const { stepState } = useContext(StepPagesContext)
  // let { data: officerReviewData } = useQuery('학종-기타-평가', () =>
  //   memberAxios
  //     .get('/pearlyd/sr/officer-student-evaludate/avg-score/etc/details')
  //     .then((x) => x.data as 학종_사정관평가데이터_형식)
  // )

  const { setBundle, commentList, scoreList, avg, setStep4Avg } = useContext(StepPagesContext)
  const convertTotalScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 25; i < 40; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 15
    setStep4Avg(scoreAvg)
    if (scoreAvg >= 6) {
      return 'A+'
    } else if (scoreAvg > 5) {
      return 'A'
    } else if (scoreAvg > 4) {
      return 'B+'
    } else if (scoreAvg > 3) {
      return 'B'
    } else if (scoreAvg > 2) {
      return 'C+'
    } else if (scoreAvg > 1) {
      return 'C'
    } else if (scoreAvg > 0) {
      return 'D'
    } else {
      return '-'
    }
  }

  const convertSection1ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 25; i < 28; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 3
    return Number(scoreAvg).toFixed(0)
  }

  const convertSection2ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 28; i < 32; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 4
    return Number(scoreAvg).toFixed(0)
  }

  const convertSection3ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 32; i < 35; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 3
    return Number(scoreAvg).toFixed(0)
  }

  const convertSection4ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 35; i < 38; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 3
    return Number(scoreAvg).toFixed(0)
  }

  const convertSection5ScoreToGrade = () => {
    let scoreSum = 0
    for (let i = 38; i < 40; i++) {
      scoreSum = scoreSum + scoreList[i].score
    }

    let scoreAvg = scoreSum / 2
    return Number(scoreAvg).toFixed(0)
  }

  let totalGrade = convertTotalScoreToGrade()
  let section1Grade = convertSection1ScoreToGrade()
  let section2Grade = convertSection2ScoreToGrade()
  let section3Grade = convertSection3ScoreToGrade()
  let section4Grade = convertSection4ScoreToGrade()
  let section5Grade = convertSection5ScoreToGrade()

  const data = [
    {
      name: '자기주도성',
      grade: Number(section1Grade),
    },
    {
      name: '경험의 다양성',
      grade: Number(section2Grade),
    },
    {
      name: '창의적 문제해결력',
      grade: Number(section3Grade),
    },
    {
      name: '교직 적합성 및 잠재력',
      grade: Number(section4Grade),
    },
    {
      name: '다문화글로벌 역량',
      grade: Number(section5Grade),
    },
  ]

  // const grouped: any[] = []
  // const dataList = officerReviewData?.list ?? []
  // console.log(dataList)

  // for (let i = 0; i < dataList.length; i++) {
  //   const idx = grouped.findIndex((val) => val.middleName === dataList[i].middleName)
  //   if (idx === -1) {
  //     grouped.push({ middleName: dataList[i].middleName, list: [dataList[i]] })
  //   } else {
  //     console.log(idx, dataList[i], i)
  //     grouped[idx]?.list?.push(dataList[i])
  //   }
  // }

  // const sections = grouped.map((x) => (
  //   <ExplainSection
  //     title={x.middleName}
  //     items={x.list.map((item: any) => ({
  //       text: item.subName,
  //       grade: item.avgGrade,
  //     }))}
  //   />
  // ))

  return (
    <>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px', height: '350px' }}>
        <AlphabetGradeChart data={data} />
      </TurtlePaper>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px' }}>
        <Typography variant="H3_Bold, 28, 36" sx={{ color: '#000' }}>
          기타역량
        </Typography>
        <Stack mt={5} gap={'30px'}>
          <ExplainSection
            title="자기주도성"
            items={[
              {
                text: '교내 다양한 활동에서 주도적, 적극적으로 활동을 수행하는가?',
                grade: JSON.stringify(scoreList[25].grade),
              },
              {
                text: '새로운 과제를 주도적으로 만들고 성과를 내었는가?',
                grade: JSON.stringify(scoreList[26].grade),
              },
              {
                text: '기존에 경험한 내용을 바탕으로 스스로 외연을 확장하려고 노력하였는가?',
                grade: JSON.stringify(scoreList[27].grade),
              },
            ]}
          />
          <ExplainSection
            title="경험의 다양성"
            items={[
              {
                text: '자율, 동아리, 봉사, 진로활동 등 체험활동을 통해 다양한 경험을 쌓았는가?',
                grade: JSON.stringify(scoreList[28].grade),
              },
              {
                text: '독서활동을 통해 다양한 영역에서 지식과 문화적 소양을 쌓았는가?',
                grade: JSON.stringify(scoreList[29].grade),
              },
              {
                text: '예체능 영역에서 적극적이고 성실하게 참여하였는가?',
                grade: JSON.stringify(scoreList[30].grade),
              },
              {
                text: '자신의 목표를 위해 도전한 경험을 통해 성취한 적이 있는가?',
                grade: JSON.stringify(scoreList[31].grade),
              },
            ]}
          />
          <ExplainSection
            title="창의적 문제해결력"
            items={[
              {
                text: '교내 행동 과정에서 창의적인 발상을 통해 일을 진행한 경험이 있는가?',
                grade: JSON.stringify(scoreList[32].grade),
              },
              {
                text: '교내 활동 과정에서 나타나는 문제점을 적극적으로 해결하기 위해 노력 하였는가?',
                grade: JSON.stringify(scoreList[33].grade),
              },
              {
                text: '주어진 교육환경을 극복하거나 충분히 활용한 경험이 있는가?',
                grade: JSON.stringify(scoreList[34].grade),
              },
            ]}
          />
          <ExplainSection
            title="교직 적합성 및 잠재력"
            items={[
              {
                text: '교직에 대한 흥미와 관심: 교직에 대한 적극적인 모습과 알고 있는 정도 및 교원양성기관 진학을 위한 노력 정도',
                grade: JSON.stringify(scoreList[35].grade),
              },
              {
                text: '교직 수행을 위한 다양한 경험: 교직에 대한 관심을 갖고 본인이 참여한 활동, 과정을 통해 얻은 다양한 경험',
                grade: JSON.stringify(scoreList[36].grade),
              },
              {
                text: '교직 활동을 위한 리더십 및 자기주도성: 공동체 활동에 참여하여, 구성원을 긍정적인 방향으로 변화시킨 경험과 교직 관련 활동에서 능동적으로 주도하려는 태도, 가치관, 역량',
                grade: JSON.stringify(scoreList[37].grade),
              },
            ]}
          />
          <ExplainSection
            title="다문화글로벌 역량"
            items={[
              {
                text: '다문화 역량, 글로벌 역량',
                grade: JSON.stringify(scoreList[38].grade),
              },
              {
                text: '지역 및 세계 속 공동체의 일원으로서 환경, 기아, 빈곤, 인권과 같은 범지구적 공동체 문제에 관심을 가지고 문제 해결에 참여하고자 하는가?',
                grade: JSON.stringify(scoreList[39].grade),
              },
            ]}
          />
        </Stack>
      </TurtlePaper>
      <TurtlePaper mt={5} sx={{ px: '30px', py: '25px' }}>
        <Stack>
          <Typography variant="D2_exBold, 16, 24" mb={'10px'}>
            사정관 주석
          </Typography>
          <Typography variant="D4_regular, 16, 24">{commentList[3].comment}</Typography>
        </Stack>
      </TurtlePaper>
      <GradeCard title="기타역량" grade={totalGrade} />
      <StepMoveButtonGroup />
    </>
  )
}

export default Step4
