import { BoxProps } from '@mui/material'
import StepIndicator from 'components/common/StepIndicator'
import TitleSection from 'components/layout/TitleSection'
import usePrevious from 'hooks/usePrevious'
import _ from 'lodash'
import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import { Setter } from 'types/utilType'
import StepMoveButtonGroup from './StepMoveButtonGroup'
import { WithPageCommonLogic } from './WithPageCommonLogic'
import { StepPagesContext } from './StepPagesContext'

type StepPageProps = {
  title: string
  pages: { label: React.ReactNode; page: React.ReactNode }[]
} & BoxProps

export type StepState = Record<
  number,
  {
    bundle: Record<string, any>
  }
>

export type StepPageNavigateButtonState = Record<
  number,
  {
    isNextButtonEnabled?: boolean
    isPrevButtonEnabled?: boolean
    onNextButtonClick?: MouseEventHandler
    onPreviousButtonClick?: MouseEventHandler
  }
>

const StepPages = ({ pages, title, ...boxProp }: StepPageProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const numSteps = pages.length
  const [step, setStep] = useState(1)
  const [commentList, setCommmentList] = React.useState<any>([])
  const [scoreList, serScoreList] = React.useState<any>([])
  const [avg, setAvg] = React.useState('')
  const [step1Avg, setStep1Avg] = React.useState('')
  const [step2Avg, setStep2Avg] = React.useState('')
  const [step3Avg, setStep3Avg] = React.useState('')
  const [step4Avg, setStep4Avg] = React.useState('')
  const [grandSeries, setGrandSeries] = React.useState('')
  const [middleSeries, setMiddleSeries] = React.useState('')
  const [rowSeries, setRowSeries] = React.useState('')

  const initialStepState: StepState = {}
  for (let step = 1; step <= numSteps; step++) {
    initialStepState[step] = { bundle: {} }
  }

  const [stepState, setStepState] = useState<StepState>(initialStepState)

  const setBundle: Setter<Record<string, any>> = (param) => {
    const newBundle = typeof param === 'function' ? param(stepState[step].bundle) : param
    setStepState((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],
        bundle: newBundle,
      },
    }))
  }

  const goNextStep = () => {
    if (step < numSteps) {
      navigate(String(step + 1))
      setStep(step + 1)
    }
  }

  const goPrevStep = () => {
    if (step > 1) {
      navigate(String(step - 1))
      setStep(step - 1)
    }
  }

  const initializeStepState = () => {
    setStepState((prev) => ({
      ...prev,
      [step]: {
        bundle: {},
      },
    }))
  }

  const lastPath = location.pathname.split('/').pop()

  /**
   * url 접근 시 해당 페이지 이동
   */
  const pathNum = Number(lastPath)
  useEffect(() => {
    if (isNaN(pathNum)) {
      setStep(1)
      navigate('1')
    } else {
      setStep(pathNum)
    }
  }, [pathNum])

  /**
   * 새로고침 시 스텝 1 페이지로 이동
   */
  const prevPath = usePrevious(lastPath)
  useEffect(() => {
    if (prevPath === undefined) navigate('1')
  }, [prevPath])

  /**
   * 개별 페이지 생성
   */
  const pageRoutes = useMemo(() => {
    return _.range(1, numSteps + 1).map((step, index) => {
      const page = <WithPageCommonLogic key={index}>{pages[index].page}</WithPageCommonLogic>
      return <Route key={index} path={`/${step}`} element={page}></Route>
    })
  }, [numSteps, pages])

  if (!prevPath) {
    return null
  }

  return (
    <StepPagesContext.Provider
      value={{
        pageSize: 15,
        step,
        numSteps,
        goNextStep,
        goPrevStep,
        stepState,
        setStepState,
        setBundle,
        initializeStepState,
        commentList,
        scoreList,
        setCommmentList,
        serScoreList,
        avg,
        setAvg,
        step1Avg,
        setStep1Avg,
        step2Avg,
        setStep2Avg,
        step3Avg,
        setStep3Avg,
        step4Avg,
        setStep4Avg,
        grandSeries,
        middleSeries,
        rowSeries,
        setGrandSeries,
        setMiddleSeries,
        setRowSeries,
      }}
    >
      <TitleSection title={title} {...boxProp} sx={{ userSelect: 'none', ...boxProp.sx }}>
        <StepIndicator currentStep={step} infos={_.map(pages, (x) => _.pick(x, 'label'))} sx={{ mt: 5 }} />
        <Routes>{pageRoutes}</Routes>
      </TitleSection>
    </StepPagesContext.Provider>
  )
}

export default StepPages
