import {
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { Stack, Typography } from '@mui/material'
import { Box, SxProps, SystemStyleObject, Theme } from '@mui/system'
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { Setter } from 'types/utilType'
import { muiTheme } from 'theme'

type Props = {
  columns: any[]
  data: any[]
  sx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  isPaginated?: boolean
  tableStyle?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  columnVisibility?: Record<string, boolean>
}

export const turtleTableGreyHeaderStyle: (theme: Theme) => SystemStyleObject<Theme> = (theme: Theme) => ({
  overflowX: 'auto',
  p: 2,
  table: {
    width: '100%',
  },
  '& th, td': {
    px: 1,
    py: 0.5,
    verticalAlign: 'middle',
  },
  th: {
    color: '#9a9a9a',
  },
  td: {
    borderBottom: '1px solid #e9e9e9',
    pl: 1,
  },
  [theme.breakpoints.down('md')]: {
    '& td,th': {
      px: 0.5,
      py: 0.5,
      wordBreak: 'keep-all',
    },
    p: 2,
  },
  [theme.breakpoints.down('sm')]: {
    '& td,th': {
      // border: '1px solid #a9a9a9aa',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
    th: {
      // backgroundColor: '#d9d9d9',
      // color: 'black',
    },
    '& th p': {
      // borderLeft: '0px',
    },
    p: 0,
    pb: 2,
  },
  '&::-webkit-scrollbar': {
    width: '11px',
    height: '11px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#e4e4e4',
    height: '11px',
    borderRadius: 2,
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#103abf',
    borderRadius: 2,
    height: 1,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#1c4bc3',
  },
})

const basicStyle = (theme: Theme) => ({
  padding: 2,
  '& table': {},
  '& td:first-child, th:first-child': {
    pl: 2,
  },
  '& th': { fontSize: 14, fontWeight: 700, color: '#9A9A9A' },
  '& td': {
    fontWeight: 700,
    fontSize: 16,
    borderTop: '1px solid #c2c2c2',
  },
  '& td, th': {
    px: 0.5,
    pr: 5,
    py: 2,
  },
})

export const turtleTableBasicStyle2 = (theme: Theme) => ({
  p: 0,
  '& th, td': {
    border: '1px solid #00000030 !important',
    color: '#000000',
    verticalAlign: 'middle',
    textAlign: 'center',
    padding: '8px 4px',
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
  },
  th: {
    background: '#f4f4f4',
    fontWeight: 700,
    borderTop: '2px solid #00000050 !important',
    ...muiTheme.typography['D3_bold, 16, 24'],
  },
  td: {
    ...muiTheme.typography['D5_Regular, 14, 20'],
  },
})

export const TurtleTable2Context = React.createContext<{
  columnFilters: ColumnFiltersState
  setColumnFilters: Setter<any[]>
}>({
  columnFilters: [],
  setColumnFilters: () => {},
})

const TurtleTable2 = ({ columns, data, sx = {}, isPaginated = false, tableStyle, columnVisibility }: Props) => {
  const paginationModel = isPaginated ? getPaginationRowModel() : undefined

  const { columnFilters, setColumnFilters } = useContext(TurtleTable2Context)

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: paginationModel,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      columnFilters,
      columnVisibility,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: undefined,
    enableGlobalFilter: true,
    filterFns: {} as any,
  })

  useEffect(() => {
    table.setPageSize(10)
  }, [])

  const pageNavigationBar = (
    <Stack direction="row" gap={2} justifyContent={'center'}>
      <button
        className="border rounded p-1"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        {'<<'}
      </button>
      <button
        className="border rounded p-1"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<'}
      </button>
      <Stack alignItems={'center'} justifyContent={'center'}>
        <Typography variant="body2">페이지 </Typography>
        <Typography variant="body2">
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </Typography>
      </Stack>
      <button className="border rounded p-1" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
        {'>'}
      </button>
      <button
        className="border rounded p-1"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        {'>>'}
      </button>
    </Stack>
  )

  return (
    <Box
      sx={[
        (theme) => ({
          overflowX: 'auto',
          [theme.breakpoints.down('sm')]: {
            '& td,th': {
              px: 1,
              py: 0.5,
              wordBreak: 'keep-all',
              fontSize: '14px !important',
            },
          },
        }),
        tableStyle ?? basicStyle,
        sx,
      ]}
    >
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isPaginated && pageNavigationBar}
    </Box>
  )
}

export const stuffRowData = (arr: any[], count: number, columns?: any, stuffContents?: React.ReactNode) => {
  let ret = [...arr]
  for (let i = 0; i < count - arr.length; i++) {
    const obj: any = {}
    for (const { accessorKey } of columns) {
      obj[accessorKey] = stuffContents ?? '-'
    }
    ret.push(obj)
  }
  return ret
}

export function generateDefaultColumnInfo(columns: string[]) {
  return columns.map((column) => ({
    accessorKey: column,
    header: column,
    cell: (info: any) => info.renderValue(),
  }))
}

export default TurtleTable2
