import TextField from '@mui/material/TextField'
import { useState } from 'react'

interface Props {
  onChangeNaesin: (naesin: number) => void
}

const InputNaesinFilter = ({ onChangeNaesin }: Props) => {
  const [naesin, setNaesin] = useState<number | ''>(3)

  const onChagneFilter = (number: string) => {
    if (number === '') {
      setNaesin('')
    } else {
      const _naesin = Number(number)
      if (_naesin >= 1 && _naesin < 10) {
        setNaesin(_naesin)
        onChangeNaesin(_naesin)
      }
    }
  }

  return (
    <>
      <div className="inputNaesinFilterBox">
        <div>
          <p>내신 등급 평균 (국, 영, 수, 사 기준)</p>
          <TextField
            value={naesin}
            type="number"
            onChange={(e) => onChagneFilter(e.currentTarget.value)}
            size="small"
          />
        </div>
      </div>
    </>
  )
}

export default InputNaesinFilter
