import { Box, BoxProps, Stack, Typography } from '@mui/material'
import StackX from 'components/common/StackX'
import React, { PureComponent } from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'

type Props = {}

function Step7Chart({ data, ...boxProps }: { data: 학종_대학별유불리_ChartDataItem[] } & BoxProps) {
  data = data.map((item) => {
    return {
      ...item,
      xTick: `${item.collegeName}/${item.typeName}`,
    }
  })

  // let test: any = _.range(0, 100, 1).map((x) => ({
  //   xTick: `${Math.random().toFixed(5)}/${Math.random().toFixed(5)}`,
  //   score: Math.random().toFixed(5),
  // }))

  const barSize = 120

  return (
    <WithRechartResponsive {...boxProps} responsiveWidth={Math.max(1188, barSize * data.length)} height={'450px'}>
      <BarChart data={data} barCategoryGap={8} style={{ stroke: '#fff', strokeWidth: 2 }}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="xTick" fontWeight={700} dy={10} stroke="0" tick={<TickX />} height={90} interval={0} />
        <Bar dataKey="score" barSize={30} fill="#1c4bc3" />
      </BarChart>
    </WithRechartResponsive>
  )
}

class TickX extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props as {
      x: number
      y: number
      stroke: string
      payload: any
    }
    const [collegeName, typeName]: [string, string, string] = payload.value.split('/')

    const width = 110

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject className="node" x={-width / 2} y="0" width={width} height="100%">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
            }}
          >
            <p style={{ fontWeight: 800 }}>{collegeName}</p>
            <p style={{ textAlign: 'center', marginTop: 4, fontWeight: 700, color: '#616161', wordBreak: 'keep-all' }}>
              {typeName}
            </p>
          </div>
        </foreignObject>
      </g>
    )
  }
}

import { ResponsiveBar } from '@nivo/bar'
import _ from 'lodash'
import { 학종_대학별유불리_ChartDataItem } from './Step7'
import WithRechartResponsive from 'hoc/WithRechartResponsive'

export default Step7Chart
