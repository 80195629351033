import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { schoolrecordSubjectLearningItem } from 'api/fetches/earlyd'
import {useEffect} from "react";
import {SelectChangeEvent} from "@mui/material";

interface gyoGwaCode {
    [key: string]: {
        label: string;
        value: string;
        list?: { label: string; value: string; type: string }[];
    };
}
const NormalGyoGwaInput = ({
                               index,
                               schoolrecordSubjectLearningItem,
                               onChangeNormalGyogwaValue,
                               gyoGwaCode
                           }: {
    index: number
    schoolrecordSubjectLearningItem: schoolrecordSubjectLearningItem
    onChangeNormalGyogwaValue: (index: number, type: string, value: any) => void
    gyoGwaCode:gyoGwaCode
}) => {

    useEffect(()=>{

    },[gyoGwaCode])
    return (
        <div className="d-flex align-items-center mb-3">
            {/*<p className="col-3 gyogwaTitle">{schoolrecordSubjectLearningItem.mainSubjectName}</p>*/}
            <div className="col-1 subjectSelectContainer">
                <FormControl className="formControl">
                    <Select
                        name="kor"
                        className="subjectSelect"
                        value={schoolrecordSubjectLearningItem.semester}
                        onChange={(e) => {
                            onChangeNormalGyogwaValue(index, 'semester', e.target.value)
                        }}
                    >
                        <MenuItem disabled value={'_'}>
                            <em>학기선택</em>
                        </MenuItem>
                        <MenuItem value={1}>
                            1학기
                        </MenuItem>
                        <MenuItem value={2}>
                            2학기
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="col-2 subjectSelectContainer">
                <FormControl className="formControl">
                    <Select
                        name="kor"
                        className="subjectSelect"
                        value={schoolrecordSubjectLearningItem.mainSubjectCode}
                        onChange={(e:any) => {
                            onChangeNormalGyogwaValue(index, 'mainSubjectCode', e.target.value)
                            onChangeNormalGyogwaValue(index, 'mainSubjectName', gyoGwaCode[e.target.value].label)
                        }}
                    >
                        <MenuItem disabled value={'_'}>
                            <em>과목선택</em>
                        </MenuItem>
                        {Object.keys(gyoGwaCode).map((key, index) => {
                            return (
                                <MenuItem key={index} {...gyoGwaCode[key]}>
                                    {gyoGwaCode[key].label}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className="col-2 subjectSelectContainer ">
                <FormControl style={{ border: 'none' }} variant="outlined" className="formControl">
                    <Select
                        name="kor"
                        className="subjectSelect"
                        value={schoolrecordSubjectLearningItem.subjectCode}
                        style={{ borderWidth: 0 }}
                        onChange={(e) => {
                            onChangeNormalGyogwaValue(index, 'subjectCode', e.target.value)
                            const gyogwaList = gyoGwaCode[schoolrecordSubjectLearningItem.mainSubjectCode].list??[];
                            let gyoGwaName ="";
                            for(var i=0;i<gyogwaList.length;i++) {
                                if(gyogwaList[i].value===e.target.value) {
                                    gyoGwaName = gyogwaList[i].label;
                                    break;
                                }
                            }
                            onChangeNormalGyogwaValue(index, 'subjectName',gyoGwaName)
                        }}
                    >
                        <MenuItem disabled value={'_'}>
                            <em>세부과목</em>
                        </MenuItem>
                        {schoolrecordSubjectLearningItem.mainSubjectCode !== '_' &&
                            gyoGwaCode[schoolrecordSubjectLearningItem.mainSubjectCode].list
                                ?.filter((subject) => {
                                    return subject.type === '0'
                                })
                                .map((subject) => {
                                    return (
                                        <MenuItem style={{ borderWidth: 0 }} key={subject.value} {...subject}>
                                            {subject.label}
                                        </MenuItem>
                                    )
                                })}
                    </Select>
                </FormControl>
            </div>
            <div className="col-1 ">
                <input
                    type="text"
                    value={schoolrecordSubjectLearningItem.unit}
                    onChange={(e) => onChangeNormalGyogwaValue(index, 'unit', e.target.value)}
                ></input>
            </div>
            <div className="d-flex col-3 align-items-center ">
                <input
                    type="text"
                    value={schoolrecordSubjectLearningItem.rawScore}
                    onChange={(e) => onChangeNormalGyogwaValue(index, 'rawScore', e.target.value)}
                ></input>
                /
                <input
                    value={schoolrecordSubjectLearningItem.subSubjectAverage}
                    onChange={(e) => onChangeNormalGyogwaValue(index, 'subSubjectAverage', e.target.value)}
                    type="text"
                ></input>
                ({' '}
                <input
                    value={schoolrecordSubjectLearningItem.standardDeviation}
                    onChange={(e) => onChangeNormalGyogwaValue(index, 'standardDeviation', e.target.value)}
                    type="text"
                ></input>
                )
            </div>
            <div className="col-2 d-flex align-items-center achievement ">
                <FormControl className="formControl">
                    <Select
                        name="kor"
                        className="select"
                        value={schoolrecordSubjectLearningItem.achievement}
                        onChange={(e) => {
                            onChangeNormalGyogwaValue(index, 'achievement', e.target.value)
                        }}
                    >
                        {['A', 'B', 'C', 'D', 'E'].map((achievement) => {
                            return (
                                <MenuItem value={achievement} key={achievement + 'normal'}>
                                    {achievement}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                (
                <input
                    value={schoolrecordSubjectLearningItem.studentsNum}
                    onChange={(e) => onChangeNormalGyogwaValue(index, 'studentsNum', e.target.value)}
                    type="text"
                ></input>
                )
            </div>
            <div className="col-1 ">
                <input
                    value={schoolrecordSubjectLearningItem.ranking}
                    onChange={(e) => onChangeNormalGyogwaValue(index, 'ranking', e.target.value)}
                    type="text"
                ></input>
            </div>

        </div>
    )
}

export default NormalGyoGwaInput