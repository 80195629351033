import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useEffect } from 'react'
import { getSeriesListFetch,  searchSeriesAllFetch, } from '../../../../../api/fetches/officer'
import StackX from 'components/common/StackX'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

/**
 *
 * @param callbackFunc
 * @param type 0: 소분류만 func 1: 전체 이벤트
 * @constructor
 */
const SeriesComponent = ({ callbackFunc, type }: any) => {
  const [grandSeries, setGrandSeries] = React.useState<string | undefined>('대분류')
  const [middleSeries, setMiddleSeries] = React.useState('중분류')
  const [rowSeries, setRowSeries] = React.useState('소분류')
  const [grandSeriesMenuList, setGrandSeriesMenuList] = React.useState(['대분류'])
  const [middleSeriesMenuList, setMiddleSeriesMenuList] = React.useState(['중분류'])
  const [rowSeriesMenuListNew, setRowSeriesMenuListNew] = React.useState([{label:'소분류'}])
  const [seriesListAll, setSeriesListAll] = React.useState<any>(null);
  useEffect(() => {
    fetchSeriesAll()
  }, [])


  //fetchAll된 시리즈에서 대분류 목록만 추출
  function extractGrandSeries(data: any[]): string[] {
    const grandSeriesSet = new Set<string>();
    data.forEach(item => {
      grandSeriesSet.add(item.grandSeries);
    });
    return Array.from(grandSeriesSet);
  }
  
  //fetchAll된 시리즈에서 대분류값에 해당하는 중분류목록만 리턴
  const extractMiddleSeries = (data: any[], grandSeries: string): string[] => {
      const filteredData = data.filter(item => item.grandSeries === grandSeries);

      const middleSeriesSet = new Set(filteredData.map(item => item.middleSeries));

      const middleSeriesList = Array.from(middleSeriesSet);

      return middleSeriesList;
  };

   //fetchAll된 시리즈에서 대분류값에 해당하는 소분류목록만 리턴
   const extractRowSeriesFromGrand = (data: any[], grandSeries: string): any[] => {
      return data.filter(item => item.grandSeries === grandSeries);
  };



  //fetchAll된 시리즈에서 대분류값, 중분류값에 해당하는 소분류목록만 리턴
  const extractRowSeries = (data: any[], grandSeries: string, middleSeries: string): any[] => {
    const filteredData = data.filter(item => item.grandSeries === grandSeries && item.middleSeries === middleSeries);

    const rowSeriesList: any[] = filteredData.map(item => ({
        label: item.rowSeries,
        ...item
    }));

    return rowSeriesList;
  };


  //fetchAll된 시리즈에서 소분류에 해당하는 항목의 grandSeries, middleSeries 을 리턴
  const findSeriesInfo = (data: any[], targetRowSeries: string): any | null => {

    const foundItem = data.find(item => item.rowSeries === targetRowSeries);

    if (!foundItem) {
        return null;
    }

    const { grandSeries, middleSeries } = foundItem;

    return { grandSeries, middleSeries };
  };

  const handleGrandChange = (event: SelectChangeEvent) => {
    setGrandSeries(event.target.value as string)
    setMiddleSeries('중분류')
    setRowSeries('소분류')

    //대분류 값에 따른 중분류 목록설정
    //getSeries('M', event.target.value as string, setMiddleSeriesMenuList)
    const middlist = extractMiddleSeries( seriesListAll,  event.target.value);
    middlist.unshift('중분류');
    setMiddleSeriesMenuList(middlist);


    //---------------------------------------------------
    //대분류 값에 따른 소분류 목록설정
    //---------------------------------------------------
    const rowlist = extractRowSeriesFromGrand( seriesListAll,  event.target.value);
    if(rowlist!=null){
      const tempList = rowlist.map((item:any)  => {
        const { rowSeries, ...rest } = item;
        return { label: rowSeries, ...rest };
      });
      tempList.unshift({label:'소분류'});
      setRowSeriesMenuListNew(tempList);
    }
    //---------------------------------------------------


    
    //---------------------------------------------------
    //대분류 선택시 소분류전체를 메뉴UI에 셋팅
    //---------------------------------------------------
    if(event.target.value=='대분류'){
      const tempList = seriesListAll.map((item:any)  => {
        const { rowSeries, ...rest } = item;
        return { label: rowSeries, ...rest };
      });
      setRowSeriesMenuListNew(tempList);
    }
    //---------------------------------------------------


    if (callbackFunc && type == 1) {
      callbackFunc({ grandSeries: event.target.value, middleSeries: middleSeries, rowSeries: rowSeries })
    }
  }
  const handleMiddleChange = (event: SelectChangeEvent) => {
    setMiddleSeries(event.target.value as string)
    setRowSeries('소분류')

    //---------------------------------------------------
    //중분류를 선택했을 경우 소분류전체를 메뉴UI에 셋팅
    //---------------------------------------------------
    if(event.target.value=='중분류'){
      const tempList = seriesListAll.map((item:any)  => {
        const { rowSeries, ...rest } = item;
        return { label: rowSeries, ...rest };
      });
      setRowSeriesMenuListNew(tempList);
    }
    //---------------------------------------------------


    //---------------------------------------------------
    //대,중 분류에 다른 소분류 목록 설정
    //---------------------------------------------------
    //getSeries('R', event.target.value as string, setRowSeriesMenuList)
    const rowlist = extractRowSeries( seriesListAll,  grandSeries??"" , event.target.value );
    {
      const tempList = rowlist.map((item:any)  => {
        const { rowSeries, ...rest } = item;
        return { label: rowSeries, ...rest };
      });
      tempList.unshift({label:'소분류'});
      setRowSeriesMenuListNew(tempList);
    }
    //---------------------------------------------------


    if (callbackFunc && type == 1) {
      callbackFunc({ grandSeries: grandSeries, middleSeries: event.target.value, rowSeries: rowSeries })
    }
  }
  // const handleRowChange = (event: SelectChangeEvent) => {

  //   setRowSeries(event.target.value as string)
  //   console.log('로우:'+event.target.value);
  //   //callback func가 존재할경우에만 사용가능,
  //   if (callbackFunc) {
  //     callbackFunc({ grandSeries: grandSeries, middleSeries: middleSeries, rowSeries: event.target.value })
  //   }
  // }

  const handleRowChange = (event: any) => {

    setRowSeries(event.target.textContent as string)

    //소분류가 속한 대,중 분류를 찾음
    let findItem = findSeriesInfo( seriesListAll , event.target.textContent );

    //callback func가 존재할경우에만 사용가능,
    if (callbackFunc) {
      
      //대분류,중분류를 미선택하고 바로 소분류 선택시에만 대분류,중분류 를 자동설정시킴
      if( findItem!=null && ( grandSeries=='대분류' || middleSeries == '중분류' ) ){

        //대분류값설정
        setGrandSeries(findItem.grandSeries)

        //대분류 값에 따른 중분류 목록설정
        const middlist = extractMiddleSeries( seriesListAll,  findItem.grandSeries);
        middlist.unshift('중분류');
        setMiddleSeriesMenuList(middlist);

        //중분류값설정
        setMiddleSeries(findItem.middleSeries)

        //대,중 분류에 다른 소분류 목록 설정
        const rowlist = extractRowSeries( seriesListAll,  findItem.grandSeries??"" , findItem.middleSeries );
        {
          const tempList = rowlist.map((item:any)  => {
            const { rowSeries, ...rest } = item;
            return { label: rowSeries, ...rest };
          });
          tempList.unshift({label:'소분류'});
          setRowSeriesMenuListNew(tempList);
        }
        
        callbackFunc({ grandSeries: findItem.grandSeries, middleSeries: findItem.middleSeries, rowSeries: event.target.textContent })
      }else{

        callbackFunc({ grandSeries: grandSeries, middleSeries: middleSeries, rowSeries: event.target.textContent })
      }
      
    }
  }


  //대중소 데이터 모두 조회 및 저장
  const fetchSeriesAll = async () => {
    try {
      // searchSeriesAll API 호출
      const seriesListDataAll : any = await searchSeriesAllFetch()

      // API 호출 결과를 상태로 저장
      setSeriesListAll(seriesListDataAll.data);

      if (Array.isArray(seriesListDataAll.data)) {

        const tempList = seriesListDataAll.data.map((item:any)  => {
          const { rowSeries, ...rest } = item;
          return { label: rowSeries, ...rest };
        });


        //소분류 메뉴UI에 셋팅
        setRowSeriesMenuListNew(tempList);

        //대분류 목록 전체 설정 
        const grandmenulists = extractGrandSeries(seriesListDataAll.data);
        grandmenulists.unshift('대분류');
        setGrandSeriesMenuList(grandmenulists);
        setGrandSeries('대분류');

      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <StackX>
      <FormControl
        sx={{ minWidth: '140px', mr: 1, width: 'fit-content', '& .MuiSelect-select': { color: '#000 !important' } }}
      >
        <Select value={grandSeries} onChange={handleGrandChange} size="small" sx={{ bgcolor: '#f0f0f0' }}>
          {grandSeriesMenuList.map((item: any, index: number) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl
        sx={{ minWidth: '140px', mr: 1, width: 'fit-content', '& .MuiSelect-select': { color: '#000 !important' } }}
      >
        <Select value={middleSeries} onChange={handleMiddleChange} size="small" sx={{ bgcolor: '#f0f0f0' }}>
          {middleSeriesMenuList.map((item: any, index: number) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            )
          })}
        </Select>{' '}
      </FormControl>

      <FormControl
        sx={{ minWidth: '140px', mr: 1, width: 'fit-content', '& .MuiSelect-select': { color: '#000 !important' } }}
      >
         <Autocomplete
          disablePortal
          id="rowseriescbo"
          options={rowSeriesMenuListNew}
          sx={{ width: 220 ,  background: "#f0f0f0" }}
          onChange={handleRowChange}
          value={{label:rowSeries}} 
          renderInput={(params) => <TextField {...params}  size="small" />}
        />
        {/* <Select value={rowSeries} onChange={handleRowChange} size="small" sx={{ bgcolor: '#f0f0f0' }}>
          {rowSeriesMenuList.map((item: any, index: number) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            )
          })}
        </Select>{' '} */}
      </FormControl>
    </StackX>
  )
}

//-----------------------------------------

export default SeriesComponent
