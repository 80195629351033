import { Skeleton, Stack, StackProps } from '@mui/material'
import { useEffect, useState } from 'react'

type Props = {}

const ChartSkeleton = (props: StackProps) => {
  const bars = [0.2, 0.3, 0.7, 0.2, 0.4, 0.9, 0.4].map((ratio, index) => (
    <Skeleton
      key={index}
      width={'40px'}
      variant="rounded"
      sx={{ bgcolor: 'grey.100', maxWidth: '40px' }}
      height={`${(ratio * 0.8 + 0.2) * 100}%`}
    />
  ))

  return (
    <Stack justifyContent={'center'} alignItems={'center'} height={'100%'} minHeight={'300px'} {...props}>
      <Stack direction={'row'} height={200} px={2} py={1} alignItems={'end'} gap={2}>
        <>{bars}</>
      </Stack>
    </Stack>
  )
}

export default ChartSkeleton
