import { Button, ButtonBase, Pagination, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  ColumnFiltersState,
  FilterFn,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  PaginationState,
  Row,
  ColumnDef,
} from '@tanstack/react-table'
import Center from 'components/common/Center'
import IndeterminateCheckbox from 'components/common/IndeterminateCheckbox'
import StackX from 'components/common/StackX'
import TableSkeleton from 'components/skeleton/TableSkeleton'
import { mainColor } from 'constants/style'
import React, { useEffect, useState } from 'react'
import { MuiStyleExtension, Setter } from 'types/utilType'
import { ReactComponent as CheckAllIcon } from 'assets/icon/select/check_all.svg'
import { ReactComponent as UncheckAllIcon } from 'assets/icon/select/uncheck_all.svg'
import TextField from '@mui/material/TextField'

type BaseTableProps<T> = {
  isLoading?: boolean
  columns: ColumnDef<T, any>[]
  data?: T[]
  sx?: MuiStyleExtension
  tableStyle?: MuiStyleExtension
  columnVisibility?: Record<string, boolean>
  filterFns?: Record<string, FilterFn<any>>
  enableSelection?: boolean
  rowSelection?: Record<string, boolean>
  setRowSelection?: Setter<Record<string, boolean>>

  columnFilters?: ColumnFiltersState
  setColumnFilters?: Setter<any[]>

  enablePagination?: boolean
  pageSize?: number

  getRowId?: (originalRow: T, index: number, parent?: Row<T>) => string
  searchInfo?: searchInfo
}

interface searchInfo {
  label: string // 검색 라벨 이름
  value: string // 검색 값 정보
  mode: boolean // 서치바 존재여부
}

// export const BaseTableContext = React.createContext<{
//   columnFilters: ColumnFiltersState
//   setColumnFilters: Setter<any[]>
// }>({
//   columnFilters: [],
//   setColumnFilters: () => {},
// })

export const useBaseTableFilter = (initialState?: ColumnFiltersState) => {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(initialState ?? [])
  return [columnFilters, setColumnFilters] as const
}

export const baseTableStyle1: MuiStyleExtension = {
  td: {
    borderTop: '1px solid #9A9A9A',
  },
  '& th, td': {
    textAlign: 'left',
    py: '12px',
  },
  th: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
    color: '#9A9A9A',
    paddingTop: '0 !important',
  },
  'th:not(:first-of-type)': {
    borderLeft: '2px solid #252525',
    position: 'relative',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  'th:not(:first-of-type)::before': {
    position: 'absolute',
    content: `''`,
    top: 0,
    left: '-2px',
    height: '4px',
    width: '8px',
    backgroundColor: '#fff',
  },
  'th:not(:first-of-type)::after': {
    position: 'absolute',
    content: `''`,
    bottom: 0,
    left: '-2px',
    height: '14px',
    width: '8px',
    backgroundColor: '#fff',
  },
  'td:not(:first-of-type)': {
    paddingLeft: '10px',
  },
}

export const baseTableStyle2: MuiStyleExtension = {
  td: {
    borderTop: '1px solid #e9e9e9',
    paddingLeft: '4px',
    fontWeight: 700,
  },

  th: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
    color: '#9A9A9A',
    paddingTop: '6px !important',
    borderLeft: '2px solid #252525',
    position: 'relative',
    paddingLeft: '4px',
    paddingBottom: '4px',
  },

  'th::before': {
    position: 'absolute',
    content: `''`,
    top: '0',
    left: '-4px',
    height: '6px',
    width: '8px',
    backgroundColor: '#fff',
  },
  'th::after': {
    position: 'absolute',
    content: `''`,
    bottom: '-2px',
    left: '-3px',

    height: '10px',
    width: '8px',
    backgroundColor: '#fff',
  },
  '& th, td': {
    textAlign: 'left',
    fontSize: '14px',
    padding: 1,
  },
}

const BaseTable = <T,>({
  isLoading,
  rowSelection,
  setRowSelection,
  filterFns,
  enableSelection = false,
  columns,
  data,
  sx = {},
  enablePagination = false,
  tableStyle,
  columnVisibility,
  pageSize = 20,
  columnFilters,
  setColumnFilters,
  getRowId,
  searchInfo = { label: '', value: '', mode: false },
}: BaseTableProps<T>) => {
  const [rowData, setRowData] = useState<any>([])
  // const rowData = data ?? []

  const paginationModel = enablePagination ? getPaginationRowModel() : undefined

  useEffect(() => {
    const t = data ?? []
    setRowData(t)
  }, [data])

  const [pageIndex, setPageIndex] = useState(0)
  useEffect(() => {
    setPageIndex(0)
  }, [rowData])

  useEffect(() => {
    table.setPageIndex(pageIndex)
  }, [pageIndex])

  const rowSelectionOption = enableSelection
    ? {
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
      }
    : {}

  const columnHelper = createColumnHelper<any>()
  const selectBoxColumnId = '%#_select_#%'
  const selectionColumn = columnHelper.display({
    id: selectBoxColumnId,
    header: ({ table }) =>
      rowData?.length > 0 ? (
        <Center>
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllPageRowsSelected(),
              indeterminate: table.getIsSomePageRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
              // getToggleAllRowsSelectedHandler(),
              //getToggleAllPageRowsSelectedProps
            }}
          />
        </Center>
      ) : (
        <></>
      ),
    cell: ({ row }) => (
      <Center>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </Center>
    ),
    size: 30,
  })

  const augmentedColumn = columns.concat(selectionColumn as any)
  augmentedColumn.forEach((item) => {
    if (!item.footer && rowData?.length === 0) {
      item.footer = () => {
        return <Box ml={0.5}>-</Box>
      }
    }
  })

  const augmentedVisivility = {
    ...columnVisibility,
    [selectBoxColumnId]: enableSelection,
  }

  const table = useReactTable({
    columns: augmentedColumn,
    data: rowData ?? [],
    filterFns: filterFns ?? {},
    getRowId: getRowId,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: paginationModel,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      rowSelection: rowSelection ?? {},
      columnFilters,
      columnVisibility: augmentedVisivility,
    },
    onColumnFiltersChange: setColumnFilters,
    ...rowSelectionOption,
  })

  useEffect(() => {
    table.setPageSize(pageSize)
  }, [])

  const pageNavigationBar = (
    <Center py={2}>
      <Pagination
        count={table.getPageCount()}
        page={pageIndex + 1}
        boundaryCount={2}
        onChange={(_, value) => {
          setPageIndex(value - 1)
        }}
      />
    </Center>
  )
  const onChangeCollegeData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const t = data?.filter((item: any): any => {
      const collegeName = item[searchInfo.value]
      if (collegeName.indexOf(event.target.value) > -1) return true
      return false
    })
    setRowData(t)
  }

  if (isLoading) {
    return <TableSkeleton height={'100%'} minHeight={'300px'} />
  }

  return (
    <Box
      sx={[
        (theme) => ({
          userSelect: 'none',
          overflowX: 'auto',
          [theme.breakpoints.down('sm')]: {
            '& td,th': {
              px: 1,
              py: 0.5,
              wordBreak: 'keep-all',
              fontSize: '14px !important',
            },
          },
        }),
        tableStyle ?? baseTableStyle1,
        sx,
      ]}
    >
      {enableSelection && (
        <StackX justifyContent={searchInfo?.mode ? 'space-between' : 'end'} mb={1} alignItems={'center'}>
          {searchInfo?.mode && (
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px' }}>
              <Typography
                component={'span'}
                sx={{ color: '#000000', textDecoration: 'underline', userSelect: 'none', marginRight: '20px' }}
                variant="D3_bold, 16, 24"
              >
                {searchInfo.label}
              </Typography>
              <TextField
                id="outlined-basic"
                label="검색"
                variant="outlined"
                size="small"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeCollegeData(event)}
              />
            </Box>
          )}
          <Box>
            <Typography component={'span'} sx={{ color: '#00CA8D', userSelect: 'none' }} variant="D3_bold, 16, 24">
              {`${table.getSelectedRowModel().rows.length}`}
            </Typography>
            <Typography component={'span'} sx={{ color: '#C2C2C2', userSelect: 'none' }} variant="D3_bold, 16, 24">
              {`/${table.getCoreRowModel().rows.length}`}
            </Typography>
            <Button
              hidden={rowData.length < 1}
              onClick={() => {
                table.toggleAllRowsSelected()
              }}
              disableElevation
              variant="contained"
              sx={{
                ml: 2,
                px: '8px',
                py: '4px',
                borderRadius: '10px',
                border: '1px solid var(--gray-06-e-9-e-9-e-9, #E9E9E9)',
                background: 'var(--gray-07-f-4-f-4-f-4, #F4F4F4)',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
              }}
              size="small"
              disableRipple
            >
              {table.getIsAllRowsSelected() ? <UncheckAllIcon /> : <CheckAllIcon />}

              <Typography
                ml={'4px'}
                variant="D2_exBold, 16, 24"
                sx={{
                  color: '#252525',

                  fontSize: '13px',
                  lineHeight: '24px',
                }}
              >
                전체 {table.getIsAllRowsSelected() ? '해제' : '선택'}
              </Typography>
            </Button>
          </Box>
        </StackX>
      )}

      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </th>
                )
              })}
            </tr>
            /**
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
             */
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
        {rowData?.length === 0 && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <td key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
      {enablePagination && pageSize <= rowData?.length && pageNavigationBar}
    </Box>
  )
}

export const stuffRowData = (arr: any[], count: number, columns?: any, stuffContents?: React.ReactNode) => {
  let ret = [...arr]
  for (let i = 0; i < count - arr.length; i++) {
    const obj: any = {}
    for (const { accessorKey } of columns) {
      obj[accessorKey] = stuffContents ?? '-'
    }
    ret.push(obj)
  }
  return ret
}

export function getDefaultTurtleTableColumnModel(columns: string[]) {
  return columns.map((column) => ({
    accessorKey: column,
    header: column,
    cell: (info: any) => info.renderValue(),
  })) as any as Column[]
}

type Column = {
  accessorKey: string | ((x: any) => any) | number
  header: string
  cell: (info: any) => any
}

export default BaseTable
