import { Box, SxProps, Typography } from '@mui/material'
import { pageContentConstraint } from 'constants/style'
import { useLocation } from 'react-router-dom'

const LocationIndicator = ({ sx }: { sx?: SxProps }) => {
  const { pathname } = useLocation()
  const [firstClass, secondClass, thirdClass] = pathname.split('/').filter((x) => x !== '')

  const pathMap: Record<string, any> = {
    prototype: { $label: '학종' },

    gyoGwa: {
      $label: '교과',
      searchUniv: { $label: '대학검색' },
    },
    hakJong: { $label: '학종' },
    nonSul: {
      $label: '논술',
      munGwa: { $label: '문과논술' },
      liGwa: { $label: '이과논술' },
      uiChiHanYacSu: { $label: '의치한약수 논술' },
    },
    strategy: {
      $label: '지원전략',
      interestUniv: { $label: '나의 관심대학' },
      combination: { $label: '조합' },
    },
    inputAndAnalysis: {
      $label: '입력과분석',
      howTo: { $label: '거북스쿨 사용법' },
      hakJongInput: { $label: '생기부 입력' },
      gradeInput: { $label: '모의고사 성적 입력' },
      departmentCompatibility: { $label: '계열 적합성 진단' },
      saJungDan: { $label: '사정관 평가 신청' },
      nonSubject: { $label: '비교과 분석' },
    },
  }

  const secondPathLabel = pathMap[secondClass]?.['$label']
  const thirdPathLabel = pathMap[secondClass]?.[thirdClass]?.['$label']

  return (
    <Box sx={{ ...sx, display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          ...pageContentConstraint,
          height: 64,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            '& .label': {
              fontSize: '12px',
              fontFamily: 'NanumSquareOTF',
              fontWeight: 700,
              lineHeight: '18px',
              color: '#9A9A9A',
            },
            '& .label:last-child': {
              color: 'black',
            },
          }}
        >
          <Typography variant="D6_bold, 12, 18" sx={{ color: '#9A9A9A' }}>
            홈
          </Typography>
          {secondPathLabel && (
            <Typography variant="D6_bold, 12, 18" sx={{ color: '#9A9A9A' }}>
              &gt;
            </Typography>
          )}
          {secondPathLabel && thirdClass != 'howTo' &&(
            <Typography variant="D6_bold, 12, 18" sx={{ color: thirdPathLabel ? '#9A9A9A' : 'black' }}>
              {secondPathLabel}
            </Typography>
          )}
          {secondPathLabel && thirdClass === 'howTo' && (
            <Typography variant="D6_bold, 12, 18" sx={{ color: thirdPathLabel ? '#9A9A9A' : 'black' }}>
              {thirdPathLabel}
            </Typography>
          )}
          {thirdPathLabel && thirdClass != 'howTo' && (
            <Typography variant="D6_bold, 12, 18" sx={{ color: '#9A9A9A' }}>
              &gt;
            </Typography>
          )}
          {thirdPathLabel && thirdClass != 'howTo' && <Typography variant="D6_bold, 12, 18">{thirdPathLabel}</Typography>}
        </Box>
      </Box>
    </Box>
  )
}

export default LocationIndicator
