import { BoxProps, Box, Typography } from '@mui/material'
import React from 'react'

type Props = {}

function MessageBox({ title = '', subtitle = '', ...props }: BoxProps & { title?: string; subtitle?: string }) {
  return (
    <Box
      style={{ height: '100%' }}
      {...props}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography variant="H5_regular, 24, 32" fontSize={22}>
        {title}
      </Typography>
      <Typography variant="D4_regular, 16, 24">{subtitle}</Typography>
    </Box>
  )
}

export default MessageBox
